var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'src/client/routing/routes';
import { CreateWorkItem } from '../WorkItems/createWorkItem/createWorkItem';
import styles from './footer.less';
import amex from './img/amex.svg';
import apay from './img/applepay.svg';
import gpay from './img/gpay.svg';
import mastercard from './img/mastercard.svg';
import trustpilot from './img/trustpilot.svg';
import visa from './img/visa.svg';
import { FollowUs } from '@components/FollowUs/followUs';
// https://gitlab.com/ticketex/ticketex/-/issues/2148
// This section to be removed until we have valid choices, to be removed for now
// const countryOptions = [
//   {
//     key: 'UK',
//     value: 'UK',
//     text: 'UK',
//   },
// ];
// const languageOptions = [
//   {
//     key: 'English',
//     value: 'English',
//     text: 'English',
//   },
// ];
// const currencyOptions = [
//   {
//     key: 'GBP',
//     value: 'GBP',
//     text: 'GBP',
//   },
//   {
//     key: 'EUR',
//     value: 'EUR',
//     text: 'EUR',
//   },
// ];
export default function Footer(_a) {
    var _b;
    var _c;
    var hideFooterMargin = _a.hideFooterMargin;
    var location = useLocation();
    var year = new Date().getFullYear();
    var trending = useSelector(function (gs) { return gs.performer.promotedPerformers; });
    var settings = useSelector(function (gs) { var _a; return (_a = gs.userSettings.userSettings) !== null && _a !== void 0 ? _a : gs.userSettings.globalSettings; });
    var renderTrustpilotSection = function () {
        return React.createElement(React.Fragment, null,
            React.createElement("h3", null, " Review Us"),
            React.createElement("a", { href: "https://uk.trustpilot.com/evaluate/beeyay.com", target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: trustpilot, alt: "TrustPilot", title: "TrustPilot", className: styles.trustpilot })));
    };
    return (React.createElement("footer", __assign({ className: cn(styles.footer, (_b = {},
            _b[styles.hideFooterMargin] = hideFooterMargin,
            _b)) }, t('Footer')),
        React.createElement("section", null,
            React.createElement(FollowUs, { header: React.createElement("h3", null, "Follow Us") }),
            ((_c = settings === null || settings === void 0 ? void 0 : settings.uiSettings) === null || _c === void 0 ? void 0 : _c.trustpilotFooter) && renderTrustpilotSection()),
        React.createElement("section", { className: styles.aboutUs },
            React.createElement("h3", null, "About Us"),
            React.createElement("nav", null,
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.companyDetailsPage() }, "Company Details")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.whyUseBeeyay() }, "Why use Beeyay?")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.beeyayGuarantee() }, "Beeyay Guarantee")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.disputeResolutionPolicy() }, "Dispute Resolution Policy")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.coronavirusAdvicePage() }, "COVID-19 (Coronavirus) Advice")))),
        React.createElement("section", null,
            React.createElement("h3", null, "Trending"),
            React.createElement("nav", null, trending.map(function (performer) { return (React.createElement("li", { key: performer.slug },
                React.createElement(Link, { to: routes.performer(performer.id, performer.slug) }, performer.name))); }))),
        React.createElement("section", { className: styles.help },
            React.createElement("h3", null, "Help"),
            React.createElement("nav", null,
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.faq() }, "FAQ")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.howToBuy() }, "How to Buy tickets")),
                React.createElement("li", null,
                    React.createElement(Link, { to: routes.howToSell() }, "How to Sell tickets")),
                React.createElement("li", null,
                    React.createElement(CreateWorkItem, { linkProps: { className: styles.link }, baseRoute: routes.revertCreateUserWorkItem(location.pathname), wiType: 'fgeneric', biId: null, fromFooter: true })))),
        React.createElement("section", null,
            React.createElement("h3", null, "Payment Methods"),
            React.createElement("div", { className: styles.payments },
                React.createElement("img", { src: visa, alt: "visa", title: "visa" }),
                React.createElement("img", { src: mastercard, className: styles.mastercard, alt: "mastercard", title: "mastercard" }),
                React.createElement("img", { src: amex, className: styles.amex, alt: "AmEx", title: "American Express" }),
                React.createElement("img", { src: apay, alt: "Apple Pay", title: "Apple Pay" }),
                React.createElement("img", { src: gpay, alt: "GPay", title: "Google Pay" }))),
        React.createElement("p", null,
            year,
            " \u00A9 Beeyay Limited. All rights reserved. Use of this website constitutes acceptance of the",
            ' ',
            React.createElement(Link, { to: routes.terms() }, "Terms of Service"),
            ",",
            ' ',
            React.createElement(Link, { to: routes.privacyPolicy() }, "Privacy "),
            " and",
            ' ',
            React.createElement(Link, { to: routes.cookiesPolicy() }, " Cookies Policy"))));
}

import React from 'react';
import styles from './biography.less';
import ShowMoreText from 'react-show-more-text';
import cn from 'classnames';
export var Biography = function (_a) {
    var _b;
    var details = _a.details, bioRef = _a.bioRef, className = _a.className;
    return (details.description ?
        React.createElement("div", { className: cn(styles.biography, className), ref: bioRef },
            React.createElement("h1", null,
                "About ",
                details.name),
            React.createElement(ShowMoreText, { expanded: true, more: '', less: '', keepNewLines: true }, (_b = details === null || details === void 0 ? void 0 : details.description) !== null && _b !== void 0 ? _b : ''))
        : null);
};

import isString from 'lodash/isString';
import { hasProperty } from './helpers/hasProperty';
import get from 'lodash/get';
var aggregatedErrors = {
    AUTH_MODEL_AGGREGATED_ERROR: function (agg) { var _a; return (_a = agg[0]) !== null && _a !== void 0 ? _a : 'Something went wrong, please try again later.'; },
};
var isSupportedAggregatedErrorCode = function (c) {
    return Object.keys(aggregatedErrors).includes(c);
};
export var portalErrorCodes = {
    STRIPE_ERROR_DECLINED: function () { return 'Sorry, we have been unable to process a payment with the card details provided. Please try an alternative payment card or contact your card issuer for more information.'; },
    STRIPE_ERROR_INCORRECT_CARD_DETAIL: function () { return 'Sorry, we have been unable to process a payment with the details provided. Please review that the details you have entered are correct and try again.'; },
    STRIPE_ERROR_INCORRECT_RETRY_THEN_DECLINE: function () { return 'Sorry, we have been unable to process a payment with the card details provided. Please try again or try an alternative payment card.'; },
    STRIPE_ERROR_UNKNOW: function () { return 'Sorry, we have been unable to process a payment. Please try again or try an alternative payment card.'; },
    STRIPE_ERROR_REQUIRES_PAYMENT_METHOD: function () { return 'Sorry, we have been unable to process a payment. Please try again or try an alternative payment card.'; },
    STRIPE_ERROR_UNSUPPORTED_ACTION_REQUIRED: function () { return 'Sorry, we have been unable to process a payment with the card details provided. Please try an alternative payment card or contact your card issuer for more information.'; },
    ValidationFailed: function (message) {
        if (message === null || message === void 0 ? void 0 : message.match('tickets quantity is invalid')) {
            return 'We\'re sorry but your listing could not be updated, it looks like a reservation has been created in the meantime.';
        }
        if (message === null || message === void 0 ? void 0 : message.match('Cannot update listing with given quantity and ticket split')) {
            return 'We\'re sorry but your listing could not be updated, the available quantity is not valid for your listing ticket split.';
        }
    }
};
var isSupportedPortalErrorCode = function (c) {
    return Object.keys(portalErrorCodes).includes(c);
};
export var authErrorCodes = {
    AUTH_MODEL_ERROR: null,
    AUTH_ERROR_INCORRECT_PASSWORD: 'The existing password you have entered is incorrect',
};
var isSupportedAuthErrorCode = function (c) {
    return Object.keys(authErrorCodes).includes(c);
};
export var stripeCodes = {
    payment_intent_authentication_failure: 'We have been unable to process your card payment. Please check that the details you have entered are correct and try again or alternatively please try another card.',
};
var isSupportedStripeErrorCode = function (c) {
    return Object.keys(stripeCodes).includes(c);
};
export var tryMapError = function (e) {
    var _a, _b;
    if (e == null || isString(e)) {
        return undefined;
    }
    if (hasProperty(e, 'errorCode')) {
        var code = e.errorCode;
        if (isSupportedAggregatedErrorCode(code)) {
            var errors = ((_a = get(e, 'errors')) !== null && _a !== void 0 ? _a : []).map(tryMapError).filter(function (em) { return em; });
            return aggregatedErrors[code](errors);
        }
        if (isSupportedPortalErrorCode(code)) {
            return portalErrorCodes[code](get(e, 'message'));
        }
        if (isSupportedAuthErrorCode(code)) {
            return (_b = authErrorCodes[code]) !== null && _b !== void 0 ? _b : get(e, 'message');
        }
    }
    if (hasProperty(e, 'code')) {
        var code = e.code;
        if (isSupportedStripeErrorCode(code)) {
            return stripeCodes[code];
        }
    }
    if (hasProperty(e, 'data')) {
        return tryMapError(e.data);
    }
};

import React from 'react';
import { Page } from '../Page';
import { Features } from './features';
import styles from './homePage.less';
import Performers from './performers';
import { CategoriesNavigationHome } from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigationHome';
import { ScrollToTopOnMount } from '../components/scrollToTopOnMount/scrollToTopOnMount';
export var HomePage = function () {
    return (React.createElement(Page, { search: 'expanded', className: styles.homePage, noMargin: true },
        React.createElement(ScrollToTopOnMount, null),
        React.createElement(CategoriesNavigationHome, null),
        React.createElement("h2", { className: styles.Header }, "Hottest Tickets"),
        React.createElement(Performers, null),
        React.createElement(Features, null)));
};
export default HomePage;

import { Seo } from '@components/seo';
import React from 'react';
import { hasNoIndex } from '@containers/DetailsPages/pages/seo/common';
export var LocationSeo = function (_a) {
    var details = _a.details, events = _a.events, eventsLoading = _a.eventsLoading, canonicalUrl = _a.canonicalUrl;
    var description = "Tickets for " + (details === null || details === void 0 ? void 0 : details.name) + " events available now! Buy and Sell tickets for Concerts, Festivals, Theatre & Comedy and Sports and more. The tickets you want at the price you choose on Beeyay. All purchases 100% protected by the Beeyay Guarantee.";
    var title = "Tickets for all " + (details === null || details === void 0 ? void 0 : details.name) + " events - concerts, sports, theatre & comedy and festivals - Beeyay";
    var noIndex = hasNoIndex(description, events, eventsLoading);
    return (React.createElement(Seo, { title: title, description: description, keywords: (details === null || details === void 0 ? void 0 : details.name) + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", canonical: canonicalUrl, noIndex: noIndex }));
};

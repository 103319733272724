import { useEffect, useRef } from 'react';
import { hasInput, input, Morph, nonNull, parent } from './morph';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
export function useMorpher(heroRef, searchRef, search) {
    var morpher = useRef(null);
    var blockMorpher = useRef(false);
    var customBreakPoint = useViewportInfo().customBreakPoint;
    useEffect(function () {
        var removeMorpher = function () {
            if (morpher.current) {
                // Morpher explode if resizing is super quick. It produces lot of e2e issues which I as dump developer couldn't fix for months.
                // in commit #8909065de you can git blame all forced changes
                // morpher.current.resetStyles();
                morpher.current = null;
            }
        };
        var onResize = function () {
            removeMorpher(); // Make sure we re-measure elements after reflow after resize is complete.
            // this flag prevents scroll event firing right after resize event, but before the timeout.
            blockMorpher.current = true;
            setTimeout(function () {
                blockMorpher.current = false;
                onScroll();
            });
        };
        var onScroll = function () {
            function container(x) {
                return parent(parent(parent(x)));
            }
            function icon(x) {
                return nonNull(container(x).querySelector('i'));
            }
            if (!heroRef || !searchRef || customBreakPoint === 'smallHomeNavigation' ||
                blockMorpher.current || !hasInput(heroRef) || !hasInput(searchRef)) {
                return;
            }
            if (!morpher.current) {
                morpher.current = new Morph(input(nonNull(heroRef)), input(nonNull(searchRef)), {
                    props: ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom', 'fontSize', 'lineHeight', 'height', [icon, 'right'], [icon, 'fontSize']],
                    onUpdate: function (from, to) {
                        if (document.activeElement === to) {
                            from.focus();
                        }
                        container(from).style.opacity = '1';
                        container(to).style.opacity = '0';
                    },
                    onEnd: function (from, to) {
                        container(from).style.opacity = '0';
                        container(to).style.opacity = '1';
                        if (document.activeElement === from) {
                            to.focus();
                        }
                    },
                });
            }
            morpher.current.update();
        };
        if (search === 'expanded') {
            window.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onResize);
            onScroll();
            return function () {
                window.removeEventListener('scroll', onScroll);
                window.removeEventListener('resize', onResize);
                removeMorpher();
            };
        }
    }, [search, heroRef, searchRef, customBreakPoint]);
}

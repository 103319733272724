import { Icon } from '@components/icon';
import cn from 'classnames';
import React, { useCallback } from 'react';
import styles from './files.less';
function FileItem(_a) {
    var _b;
    var file = _a.file, idx = _a.idx, downloadFile = _a.downloadFile, deleteFile = _a.deleteFile;
    var handleDownload = useCallback(function () { return downloadFile && downloadFile(file, idx); }, [downloadFile, file, idx]);
    var handleDelete = useCallback(function () { return deleteFile && deleteFile(file.id); }, [file, deleteFile]);
    return (React.createElement("div", { className: styles.fileInfo },
        React.createElement("span", { className: cn((_b = {}, _b[styles.download] = !!downloadFile, _b)), onClick: handleDownload }, file.name),
        deleteFile && React.createElement(Icon, { icon: 'close', className: styles.deleteIcon, width: 10, onClick: handleDelete })));
}
export function Files(_a) {
    var files = _a.files, downloadFile = _a.downloadFile, deleteFile = _a.deleteFile;
    return (React.createElement("div", { className: styles.filesList }, files.map(function (file, idx) { return React.createElement(FileItem, { key: file.id, idx: idx, file: file, downloadFile: downloadFile, deleteFile: deleteFile }); })));
}

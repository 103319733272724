import { Seo } from '@components/seo';
import React from 'react';
import { CATEGORY_TYPES } from '@logic/categories';
import { hasNoIndex } from '@containers/DetailsPages/pages/seo/common';
import { routes } from 'src/client/routing/routes';
var getCategoryTitle = function (type) {
    switch (type) {
        case CATEGORY_TYPES.sport:
            return 'Sports tickets - Buy and Sell - Football, Rugby, Tennis and more - Beeyay';
        case CATEGORY_TYPES.theatre_and_comedy:
            return 'Theatre & Comedy tickets - Buy and Sell - Musicals, Plays, Stand-up and more - Beeyay';
        case CATEGORY_TYPES.festival:
            return 'Festival tickets - Buy and Sell - Rock & Pop, Electronic and more - Beeyay';
        case CATEGORY_TYPES.concert:
        default:
            return 'Concert tickets - Buy and Sell - all music genres; Rock & Pop, R&B, Hip-Hop and more - Beeyay';
    }
};
var getCategoryDescription = function (type) {
    switch (type) {
        case CATEGORY_TYPES.sport:
            return 'Tickets for live sport available now - see your favourite team! Buy and Sell tickets for Six Nations Rugby, Wimbledon Tennis and more. The tickets you want at the price you choose on Beeyay. All purchases 100% protected by the Beeyay Guarantee.';
        case CATEGORY_TYPES.theatre_and_comedy:
            return 'Tickets for Theatre & Comedy available now - see your favourite musicals, plays and comedians! The tickets you want at the price you choose on Beeyay. All purchases 100% protected by the Beeyay Guarantee.';
        case CATEGORY_TYPES.festival:
            return 'Tickets for many Festivals available now. Buy and sell tickets for Reading, Leeds, SW4, Download and more. The tickets you want at the price you choose on Beeyay. All purchases 100% protected by the Beeyay Guarantee.';
        case CATEGORY_TYPES.concert:
        default:
            return 'Tickets for live music available now - see your favourite artists! Buy and Sell tickets for Rock & Pop, R&B, Hip-Hop and more. The tickets you want at the price you choose on Beeyay. All purchases 100% protected by the Beeyay Guarantee.';
    }
};
export var CategorySeo = function (_a) {
    var details = _a.details, events = _a.events, eventsLoading = _a.eventsLoading;
    if (!details) {
        return null;
    }
    var description = getCategoryDescription(details.name);
    var title = getCategoryTitle(details.name);
    var noIndex = hasNoIndex(details.description, events, eventsLoading);
    return (React.createElement(Seo, { title: title, description: description, keywords: details.name + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", canonical: routes.category(details.localId), noIndex: noIndex }));
};

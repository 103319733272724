import memoize from 'lodash/memoize';
import upperFirst from 'lodash/upperFirst';
var empty = {}; // Returns a test handle for E2E tests.
export var t = memoize(function (handle) {
    if (!process.env.RAZZLE_TEST_HANDLES && process.env.NODE_ENV !== 'development') {
        return empty;
    }
    var handleName = Array.isArray(handle) ? handle.join('-') : handle;
    return {
        'data-test': upperFirst(handleName),
    };
});

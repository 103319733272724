var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Input } from '@components/forms';
import cn from 'classnames';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './clearableInput.less';
export var ClearableInput = function (props) {
    var onClear = props.onClear, icon = props.icon, extraAction = props.extraAction, className = props.className, newProps = __rest(props, ["onClear", "icon", "extraAction", "className"]);
    return (React.createElement(Input, __assign({ iconPosition: 'left', className: cn(className, styles.input) }, newProps),
        React.createElement(Icon, { name: icon }),
        React.createElement("input", { autoComplete: 'new-password', "aria-autocomplete": 'none' }),
        React.createElement("div", { className: styles.inputActions },
            extraAction,
            React.createElement(Icon, { className: newProps.value ? '' : 'hidden', link: true, name: 'remove', onClick: onClear }))));
};

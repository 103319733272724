import React from 'react';
import { Link } from '@components/forms';
export var BreadcrumbLink = function (_a) {
    var values = _a.values, route = _a.route, separator = _a.separator, extraText = _a.extraText;
    // it can be more generic, but we're supporting max two params routes so atm seems to be good enough
    var parametrizedRoute = values === null
        ? route
        : values.length > 1
            ? function () { return route(values[0].localId, values[1].localId); }
            : function () { return route(values[0].localId); };
    var linkValue = values === null ? 'Home' : values[(values === null || values === void 0 ? void 0 : values.length) - 1].name;
    var breadcrumbText = linkValue + " " + (extraText ? extraText : '');
    return (React.createElement(React.Fragment, null,
        !!separator && React.createElement("span", null,
            " ",
            separator,
            " "),
        React.createElement(Link, { to: parametrizedRoute() }, breadcrumbText)));
};
export default BreadcrumbLink;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import { mapEventSearchToEventSummary, } from '@logic/events';
import { formatTime } from '@logic/helpers/date';
import { getBuyCosts, getSellCosts } from '@logic/transactions';
import { formatVenueData } from '@logic/venue';
import cn from 'classnames';
import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BUY_ONLY, eventLinkSlugToEventRoute } from 'src/client/routing/routes';
import styles from './results.less';
import sharedStyles from '@containers/DetailsPages/components/eventsTable/details.less';
import { PriceButton } from '@components/priceButton/priceButton';
import { DateDisplay } from '@components/date';
import { TxLazyImage } from '@components/image/lazyImage';
import { OnSaleDate } from './onSaleDate/onSaleDate';
import { MarketDataLink } from '@containers/MarketData';
import { Loader } from 'semantic-ui-react';
export var Results = function (props) {
    var noDataMessage = props.noDataMessage, getImage = props.getImage, isLoading = props.isLoading, thickLine = props.thickLine, events = props.events, buyOnly = props.buyOnly, tableVariant = props.tableVariant;
    var userSettings = useSelector(function (gs) { var _a; return (_a = gs.userSettings.userSettings) !== null && _a !== void 0 ? _a : gs.userSettings.globalSettings; });
    if (isLoading && !events.length || !userSettings) {
        return (React.createElement("div", { className: styles.loading },
            React.createElement(Loader, { active: true })));
    }
    if (!events.length) {
        return (React.createElement("div", __assign({ className: styles.message }, t('Message')), noDataMessage));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: tableVariant !== 'advanced' ? sharedStyles.resultsContainer : sharedStyles.resultsAdvancedSearch }, events.map(function (event, idx) {
            var _a;
            return (React.createElement(ResultRow, { key: event.id, idx: idx, tableVariant: tableVariant, className: cn((_a = {}, _a[styles.thickLine] = thickLine && idx === 0, _a)), event: event, buyOnly: buyOnly, getImage: getImage, userSettings: userSettings }));
        })),
        (isLoading || !userSettings) && React.createElement("div", { className: styles.loading },
            React.createElement(Loader, { active: true }))));
};
var PriceLink = function (_a) {
    var slug = _a.slug, price = _a.price, transactionSide = _a.transactionSide, round = _a.round, narrow = _a.narrow;
    var link = eventLinkSlugToEventRoute(slug, transactionSide);
    return (React.createElement(Link, { to: link },
        React.createElement(PriceButton, { price: price, transactionSide: transactionSide, center: true, round: round, narrow: narrow })));
};
var ResultRow = function (_a) {
    var _b, _c, _d, _e;
    var event = _a.event, getImage = _a.getImage, idx = _a.idx, tableVariant = _a.tableVariant, userSettings = _a.userSettings, buyOnly = _a.buyOnly;
    var date = (React.createElement(DateDisplay, { date: event.eventDate, timezone: event.timezone, column: true }));
    var minSellPrice = useMemo(function () {
        if (!event.minSellPrice) {
            return null;
        }
        var ticketFee = getBuyCosts(event.minSellPrice.value, 'any', 1, userSettings, false).ticketFee;
        return {
            value: event.minSellPrice.value + ticketFee,
            currency: event.minSellPrice.currency,
        };
    }, [event.minSellPrice, userSettings]);
    var maxBuyPrice = useMemo(function () {
        if (!event.maxBuyPrice) {
            return null;
        }
        var ticketFee = getSellCosts(event.maxBuyPrice.value, 'any', 1, userSettings, false).ticketFee;
        return {
            value: event.maxBuyPrice.value - ticketFee,
            currency: event.maxBuyPrice.currency,
        };
    }, [event.maxBuyPrice, userSettings]);
    return (React.createElement("div", __assign({}, t('ResultsRow'), { className: cn((_b = {}, _b[sharedStyles.venueTable] = tableVariant === 'simple', _b), (_c = {}, _c[sharedStyles.performerTable] = tableVariant === 'simpleWithLocationFilter', _c), (_d = {}, _d[sharedStyles.advancedTable] = tableVariant === 'advanced', _d)) }),
        React.createElement(Link, { to: eventLinkSlugToEventRoute(event.eventLinkSlug, 'buy'), className: sharedStyles.date }, event.status === 'postponed' ?
            React.createElement("div", { className: styles.eventPostponed },
                React.createElement("p", null, "EVENT POSTPONED"),
                React.createElement("p", null, "NEW DATE TBA"))
            : date),
        React.createElement(Link, { to: eventLinkSlugToEventRoute(event.eventLinkSlug, 'buy'), className: sharedStyles.image },
            React.createElement("div", { className: styles.imageWrapper },
                React.createElement(TxLazyImage, { useLargeAspectRatio: true, src: getImage(event), mobileSrc: getImage(event), className: styles.mobileRowImage, visibleByDefault: idx <= 2 }))),
        React.createElement(Link, { to: eventLinkSlugToEventRoute(event.eventLinkSlug, 'buy'), className: sharedStyles.location },
            React.createElement("div", { className: sharedStyles.info },
                React.createElement("div", { className: sharedStyles.time }, formatTime(event.eventDate, event.timezone)),
                React.createElement("div", { className: sharedStyles.event },
                    event.venueCity,
                    " - ",
                    event.name),
                React.createElement("div", { className: sharedStyles.venue }, formatVenueData(event)))),
        React.createElement("div", __assign({ className: cn(styles.onSaleDateCell, sharedStyles.onSaleDate) }, t('OnSaleDate')),
            React.createElement(OnSaleDate, { date: event.onSaleDate })),
        React.createElement("div", { className: cn(sharedStyles.buyPrice, (_e = {}, _e[sharedStyles.spannedBuyPrice] = buyOnly, _e)) },
            React.createElement(PriceLink, { slug: event.eventLinkSlug, price: minSellPrice, transactionSide: buyOnly ? BUY_ONLY : 'buy', round: "up", narrow: true })),
        !buyOnly && React.createElement("div", { className: sharedStyles.sellPrice },
            React.createElement(PriceLink, { slug: event.eventLinkSlug, price: maxBuyPrice, transactionSide: "sell", round: "down", narrow: true })),
        React.createElement("div", __assign({ className: cn(styles.marketData, sharedStyles.marketData) }, t('MarkedDataLink')),
            React.createElement(MarketDataLink, { icon: true, event: mapEventSearchToEventSummary(event), section: undefined, listingType: 'sell' }))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Link } from 'react-router-dom';
import React from 'react';
import styles from '@containers/DetailsPages/components/lastNextEvent/lastNextEvent.component.less';
import { mapCategoryToEventOrVenueType } from '../eventsTable/utils/utils';
import { eventLinkSlugToEventRoute, routes } from 'src/client/routing/routes';
import { formatDisplayDate } from '@logic/helpers/date';
import { useLocation } from 'react-router';
import { t } from '@containers/helpers/tests';
export var LastNextEventComponent = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var lastNextEvent = _a.lastNextEvent, eventCategory = _a.eventCategory;
    var location = useLocation();
    return (React.createElement("div", null,
        React.createElement("div", __assign({ className: styles.lastNextEvent }, t('LastNextEvent')),
            React.createElement("h5", { className: styles.sectionTitle },
                "Next ",
                mapCategoryToEventOrVenueType(eventCategory)),
            ((_b = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.nextEvent) === null || _b === void 0 ? void 0 : _b.eventLinkSlug)
                ?
                    React.createElement(Link, { to: eventLinkSlugToEventRoute((_c = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.nextEvent) === null || _c === void 0 ? void 0 : _c.eventLinkSlug, 'buy') },
                        React.createElement("div", null,
                            formatDisplayDate((_d = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.nextEvent) === null || _d === void 0 ? void 0 : _d.eventDate),
                            " (", (_e = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.nextEvent) === null || _e === void 0 ? void 0 :
                            _e.venueCity,
                            ")"))
                :
                    React.createElement("div", null,
                        " Hmm, we can't find anything.",
                        React.createElement(Link, { className: styles.requestEvent, to: routes.newEventRequest(location.pathname), rel: 'nofollow' }, "\u00A0 Are we missing something?"))),
        React.createElement("div", { className: styles.lastNextEvent },
            React.createElement("h5", { className: styles.sectionTitle },
                "Last ",
                mapCategoryToEventOrVenueType(eventCategory)),
            ((_f = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.lastEvent) === null || _f === void 0 ? void 0 : _f.eventLinkSlug)
                ? React.createElement("div", null,
                    formatDisplayDate((_g = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.lastEvent) === null || _g === void 0 ? void 0 : _g.eventDate),
                    " (", (_h = lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.lastEvent) === null || _h === void 0 ? void 0 :
                    _h.venueCity,
                    ")")
                : React.createElement("div", null, " Hmm, we can't find anything right now but keep checking back! "))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import React from 'react';
import styles from './features.less';
import { ReactComponent as IcoMoney } from './icons/ico-money.svg';
import { ReactComponent as IcoShield } from './icons/ico-shield.svg';
import { ReactComponent as IcoTicket } from './icons/ico-ticket.svg';
export function Features() {
    return React.createElement("div", __assign({ className: styles.Features }, t('Features')),
        React.createElement("h3", null, "Why use Beeyay?"),
        React.createElement("h4", null, "Changing the way people buy and sell tickets"),
        React.createElement("div", { className: styles['Features-List'] },
            React.createElement("div", { className: styles['Features-Feature'] },
                React.createElement("div", { className: styles['Feature-Circle'] },
                    React.createElement(IcoShield, null)),
                "All transactions are ",
                React.createElement("br", null),
                " covered by the ",
                React.createElement("br", null),
                " Beeyay Guarantee"),
            React.createElement("div", { className: styles['Features-Feature'] },
                React.createElement("div", { className: styles['Feature-Circle'] },
                    React.createElement(IcoTicket, null)),
                "Buying and selling tickets ",
                React.createElement("br", null),
                " at your price on your terms"),
            React.createElement("div", { className: styles['Features-Feature'] },
                React.createElement("div", { className: styles['Feature-Circle'] },
                    React.createElement(IcoMoney, null)),
                "No gimmicks,",
                React.createElement("br", null),
                " no pressure selling,",
                React.createElement("br", null),
                " just complete transparency")));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SortingDropdown } from '@containers/DetailsPages/components/eventsTable/sorting/sortingDropdown';
import { SortingRow } from '@containers/DetailsPages/components/eventsTable/sorting/index';
import styles from '@containers/DetailsPages/components/eventsTable/eventsTable.less';
import React from 'react';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
export var defaultSorting = 'event';
export var sortings = {
    date: 'date',
    event: 'event',
    onSaleDate: 'onSaleDate',
    sellPrice: 'sellPrice',
    buyPrice: 'buyPrice',
};
export var defaultOrdering = 'ascending';
export var orderings = {
    ascending: 'ascending',
    descending: 'descending',
};
export var Sorting = function (_a) {
    var variant = _a.variant, sortingHeader = _a.sortingHeader, rest = __rest(_a, ["variant", "sortingHeader"]);
    var width = useViewportInfo().width;
    switch (variant) {
        case 'advanced':
            return width < 1336
                ? React.createElement(SortingDropdown, __assign({ variant: variant }, rest))
                : React.createElement(SortingRow, __assign({ variant: variant }, rest));
        case 'simpleWithLocationFilter':
            return React.createElement(React.Fragment, null, width < 778
                ?
                    React.createElement(React.Fragment, null,
                        sortingHeader,
                        React.createElement("div", { className: styles.mobileSortingHeader },
                            React.createElement(SortingRow, __assign({ variant: variant, hasSortingDropdown: true }, rest)),
                            React.createElement(SortingDropdown, __assign({ variant: variant }, rest))))
                : React.createElement(React.Fragment, null,
                    sortingHeader,
                    React.createElement(SortingRow, __assign({ variant: variant }, rest))));
        default:
            return React.createElement(React.Fragment, null, width < 830
                ? React.createElement("div", { className: styles.mobileSortingHeader },
                    sortingHeader,
                    React.createElement(SortingDropdown, __assign({ variant: variant }, rest)))
                : React.createElement(React.Fragment, null,
                    sortingHeader,
                    React.createElement(SortingRow, __assign({ variant: variant }, rest))));
    }
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useToggle } from '@components/forms';
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { useStaticContext } from '@components/useStaticContext';
import { getRouteParams } from '@containers/DetailsPages/pages/EventDetails/helpers';
import { buyListingsSelector, getEventFullName, isLoadingSelector, isSeatingPlanLoadingSelector, actions as eventsActions, sellListingsSelector, } from '@logic/events';
import { formatDateTimeWithoutTZ } from '@logic/helpers/date';
import { getEventFilter } from '@logic/helpers/localStorage';
import { clearEventPageState, createReservation } from '@logic/reservations';
import { showSuspendedUserPopupWithNotification } from '@logic/suspendedUser';
import { formatVenue } from '@logic/venue';
import { push } from 'connected-react-router';
import omit from 'lodash/omit';
import React, { PureComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators, compose } from 'redux';
import { pushAddToBasket, pushProductDetailView } from 'src/client/gtm';
import { routes } from 'src/client/routing/routes';
import { withPageOptions } from '@containers/Page';
import { EventDetails } from '@containers/DetailsPages/pages/EventDetails/components/EventDetails';
import { withEventDetails } from '@containers/DetailsPages/pages/EventDetails/loader';
import { actions as stateActions, useEventTabState } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { SuspendedEventDetails } from '@containers/DetailsPages/pages/EventDetails/components/SuspendedEventDetails';
import { PastEventDetails } from '@containers/DetailsPages/pages/EventDetails/components/PastEventDetails';
import { CancelledEventDetails } from '@containers/DetailsPages/pages/EventDetails/components/CancelledEventDetails';
import { addHours } from 'date-fns';
import { createEventBreadcrumb } from '@containers/DetailsPages/breadcrumbFactory.utils';
import { EMPTY_BREADCRUMB } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
var initState = {
    sections: [],
    categories: [],
    sort: 'price',
    sortReverse: false,
    ticketTypes: {
        'e-Ticket': false,
        'Any': false,
        'Mobile': false,
        'Paper': false,
    },
    listings: [],
    allListings: [],
    event: null,
    quantity: 'any',
};
export function Details(props) {
    var _a, _b, _c, _d, _e;
    var event = props.event, eventLoading = props.eventLoading, listingsLoading = props.listingsLoading, seatingPlanLoading = props.seatingPlanLoading, transactionSide = props.transactionSide, sellListings = props.sellListings, buyListings = props.buyListings, settings = props.settings, actions = props.actions, eventPageState = props.eventPageState, userDetails = props.userDetails, buyOnly = props.buyOnly, eventId = props.id;
    var storedQuantityValues = getEventFilter(event);
    var _f = __read(useState(null), 2), hoveredListing = _f[0], changeHoveredListing = _f[1];
    var _g = __read(useState(0), 2), buyNotifyRequireFilter = _g[0], buyUpdateNotifyRequireFilter = _g[1];
    var _h = __read(useState(0), 2), buyOnSaleTimeNotification = _h[0], buyUpdateOnSaleTimeNotification = _h[1];
    var _j = __read(useState(0), 2), sellOnSaleTimeNotification = _j[0], sellUpdateOnSaleTimeNotification = _j[1];
    var _k = __read(useEventTabState('buy', __assign(__assign({}, initState), { event: event !== null && event !== void 0 ? event : null, listings: sellListings !== null && sellListings !== void 0 ? sellListings : [], quantity: (_a = storedQuantityValues.buyQuantity) !== null && _a !== void 0 ? _a : 'any' })), 2), buyState = _k[0], buyDispatch = _k[1];
    var _l = __read(useEventTabState('sell', __assign(__assign({}, initState), { event: event !== null && event !== void 0 ? event : null, listings: buyListings !== null && buyListings !== void 0 ? buyListings : [], sortReverse: true, quantity: (_b = storedQuantityValues.sellQuantity) !== null && _b !== void 0 ? _b : 'any' })), 2), sellState = _l[0], sellDispatch = _l[1];
    var _m = transactionSide === 'buy' ? buyState : sellState, sections = _m.sections, categories = _m.categories, sort = _m.sort, sortReverse = _m.sortReverse, quantity = _m.quantity, ticketTypes = _m.ticketTypes;
    var dispatch = useDispatch();
    var breadcrumb = useMemo(function () { return event
        ? createEventBreadcrumb(event)
        : EMPTY_BREADCRUMB; }, [event]);
    var _o = useToggle(transactionSide === 'buy' && storedQuantityValues.buyQuantity == null), isOpenQuantityModal = _o.state, closeQuantityModal = _o.deactivate;
    (_e = (_d = (_c = useStaticContext()) === null || _c === void 0 ? void 0 : _c.imagesToPush) === null || _d === void 0 ? void 0 : _d.add) === null || _e === void 0 ? void 0 : _e.call(_d, event.seatingPlan.svgFileUrl);
    useEffect(function () {
        buyUpdateOnSaleTimeNotification(0);
        sellUpdateOnSaleTimeNotification(0);
    }, [transactionSide]);
    useEffect(function () {
        sellDispatch(stateActions.initialize({ event: event, allListings: buyListings }));
        buyDispatch(stateActions.initialize({ event: event, allListings: sellListings }));
    }, [event, buyDispatch, sellDispatch, buyListings, sellListings]);
    useEffect(function () {
        if (eventPageState) {
            sellDispatch(stateActions.revertFilter(eventPageState.sell));
            buyDispatch(stateActions.revertFilter(eventPageState.buy));
            actions.clearEventPageState();
        }
    }, [eventPageState, sellDispatch, buyDispatch, actions]);
    useEffect(function () {
        if (event.onSaleDate.getTime() <= Date.now()) {
            return;
        }
        var intervalId = window.setInterval(function () {
            if (event.onSaleDate.getTime() > Date.now()) {
                return;
            }
            setTimeout(function () { return dispatch(eventsActions.loadEventDetails.request({ eventId: event.id })); }, 1000);
            toast.success('This event go on sale right now');
            sellDispatch(stateActions.changeFilter(sellState.quantity));
            buyDispatch(stateActions.changeFilter(buyState.quantity));
            window.clearInterval(intervalId);
        }, 1000);
        return function () { return window.clearInterval(intervalId); };
    }, [event, sellDispatch, buyDispatch, sellState.quantity, buyState.quantity, dispatch]);
    var fullEventName = getEventFullName(event);
    var venue = formatVenue(event.venue);
    var eventName = event.name;
    useEffect(function () {
        if (transactionSide === 'buy') {
            pushProductDetailView({
                eventId: event.id,
                fullEventName: fullEventName,
                category: [eventName, venue],
            });
        }
    }, [transactionSide, fullEventName, eventName, venue, event.id]);
    var handleListingSelect = useCallback(function (listing) {
        var _a, _b, _c, _d;
        if (listing.ownedByMe) {
            return actions.push(routes.listing(listing.type, eventId, listing.id));
        }
        if (userDetails && userDetails.status !== 'Active') {
            return actions.showSuspendedUserPopupWithNotification();
        }
        if (event.onSaleDate.getTime() > Date.now()) {
            toast.error("This event will go on sale on " + formatDateTimeWithoutTZ(event.onSaleDate) + " local time");
            buyUpdateOnSaleTimeNotification(function (s) { return s + 1; });
            sellUpdateOnSaleTimeNotification(function (s) { return s + 1; });
            return;
        }
        var quantityFilter = transactionSide === 'sell' ? sellState.quantity : buyState.quantity;
        if (quantityFilter === 'any' && transactionSide === 'buy') {
            buyUpdateNotifyRequireFilter(buyNotifyRequireFilter + 1);
            return;
        }
        if (transactionSide === 'buy') {
            pushAddToBasket({
                currencyCode: event.currency,
                eventId: event.id,
                fullEventName: getEventFullName(event),
                quantity: quantityFilter,
                category: [event.name, formatVenue(event.venue)],
                price: listing.price.value,
            });
        }
        if ((_a = buyState === null || buyState === void 0 ? void 0 : buyState.event) === null || _a === void 0 ? void 0 : _a.eventLinkSlug) {
            var eventLinkSlug = (_c = (_b = buyState === null || buyState === void 0 ? void 0 : buyState.event) === null || _b === void 0 ? void 0 : _b.eventLinkSlug) !== null && _c !== void 0 ? _c : (_d = sellState === null || sellState === void 0 ? void 0 : sellState.event) === null || _d === void 0 ? void 0 : _d.eventLinkSlug;
            var pageState = { slug: eventLinkSlug, transactionSide: transactionSide, buy: omit(buyState, 'event'), sell: omit(sellState, 'event') };
            actions.createReservation(listing.id, quantityFilter === 'any' ? listing.maxTreadableQuantities : quantityFilter, transactionSide, pageState);
        }
    }, [
        eventId,
        transactionSide,
        buyState,
        sellState,
        actions,
        buyUpdateNotifyRequireFilter,
        buyNotifyRequireFilter,
        event,
        userDetails,
    ]);
    var handleListingNew = useCallback(function (listing) {
        if (userDetails && userDetails.status !== 'Active') {
            return actions.showSuspendedUserPopupWithNotification();
        }
        actions.push(routes.listingFromPage(listing, eventId, 'new', 'event'));
    }, [actions, eventId, userDetails]);
    if (eventLoading) {
        return React.createElement(PleaseWait, null);
    }
    if (event.status === 'suspended') {
        return React.createElement(SuspendedEventDetails, { event: event, buyOnly: buyOnly, breadcrumb: breadcrumb });
    }
    if (event.status === 'cancelled') {
        return React.createElement(CancelledEventDetails, { event: event, buyOnly: buyOnly, breadcrumb: breadcrumb });
    }
    if (event.eventDate.getTime() < addHours(new Date(), -3).getTime()) {
        return React.createElement(PastEventDetails, { event: event, buyOnly: buyOnly, breadcrumb: breadcrumb });
    }
    return React.createElement(EventDetails, { event: event, isLoading: listingsLoading, isLoadingSeatingPlan: seatingPlanLoading, filterValue: quantity, onListingNew: handleListingNew, onListingSelect: handleListingSelect, dispatch: transactionSide === 'sell' ? sellDispatch : buyDispatch, onListingHover: changeHoveredListing, hoveredListing: hoveredListing, sections: sections, breadcrumb: breadcrumb, categories: categories, sellListings: buyState.listings, buyListings: sellState.listings, allSellListings: sellListings, allBuyListings: buyListings, transactionSide: transactionSide, sort: sort, sortReverse: sortReverse, isOpenQuantityModal: isOpenQuantityModal, closeQuantityModal: closeQuantityModal, notifyRequireFilter: buyNotifyRequireFilter, notifyOnSaleNotification: transactionSide === 'sell' ? sellOnSaleTimeNotification : buyOnSaleTimeNotification, settings: settings, ticketTypes: ticketTypes, buyOnly: buyOnly });
}
var mapStateToProps = function (state) { return ({
    buyListings: buyListingsSelector(state),
    sellListings: sellListingsSelector(state),
    listingsLoading: isLoadingSelector(state),
    seatingPlanLoading: isSeatingPlanLoadingSelector(state),
    eventPageState: state.reservations.eventPageState,
    settings: state.userSettings.userSettings || state.userSettings.globalSettings,
    userDetails: state.user.userDetails,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        createReservation: createReservation,
        push: push,
        clearEventPageState: clearEventPageState,
        showSuspendedUserPopupWithNotification: showSuspendedUserPopupWithNotification,
    }, dispatch),
}); };
export var ConnectedDetails = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withEventDetails)(Details);
var RoutedDetails = /** @class */ (function (_super) {
    __extends(RoutedDetails, _super);
    function RoutedDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoutedDetails.fetchData = function () {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (ConnectedDetails.fetchData) {
            (_a = ConnectedDetails).fetchData.apply(_a, __spreadArray([], __read(args)));
        }
    };
    RoutedDetails.prototype.render = function () {
        return React.createElement(ConnectedDetails, __assign({}, getRouteParams(this.props)));
    };
    return RoutedDetails;
}(PureComponent));
export { RoutedDetails };
export default withPageOptions({
    containerSize: 'full',
    search: true,
    showCategoriesNavigation: true,
    showSecondaryMarketplaceBanner: false,
    hideFooterMargin: true,
})(RoutedDetails);

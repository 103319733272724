var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Icon } from '@components/icon';
import { t } from '@containers/helpers/tests';
import { formatDateTime } from '@logic/helpers/date';
import React from 'react';
import { List } from 'semantic-ui-react';
import styles from './itemContent.less';
var ItemContent = function (_a) {
    var title = _a.title, route = _a.route, date = _a.date, collection = _a.collection, timezone = _a.timezone;
    return (React.createElement(List.Item, __assign({}, t('SearchResultItem')),
        React.createElement(List.Content, null, !route
            ? title
            : React.createElement("div", { className: styles.row },
                collection && React.createElement(Icon, { icon: collection }),
                React.createElement("div", null,
                    title,
                    date && React.createElement("div", null, formatDateTime(date, timezone || '')))))));
};
export default (function (phrase) { return function (props) { return React.createElement(ItemContent, __assign({ key: 0, phrase: phrase }, props)); }; });

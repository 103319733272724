var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Link, useToggle } from '@components/forms';
import { t } from '@containers/helpers/tests';
import React, { useCallback, useEffect } from 'react';
import { routes } from 'src/client/routing/routes';
import styles from './banners.less';
import { windowIsDefined } from '@containers/utils';
var COOKIE_KEY = 'hideCookiesWarning';
var getBannerVisibilityTime = function () { var _a; return (windowIsDefined() && ((_a = window) === null || _a === void 0 ? void 0 : _a.BANNER_VISIBILITY_TIME)) || 15 * 1000; };
var useBanner = function (key) {
    var BANNER_VISIBILITY_TIME = getBannerVisibilityTime();
    var _a = useToggle(false), state = _a.state, deactivate = _a.deactivate, changeState = _a.changeState;
    useEffect(function () {
        var hide = localStorage.getItem(key);
        changeState(!hide);
    }, [changeState, key]);
    var handleOk = useCallback(function () {
        localStorage.setItem(key, 'true');
        deactivate();
    }, [deactivate, key]);
    useEffect(function () {
        var timeout = setTimeout(function () {
            if (!state) {
                return;
            }
            handleOk();
        }, BANNER_VISIBILITY_TIME);
        return function () {
            clearTimeout(timeout);
        };
    }, [state, handleOk, BANNER_VISIBILITY_TIME]);
    return { state: state, handleOk: handleOk };
};
export function Banners() {
    var _a = useBanner(COOKIE_KEY), showCookie = _a.state, handleOkCookie = _a.handleOk;
    if (!showCookie) {
        return null;
    }
    return (React.createElement("div", { className: styles.banner }, showCookie &&
        React.createElement("div", __assign({ className: styles.contentWrapper }, t('CookiesBanner')),
            React.createElement("div", null,
                "We use cookies to give you a safer and more personalised experience. By using this site you agree to our ",
                React.createElement(Link, { to: routes.cookiesPolicy(), ternary: true, className: styles.link }, "cookie policy"),
                "."),
            React.createElement(Button, { ternary: true, onClick: handleOkCookie, className: styles.button }, "Ok"))));
}

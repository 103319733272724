var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { goBack, push, replace } from 'connected-react-router';
import React from 'react';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { eventLinkSlugToEventRoute, routes } from 'src/client/routing/routes';
import { eventDetailsSelector, actions as eventsActions } from './events';
import { getReservation, removeReservation, setReservation } from './helpers/localStorage';
import request from './helpers/request';
import { listingsApi } from './listings';
import { normalizeBuySeatingInfo, normalizeSellSeatingInfo } from './seatingInfo';
import { showSuspendedUserPopupWithNotification } from './suspendedUser';
import { selectUserDetails } from './user';
import { addSeconds, differenceInMilliseconds } from 'date-fns';
import get from 'lodash/get';
import { setGuestCheckoutEmail } from '@logic/userSettings';
export var changeReservation = createAction('set reservation in store', function (reservation) { return reservation; });
export var changeTempReservation = createAction('set temp reservation in store', function (reservation) { return reservation; });
export var reloadReservationFromCache = function () { return changeReservation(getReservation()); };
export var keepOldReservation = createAction('keep old reservation');
export var forceNewReservation = createAction('force creation of new reservation');
export var createReservation = createAction('create new reservation', function (listingId, quantity, type, eventPageState) { return ({
    listingId: listingId,
    quantity: quantity,
    type: type,
    eventPageState: eventPageState,
}); });
export var createReservationSuccess = createAction('create new reservation success');
export var createReservationTempSuccess = createAction('create new reservation failure');
export var createReservationFailure = createAction('create new reservation failure');
export var clearReservations = createAction('clear reservations data');
export var clearTempReservation = createAction('clear reservation data');
export var releaseReservation = createAction('manual release of reservation', function (isRedirectToEventPage) { return ({
    isRedirectToEventPage: isRedirectToEventPage
}); });
export var setEventPageState = createAction('set event page state', function (eventPageState) { return ({
    eventPageState: eventPageState,
}); });
export var clearEventPageState = createAction('clear event page state');
export var reservationExpired = createAction('reservation expired', function (payload) { return payload; });
export var updateReservationQuantity = createAction('update reservation quantity', function (quantity) { return ({
    quantity: quantity,
}); });
export var updateReservationQuantitySuccess = createAction('update reservation quantity  success', function (quantity) { return ({
    quantity: quantity,
}); });
export var updateReservationQuantityFailure = createAction('update reservation quantity failure', function (error) { return ({ error: error }); });
export var updateReservationTimer = createAction('update reservation timer', function (timerInSeconds) { return ({
    timerInSeconds: timerInSeconds,
}); });
export var updateReservationTimerSuccess = createAction('update reservation timer success', function (timerInSeconds) { return ({
    timerInSeconds: timerInSeconds,
}); });
export var updateReservationTimerFailure = createAction('update reservation timer failure', function (error) { return ({ error: error }); });
export var updateTreadableQuantities = createAction('update transactable quantities');
export var updateTreadableQuantitiesSuccess = createAction('update transactable quantities success', function (quantities) { return ({
    quantities: quantities,
}); });
export var updateTreadableQuantitiesFailure = createAction('update transactable quantities failure', function (error) { return ({ error: error }); });
export var assignReservationToUser = createAction('assign dangling reservation to user');
export var setReservationState = createAction('set reservation state', function (reservationId, page) { return ({ reservationId: reservationId, page: page }); });
var initialState = {
    treadableQuantities: [],
    reservation: null,
    tempReservation: null,
    flowStatus: 'none',
    eventPageState: null,
    reservationState: ['', ''],
};
export var reservationsReducer = createReducer((_a = {},
    _a[createReservation.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'creating', reservation: null })); },
    _a[changeReservation.getType()] = function (state, payload) { return (__assign(__assign({}, state), { reservation: payload, flowStatus: 'ok' })); },
    _a[changeTempReservation.getType()] = function (state, payload) { return (__assign(__assign({}, state), { tempReservation: payload, flowStatus: 'ok' })); },
    _a[createReservationTempSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'otherPending' })); },
    _a[createReservationFailure.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'none' })); },
    _a[keepOldReservation.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'ok' })); },
    _a[updateReservationQuantity.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'quantityUpdating' })); },
    _a[updateReservationQuantitySuccess.getType()] = function (state, _a) {
        var quantity = _a.quantity;
        return (__assign(__assign({}, state), { reservation: !state.reservation
                ? null
                : __assign(__assign({}, state.reservation), { quantity: quantity }), flowStatus: 'ok' }));
    },
    _a[updateReservationQuantityFailure.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'ok' })); },
    _a[updateReservationTimer.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'timerUpdating' })); },
    _a[updateReservationTimerSuccess.getType()] = function (state, _a) {
        var timerInSeconds = _a.timerInSeconds;
        return (__assign(__assign({}, state), { reservation: !state.reservation
                ? null
                : __assign(__assign({}, state.reservation), { endTime: addSeconds(new Date(), timerInSeconds) }), flowStatus: 'ok' }));
    },
    _a[updateReservationTimerFailure.getType()] = function (state) { return (__assign(__assign({}, state), { flowStatus: 'ok' })); },
    _a[updateTreadableQuantitiesSuccess.getType()] = function (state, _a) {
        var quantities = _a.quantities;
        return (__assign(__assign({}, state), { treadableQuantities: quantities }));
    },
    _a[updateTreadableQuantitiesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { treadableQuantities: [] })); },
    _a[setEventPageState.getType()] = function (state, _a) {
        var eventPageState = _a.eventPageState;
        return (__assign(__assign({}, state), { eventPageState: eventPageState }));
    },
    _a[clearEventPageState.getType()] = function (state) { return (__assign(__assign({}, state), { eventPageState: null })); },
    _a[setReservationState.getType()] = function (state, _a) {
        var reservationId = _a.reservationId, page = _a.page;
        return (__assign(__assign({}, state), { reservationState: [reservationId, page] }));
    },
    _a), initialState);
var reservationsApi = {
    createSell: function (listingId, quantity, isTemporary) {
        if (isTemporary === void 0) { isTemporary = false; }
        return request('/api/reservations/sell/create', {
            listingId: listingId,
            quantity: quantity,
            isTemporary: isTemporary,
        });
    },
    createBuy: function (listingId, quantity, isTemporary) {
        if (isTemporary === void 0) { isTemporary = false; }
        return request('/api/reservations/buy/create', {
            listingId: listingId,
            quantity: quantity,
            isTemporary: isTemporary,
        });
    },
    updateQuantity: function (reservationId, quantity) { return request('/api/reservations/update', {
        reservationId: reservationId,
        quantity: quantity,
    }); },
    updateTimer: function (reservationId, expirationTime) { return request('/api/reservations/update', {
        reservationId: reservationId,
        expirationTime: expirationTime,
    }); },
    delete: function (reservationId) { return request("/api/reservations/delete/" + reservationId, {}); },
    swap: function (oldReservationId, tempreservationId) { return request('/api/reservations/swap', {
        tempreservationId: tempreservationId,
        oldReservationId: oldReservationId,
    }); },
    userReservation: function () { return request('/api/reservations/user'); },
};
export var selectReservation = function (s) { return s.reservations.reservation; };
var selectTempReservation = function (s) { return s.reservations.tempReservation; };
var selectCreateTransactionPending = function (gs) { return gs.transactions.createTransactionPending; };
export function onChangeReservation(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload) return [3 /*break*/, 2];
                return [4 /*yield*/, call(setReservation, payload)];
            case 1:
                _b.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, call(removeReservation)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
export function onChangeTempReservation(_a) {
    var now, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, call(Date.now)];
            case 1:
                now = _b.sent();
                return [4 /*yield*/, delay(differenceInMilliseconds(payload.endTime, now))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                _b.trys.push([3, 6, , 9]);
                return [4 /*yield*/, call(reservationsApi.delete, payload.id)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(changeTempReservation(null))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_1 = _b.sent();
                if (!(get(e_1, ['data', 'case']) !== 'EntityNotFound')) return [3 /*break*/, 8];
                return [4 /*yield*/, call(toast.error, 'Cannot delete reservation')];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8: return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onReservationExpired(_a) {
    var reservation, createTransactionPending, e_2;
    var redirect = _a.payload.redirect;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 8]);
                return [4 /*yield*/, select(selectCreateTransactionPending)];
            case 3:
                createTransactionPending = _b.sent();
                if (createTransactionPending) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, call(reservationsApi.delete, reservation.id)];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_2 = _b.sent();
                if (!(get(e_2, ['data', 'case']) !== 'EntityNotFound')) return [3 /*break*/, 7];
                return [4 /*yield*/, call(toast.error, 'Cannot delete reservation')];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 8];
            case 8: return [4 /*yield*/, put(changeReservation(null))];
            case 9:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'We\'re sorry, your reservation has expired')];
            case 10:
                _b.sent();
                if (!redirect) return [3 /*break*/, 12];
                return [4 /*yield*/, put(push(eventLinkSlugToEventRoute(reservation.eventPageState.slug, reservation.type)))];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12: return [2 /*return*/];
        }
    });
}
var rawSellReservationToReservation = function (listingId, _a, eventPageState) {
    var reservationId = _a.reservationId, activeTimespan = _a.activeTimespan, listingSnapshot = _a.listingSnapshot, quantity = _a.quantity, hasTicketsAssigned = _a.hasTicketsAssigned;
    var buyCurrency = listingSnapshot.buyCurrency, buyPrice = listingSnapshot.buyPrice, restrictions = listingSnapshot.restrictions, eventId = listingSnapshot.eventId, ticketType = listingSnapshot.ticketType, faceValue = listingSnapshot.faceValue, seatingInformation = listingSnapshot.seatingInformation;
    return {
        id: reservationId,
        listingId: listingId,
        quantity: quantity,
        endTime: addSeconds(new Date(), activeTimespan),
        type: 'sell',
        listingSnapshot: {
            eventId: eventId,
            currency: buyCurrency,
            value: buyPrice,
            faceValue: faceValue,
            restrictions: restrictions,
            ticketType: ticketType,
            hasTicketsAssigned: hasTicketsAssigned,
            ticketSplit: 'any',
            seatingInformation: normalizeBuySeatingInfo(seatingInformation),
            startingSeat: 0,
            endingSeat: 0,
        },
        eventPageState: eventPageState,
        hasTicketsAssigned: hasTicketsAssigned,
    };
};
var rawBuyReservationToReservation = function (listingId, _a, eventPageState) {
    var reservationId = _a.reservationId, activeTimespan = _a.activeTimespan, listingSnapshot = _a.listingSnapshot, quantity = _a.quantity, hasTicketsAssigned = _a.hasTicketsAssigned;
    var sellCurrency = listingSnapshot.sellCurrency, sellPrice = listingSnapshot.sellPrice, seatingInformation = listingSnapshot.seatingInformation, faceValue = listingSnapshot.faceValue, restrictions = listingSnapshot.restrictions, eventId = listingSnapshot.eventId, ticketType = listingSnapshot.ticketType, ticketSplit = listingSnapshot.ticketSplit, ownerInfo = listingSnapshot.ownerInfo, startingSeat = listingSnapshot.startingSeat, endingSeat = listingSnapshot.endingSeat;
    return {
        id: reservationId,
        listingId: listingId,
        quantity: quantity,
        endTime: addSeconds(new Date(), activeTimespan),
        type: 'buy',
        listingSnapshot: {
            currency: sellCurrency,
            eventId: eventId,
            value: sellPrice,
            faceValue: faceValue,
            restrictions: restrictions,
            ticketType: ticketType,
            hasTicketsAssigned: hasTicketsAssigned,
            ticketSplit: ticketSplit,
            seatingInformation: [normalizeSellSeatingInfo(seatingInformation, null)],
            ownerInfo: ownerInfo,
            startingSeat: startingSeat,
            endingSeat: endingSeat,
        },
        eventPageState: eventPageState,
        hasTicketsAssigned: hasTicketsAssigned,
    };
};
export function invokeCreateReservation(type, listingId, quantity, temp, eventPageState) {
    var rawReservation, rawReservation, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                if (!(type === 'sell')) return [3 /*break*/, 2];
                return [4 /*yield*/, call(reservationsApi.createSell, listingId, quantity, temp)];
            case 1:
                rawReservation = _a.sent();
                return [2 /*return*/, rawSellReservationToReservation(listingId, rawReservation, eventPageState)];
            case 2: return [4 /*yield*/, call(reservationsApi.createBuy, listingId, quantity, temp)];
            case 3:
                rawReservation = _a.sent();
                return [2 /*return*/, rawBuyReservationToReservation(listingId, rawReservation, eventPageState)];
            case 4: return [3 /*break*/, 6];
            case 5:
                e_3 = _a.sent();
                return [2 /*return*/, null];
            case 6: return [2 /*return*/];
        }
    });
}
export function getReservationErrorNotification(sub) {
    return (React.createElement("div", null,
        "Sorry, these tickets can't be reserved at this time as they have been reserved by another user.",
        React.createElement("br", null),
        React.createElement("br", null),
        sub));
}
function swapReservation(oldReservationId, tempReservation) {
    var rawReservation, rawReservation, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                if (!(tempReservation.type === 'sell')) return [3 /*break*/, 2];
                return [4 /*yield*/, call(reservationsApi.swap, oldReservationId, tempReservation.id)];
            case 1:
                rawReservation = _a.sent();
                return [2 /*return*/, rawSellReservationToReservation(tempReservation.listingId, rawReservation, tempReservation.eventPageState)];
            case 2: return [4 /*yield*/, call(reservationsApi.swap, oldReservationId, tempReservation.id)];
            case 3:
                rawReservation = _a.sent();
                return [2 /*return*/, rawBuyReservationToReservation(tempReservation.listingId, rawReservation, tempReservation.eventPageState)];
            case 4: return [3 /*break*/, 6];
            case 5:
                e_4 = _a.sent();
                return [2 /*return*/, null];
            case 6: return [2 /*return*/];
        }
    });
}
export function onCreateReservation(_a) {
    var listingId, quantity, type, eventPageState, timestamp, cachedReservation, event, errorMessageSubtext, tempReservation, reservation, tempReservation2, oldReservation, reservation2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                listingId = payload.listingId, quantity = payload.quantity, type = payload.type, eventPageState = payload.eventPageState;
                return [4 /*yield*/, call(Date.now)];
            case 1:
                timestamp = _b.sent();
                return [4 /*yield*/, call(getReservation)];
            case 2:
                cachedReservation = _b.sent();
                return [4 /*yield*/, select(eventDetailsSelector)];
            case 3:
                event = _b.sent();
                errorMessageSubtext = 'Please refresh the page and try again.';
                return [4 /*yield*/, put(setGuestCheckoutEmail(''))];
            case 4:
                _b.sent();
                if (!(cachedReservation && cachedReservation.endTime.getTime() > timestamp)) return [3 /*break*/, 15];
                return [4 /*yield*/, put(changeReservation(cachedReservation))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(updateTreadableQuantities())];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(invokeCreateReservation, type, listingId, quantity, true, eventPageState)];
            case 7:
                tempReservation = _b.sent();
                if (!tempReservation) return [3 /*break*/, 11];
                return [4 /*yield*/, put(changeTempReservation(tempReservation))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(createReservationTempSuccess())];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(push(routes.reservation(type, 'summary')))];
            case 10:
                _b.sent();
                return [2 /*return*/];
            case 11: return [4 /*yield*/, call(toast.error, getReservationErrorNotification(errorMessageSubtext))];
            case 12:
                _b.sent();
                if (!event) return [3 /*break*/, 14];
                return [4 /*yield*/, put(eventsActions.loadEventDetails.request({ eventId: event.id }))];
            case 13:
                _b.sent();
                _b.label = 14;
            case 14: return [2 /*return*/];
            case 15: return [4 /*yield*/, call(invokeCreateReservation, type, listingId, quantity, false, eventPageState)];
            case 16:
                reservation = _b.sent();
                if (!reservation) return [3 /*break*/, 21];
                return [4 /*yield*/, put(changeReservation(reservation))];
            case 17:
                _b.sent();
                return [4 /*yield*/, put(createReservationSuccess())];
            case 18:
                _b.sent();
                return [4 /*yield*/, put(updateTreadableQuantities())];
            case 19:
                _b.sent();
                return [4 /*yield*/, put(push(routes.reservation(type, 'summary')))];
            case 20:
                _b.sent();
                return [2 /*return*/];
            case 21: return [4 /*yield*/, call(invokeCreateReservation, type, listingId, quantity, true, eventPageState)];
            case 22:
                tempReservation2 = _b.sent();
                if (!tempReservation2) return [3 /*break*/, 28];
                return [4 /*yield*/, call(reservationsApi.userReservation)];
            case 23:
                oldReservation = _b.sent();
                if (!(oldReservation.count > 0)) return [3 /*break*/, 28];
                return [4 /*yield*/, call(swapReservation, oldReservation.results[0].reservationId, tempReservation2)];
            case 24:
                reservation2 = _b.sent();
                return [4 /*yield*/, put(changeReservation(reservation2))];
            case 25:
                _b.sent();
                return [4 /*yield*/, put(createReservationSuccess())];
            case 26:
                _b.sent();
                return [4 /*yield*/, put(push(routes.reservation(type, 'summary')))];
            case 27:
                _b.sent();
                return [2 /*return*/];
            case 28: // you cannot create reservation at all, probably someone has bought tickets. Reload listings
            return [4 /*yield*/, call(toast.error, getReservationErrorNotification(errorMessageSubtext))];
            case 29:
                _b.sent();
                if (!event) return [3 /*break*/, 31];
                return [4 /*yield*/, put(eventsActions.loadEventDetails.request({ eventId: event.id }))];
            case 30:
                _b.sent();
                _b.label = 31;
            case 31: return [2 /*return*/];
        }
    });
}
export function onForceNewReservation(_) {
    var oldReservation, tempReservation, reservation;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                oldReservation = _a.sent();
                return [4 /*yield*/, select(selectTempReservation)];
            case 2:
                tempReservation = _a.sent();
                if (!tempReservation || !oldReservation) {
                    return [2 /*return*/, call(toast.error, 'Cannot create reservation')];
                }
                return [4 /*yield*/, call(swapReservation, oldReservation.id, tempReservation)];
            case 3:
                reservation = _a.sent();
                return [4 /*yield*/, put(changeTempReservation(null))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(changeReservation(reservation))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(updateTreadableQuantities())];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onKeepOldReservation(_) {
    var reservation, tempReservation, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _a.sent();
                return [4 /*yield*/, select(selectTempReservation)];
            case 2:
                tempReservation = _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 9]);
                if (!tempReservation) return [3 /*break*/, 5];
                return [4 /*yield*/, call(reservationsApi.delete, tempReservation.id)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_5 = _a.sent();
                if (!(get(e_5, ['data', 'case']) !== 'EntityNotFound')) return [3 /*break*/, 8];
                return [4 /*yield*/, call(toast.error, 'Cannot delete reservation')];
            case 7:
                _a.sent();
                _a.label = 8;
            case 8: return [3 /*break*/, 9];
            case 9: return [4 /*yield*/, put(changeTempReservation(null))];
            case 10:
                _a.sent();
                if (!!reservation) return [3 /*break*/, 13];
                return [4 /*yield*/, put(goBack())];
            case 11:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'This reservation has expired')];
            case 12:
                _a.sent();
                return [2 /*return*/];
            case 13: return [4 /*yield*/, put(replace(routes.reservation(reservation.type, 'summary')))];
            case 14:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onClearReservation(_) {
    var reservation, tempReservation, e_6, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _a.sent();
                return [4 /*yield*/, select(selectTempReservation)];
            case 2:
                tempReservation = _a.sent();
                if (!reservation) return [3 /*break*/, 7];
                _a.label = 3;
            case 3:
                _a.trys.push([3, 5, , 7]);
                return [4 /*yield*/, call(reservationsApi.delete, reservation.id)];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_6 = _a.sent();
                return [4 /*yield*/, call(toast.error, 'An error occurred when trying to delete reservation')];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7:
                if (!tempReservation) return [3 /*break*/, 12];
                _a.label = 8;
            case 8:
                _a.trys.push([8, 10, , 12]);
                return [4 /*yield*/, call(reservationsApi.delete, tempReservation.id)];
            case 9:
                _a.sent();
                return [3 /*break*/, 12];
            case 10:
                e_7 = _a.sent();
                return [4 /*yield*/, call(toast.error, 'An error occurred when trying to delete reservation')];
            case 11:
                _a.sent();
                return [3 /*break*/, 12];
            case 12: return [4 /*yield*/, put(changeTempReservation(null))];
            case 13:
                _a.sent();
                return [4 /*yield*/, put(changeReservation(null))];
            case 14:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onUpdateReservationQuantity(_a) {
    var quantity, reservation, updatedReservation, updatedReservation, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 12, , 15]);
                quantity = payload.quantity;
                return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _b.sent();
                if (!!reservation) return [3 /*break*/, 3];
                return [4 /*yield*/, call(toast.error, 'You do not have any reservation')];
            case 2:
                _b.sent();
                return [2 /*return*/];
            case 3:
                if (reservation.quantity === payload.quantity) {
                    return [2 /*return*/];
                }
                if (!(reservation.type === 'sell')) return [3 /*break*/, 6];
                return [4 /*yield*/, call(reservationsApi.updateQuantity, reservation.id, payload.quantity)];
            case 4:
                updatedReservation = _b.sent();
                return [4 /*yield*/, put(changeReservation(rawSellReservationToReservation(reservation.listingId, updatedReservation, reservation.eventPageState)))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6: return [4 /*yield*/, call(reservationsApi.updateQuantity, reservation.id, payload.quantity)];
            case 7:
                updatedReservation = _b.sent();
                return [4 /*yield*/, put(changeReservation(rawBuyReservationToReservation(reservation.listingId, updatedReservation, reservation.eventPageState)))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [4 /*yield*/, put(updateReservationQuantitySuccess(quantity))];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(updateTreadableQuantities())];
            case 11:
                _b.sent();
                return [3 /*break*/, 15];
            case 12:
                e_8 = _b.sent();
                return [4 /*yield*/, put(updateReservationQuantityFailure(e_8))];
            case 13:
                _b.sent();
                return [4 /*yield*/, call(toast.error, getReservationErrorNotification('Your original tickets are still reserved for you.'))];
            case 14:
                _b.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function onUpdateReservationTimer(_a) {
    var timerInSeconds, reservation, updatedReservation, updatedReservation, e_9;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 11, , 14]);
                timerInSeconds = payload.timerInSeconds;
                return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _b.sent();
                if (!!reservation) return [3 /*break*/, 3];
                return [4 /*yield*/, call(toast.error, 'You do not have any reservation')];
            case 2:
                _b.sent();
                return [2 /*return*/];
            case 3:
                if (!(reservation.type === 'sell')) return [3 /*break*/, 6];
                return [4 /*yield*/, call(reservationsApi.updateTimer, reservation.id, payload.timerInSeconds)];
            case 4:
                updatedReservation = _b.sent();
                return [4 /*yield*/, put(changeReservation(rawSellReservationToReservation(reservation.listingId, updatedReservation, reservation.eventPageState)))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6: return [4 /*yield*/, call(reservationsApi.updateTimer, reservation.id, payload.timerInSeconds)];
            case 7:
                updatedReservation = _b.sent();
                return [4 /*yield*/, put(changeReservation(rawBuyReservationToReservation(reservation.listingId, updatedReservation, reservation.eventPageState)))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [4 /*yield*/, put(updateReservationTimerSuccess(timerInSeconds))];
            case 10:
                _b.sent();
                return [3 /*break*/, 14];
            case 11:
                e_9 = _b.sent();
                return [4 /*yield*/, put(updateReservationTimerFailure(e_9))];
            case 12:
                _b.sent();
                return [4 /*yield*/, call(toast.error, getReservationErrorNotification('Your original tickets are still reserved for you.'))];
            case 13:
                _b.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
export function onUpdateRemainingListingQuantity(_) {
    var reservation, action, simplifiedListing, quantities, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 8, , 10]);
                return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _a.sent();
                if (!reservation) {
                    return [2 /*return*/];
                }
                action = reservation.type === 'buy' ? listingsApi.getSimplifiedSellListingById : listingsApi.getSimplifiedBuyListingById;
                return [4 /*yield*/, call(action, reservation.listingId, reservation.quantity)];
            case 2:
                simplifiedListing = _a.sent();
                if (!simplifiedListing.ownedByMe) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'You cannot transact your own tickets.')];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(goBack())];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(clearReservations())];
            case 5:
                _a.sent();
                return [2 /*return*/];
            case 6:
                quantities = simplifiedListing.treadableQuantities || [];
                return [4 /*yield*/, put(updateTreadableQuantitiesSuccess(quantities))];
            case 7:
                _a.sent();
                return [3 /*break*/, 10];
            case 8:
                e_10 = _a.sent();
                return [4 /*yield*/, put(updateTreadableQuantitiesFailure(e_10))];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function onReleaseReservation(_a) {
    var reservation, createTransactionPending, e_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _b.sent();
                if (!reservation) {
                    return [2 /*return*/];
                }
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 8]);
                return [4 /*yield*/, select(selectCreateTransactionPending)];
            case 3:
                createTransactionPending = _b.sent();
                if (createTransactionPending) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, call(reservationsApi.delete, reservation.id)];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_11 = _b.sent();
                if (!(get(e_11, ['data', 'case']) !== 'EntityNotFound')) return [3 /*break*/, 7];
                return [4 /*yield*/, call(toast.error, 'Cannot delete reservation')];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 8];
            case 8: return [4 /*yield*/, put(changeReservation(null))];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(setEventPageState(reservation.eventPageState))];
            case 10:
                _b.sent();
                if (!payload.isRedirectToEventPage) return [3 /*break*/, 12];
                return [4 /*yield*/, put(push(eventLinkSlugToEventRoute(reservation.eventPageState.slug, reservation.eventPageState.transactionSide)))];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12: return [2 /*return*/];
        }
    });
}
export function onAssignReservationToUser(_) {
    var reservation, userDetails, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _a.sent();
                return [4 /*yield*/, select(selectUserDetails)];
            case 2:
                userDetails = _a.sent();
                if (!reservation) {
                    return [2 /*return*/];
                }
                if (!(userDetails && userDetails.status !== 'Active')) return [3 /*break*/, 5];
                return [4 /*yield*/, put(releaseReservation(true))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(showSuspendedUserPopupWithNotification())];
            case 4:
                _a.sent();
                return [2 /*return*/];
            case 5:
                _a.trys.push([5, 7, , 13]);
                return [4 /*yield*/, call(reservationsApi.updateQuantity, reservation.id, reservation.quantity)];
            case 6:
                _a.sent(); // do not change quantity, just update.
                return [3 /*break*/, 13];
            case 7:
                e_12 = _a.sent();
                return [4 /*yield*/, call(reservationsApi.delete, reservation.id)];
            case 8:
                _a.sent();
                return [4 /*yield*/, put(changeReservation(null))];
            case 9:
                _a.sent();
                return [4 /*yield*/, put(setEventPageState(reservation.eventPageState))];
            case 10:
                _a.sent();
                return [4 /*yield*/, put(push(eventLinkSlugToEventRoute(reservation.eventPageState.slug, reservation.eventPageState.transactionSide)))];
            case 11:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'You cannot have two reservations at the same time')];
            case 12:
                _a.sent();
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
export function reservationsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(changeReservation.getType(), onChangeReservation)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(changeTempReservation.getType(), onChangeTempReservation)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(reservationExpired.getType(), onReservationExpired)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(forceNewReservation.getType(), onForceNewReservation)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(keepOldReservation.getType(), onKeepOldReservation)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(createReservation.getType(), onCreateReservation)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(clearReservations.getType(), onClearReservation)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateReservationQuantity.getType(), onUpdateReservationQuantity)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateReservationTimer.getType(), onUpdateReservationTimer)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateTreadableQuantities.getType(), onUpdateRemainingListingQuantity)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatest(releaseReservation.getType(), onReleaseReservation)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(assignReservationToUser.getType(), onAssignReservationToUser)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

import { useToggle } from '@components/forms';
import cn from 'classnames';
import React from 'react';
import styles from '../Login/login.less';
export function DetailsInfo(_a) {
    var _b;
    var header = _a.header, children = _a.children;
    var _c = useToggle(false), state = _c.state, toggle = _c.toggle;
    return (React.createElement("div", { className: styles.detailsInfo },
        React.createElement("p", { onClick: toggle, className: cn((_b = {}, _b[styles.open] = state, _b)) }, header),
        state && React.createElement("div", null, children)));
}

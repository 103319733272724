var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { formatDateTime, formatDateWithoutTZ } from './helpers/date';
import { formatVenueData } from './venue';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { slugifyName } from './helpers/location';
import request, { blobFile, SANE_LIMIT } from './helpers/request';
import { normalizeBuySeatingInfo, normalizeSellSeatingInfo, } from './seatingInfo';
import { rawVenueToVenue } from './venue';
import { createAsyncAction, createAction, getType } from 'typesafe-actions';
import get from 'lodash/get';
import { mapCategoryToEventType } from '@containers/DetailsPages/components/eventsTable/utils/utils';
import { findMostPopular } from '@containers/utils';
export var hasReservedSeating = function (section, sp) {
    if (!sp || section.category === 'any') {
        return false;
    }
    return !!sp.structure.categories.find(function (c) { return c.name === section.category && c.isReservedSeating; });
};
export var hasSection = function (section, sp) {
    if (!sp || section.category === 'any') {
        return true;
    }
    return !!sp.structure.categories.find(function (c) { return c.name === section.category; });
};
export var actions = {
    searchEvents: createAsyncAction('search for events', 'search for events success', 'search for events failure')(),
    loadEventDetails: createAsyncAction('load event details', 'load event details success', 'load event details failure')(),
    reloadEventDetails: createAction('reload event details')(),
    loadSeatingPlan: createAsyncAction('load seating plan', 'load seating plan success', 'load seating plan failure')(),
    loadNewEvents: createAction('load new events')(),
    loadEventLocations: createAsyncAction('load event locations', 'load event locations success', 'load event locations failure')(),
    loadEventCategories: createAsyncAction('load event categories', 'load event categories success', 'load event categories failure')(),
    setEventSSRFlag: createAction('set event SSR flag')()
};
var initialState = {
    isLoading: false,
    eventsCount: 0,
    eventsSearched: [],
    isLoadingDetails: true,
    isLoadingSeatingPlan: false,
    isLoadingCategories: false,
    isLoadingLocations: false,
    eventDetails: null,
    sellListings: [],
    buyListings: [],
    locations: [],
    categories: [],
    loadedWithSSR: false
};
export var eventsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case getType(actions.searchEvents.request):
        case getType(actions.loadNewEvents): return __assign(__assign({}, state), { isLoading: true });
        case getType(actions.searchEvents.success): return __assign(__assign({}, state), { eventsSearched: action.payload.displayMoreResults ? __spreadArray(__spreadArray([], __read(state.eventsSearched)), __read(action.payload.events)) : action.payload.events, eventsCount: action.payload.count, isLoading: false });
        case getType(actions.searchEvents.failure): return __assign(__assign({}, state), { isLoading: false });
        case getType(actions.loadEventCategories.request): return __assign(__assign({}, state), { isLoadingCategories: true });
        case getType(actions.loadEventCategories.success): return __assign(__assign({}, state), { categories: action.payload, isLoadingCategories: false });
        case getType(actions.loadEventCategories.failure): return __assign(__assign({}, state), { isLoadingCategories: false });
        case getType(actions.loadEventLocations.request): return __assign(__assign({}, state), { isLoadingLocations: true });
        case getType(actions.loadEventLocations.success): return __assign(__assign({}, state), { locations: action.payload, isLoadingLocations: false });
        case getType(actions.loadEventLocations.failure): return __assign(__assign({}, state), { isLoadingLocations: false });
        case getType(actions.loadEventDetails.request): return __assign(__assign({}, state), { isLoadingDetails: true });
        case getType(actions.loadEventDetails.success): {
            var eventDetails = state.eventDetails;
            if (!eventDetails || !action.payload.event || eventDetails.type !== 'event' || action.payload.event.type !== 'event') {
                return __assign(__assign({}, state), { isLoadingDetails: false, eventDetails: action.payload.event, sellListings: action.payload.sellListings, buyListings: action.payload.buyListings });
            }
            return __assign(__assign({}, state), { isLoadingDetails: false, eventDetails: __assign(__assign({}, action.payload.event), { seatingPlan: __assign(__assign({}, action.payload.event.seatingPlan), { svgFile: eventDetails.id === action.payload.event.id ? eventDetails.seatingPlan.svgFile : undefined }) }), sellListings: action.payload.sellListings, buyListings: action.payload.buyListings });
        }
        case getType(actions.loadEventDetails.failure): return __assign(__assign({}, state), { isLoadingDetails: false, eventDetails: null, sellListings: [], buyListings: [] });
        case getType(actions.loadSeatingPlan.request): return __assign(__assign({}, state), { isLoadingSeatingPlan: true });
        case getType(actions.loadSeatingPlan.success): {
            var _a = action.payload, id = _a.id, content = _a.content;
            var eventDetails = state.eventDetails;
            if (!eventDetails || eventDetails.type !== 'event') {
                return __assign(__assign({}, state), { isLoadingSeatingPlan: false });
            }
            if (eventDetails.id !== id) {
                return state;
            }
            return __assign(__assign({}, state), { isLoadingSeatingPlan: false, eventDetails: __assign(__assign({}, eventDetails), { seatingPlan: __assign(__assign({}, eventDetails.seatingPlan), { svgFile: content }) }) });
        }
        case getType(actions.loadSeatingPlan.failure): return __assign(__assign({}, state), { isLoadingSeatingPlan: false });
        case getType(actions.setEventSSRFlag): return __assign(__assign({}, state), { loadedWithSSR: action.payload });
        default: return state;
    }
};
var rawEventPerformerToEventPerformer = function (performer) {
    return __assign(__assign({}, performer), { slug: slugifyName(performer.name) });
};
export var createEventLinkData = function (name, eventDate, city, id) { return ({
    eventName: slugifyName(name),
    venueCity: slugifyName(city),
    eventDate: slugifyName(formatDateWithoutTZ(new Date(eventDate))),
    id: id
}); };
var setEventStatus = function (event) {
    if (event === null || event === void 0 ? void 0 : event.postponed) {
        return 'postponed';
    }
    else if (event === null || event === void 0 ? void 0 : event.previousEventDate) {
        return 'rescheduled';
    }
    else {
        return 'active';
    }
};
export var rawEventToEvent = function (event) {
    var _a, _b, _c, _d;
    return ({
        type: 'event',
        id: event.id,
        name: event.name,
        eventLinkSlug: createEventLinkData(event.name, event.eventDate, event.venue.address.city, event.id),
        description: event.description,
        eventDate: new Date(event.eventDate),
        onSaleDate: new Date(event.onSaleDate),
        performers: event.performers.map(rawEventPerformerToEventPerformer),
        venue: rawVenueToVenue(event.venue),
        keywords: event.keywords,
        seatingPlan: __assign(__assign({}, event.seatingPlan), { svgFileUrl: blobFile(event.seatingPlan.svgFileUrl) }),
        currency: event.currency || 'GBP',
        timezone: event.timezone,
        notes: event.notes || null,
        imageUrl: blobFile(event.imageUrl),
        restrictions: event.restrictions || null,
        category: (_a = event.category) !== null && _a !== void 0 ? _a : '',
        subcategory: (_b = event.subcategory) !== null && _b !== void 0 ? _b : '',
        addressCountry: (_c = event.addressCountry) !== null && _c !== void 0 ? _c : '',
        postalCode: (_d = event.postalCode) !== null && _d !== void 0 ? _d : '',
        status: setEventStatus(event),
        faqs: event.faqs,
        similarPerformers: event.similarPerformers,
        mediaLinks: event.mediaLinks,
        previousEventDate: event.previousEventDate ? new Date(event.previousEventDate) : undefined,
    });
};
export var rawEventSearchToEventSearch = function (search) {
    var _a, _b;
    return ({
        id: search.eventId,
        name: search.eventName,
        eventDate: new Date(search.eventDate),
        onSaleDate: new Date(search.onSaleDate),
        imageUrl: blobFile(search.imageUrl),
        minSellPrice: search.minSellPrice,
        maxBuyPrice: search.maxBuyPrice,
        venueId: search.venueId,
        venueLocation: search.venueLocation,
        venueName: search.venueName,
        venueCity: search.venueCity,
        venueStreet: search.venueStreet,
        eventLinkSlug: createEventLinkData(search.eventName, search.eventDate, search.venueCity, search.eventId),
        performers: search.performers,
        timezone: search.timezone,
        venueImageUrl: blobFile(search.venueImageUrl),
        category: (_a = search.category) !== null && _a !== void 0 ? _a : '',
        subcategory: (_b = search.subcategory) !== null && _b !== void 0 ? _b : '',
        addressCountry: search.addressCountry,
        postalCode: search.postalCode,
        seatingPlan: search.seatingPlan,
        ticketTypes: search.ticketTypes,
        status: setEventStatus(search),
        previousEventDate: search.previousEventDate ? new Date(search.previousEventDate) : undefined,
    });
};
export var rawLastNextEventToLastNextEvent = function (lastNextEvent) { return ({
    nextEvent: (lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.nextEvent) ? rawEventSearchToEventSearch(lastNextEvent.nextEvent) : null,
    lastEvent: (lastNextEvent === null || lastNextEvent === void 0 ? void 0 : lastNextEvent.lastEvent) ? rawEventSearchToEventSearch(lastNextEvent.lastEvent) : null,
}); };
export var rawSellEventListingToEventListing = function (_a) {
    var treadableQuantities = _a.treadableQuantities, seatingInformation = _a.seatingInformation, sellPrice = _a.sellPrice, sellCurrency = _a.sellCurrency, faceValue = _a.faceValue, currentReservations = _a.currentReservations, rest = __rest(_a, ["treadableQuantities", "seatingInformation", "sellPrice", "sellCurrency", "faceValue", "currentReservations"]);
    return (__assign({ type: 'sell', treadableQuantities: treadableQuantities, maxTreadableQuantities: Math.max.apply(Math, __spreadArray(__spreadArray([], __read(treadableQuantities)), [0])), price: {
            value: sellPrice,
            currency: sellCurrency,
        }, faceValue: {
            value: faceValue,
            currency: sellCurrency,
        }, seatingInfo: [normalizeSellSeatingInfo(seatingInformation, null)], currentReservations: currentReservations.map(function (_a) {
            var expirationTime = _a.expirationTime, rest = __rest(_a, ["expirationTime"]);
            return (__assign(__assign({}, rest), { expirationTime: new Date(expirationTime) }));
        }) }, rest));
};
export var getEventMainPerformer = function (event) {
    var _a, _b;
    return (_b = (_a = event.performers.find(function (p) { return p.isMainPerformer; })) !== null && _a !== void 0 ? _a : event.performers[0]) !== null && _b !== void 0 ? _b : { id: 0, name: '', slug: '', isMainPerformer: false };
};
export var rawBuyEventListingToEventListing = function (_a) {
    var availableQuantity = _a.availableQuantity, seatingInformation = _a.seatingInformation, buyPrice = _a.buyPrice, buyCurrency = _a.buyCurrency, minimumFaceValue = _a.minimumFaceValue, currentReservations = _a.currentReservations, rest = __rest(_a, ["availableQuantity", "seatingInformation", "buyPrice", "buyCurrency", "minimumFaceValue", "currentReservations"]);
    return __assign({ type: 'buy', treadableQuantities: [availableQuantity], maxTreadableQuantities: availableQuantity, price: {
            value: buyPrice,
            currency: buyCurrency,
        }, seatingInfo: normalizeBuySeatingInfo(seatingInformation), ticketSplit: 'all', faceValue: {
            value: minimumFaceValue,
            currency: buyCurrency,
        }, ownerInfo: { affiliatedWithPlatform: false, isBusinessSeller: false, isEventOrganiser: false }, currentReservations: currentReservations.map(function (_a) {
            var expirationTime = _a.expirationTime, rest = __rest(_a, ["expirationTime"]);
            return (__assign(__assign({}, rest), { expirationTime: new Date(expirationTime) }));
        }) }, rest);
};
export var emptySeatingPlan = {
    svgFileUrl: '',
    name: '',
    structure: {
        categories: [],
    },
};
export var eventDetailsSelector = function (state) { return state.events.eventDetails; };
export function searchParamsToQueryString(qp) {
    function date(d) {
        try {
            return d.toISOString();
        }
        catch (e) {
            return '';
        }
    }
    return qs.stringify(__assign(__assign(__assign(__assign(__assign({}, qp), (qp.dateFrom ? { dateFrom: date(qp.dateFrom) } : {})), (qp.dateTo ? { dateTo: date(qp.dateTo) } : {})), (qp.onSaleDateFrom ? { onSaleDateFrom: date(qp.onSaleDateFrom) } : {})), (qp.onSaleDateTo ? { onSaleDateTo: date(qp.onSaleDateTo) } : {})));
}
export var eventsApi = {
    getLocations: function () { return request('/api/events/locations'); },
    getCategories: function () { return request('/api/events/categories'); },
    search: function (queryParams) {
        return request('/api/events/search', queryParams);
    },
    lastNextEvent: function (type, id, id2) {
        if ((type === 'subcategory' || type === 'city') && id2) {
            return request("/api/events/lastnext/" + type + "/" + id + "/" + id2);
        }
        return request("/api/events/lastnext/" + type + "/" + id);
    },
    getById: function (id) { return request("/api/events/get/" + id); },
    getWithListings: function (eventId) {
        // this is in listings api, but imho should be here.
        return request("/api/listings/event/" + eventId);
    },
};
export var rawEventSummaryToEventSummary = function (event) {
    var date = event.date, rest = __rest(event, ["date"]);
    return __assign(__assign({}, rest), { date: new Date(date) });
};
export var mapEventToEventSummary = function (_a) {
    var id = _a.id, name = _a.name, venue = _a.venue, eventDate = _a.eventDate, timezone = _a.timezone, performers = _a.performers, keywords = _a.keywords, seatingPlan = _a.seatingPlan, postponed = _a.postponed;
    return {
        id: id,
        name: name,
        date: eventDate,
        timezone: timezone,
        venueCity: venue.address.city,
        venueName: venue.name,
        performers: performers.map(function (p) { return p.name; }),
        keywords: keywords,
        seatingPlan: seatingPlan,
        ticketTypes: venue.ticketTypes,
        venueId: venue.id,
        postponed: postponed
    };
};
export var mapEventSearchToEventSummary = function (_a) {
    var id = _a.id, name = _a.name, venueCity = _a.venueCity, venueName = _a.venueName, eventDate = _a.eventDate, timezone = _a.timezone, performers = _a.performers, venueId = _a.venueId;
    return ({
        id: id,
        name: name,
        date: eventDate,
        timezone: timezone,
        venueCity: venueCity,
        venueName: venueName,
        performers: performers.map(function (p) { return p.performerName; }),
        keywords: [],
        seatingPlan: { name: '', structure: { categories: [] }, svgFileUrl: '' },
        venueId: venueId,
        ticketTypes: {}
    });
};
export var getEventsDateRangeText = function (events) {
    if (events.length === 0) {
        return '';
    }
    var sortedEventsDates = events
        .sort(function (a, b) {
        return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
    })
        .map(function (e) { return e.eventDate.getFullYear(); });
    var mostRecentDate = sortedEventsDates[0];
    var lastDate = sortedEventsDates[sortedEventsDates.length - 1];
    var dateRange = mostRecentDate === lastDate
        ? lastDate.toString()
        : mostRecentDate + "-" + lastDate.toString().slice(2);
    return dateRange;
};
export var combineEventsToCategoryAndDateTitle = function (events) {
    if (!events.length) {
        return '';
    }
    var mostPopularCategory = findMostPopular(events, 'category');
    return mapCategoryToEventType(mostPopularCategory[0]) + " " + getEventsDateRangeText(events);
};
export var mapEventToEventSearch = function (_a, smallestSellPrice) {
    var _b;
    var seatingPlan = _a.seatingPlan, eventDate = _a.eventDate, onSaleDate = _a.onSaleDate, venue = _a.venue, performers = _a.performers, category = _a.category, timezone = _a.timezone, eventLinkSlug = _a.eventLinkSlug, subcategory = _a.subcategory, addressCountry = _a.addressCountry, postalCode = _a.postalCode, name = _a.name, id = _a.id, imageUrl = _a.imageUrl, currency = _a.currency, status = _a.status, previousEventDate = _a.previousEventDate;
    return ({
        id: id,
        name: name,
        imageUrl: imageUrl,
        eventDate: eventDate,
        onSaleDate: onSaleDate,
        addressCountry: addressCountry,
        postalCode: postalCode,
        category: category,
        seatingPlan: seatingPlan,
        subcategory: subcategory,
        eventLinkSlug: eventLinkSlug,
        timezone: timezone,
        minSellPrice: smallestSellPrice ? { currency: currency, value: smallestSellPrice } : null,
        maxBuyPrice: null,
        performers: performers.map(function (val) { return ({
            performerId: val.id,
            performerName: val.name
        }); }),
        venueCity: venue.address.city,
        venueId: venue.id,
        venueImageUrl: (_b = venue.imageUrl) !== null && _b !== void 0 ? _b : '',
        venueLocation: venue.address.city,
        venueName: venue.name,
        venueStreet: venue.address.street1,
        status: status,
        previousEventDate: previousEventDate
    });
};
export var getEventFullNameFromArgs = function (eventName, venueName, venueCity, eventDate, timezone) {
    return eventName + " - " + formatVenueData({ venueName: venueName, venueCity: venueCity }) + " - " + formatDateTime(eventDate, timezone);
};
export var getEventFullName = function (evt) {
    return getEventFullNameFromArgs(evt.name, evt.venue.name, evt.venue.address.city, evt.eventDate, evt.timezone);
};
export var getNewEventsSearchParams = function () {
    var now = Date.now();
    var onSaleDateFrom = new Date(now - 96 * 3600 * 1000);
    var onSaleDateTo = new Date(now + 120 * 3600 * 1000);
    return {
        onSaleDateFrom: onSaleDateFrom,
        onSaleDateTo: onSaleDateTo
    };
};
export function onSearchEvents(_a) {
    var _b, items, count, e_1;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(eventsApi.search, payload)];
            case 1:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(actions.searchEvents.success({ events: items.map(rawEventSearchToEventSearch), count: count, displayMoreResults: !!payload.offset }))];
            case 2:
                _c.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _c.sent();
                return [4 /*yield*/, put(actions.searchEvents.failure(e_1))];
            case 4:
                _c.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot search Events')];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadCategories() {
    var categories, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(eventsApi.getCategories)];
            case 1:
                categories = _a.sent();
                return [4 /*yield*/, put(actions.loadEventCategories.success(categories))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_2 = _a.sent();
                return [4 /*yield*/, put(actions.loadEventCategories.failure(e_2))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load event categories')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadLocations() {
    var locations, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(eventsApi.getLocations)];
            case 1:
                locations = _a.sent();
                return [4 /*yield*/, put(actions.loadEventLocations.success(locations))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_3 = _a.sent();
                return [4 /*yield*/, put(actions.loadEventLocations.failure(e_3))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load event locations')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadSeatingPlan(_a) {
    var id, event, seatingPlanFileContent, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 9, , 11]);
                return [4 /*yield*/, select(eventDetailsSelector)];
            case 2:
                event = _b.sent();
                if (!(!event || event.type === 'eventNotFound' || event.id !== id)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(actions.loadSeatingPlan.failure({ id: id, error: 'Event not available' }))];
            case 3:
                _b.sent();
                return [2 /*return*/];
            case 4:
                if (!event.seatingPlan.svgFile) return [3 /*break*/, 6];
                return [4 /*yield*/, put(actions.loadSeatingPlan.success({ id: id, content: event.seatingPlan.svgFile }))];
            case 5:
                _b.sent();
                return [2 /*return*/];
            case 6: return [4 /*yield*/, call(request, event.seatingPlan.svgFileUrl, undefined, { responseType: 'text' })];
            case 7:
                seatingPlanFileContent = _b.sent();
                return [4 /*yield*/, put(actions.loadSeatingPlan.success({ id: id, content: seatingPlanFileContent }))];
            case 8:
                _b.sent();
                return [3 /*break*/, 11];
            case 9:
                e_4 = _b.sent();
                return [4 /*yield*/, put(actions.loadSeatingPlan.failure({ id: id, error: "" + e_4 }))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function onLoadEventDetails(_a) {
    var eventDetails, event, sellListings, buyListings, queryParams, _b, items, count, e_5;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 10]);
                return [4 /*yield*/, call(eventsApi.getWithListings, payload.eventId)];
            case 1:
                eventDetails = _c.sent();
                event = rawEventToEvent(eventDetails.event);
                sellListings = eventDetails.sellListings.map(rawSellEventListingToEventListing);
                buyListings = eventDetails.buyListings.map(rawBuyEventListingToEventListing);
                if (!payload.shouldFetchEvents) return [3 /*break*/, 4];
                queryParams = {
                    performerId: event.performers[0].id,
                    limit: SANE_LIMIT,
                    orderBy: 'eventDate',
                };
                return [4 /*yield*/, call(eventsApi.search, queryParams)];
            case 2:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(actions.searchEvents.success({ events: items.map(rawEventSearchToEventSearch), count: count }))];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [4 /*yield*/, put(actions.loadEventDetails.success({ event: event, sellListings: sellListings, buyListings: buyListings }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 10];
            case 6:
                e_5 = _c.sent();
                if (!(get(e_5, 'status') === 404)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(actions.loadEventDetails.success({ event: { type: 'eventNotFound', id: payload.eventId, redirectUrl: get(e_5, ['data', 'redirectUrl']) }, sellListings: [], buyListings: [] }))];
            case 7:
                _c.sent();
                return [2 /*return*/];
            case 8: return [4 /*yield*/, put(actions.loadEventDetails.failure({ id: payload.eventId, error: "" + e_5 }))];
            case 9:
                _c.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function onReloadEventDetails(_a) {
    var id, currentDetails, currentDetailsId, idForFetch, eventDetails, event, sellListings, buyListings, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload;
                return [4 /*yield*/, select(eventDetailsSelector)];
            case 1:
                currentDetails = _b.sent();
                currentDetailsId = (!currentDetails || !('id' in currentDetails)) ? null : currentDetails.id;
                idForFetch = id !== null && id !== void 0 ? id : currentDetailsId;
                if (idForFetch == null) {
                    return [2 /*return*/];
                }
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 10]);
                return [4 /*yield*/, call(eventsApi.getWithListings, idForFetch)];
            case 3:
                eventDetails = _b.sent();
                return [4 /*yield*/, select(eventDetailsSelector)];
            case 4:
                event = _b.sent();
                if ((event === null || event === void 0 ? void 0 : event.type) !== 'event') {
                    throw { status: 404 };
                }
                sellListings = eventDetails.sellListings.map(rawSellEventListingToEventListing);
                buyListings = eventDetails.buyListings.map(rawBuyEventListingToEventListing);
                return [4 /*yield*/, put(actions.loadEventDetails.success({ event: event, sellListings: sellListings, buyListings: buyListings }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 10];
            case 6:
                e_6 = _b.sent();
                if (!(get(e_6, 'status') === 404)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(actions.loadEventDetails.success({ event: { type: 'eventNotFound', id: idForFetch }, sellListings: [], buyListings: [] }))];
            case 7:
                _b.sent();
                return [2 /*return*/];
            case 8: return [4 /*yield*/, put(actions.loadEventDetails.failure({ id: idForFetch, error: "" + e_6 }))];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function onLoadNewEvents(_a) {
    var newEventParams, _b, items, count, e_7;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 7]);
                newEventParams = getNewEventsSearchParams();
                return [4 /*yield*/, call(eventsApi.search, __assign(__assign({ searchPhrase: payload }, newEventParams), { limit: SANE_LIMIT }))];
            case 1:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(actions.searchEvents.success({ events: items.map(rawEventSearchToEventSearch), count: count }))];
            case 2:
                _c.sent();
                return [3 /*break*/, 7];
            case 3:
                e_7 = _c.sent();
                return [4 /*yield*/, put(actions.searchEvents.failure(e_7))];
            case 4:
                _c.sent();
                if (!(get(e_7, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull New Events')];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function eventsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.searchEvents.request), onSearchEvents)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.loadEventDetails.request), onLoadEventDetails)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.reloadEventDetails), onReloadEventDetails)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.loadNewEvents), onLoadNewEvents)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.loadSeatingPlan.request), onLoadSeatingPlan)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.loadEventCategories.request), onLoadCategories)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.loadEventLocations.request), onLoadLocations)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var sellListingsSelector = function (state) { return state.events.sellListings; };
export var buyListingsSelector = function (state) { return state.events.buyListings; };
export var isLoadingSelector = function (state) { return state.events.isLoading; };
export var isSeatingPlanLoadingSelector = function (state) { return state.events.isLoadingSeatingPlan; };

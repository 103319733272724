var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export var isEmail = function (email) {
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
};
export var isEmpty = function (value) {
    return value === '' || value === null || value === undefined;
};
export var isEqual = function (a, b) {
    return a === b;
};
export var success = undefined;
export var error = function (msg) { return ({ error: msg }); };
export var warning = function (msg) { return ({ warning: msg }); };
export function isError(result) {
    return !!result && !!result.error;
}
function isWarning(result) {
    return !!result && !!result.warning;
}
export var compose = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (value) { return args.reduce(function (agg, validator) {
        if (isError(agg)) {
            return agg;
        }
        var result = typeof validator === 'function' ? validator(value) : validator;
        if (isError(result) || (isWarning(result) && !agg)) {
            return result;
        }
        return agg;
    }, success); };
};
export var customIsRequired = function (errorMessage) { return function (v) { return v != null && !(typeof v === 'string' && v.trim() === '') ? success : error(errorMessage); }; };
export var isRequired = function (v) { return customIsRequired('This field is required')(v); };
export var isPositiveOrZero = function (v) { return parseFloat(v) >= 0 || isNaN(parseFloat(v)) ? success : error('Only non negative values are allowed'); };
export var isPositive = function (v) { return parseFloat(v) > 0 || isNaN(parseFloat(v)) ? success : error('Value has to be greater than 0'); };
export var isNaturalNumber = function (v) { return parseFloat(v) >= 0 || isNaN(parseFloat(v)) ? success : error('Value has to be greater or equal 0'); };
export var isGreaterOrEqualOne = function (v) { return parseFloat(v) >= 1 || isNaN(parseFloat(v)) ? success : error('Value has to be greater or equal 1.00'); };
export var warnIf0 = function (v) { return parseFloat(v) !== 0 || isNaN(parseFloat(v)) ? success : warning('Are you sure value 0 is correct?'); };
export var warnIfMoreThanN = function (n) { return function (v) { return parseFloat(v) <= n || isNaN(parseFloat(v)) ? success : warning("Value exceeds " + n + ", are you sure it's correct?"); }; };
export var isLessThanOrEqualN = function (n) { return function (v) { return parseFloat(v) <= n || isNaN(parseFloat(v)) ? success : error("Value has to be less than or equal " + n); }; };
export var hasLessThenNDigits = function (n) { return function (v) { return ("" + v).length <= n ? success : error("Value must be " + n + " digits or fewer"); }; };
export var maxLength = function (n) { return function (v) { return typeof v === 'string' && v.length <= n ? success : error("Length should be less than or equal " + n); }; };
export var minLength = function (n) { return function (v) { return typeof v === 'string' && v.length >= n ? success : error("Length should be greater than or equal " + n); }; };
export var isAlphanumeric = function (v) { return typeof v === 'string' && !!v.match('^[a-zA-Z0-9]*$') ? success : error('Only alphanumeric values are allowed'); };
export var hasNDecimalPlaces = function (n) { return function (v) {
    var _a = __read(("" + (v || '')).split('.'), 2), _b = _a[1], dec = _b === void 0 ? '' : _b;
    return dec.length <= n || v == null ? success : error("Only " + n + " decimal places are alowed");
}; };
export var isNumber = function (v) { return !isNaN(v) || v == null ? success : error('Number is required'); };
export var isInteger = compose(isNumber, function (v) { return parseInt(v, 10) === parseFloat(v) || isNaN(parseFloat(v)) ? success : error('Only integer values are allowed'); });
export var isChecked = function (v) { return v ? success : error('This field is required'); };
export var isRequiredError = error('This field is required');
export var customFaceValueErrorMessage = 'Please enter the minimum face value per ticket you are willing to accept. If you\'re happy with any value of ticket then please enter zero';
export var isCustomFaceValueError = error(customFaceValueErrorMessage);

import React from 'react';
import { Helmet } from 'react-helmet';
import { useHostname, useRoutingSetter } from './useStaticContext';
import { useLocation } from 'react-router-dom';
export function Seo(_a) {
    var _b;
    var title = _a.title, description = _a.description, keywords = _a.keywords, canonical = _a.canonical, noIndex = _a.noIndex, redirection = _a.redirection, isMovedPermanently = _a.isMovedPermanently;
    var hostname = useHostname();
    var location = useLocation();
    var locationPathname = location.pathname.replace(/^\/$/, '');
    var canonicalUrl = "" + hostname + (canonical !== null && canonical !== void 0 ? canonical : locationPathname);
    var redirectionUrl = redirection ? "" + hostname + redirection : canonicalUrl;
    var routingStatus = ((_b = redirection !== null && redirection !== void 0 ? redirection : canonical) !== null && _b !== void 0 ? _b : locationPathname) !== locationPathname
        ? isMovedPermanently
            ? { statusCode: 301, redirectionUrl: redirectionUrl }
            : { statusCode: 302, redirectionUrl: redirectionUrl }
        : { statusCode: 200 };
    useRoutingSetter(routingStatus);
    return (React.createElement(Helmet, null,
        React.createElement("title", null, title !== null && title !== void 0 ? title : 'Tickets for concerts, sports, theatre & comedy and festivals - Beeyay'),
        React.createElement("meta", { name: "description", content: description !== null && description !== void 0 ? description : '\'Buy and sell tickets for live events on Beeyay, the innovative ticket marketplace where fans get to name their price. Buy with confidence - all purchases covered by the Beeyay Guarantee.' }),
        React.createElement("meta", { name: "keywords", content: keywords !== null && keywords !== void 0 ? keywords : 'tickets, buy, sell, tickets, beeyay, concert, sport, theater' }),
        React.createElement("link", { rel: "canonical", href: canonicalUrl }),
        noIndex && React.createElement("meta", { name: 'robots', content: 'noindex' })));
}

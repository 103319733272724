var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Header, Loader } from 'semantic-ui-react';
import eventsStyles from '../events/events.less';
import { PerformerCard } from './performerCard';
import styles from './performers.less';
var Performers = /** @class */ (function (_super) {
    __extends(Performers, _super);
    function Performers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Performers.prototype.render = function () {
        var _a = this.props, promoted = _a.promoted, loading = _a.loading, userSettings = _a.userSettings;
        if (!promoted || !promoted.length || !userSettings) {
            return loading
                ? React.createElement(Loader, { active: true, className: styles.loader })
                : React.createElement(Header, { as: 'h2', textAlign: 'center' }, "Try looking for an event using a search box on top.");
        }
        return React.createElement("div", __assign({ className: cn(eventsStyles.Events, eventsStyles.Card) }, t('Performers')), promoted.map(function (performer, idx) { return React.createElement(PerformerCard, { key: idx, performer: performer, userSettings: userSettings }); }));
    };
    return Performers;
}(PureComponent));
var mapStateToProps = function (state) { return ({
    promoted: state.performer.promotedPerformers,
    loading: state.performer.isLoadingPromoted,
    userSettings: state.userSettings.userSettings || state.userSettings.globalSettings,
}); };
export default connect(mapStateToProps)(Performers);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { Icon } from '@components/icon';
import cn from 'classnames';
var SortingItem = /** @class */ (function (_super) {
    __extends(SortingItem, _super);
    function SortingItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.changeSorting = function () {
            var _a = _this.props, onChange = _a.onChange, direction = _a.direction, name = _a.name;
            if (!name) {
                return;
            }
            var isActive = _this.props.by === name;
            var dir = isActive ? direction === 'ascending' ? 'descending' : 'ascending' : direction;
            onChange(name, dir);
        };
        return _this;
    }
    SortingItem.prototype.render = function () {
        var _a;
        var _b = this.props, name = _b.name, children = _b.children, direction = _b.direction, className = _b.className;
        var isActive = this.props.by === name;
        return (React.createElement("div", { onClick: name && this.changeSorting, className: className },
            children,
            React.createElement(Icon, { height: 16, width: 16, className: cn((_a = {}, _a['visibleIcon'] = isActive, _a)), icon: direction === 'ascending' ? 'caretUp' : 'caretDown' })));
    };
    return SortingItem;
}(Component));
export { SortingItem };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { DATE_TIME_FORMAT } from '@logic/helpers/date';
import { addHours, addMinutes, startOfDay } from 'date-fns';
import range from 'lodash/range';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useField, useFormState } from 'react-final-form';
import { DayPicker } from '../dayPicker/dayPicker';
import { Dropdown, genOptions } from '../dropdown';
import { useFirstNotNullableValue } from '../useFirstNotNullableValue';
import styles from './dateTimePicker.less';
var twoDigit = function (x) { return ('0' + x).slice(-2); };
var getTimeOptions = function (cutoff, dt) {
    var _a;
    var generateOptions = function (from, to) { return genOptions.apply(void 0, __spreadArray([], __read(range(from, to)
        .map(function (x) { return twoDigit(Math.floor(x / 2)) + ":" + twoDigit(x % 2 * 30); })
        .map(function (x) { return [x, x]; })))); };
    if (!cutoff || !dt) {
        return generateOptions(0, 48);
    }
    else {
        var hours = cutoff.getHours();
        var minutes = cutoff.getMinutes();
        var cutoffTo = (hours * 2) + Math.floor(minutes / 30) + 1;
        var to = ((_a = dt.date) === null || _a === void 0 ? void 0 : _a.getDate()) === cutoff.getDate() ? cutoffTo : 48;
        var cutoffDt = toDateTimeInfo(cutoff);
        var eventTime = cutoffDt.time ? genOptions([cutoffDt.time, cutoffDt.time]) : [];
        var opts = uniqBy(generateOptions(0, to).concat(eventTime), function (x) { return x.value; });
        return sortBy(opts, function (x) { return x.value; });
    }
};
var updateDate = function (date, hour, minutes) { return addMinutes(addHours(startOfDay(date), Number(hour)), Number(minutes)); };
export var toDateTimeInfo = function (value) {
    if (value instanceof Date) {
        var time = value.toLocaleTimeString('en-GB').substr(0, 5);
        return {
            date: value,
            time: time
        };
    }
    return value;
};
export function DateTimeInput(_a) {
    var onChange = _a.onChange, onDateChange = _a.onDateChange, onTimeChange = _a.onTimeChange, value = _a.value, touched = _a.touched, error = _a.error, cutoffDate = _a.cutoffDate, disabled = _a.disabled, small = _a.small, onBlur = _a.onBlur, dayPickerInputProps = _a.dayPickerInputProps, className = _a.className;
    var dateTime = useMemo(function () { return toDateTimeInfo(value); }, [value]);
    var timeOptions = useMemo(function () { return getTimeOptions(cutoffDate, dateTime); }, [cutoffDate, dateTime]);
    var changeDate = useCallback(function (dt) {
        var _a = __read(dateTime.time ? dateTime.time.split(':') : ['0', '0'], 2), h = _a[0], m = _a[1];
        var date = updateDate(dt, h, m);
        var updatedDt = __assign(__assign({}, dateTime), { date: date });
        onChange(updatedDt);
        if (onDateChange) {
            onDateChange(updatedDt);
        }
    }, [onChange, onDateChange, dateTime]);
    var changeTime = useCallback(function (_, d) {
        var _a = __read(d.value.split(':'), 2), h = _a[0], m = _a[1];
        var date = dateTime.date ? updateDate(dateTime.date, h, m) : undefined;
        var updatedDt = { date: date, time: d.value };
        onChange(updatedDt);
        if (onTimeChange) {
            onTimeChange(updatedDt);
        }
    }, [onChange, onTimeChange, dateTime]);
    var classNames = useMemo(function () { return cn(styles.dateTimeInput, small ? styles.dateTimeInputSmall : ''); }, [small]);
    return (React.createElement("div", { className: classNames },
        React.createElement(DayPicker, __assign({}, dayPickerInputProps, { onDayChange: changeDate, format: DATE_TIME_FORMAT, value: dateTime.date, onDayPickerShow: onBlur, error: touched && !!error, disabled: disabled, className: className })),
        React.createElement(Dropdown, { selection: true, options: timeOptions, value: dateTime.time, onChange: changeTime, error: touched && !!error, disabled: disabled, onBlur: onBlur, className: className })));
}
export function DateTimeInputField(_a) {
    var name = _a.name, dateMapper = _a.dateMapper, onChange = _a.onChange, rest = __rest(_a, ["name", "dateMapper", "onChange"]);
    var state = useFormState();
    var initialValue = useFirstNotNullableValue(function () { return get(state.values, name); });
    var _b = useField(name, { initialValue: initialValue }), input = _b.input, meta = _b.meta;
    var handleChange = function (dt) {
        var value = dateMapper ? dateMapper(dt) : dt;
        input.onChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return React.createElement(DateTimeInput, __assign({ touched: !!meta.touched, error: meta.error }, rest, input, { onChange: handleChange }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { rawLastNextEventToLastNextEvent } from '@logic/events';
import { isCountryCode } from '@logic/helpers/enumerators';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import { eventsApi, rawEventSearchToEventSearch } from './events';
import { slugifyName } from './helpers/location';
import request, { blobFile, SANE_LIMIT } from './helpers/request';
import get from 'lodash/get';
export var loadVenueDetails = createAction('load venue details', function (id) { return ({
    id: id,
}); });
export var venueDetailsLoaded = createAction('fetching venue details succeed', function (venue) { return ({
    venue: venue,
}); });
export var venueDetailsLoadingFail = createAction('fetching venue details failed', function (error) { return ({
    error: error,
}); });
export var loadVenueEvents = createAction('load list of venue events', function (venueId, offset) { return ({
    venueId: venueId,
    offset: offset
}); });
export var venueEventsLoaded = createAction('fetching venue events succeed', function (events, lastEvent, count, searchParams) { return ({
    events: events,
    lastEvent: lastEvent,
    count: count,
    searchParams: searchParams
}); });
export var venueEventsLoadingFail = createAction('fetching venue events failed', function (error) { return ({
    error: error,
}); });
export var loadLastNextVenueEvent = createAction('load last next venue event', function (id) { return ({ id: id }); });
export var lastNextVenueEventLoaded = createAction('fetching last next venue event succeed', function (lastNextEvent) { return lastNextEvent; });
export var lastNextVenueLoadingFail = createAction('fetching last next venue event failed', function (error) { return ({ error: error }); });
export var removeVenueDetails = createAction('remove venue details');
export var setVenueSSRFlag = createAction('set venue SSR flag', function (flag) { return ({ flag: flag }); });
export var initialState = {
    isLoadingDetails: true,
    isLoadingEvents: true,
    venueDetails: null,
    venueLastNextEvent: null,
    venueEvents: [],
    searchParams: {
        limit: SANE_LIMIT,
        orderBy: 'eventDate',
        offset: 0,
        venueId: undefined,
    },
    eventsCount: 0,
    loadedWithSSR: false
};
export var formatVenueData = function (_a) {
    var venueName = _a.venueName, venueCity = _a.venueCity;
    return venueName + ", " + venueCity;
};
export var formatVenue = function (_a) {
    var name = _a.name, city = _a.address.city;
    return formatVenueData({ venueName: name, venueCity: city });
};
export var emptyAddress = function () { return ({
    street1: '',
    street2: null,
    street3: null,
    city: '',
    postCode: null,
    countryCode: '',
    placeId: '',
}); };
export var emptyVenue = function () { return ({
    id: 0,
    name: '',
    slug: '',
    description: null,
    capacity: 0,
    imageUrl: null,
    address: emptyAddress(),
    ticketTypes: {
        paper: true,
        eTicket: true,
        mobileTransfer: false,
        ticketmaster: false,
        qrCode: false,
    },
    status: 'active',
    faqs: []
}); };
var rawVenueAddressToVenueAddress = function (address) {
    return __assign(__assign({}, address), { countryCode: isCountryCode(address.countryCode) ? address.countryCode : '' });
};
export var rawVenueToVenue = function (venue) {
    var _a;
    return Object.keys(venue).length
        ? __assign(__assign({}, venue), { imageUrl: blobFile((_a = venue.imageUrl) !== null && _a !== void 0 ? _a : ''), slug: slugifyName(venue.name), address: rawVenueAddressToVenueAddress(venue.address) }) : emptyVenue();
};
export var venueReducer = createReducer((_a = {},
    _a[loadVenueDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingDetails: true, venueDetails: null, venueEvents: [] })); },
    _a[venueDetailsLoaded.getType()] = function (state, _a) {
        var venue = _a.venue;
        return (__assign(__assign({}, state), { isLoadingDetails: false, venueDetails: venue }));
    },
    _a[venueDetailsLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingDetails: false, venueDetails: null })); },
    _a[removeVenueDetails.getType()] = function (state) { return (__assign(__assign({}, state), { venueDetails: null })); },
    _a[loadLastNextVenueEvent.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: true, venueLastNextEvent: null })); },
    _a[lastNextVenueEventLoaded.getType()] = function (state, lastNextEvents) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: false, venueLastNextEvent: lastNextEvents })); },
    _a[lastNextVenueLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: false, venueLastNextEvent: null })); },
    _a[loadVenueEvents.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingEvents: true })); },
    _a[venueEventsLoaded.getType()] = function (state, _a) {
        var events = _a.events, count = _a.count, searchParams = _a.searchParams;
        return (__assign(__assign({}, state), { isLoadingEvents: false, venueEvents: state.searchParams.venueId === searchParams.venueId ? __spreadArray(__spreadArray([], __read(state.venueEvents)), __read(events)) : events, eventsCount: count, searchParams: searchParams }));
    },
    _a[venueEventsLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingEvents: false })); },
    _a[setVenueSSRFlag.getType()] = function (state, _a) {
        var flag = _a.flag;
        return (__assign(__assign({}, state), { loadedWithSSR: flag }));
    },
    _a), initialState);
export function onLoadVenueDetails(_a) {
    var venueDetails, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(request, "/api/venues/get/" + payload.id)];
            case 1:
                venueDetails = _b.sent();
                return [4 /*yield*/, put(venueDetailsLoaded(rawVenueToVenue(venueDetails)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_1 = _b.sent();
                return [4 /*yield*/, put(venueDetailsLoadingFail(e_1))];
            case 4:
                _b.sent();
                if (!(get(e_1, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull Venue')];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onLoadVenueEvents(_a) {
    var queryParams, todayDate, dateFrom, lastEventsPayload, previousEvents, lastEvent, _b, items, count, e_2;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 4, , 8]);
                queryParams = {
                    venueId: payload.venueId,
                    limit: SANE_LIMIT,
                    orderBy: 'eventDate',
                    offset: payload.offset
                };
                todayDate = new Date();
                dateFrom = new Date(todayDate.setFullYear(todayDate.getFullYear() - 1));
                lastEventsPayload = __assign(__assign({}, queryParams), { dateFrom: dateFrom, dateTo: todayDate, orderByDesc: true });
                return [4 /*yield*/, call(eventsApi.search, lastEventsPayload)];
            case 1:
                previousEvents = _c.sent();
                lastEvent = previousEvents.items[0] ? rawEventSearchToEventSearch(previousEvents.items[0]) : null;
                return [4 /*yield*/, call(eventsApi.search, queryParams)];
            case 2:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(venueEventsLoaded(items.map(rawEventSearchToEventSearch), lastEvent, count, queryParams))];
            case 3:
                _c.sent();
                return [3 /*break*/, 8];
            case 4:
                e_2 = _c.sent();
                return [4 /*yield*/, put(venueEventsLoadingFail(e_2))];
            case 5:
                _c.sent();
                if (!(get(e_2, 'status') !== 404)) return [3 /*break*/, 7];
                return [4 /*yield*/, call(toast.error, 'Cannot pull Events for Venue')];
            case 6:
                _c.sent();
                _c.label = 7;
            case 7: return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onLoadVenueLastNextEvent(_a) {
    var rawLastNextEvent, lastNextEvent, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'venue', payload.id)];
            case 1:
                rawLastNextEvent = _b.sent();
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextVenueEventLoaded(lastNextEvent))];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_3 = _b.sent();
                return [4 /*yield*/, put(lastNextVenueLoadingFail(e_3))];
            case 4:
                _b.sent();
                if (!(get(e_3, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull last and next event for Venue')];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function venueSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadVenueDetails.getType(), onLoadVenueDetails)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadVenueEvents.getType(), onLoadVenueEvents)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadLastNextVenueEvent.getType(), onLoadVenueLastNextEvent)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from 'classnames';
import React, { Component } from 'react';
import { useField } from 'react-final-form';
import { Icon } from 'semantic-ui-react';
import { Input, InputBase } from './input';
import styles from './input.less';
import { KEYCODES } from './keycodes';
var CurrencyInput = /** @class */ (function (_super) {
    __extends(CurrencyInput, _super);
    function CurrencyInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = null;
        _this.state = {
            value: '',
            prevPropsValue: '',
        };
        _this.getNotifyChanged = function (e) { return function () {
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange(e, __assign(__assign({}, _this.props), { value: _this.state.value }));
            }
        }; };
        _this.parseNDecPlaces = function (value) {
            if (value == null || value === '') {
                return '';
            }
            var _a = _this.props.precision, precision = _a === void 0 ? 2 : _a;
            // to read more details how to handle round up/down in js please read:
            // https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding
            // below code is designed to round down for: enter a value to 2dp. Typing an extra 6, 7, 8 or 9 at the end raises the value by 0.01...
            var calcDec = Math.pow(10, precision);
            var truncatedValue = (Math.trunc(parseFloat(value) * calcDec) / calcDec).toFixed(precision);
            return truncatedValue.length < value.length ? truncatedValue : value;
        };
        _this.isValid = function (value) {
            if (value == null || value === '')
                return true;
            if (value && isNaN(Number(value)))
                return false;
            if (value && Number(value) < 0)
                return false;
            return true;
        };
        _this.handleChange = function (e, _a) {
            var value = _a.value;
            if (_this.isValid(value)) {
                _this.setState({
                    value: _this.parseNDecPlaces(value),
                }, _this.getNotifyChanged(e));
            }
        };
        _this.handleOnBlur = function (e) {
            _this.setState(function (_a) {
                var value = _a.value;
                return {
                    value: CurrencyInput.formatValue(value, _this.props, _this.state),
                };
            }, function () {
                var onBlur = _this.props.onBlur;
                _this.getNotifyChanged(e)();
                if (onBlur) {
                    onBlur(e, __assign(__assign({}, _this.props), { value: _this.state.value }));
                }
            });
        };
        _this.handleKeyDown = function (e) {
            var _a = _this.props.step, step = _a === void 0 ? 1 : _a;
            if (e.keyCode === KEYCODES.UP) {
                e.preventDefault();
                _this.updateValueBy(e, step);
            }
            if (e.keyCode === KEYCODES.DOWN) {
                e.preventDefault();
                _this.updateValueBy(e, -step);
            }
        };
        _this.updateValueBy = function (e, by) {
            var value = parseFloat(_this.state.value);
            _this.setState({
                value: CurrencyInput.formatValue("" + (value + by), _this.props, _this.state),
            }, _this.getNotifyChanged(e));
        };
        _this.handleUp = function (e) {
            var _a = _this.props.step, step = _a === void 0 ? 1 : _a;
            e.preventDefault(); // prevent grab focus
            _this.updateValueBy(e, step);
            if (_this.ref) {
                _this.ref.focus();
            }
        };
        _this.handleDown = function (e) {
            var _a = _this.props.step, step = _a === void 0 ? 1 : _a;
            e.preventDefault(); // prevent grab focus
            _this.updateValueBy(e, -step);
            if (_this.ref) {
                _this.ref.focus();
            }
        };
        _this.setRef = function (ref) {
            _this.ref = ref;
        };
        _this.handleKeyPress = function (event) {
            var keyCode = event.keyCode || event.which;
            var keyValue = String.fromCharCode(keyCode);
            var target = event.target;
            var value = target instanceof HTMLInputElement ? target.value : '';
            var tryToAddInvalidDot = keyValue === '.' && (value.match(/\./) || value === '');
            var notNumberOrDot = /[^\d.]/.test(keyValue);
            if (tryToAddInvalidDot || notNumberOrDot) {
                event.preventDefault();
            }
        };
        _this.handleKeyUp = function (e) {
            e.persist();
            _this.setState(function (_a) {
                var value = _a.value;
                return e.keyCode === KEYCODES.ENTER ? { value: CurrencyInput.formatValue(value, _this.props, _this.state) } : null;
            }, function () {
                var onKeyUp = _this.props.onKeyUp;
                if (onKeyUp) {
                    onKeyUp(e, __assign(__assign({}, _this.props), { value: _this.state.value }));
                }
            });
        };
        return _this;
    }
    CurrencyInput.prototype.render = function () {
        var _a = this.props, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, onKeyUp = _a.onKeyUp, allowNull = _a.allowNull, disabled = _a.disabled, rest = __rest(_a, ["value", "onChange", "onBlur", "onKeyUp", "allowNull", "disabled"]);
        return (React.createElement("div", { className: cn(styles.currencyInput, { disabled: disabled }) },
            React.createElement(Input, __assign({}, rest, { type: 'text', disabled: disabled, value: this.state.value, onChange: this.handleChange, onBlur: this.handleOnBlur, onKeyPress: this.handleKeyPress, onKeyDown: this.handleKeyDown, onKeyUp: this.handleKeyUp, setRef: this.setRef })),
            React.createElement("div", { className: styles.updown },
                React.createElement(Icon, { onMouseDown: this.handleUp, name: 'angle up' }),
                React.createElement(Icon, { onMouseDown: this.handleDown, name: 'angle down' }))));
    };
    CurrencyInput.getDerivedStateFromProps = function (props, state) {
        if (props.value === state.prevPropsValue) {
            return null;
        }
        if (!props.value) {
            return {
                value: '',
                prevPropsValue: ''
            };
        }
        return {
            value: state.value !== props.value ? CurrencyInput.formatValue(props.value, props, state) : props.value,
            prevPropsValue: props.value,
        };
    };
    CurrencyInput.formatValue = function (value, props, state) {
        var _a = props.min, min = _a === void 0 ? 0 : _a, _b = props.precision, precision = _b === void 0 ? 2 : _b, allowNull = props.allowNull;
        var parsedValue = parseFloat(value);
        return isNaN(parsedValue) || parsedValue < min
            ? allowNull || !state.value
                ? ''
                : min.toFixed(precision)
            : parsedValue.toFixed(precision);
    };
    return CurrencyInput;
}(Component));
export { CurrencyInput };
export function CurrencyField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var field = useField(name);
    return React.createElement(InputBase, __assign({}, props, field, { Cmp: CurrencyInput }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { t } from '@containers/helpers/tests';
import loadable from '@loadable/component';
import { actions as eventsActions } from '@logic/events';
import noop from 'lodash/noop';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { actions } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { calculateItems, hasListing, usedListings } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/mapColor';
import styles from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMap.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isSmallScreen } from '@components/viewPortInfo/viewPort.utils';
import cn from 'classnames';
var SeatingPlan = loadable(function () { return import(/* webpackChunkName: "seating-plan-wrapper" */ '@containers/DetailsPages/pages/EventDetails/components/VenueMap/seating-plan-wrapper'); }, {
    fallback: React.createElement(Loader, { active: true }),
    ssr: false,
});
export var precomputeFoundSections = function (rawSections) {
    var entries = Object.entries(rawSections);
    return entries.map(function (_a) {
        var _b = __read(_a, 2), category = _b[0], sections = _b[1];
        var sectionList = !sections.length ? [''] : sections;
        return sectionList.map(function (section) { return ({
            category: category,
            section: section,
        }); });
    }).reduce(function (a, b) { return __spreadArray(__spreadArray([], __read(a)), __read(b)); }, []);
};
var handlePanAndZoomChange = function () { };
export function VenueMap(props) {
    var seatingPlan = props.seatingPlan, listings = props.listings, transactionSide = props.transactionSide, stateDispatch = props.stateDispatch, isLoadingSeatingPlan = props.isLoadingSeatingPlan, eventId = props.eventId, hoveredCategory = props.hoveredCategory, onPanAndZoomInstanceChange = props.onPanAndZoomInstanceChange, selectedCategories = props.selectedCategories, setSelectedCategories = props.setSelectedCategories, ticketTypes = props.ticketTypes, setSelectedSections = props.setSelectedSections, selectedSections = props.selectedSections, foundSections = props.foundSections, handleSvgParsed = props.handleSvgParsed, setSectionInForm = props.setSectionInForm;
    var media = useViewportInfo().media;
    var _a = __read(useState(null), 2), hoveredSection = _a[0], changeHoveredSection = _a[1];
    var isLowResolution = isSmallScreen(media);
    var browserInfo = useSelector(function (gs) { return gs.browser; });
    var handleMouseEnter = useCallback(function (category, section) {
        if (section === void 0) { section = ''; }
        if (!seatingPlan || !hasListing({ section: section, category: category }, listings, transactionSide, usedListings) && !setSectionInForm) {
            return;
        }
        if (seatingPlan.structure.categories.some(function (c) { return c.name === category && (!c.sections.length || c.sections.includes(section)); })) {
            changeHoveredSection({ category: category, section: section });
        }
    }, [listings, seatingPlan, transactionSide, setSectionInForm]);
    var handleMouseLeave = useCallback(function (_, __) { return changeHoveredSection(null); }, []);
    var isAllSectionsFromCategorySelected = useCallback(function (category, sections) {
        var filteredSections = sections.filter(function (section) { return section.category === category; }).filter(function (section) { return hasListing({ category: section.category, section: section.section }, listings, transactionSide, usedListings); });
        var isAllSectionsActive = filteredSections.every(function (section) { return section.isActive; });
        return isAllSectionsActive;
    }, [listings, transactionSide]);
    var handleClick = useCallback(function (category, section) {
        if (section === void 0) { section = ''; }
        if (!hasListing({ section: section, category: category }, listings, transactionSide, usedListings)) {
            return;
        }
        var updatedSelectedSections = selectedSections.map(function (selectedSection) { return selectedSection.category === category && selectedSection.section === section ? __assign(__assign({}, selectedSection), { isActive: !selectedSection.isActive }) : selectedSection; });
        setSelectedSections(updatedSelectedSections);
        var updatedSelectedCategories = selectedCategories.map(function (selectedCategory) { return selectedCategory.category === category ? __assign(__assign({}, selectedCategory), { isActive: isAllSectionsFromCategorySelected(category, updatedSelectedSections) }) : selectedCategory; });
        setSelectedCategories(updatedSelectedCategories);
        !isLowResolution && stateDispatch(actions.toggleCategoryTicketTypeSection({ categories: updatedSelectedCategories, ticketTypes: ticketTypes, sections: updatedSelectedSections }));
    }, [stateDispatch, listings, transactionSide, selectedSections, isAllSectionsFromCategorySelected, selectedCategories, ticketTypes, setSelectedCategories, setSelectedSections, isLowResolution]);
    var dispatch = useDispatch();
    useEffect(function () {
        if (!seatingPlan || !seatingPlan.svgFile) {
            dispatch(eventsActions.loadSeatingPlan.request(eventId));
        }
    }, [dispatch, eventId, seatingPlan]);
    var renderSeatingPlan = function () {
        var _a;
        var _b;
        return (React.createElement("div", { className: cn(styles.seatingPlanContainer, (_a = {}, _a[styles.seatingPlanContainerIOS] = (browserInfo === null || browserInfo === void 0 ? void 0 : browserInfo.os) === 'iOS', _a[styles.noBorder] = !!setSectionInForm, _a)) }, isLoadingSeatingPlan
            ? React.createElement("div", { className: styles.loaderContainer },
                React.createElement(Loader, { active: true }))
            : React.createElement(SeatingPlan, { svg: (_b = seatingPlan === null || seatingPlan === void 0 ? void 0 : seatingPlan.svgFile) !== null && _b !== void 0 ? _b : '', onSvgParsed: handleSvgParsed, onMouseEnter: isLowResolution ? noop : handleMouseEnter, onMouseLeave: isLowResolution ? noop : handleMouseLeave, onClick: setSectionInForm !== null && setSectionInForm !== void 0 ? setSectionInForm : handleClick, onPanAndZoomChange: handlePanAndZoomChange, onPanAndZoomInstanceChange: onPanAndZoomInstanceChange, highlightedItems: calculateItems(__assign(__assign({}, props), { foundSections: foundSections, hoveredCategory: hoveredCategory, hoveredSection: hoveredSection, isEditListingPage: !!setSectionInForm })), className: styles.seatingPlan, delimiter: '||', disablePanAndZoom: false })));
    };
    return (React.createElement("div", __assign({}, t('VenueMap'), { className: styles.venueMap }), renderSeatingPlan()));
}

import { formatDate, formatTime } from '@logic/helpers/date';
import cn from 'classnames';
import React from 'react';
import styles from './date.less';
export var DateDisplay = function (_a) {
    var date = _a.date, className = _a.className, timezone = _a.timezone, column = _a.column;
    if (column) {
        return (React.createElement("time", { dateTime: date ? date.toISOString() : '', className: cn(className, styles.columnDate) },
            React.createElement("div", { className: styles.day }, formatDate(date, timezone, 'eee')),
            React.createElement("div", { className: styles.dayNum }, formatDate(date, timezone, 'dd')),
            React.createElement("div", { className: styles.monthYear }, formatDate(date, timezone, 'MMM yyyy'))));
    }
    return (React.createElement("time", { dateTime: date ? date.toISOString() : '', className: className },
        React.createElement("span", null, formatDate(date, timezone)),
        React.createElement("span", null, formatTime(date, timezone))));
};

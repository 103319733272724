var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React, { Component } from 'react';
import { useField } from 'react-final-form';
import { TextArea as SemanticUiTextArea } from 'semantic-ui-react';
import styles from './textArea.less';
export function TextArea(_a) {
    var className = _a.className, fill = _a.fill, error = _a.error, rest = __rest(_a, ["className", "fill", "error"]);
    return React.createElement(SemanticUiTextArea, __assign({ className: cn([styles.textArea, { fill: fill, error: error }, className]) }, rest));
}
var TextAreaBase = /** @class */ (function (_super) {
    __extends(TextAreaBase, _super);
    function TextAreaBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e, data) {
            var _a = _this.props, onChange = _a.onChange, input = _a.input;
            input.onChange(data.value);
            if (onChange) {
                onChange(e, data);
            }
        };
        _this.handleOnBlur = function (e, data) {
            var _a = _this.props, onBlur = _a.onBlur, input = _a.input;
            input.onBlur();
            if (onBlur) {
                onBlur(e, data);
            }
        };
        return _this;
    }
    TextAreaBase.prototype.render = function () {
        var _a = this.props, onChange = _a.onChange, input = _a.input, value = _a.value, meta = _a.meta, rest = __rest(_a, ["onChange", "input", "value", "meta"]);
        var currentValue = value != null ? value : input.value;
        return React.createElement(TextArea, __assign({ rows: 5 }, rest, { error: meta.touched && !!meta.error, value: !currentValue && currentValue !== 0 ? '' : currentValue, onChange: this.handleChange, onBlur: this.handleOnBlur }, t(input.name.replace('.', '-'))));
    };
    return TextAreaBase;
}(Component));
export { TextAreaBase };
export function TextAreaField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var field = useField(name);
    return React.createElement(TextAreaBase, __assign({}, props, field));
}

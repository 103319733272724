var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState } from 'react';
export function useToggle(init) {
    var _a = __read(useState(init), 2), state = _a[0], changeState = _a[1];
    var toggle = useCallback(function () { return changeState(!state); }, [changeState, state]);
    var activate = useCallback(function () { return changeState(true); }, [changeState]);
    var deactivate = useCallback(function () { return changeState(false); }, [changeState]);
    return { state: state, toggle: toggle, activate: activate, deactivate: deactivate, changeState: changeState };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from 'classnames';
import React from 'react';
import styles from './button.less';
import { Icon } from '@components/icon';
export function Button(_a) {
    var className = _a.className, primary = _a.primary, icon = _a.icon, shrink = _a.shrink, secondary = _a.secondary, type = _a.type, fill = _a.fill, fillOnMobiles = _a.fillOnMobiles, basic = _a.basic, children = _a.children, nowrap = _a.nowrap, ternary = _a.ternary, medium = _a.medium, small = _a.small, grayedDisabled = _a.grayedDisabled, disabled = _a.disabled, rest = __rest(_a, ["className", "primary", "icon", "shrink", "secondary", "type", "fill", "fillOnMobiles", "basic", "children", "nowrap", "ternary", "medium", "small", "grayedDisabled", "disabled"]);
    var buttonType = type && type === 'submit' ? 'submit' : 'button';
    return (React.createElement("button", __assign({ className: cn(styles.button, className, type, {
            basic: basic,
            primary: primary,
            secondary: secondary,
            ternary: ternary,
            nowrap: nowrap,
            fill: fill,
            fillOnMobiles: fillOnMobiles,
            medium: medium,
            small: small,
            grayedDisabled: grayedDisabled,
            shrink: shrink,
        }) }, rest, { type: buttonType, disabled: disabled || grayedDisabled }),
        children,
        icon && React.createElement(Icon, { className: styles.iconArrow, icon: 'play' })));
}

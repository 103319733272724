var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { isInVerticalViewport, useScrollTop } from '@components/forms';
import cn from 'classnames';
import isBoolean from 'lodash/isBoolean';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { HeadlineRow } from '../headlineRow';
import { DataTable } from './dataTable';
import styles from './genericTable.less';
import { NoRows } from './noRows';
import { useProcessedData } from './useProcessedData';
import { isEqual } from 'lodash';
export function ControlledGenericTable(props) {
    var _a, _b, _c;
    var headers = props.headers, data = props.data, filters = props.filters, omitFilteringRow = props.omitFilteringRow, extraActions = props.extraActions, extraActionsInModal = props.extraActionsInModal, tableState = props.tableState, onUpdateTableState = props.onUpdateTableState, top = props.top, stickyType = props.stickyType, tableScrollWrapper = props.tableScrollWrapper, tableGlobalScrollbar = props.tableGlobalScrollbar, defaultFilter = props.defaultFilter, isLoading = props.isLoading, rowClass = props.rowClass, onRowsUpdated = props.onRowsUpdated, pinnedRows = props.pinnedRows, idGetter = props.idGetter, captureScroll = props.captureScroll, stickThreshold = props.stickThreshold, pinnedClassName = props.pinnedClassName, rowDistinctor = props.rowDistinctor, rowPartitioner = props.rowPartitioner, nonRepeatableColumns = props.nonRepeatableColumns, secondarySortColumns = props.secondarySortColumns, useFiltering = props.useFiltering, useSorting = props.useSorting;
    var _d = __read(useState(0), 2), scrollLeft = _d[0], changeScrollLeft = _d[1];
    var _e = __read(useState(0), 2), stickyOffset = _e[0], handleChangeStickyOffset = _e[1];
    var _f = __read(useState(10), 2), rowLimit = _f[0], changeRowLimit = _f[1];
    var prevRowsRef = useRef([]);
    var _g = useProcessedData(headers, tableState, filters, useFiltering, useSorting, pinnedRows, idGetter, data, rowLimit, rowPartitioner), rows = _g.rows, hasMore = _g.hasMore;
    var handleLoad = useCallback(function () {
        if (rowLimit < data.length && rowLimit === rows.length) {
            changeRowLimit(rowLimit + 10);
        }
    }, [rowLimit, changeRowLimit, rows.length, data.length]);
    useEffect(function () {
        if (onRowsUpdated && !isEqual(rows, prevRowsRef.current)) {
            onRowsUpdated(rows);
        }
        prevRowsRef.current = rows;
    }, [rows, onRowsUpdated]);
    var handleSort = useCallback(function (newSort, sortAllowed) { return function () {
        var _a;
        if (!sortAllowed) {
            return;
        }
        var baseSortData = (secondarySortColumns !== null && secondarySortColumns !== void 0 ? secondarySortColumns : [])
            .filter(function (bsc) { return bsc !== newSort; })
            .map(function (bsc) { return ({
            sortColumn: bsc,
            sortReverse: false,
            primary: false
        }); });
        onUpdateTableState(__assign(__assign({}, tableState), { sortColumns: __spreadArray([
                {
                    sortColumn: newSort,
                    sortReverse: ((_a = tableState.sortColumns) !== null && _a !== void 0 ? _a : []).some(function (sc) { return sc.sortColumn === newSort && !sc.sortReverse; }),
                    primary: true
                }
            ], __read(baseSortData)) }));
    }; }, [secondarySortColumns, onUpdateTableState, tableState]);
    var handleSearch = useCallback(function (search) {
        onUpdateTableState(__assign(__assign({}, tableState), { search: search }));
    }, [onUpdateTableState, tableState]);
    var handleFilterChange = useCallback(function (title, filterLabel, checked) {
        var _a;
        onUpdateTableState(__assign(__assign({}, tableState), { filters: __assign(__assign({}, tableState.filters), (_a = {}, _a[title] = checked ? __spreadArray(__spreadArray([], __read((tableState.filters[title] || []))), [filterLabel]) : (tableState.filters[title] || []).filter(function (v) { return v !== filterLabel; }), _a)) }));
    }, [onUpdateTableState, tableState]);
    var handleFilterClear = useCallback(function () {
        onUpdateTableState(__assign(__assign({}, tableState), { search: '', filters: {} }));
    }, [tableState, onUpdateTableState]);
    var handleFilterDefault = useCallback(function () {
        onUpdateTableState(__assign(__assign({}, tableState), { search: '', filters: defaultFilter || {} }));
    }, [tableState, defaultFilter, onUpdateTableState]);
    var ref = useRef(null);
    var scrollTop = useScrollTop(!!captureScroll);
    useEffect(function () {
        if (ref.current && isInVerticalViewport(ref.current)) {
            handleLoad === null || handleLoad === void 0 ? void 0 : handleLoad();
        }
    }, [ref, scrollTop, handleLoad]);
    var handleScroll = useCallback(function (e) { return changeScrollLeft(e.target.scrollLeft); }, [changeScrollLeft]);
    var style = useMemo(function () { return ({ top: top }); }, [top]);
    var pinId = pinnedRows && pinnedRows.length ? pinnedRows[pinnedRows.length - 1][0] : null;
    var loaderCn = cn([styles.dimmer], (_a = {}, _a[styles.dimmerNoRows] = !rows.length, _a[styles.dimmerSmall] = rows.length <= 5, _a));
    var isPinned = useCallback(function (r) { return !!pinId && !!idGetter && pinId === idGetter(r); }, [pinId, idGetter]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.table },
            !omitFilteringRow
                && React.createElement(HeadlineRow, { filters: filters, filterValues: tableState.filters, onSearch: handleSearch, search: tableState.search, extraActions: extraActions, extraActionsInModal: !!extraActionsInModal, onFilterChange: handleFilterChange, onFilterClear: handleFilterClear, onFilterDefault: handleFilterDefault, onChangeStickyOffset: handleChangeStickyOffset }),
            React.createElement(React.Fragment, null,
                rows.length
                    ?
                        React.createElement("div", { className: cn((_b = {}, _b[styles.tableWithScroll] = tableScrollWrapper, _b), (_c = {}, _c[styles.tableWithGlobalScroll] = tableGlobalScrollbar, _c)), style: style, onScroll: handleScroll },
                            React.createElement(DataTable, { rows: rows, handleSort: handleSort, scrollLeft: scrollLeft, isLoading: isLoading, headers: headers.filter(function (h) { return !h.hidden; }), tableState: tableState, rowClass: rowClass, top: stickyOffset, stickyType: stickyType, isPinned: isPinned, idGetter: idGetter, stickThreshold: stickThreshold, pinnedClassName: pinnedClassName, rowDistinctor: rowDistinctor, nonRepeatableColumns: nonRepeatableColumns }))
                    : React.createElement(NoRows, { isLoading: isLoading }),
                React.createElement(Dimmer, { inverted: true, active: isLoading, className: loaderCn },
                    React.createElement(Loader, { inverted: true })))),
        React.createElement("div", { ref: ref, className: styles.loadingRow },
            React.createElement(Loader, { active: !isLoading && hasMore }))));
}
export var createHeader = function (name, path, options) {
    if (options === void 0) { options = {}; }
    return ({
        name: name,
        path: path,
        node: options.node || undefined,
        columnKey: options.columnKey || name,
        filter: isBoolean(options.filter) ? options.filter : false,
        sort: isBoolean(options.sort) ? options.sort : true,
        format: options.format || undefined,
        render: options.render || undefined,
        className: options.className || undefined,
        headerClassName: options.headerClassName || undefined,
        centered: options.centered || false,
        orderBy: options.orderBy || undefined,
        csvFormat: options.csvFormat || options.format,
        hidden: !!options.hidden || false,
    });
};

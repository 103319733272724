import { useStaticContext } from './useStaticContext';
import crawlers from 'crawler-user-agents';
var patterns = crawlers.map(function (_a) {
    var pattern = _a.pattern;
    return "(" + pattern + ")";
}).join('|');
export function useUserAgent() {
    var staticContext = useStaticContext();
    var userAgent = staticContext ? staticContext.userAgent : navigator.userAgent;
    var isBot = !!userAgent.match(patterns);
    return { userAgent: userAgent, isBot: isBot };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PushableImage } from '@containers/PushableImage';
import { externalLogin, loginFailure } from '@logic/user';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
// import FacebookLogin, { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import { useDispatch } from 'react-redux';
import { Modal, ModalContentRow } from '../components/forms';
import styles from './externalLogin.less';
function GoogleLogin(_a) {
    var onClick = _a.onClick;
    return (React.createElement("button", { onClick: onClick, className: styles.googleButton },
        React.createElement(PushableImage, { src: "/g-logo.png" }),
        "Log In with Google"));
}
export function ExternalLogin(_a) {
    var redirect = _a.redirect;
    var dispatch = useDispatch();
    var _b = __read(useState({
        showFacebookEmailConsentModal: false,
        facebookLoginNeedRerequest: false
    }), 2), facebookConfig = _b[0], setFacebookConfig = _b[1];
    var handleGoogleLogin = function (info) {
        if (info === null || info === void 0 ? void 0 : info.access_token) {
            dispatch(externalLogin({ provider: 'Google', accessToken: info.access_token, redirect: redirect }));
        }
    };
    var handleGoogleLoginFailure = function (errorResponse) {
        console.error(errorResponse);
        dispatch(loginFailure('Unable to log in using Google'));
    };
    var googleLogin = useGoogleLogin({
        flow: 'implicit',
        onSuccess: handleGoogleLogin,
        onError: handleGoogleLoginFailure
    });
    // 3103 - de-commision all facebook features
    // const handleFacebookLogin = (info: ReactFacebookLoginInfo) => {
    //   if (info.email) {
    //     dispatch(externalLogin({ provider: 'Facebook', accessToken: info.accessToken, redirect }));
    //     setFacebookConfig(c => ({ ...c, showFacebookEmailConsentModal: false, facebookLoginNeedRerequest: false }));
    //   } else {
    //     setFacebookConfig(c => ({ ...c, showFacebookEmailConsentModal: true, facebookLoginNeedRerequest: true }));
    //   }
    // };
    // const handleFacebookLoginFailure = (failure: ReactFacebookFailureResponse) => {
    //   console.error(failure.status);
    //   dispatch(loginFailure('Unable to log in using Facebook'));
    // };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: facebookConfig.showFacebookEmailConsentModal, closeOnDocumentClick: true, close: 'icon', contentClassName: styles.facebookEmailModalContent, onClose: function () { return setFacebookConfig(function (c) { return (__assign(__assign({}, c), { showFacebookEmailConsentModal: false })); }); } },
            React.createElement(ModalContentRow, null, "Beeyay does not have permission to obtain your email address from Facebook. We require your email address in order to manage your account and communicate important updates to you. Please enable this permission to proceed to login via Facebook, or alternatively login using another method.")),
        React.createElement(GoogleLogin, { onClick: googleLogin })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useViewportInfo } from './useViewportInfo.hook';
function RefreshOnResize(_a) {
    var component = _a.component, props = _a.props;
    var media = useViewportInfo().media;
    var Component = component;
    return React.createElement(Component, __assign({ media: media }, props));
}
export function withViewportInfo(component) {
    return function (props) { return React.createElement(RefreshOnResize, { component: component, props: props }); };
}

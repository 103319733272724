import React from 'react';
import { Link } from '@components/forms';
import { Icon } from '@components/icon';
import { Container } from '@containers/Page';
import styles from './notAvailableAction.less';
import cn from 'classnames';
export var NotAvailableAction = function (_a) {
    var _b;
    var message = _a.message, details = _a.details, to = _a.to, actionText = _a.actionText, topMargin = _a.topMargin;
    return (React.createElement(Container, { className: cn(styles.container, (_b = {}, _b[styles.topMargin] = topMargin, _b)) },
        React.createElement("div", { className: styles.message }, message),
        React.createElement("div", { className: styles.details }, details),
        React.createElement(Link, { asButton: true, ternary: true, big: true, to: to, className: styles.navigateButton },
            actionText,
            " ",
            React.createElement(Icon, { icon: 'play', width: 20, height: 20 }),
            " ")));
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { formatRemainingTime } from '@logic/helpers/date';
import cn from 'classnames';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './timer.less';
function defaultRenderer(time, className) {
    var _a;
    var id = Math.floor((((_a = time === null || time === void 0 ? void 0 : time.getTime()) !== null && _a !== void 0 ? _a : 0) - Date.now()) / 1000 / 10) % 2;
    return (React.createElement("div", { className: cn(styles.timer, className) },
        React.createElement(Icon, { name: 'clock outline' }),
        " ",
        React.createElement("span", { className: styles["flashing" + id] }, formatRemainingTime(time)),
        " remaining"));
}
var Timer = /** @class */ (function (_super) {
    __extends(Timer, _super);
    function Timer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.timer = null;
        _this.state = {
            time: null,
        };
        _this.handleUpdate = function () {
            var _a = _this.props, endTime = _a.endTime, onTimeout = _a.onTimeout;
            _this.setState({
                time: _this.props.endTime,
            });
            if (endTime < new Date() && onTimeout) {
                onTimeout();
                _this.clearTimer();
            }
        };
        return _this;
    }
    Timer.prototype.componentDidMount = function () {
        this.handleUpdate();
        this.timer = window.setInterval(this.handleUpdate, 100);
    };
    Timer.prototype.componentWillUnmount = function () {
        this.clearTimer();
    };
    Timer.prototype.clearTimer = function () {
        if (this.timer) {
            window.clearInterval(this.timer);
            this.timer = null;
        }
    };
    Timer.prototype.render = function () {
        var _a = this.props, hidden = _a.hidden, className = _a.className, render = _a.render;
        if (hidden) {
            return null;
        }
        return (React.createElement(React.Fragment, null, (render || defaultRenderer)(this.state.time, className)));
    };
    return Timer;
}(Component));
export { Timer };

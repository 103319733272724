var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Input } from '@components/forms';
import { PasswordInput } from '@components/forms/input';
import { Seo } from '@components/seo';
import { t } from '@containers/helpers/tests';
import { getRedirectToWithReplace } from '@logic/helpers/location';
import { isEmail, isEmpty } from '@logic/helpers/validators';
import { registerReset, registerUser, userRegistrationProblem, } from '@logic/registration';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Form, Header } from 'semantic-ui-react';
import { routes } from 'src/client/routing/routes';
import { ExternalLogin } from '../ExternalLogin/externalLogin';
import styles from '../Login/login.less';
import { Page } from '../Page';
import { DetailsInfo } from './detailsInfo';
import { windowIsDefined } from '@containers/utils';
import { ScrollToTopOnMount } from '../components/scrollToTopOnMount/scrollToTopOnMount';
var getHeader = function (reason) {
    switch (reason) {
        case 'sell': return '';
        case 'buy': return 'Your tickets have been reserved!';
        default: return '';
    }
};
var getSubheader = function (reason) {
    switch (reason) {
        case 'sell': return 'In order to complete your sale, please create an account. If you already have an account you can login via the link below.';
        case 'buy': return 'In order to complete your purchase, please create an account. If you already have an account you can login via the link below.';
        default: return '';
    }
};
var getDetails = function (reason) {
    switch (reason) {
        case 'sell': return null;
        case 'buy': return (React.createElement(DetailsInfo, { header: 'Why do I need an account?' }, "We require your details so that we can send you the order confirmation and to keep you up to date with the delivery of your tickets."));
        default: return null;
    }
};
var Register = /** @class */ (function (_super) {
    __extends(Register, _super);
    function Register() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',
        };
        _this.handleRegistration = function () {
            if (_this.state.password !== _this.state.confirmPassword) {
                _this.props.actions.userRegistrationProblem([{
                        code: 'PasswordNotTheSame',
                        description: 'Passwords must be the same.',
                    }]);
            }
            else {
                _this.props.actions.registerUser(_this.state);
            }
        };
        _this.handleSetFullName = function (_, data) { return _this.setState({
            fullName: data.value,
        }); };
        _this.handleSetEmail = function (_, data) { return _this.setState({
            email: data.value,
        }); };
        _this.handleSetPassword = function (_, data) { return _this.setState({
            password: data.value,
        }); };
        _this.handleSetConfirmPassword = function (_, data) { return _this.setState({
            confirmPassword: data.value,
        }); };
        return _this;
    }
    Register.prototype.componentDidMount = function () {
        this.props.actions.registerReset();
    };
    Register.prototype.render = function () {
        var match = this.props.match;
        return (React.createElement(Page, { search: false, showBeeyayGuarantee: match.params.reason === 'buy' },
            React.createElement("div", { className: styles.container },
                React.createElement("div", __assign({ className: styles.register }, t('registerPage')), this.renderForm()))));
    };
    Register.prototype.renderForm = function () {
        var _a = this.props, location = _a.location, match = _a.match;
        var subheader = getSubheader(match.params.reason);
        var details = getDetails(match.params.reason);
        return (React.createElement(React.Fragment, null,
            React.createElement(Seo, { title: 'Welcome to Beeyay! Your journey to cheap tickets starts here.', description: 'Join the most innovative ticket marketplace out there. Get the cheapest tickets to the hottest events, or give other fans the chance!' }),
            React.createElement(ScrollToTopOnMount, null),
            React.createElement(Header, { as: 'h4', className: styles.header, textAlign: 'center' }, getHeader(match.params.reason)),
            subheader && React.createElement(Header, { as: 'h5', className: styles.subheader }, subheader),
            details,
            React.createElement(Form, { error: !!this.props.messages.length },
                React.createElement(Input, __assign({ type: 'text', large: true, fluid: true, error: isEmpty(this.state.fullName), onChange: this.handleSetFullName, placeholder: 'User Name', value: this.state.fullName }, t('UserName'))),
                React.createElement(Input, __assign({ type: 'email', large: true, fluid: true, error: !isEmpty(this.state.email) && !isEmail(this.state.email), onChange: this.handleSetEmail, placeholder: 'user@email.com', value: this.state.email }, t('User'))),
                React.createElement(PasswordInput, __assign({ large: true, fluid: true, onChange: this.handleSetPassword, placeholder: 'Password', value: this.state.password }, t('password'))),
                React.createElement(PasswordInput, __assign({ large: true, fluid: true, onChange: this.handleSetConfirmPassword, placeholder: 'Confirm your password', value: this.state.confirmPassword }, t('passwordConfirm'))),
                React.createElement("div", __assign({ className: styles.errorMessage }, t('error')), this.props.messages.map(function (m) { return React.createElement(Fragment, { key: m.code },
                    m.description,
                    React.createElement("br", null)); })),
                React.createElement(Button, __assign({ className: styles.button, fill: true, ternary: true, disabled: this.props.isLoading || isEmpty(this.state.email) || !isEmail(this.state.email) || !this.state.password.length || !this.state.confirmPassword.length, onClick: this.handleRegistration }, t('register')), "Register")),
            React.createElement("div", { className: styles.footer },
                "Do you have an account? ",
                React.createElement(Link, { to: getRedirectToWithReplace({
                        redirect: routes.login(match.params.reason),
                        location: location,
                    }), replace: true },
                    React.createElement("span", __assign({}, t('toLogin')), "Sign In"))),
            windowIsDefined() && window.EXTERNAL_AUTH_ENABLED ?
                React.createElement(React.Fragment, null,
                    React.createElement(Header, { as: 'h4', className: styles.header, textAlign: 'center' }, "OR"),
                    React.createElement(ExternalLogin, { redirect: routes.reservation('buy', 'payment') }))
                : null));
    };
    return Register;
}(Component));
var mapStateToProps = function (state) { return ({
    isLoading: state.registration.isLoading,
    messages: state.registration.messages,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        registerUser: registerUser,
        registerReset: registerReset,
        userRegistrationProblem: userRegistrationProblem,
    }, dispatch),
}); };
export var RegisterComponent = compose(connect(mapStateToProps, mapDispatchToProps))(Register);
export default RegisterComponent;

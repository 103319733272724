var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Input } from '@components/forms';
import { Seo } from '@components/seo';
import { t } from '@containers/helpers/tests';
import { getRedirectToWithReplace } from '@logic/helpers/location';
import { isEmail } from '@logic/helpers/validators';
import { requestResetUserPassword } from '@logic/user';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { Form, Header } from 'semantic-ui-react';
import { routes } from 'src/client/routing/routes';
import styles from '../Login/login.less';
import { withPage } from '../Page';
export function Reset() {
    var _a = __read(useState(''), 2), email = _a[0], changeEmail = _a[1];
    var _b = __read(useState(false), 2), emailInvalid = _b[0], changeEmailInvalid = _b[1];
    var dispatch = useDispatch();
    var location = useLocation();
    var isSetPassword = location.pathname === routes.setPassword();
    var handleEmail = useCallback(function (_, data) {
        changeEmail(data.value);
        changeEmailInvalid(!isEmail(data.value));
    }, []);
    var handleSubmit = useCallback(function () {
        dispatch(requestResetUserPassword(email, false, isSetPassword ? 'set' : 'reset'));
    }, [dispatch, email, isSetPassword]);
    var error = useSelector(function (gs) { return gs.user.error; });
    var isPending = useSelector(function (gs) { return gs.user.isPending; });
    return (React.createElement("div", { className: styles.container },
        React.createElement(Seo, { title: 'Get a secure link to reset your password', description: 'Beeyay takes your account security seriously, so if you\'ve forgotten your password we\'ll send you a secure link to reset it' }),
        React.createElement("div", __assign({ className: styles.resetPassword }, t('resetPassword')),
            React.createElement(Header, { as: 'h4', className: styles.header, textAlign: 'center' },
                isSetPassword ? 'Set ' : 'Reset ',
                " your password"),
            React.createElement(Form, { loading: isPending, error: emailInvalid || !!error, size: 'large' },
                React.createElement(Input, __assign({ type: 'email', error: emailInvalid, large: true, fluid: true, onChange: handleEmail, placeholder: 'user@email.com', value: email }, t('user'))),
                React.createElement(Button, __assign({ disabled: emailInvalid || !email.length, className: styles.button, fill: true, ternary: true, type: "submit", onClick: handleSubmit }, t('reset')),
                    isSetPassword ? 'Set ' : 'Reset ',
                    " Password")),
            React.createElement("div", { className: styles.footer },
                React.createElement(Link, { to: getRedirectToWithReplace({
                        redirect: routes.register(),
                        location: location,
                    }), replace: !isSetPassword },
                    React.createElement("span", __assign({}, t('toRegister')), "Sign Up")),
                " ",
                ' ',
                "or ",
                React.createElement(Link, { to: getRedirectToWithReplace({
                        redirect: routes.login(),
                        location: location,
                    }), replace: !isSetPassword },
                    React.createElement("span", __assign({}, t('toLogin')), "Sign In"))))));
}
export default compose(withPage)(Reset);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Images } from '@logic/images';
import React from 'react';
import { TxLazyImage } from '@components/image/lazyImage';
import { Container } from '@containers/Page';
import styles from '@containers/DetailsPages/components/detailsHeader/detailsHeader.less';
import { SecondaryMarketplaceBanner } from '@components/marketPlaceBanner/marketPlaceBanner';
import { CategoriesNavigation } from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigation';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
export var DetailsHeaderContainer = function (_a) {
    var containerSize = _a.containerSize, children = _a.children;
    return (React.createElement("div", { className: styles.details },
        React.createElement(Container, { size: containerSize, className: styles.detailsContainer }, children)));
};
export var DetailsHeader = function (_a) {
    var _b, _c;
    var details = _a.details, scrollToCallback = _a.scrollToCallback, detailsSubtitle = _a.detailsSubtitle, containerSize = _a.containerSize, breadcrumb = _a.breadcrumb, rest = __rest(_a, ["details", "scrollToCallback", "detailsSubtitle", "containerSize", "breadcrumb"]);
    var media = useViewportInfo().media;
    var isMobile = isLikeMobile(media);
    return (React.createElement(React.Fragment, null,
        !isMobile && React.createElement(CategoriesNavigation, null),
        React.createElement(SecondaryMarketplaceBanner, null),
        React.createElement(DetailsHeaderContainer, { containerSize: containerSize },
            breadcrumb,
            React.createElement("div", __assign({ className: styles.detailsHeaderGrid }, rest),
                (details === null || details === void 0 ? void 0 : details.imageUrl) &&
                    React.createElement("div", { className: styles.detailsImageContainer },
                        React.createElement(TxLazyImage, { useLargeAspectRatio: true, src: details.imageUrl + "/" + Images.eventList((details === null || details === void 0 ? void 0 : details.name) || ''), mobileSrc: '', visibleByDefault: true })),
                React.createElement("div", { className: styles.detailsHeader },
                    React.createElement("div", { className: styles.detailsTitle }, (_b = details === null || details === void 0 ? void 0 : details.name) !== null && _b !== void 0 ? _b : ''),
                    detailsSubtitle && React.createElement("div", { className: styles.detailsSubtitle }, detailsSubtitle)),
                React.createElement("span", { className: styles.detailsDescription }, (_c = details === null || details === void 0 ? void 0 : details.description) !== null && _c !== void 0 ? _c : ''),
                React.createElement("div", { className: styles.readMore, onClick: scrollToCallback }, "Read more")))));
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React, { Component, useCallback, useRef } from 'react';
import { useField } from 'react-final-form';
import { createSelector } from 'reselect';
import { Dropdown as SemanticUiDropdown } from 'semantic-ui-react';
import styles from './dropdown.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
var valueInOptions = function (currentValue, options) { return options
    .find(function (_a) {
    var value = _a.value;
    return JSON.stringify(value) === JSON.stringify(currentValue);
}); };
var selectOptions = createSelector(function (_a) {
    var options = _a.options;
    return options || [];
}, function (_a) {
    var allOptions = _a.allOptions;
    return allOptions;
}, function (_a) {
    var value = _a.value;
    return value;
}, function (_a) {
    var preserveCurrentValue = _a.preserveCurrentValue;
    return preserveCurrentValue;
}, function (_a) {
    var currentValueToText = _a.currentValueToText;
    return currentValueToText;
}, function (options, allOptions, currentValue, preserveCurrentValue, currentValueToText) {
    if (!preserveCurrentValue || currentValue == null) {
        return options;
    }
    if (valueInOptions(currentValue, options)) {
        return options;
    }
    var mappedOption = allOptions && allOptions.find(function (o) { return o.value === currentValue; });
    if (mappedOption) {
        return options.concat(mappedOption);
    }
    return options.concat({
        text: currentValueToText ? currentValueToText(currentValue) : currentValue,
        value: currentValue,
        key: JSON.stringify(currentValue),
    });
});
var selectSerializedOptions = createSelector(selectOptions, function (options) {
    var native = options.map(function (_a) {
        var key = _a.key, text = _a.text, value = _a.value, content = _a.content, nativeText = _a.nativeText;
        return ({ key: key, text: text, value: JSON.stringify(value), content: content, nativeText: nativeText });
    });
    var notNative = options.map(function (_a) {
        var key = _a.key, text = _a.text, value = _a.value, content = _a.content;
        return ({ key: key, text: text, value: JSON.stringify(value), content: content });
    });
    return [native, notNative];
});
function NativeDropdown(props) {
    var _a;
    var onChange = useCallback(function (e) { return props.onChange && props.onChange(e, __assign(__assign({}, props), { value: e.currentTarget.value })); }, [props]);
    return React.createElement("select", { ref: props.selectRef, className: cn(props.className, (_a = {}, _a[styles.fluid] = props.fluid, _a)), onClick: props.onOpen, multiple: props.multiple, onChange: onChange, name: props.name, value: props.value, disabled: props.disabled },
        (props.value == null || props.value === '') && React.createElement("option", { key: 'empty', value: '' }),
        (props.options || []).map(function (_a) {
            var key = _a.key, value = _a.value, text = _a.text, nativeText = _a.nativeText;
            return (React.createElement("option", { key: key, value: value },
                " ", nativeText !== null && nativeText !== void 0 ? nativeText : text));
        }));
}
export function Dropdown(props) {
    var _a;
    var className = props.className, value = props.value, onChange = props.onChange, options = props.options, preserveCurrentValue = props.preserveCurrentValue, allOptions = props.allOptions, currentValueToText = props.currentValueToText, setRef = props.setRef, small = props.small, greyOut = props.greyOut, disabled = props.disabled, short = props.short, browserInfo = props.browserInfo, rest = __rest(props, ["className", "value", "onChange", "options", "preserveCurrentValue", "allOptions", "currentValueToText", "setRef", "small", "greyOut", "disabled", "short", "browserInfo"]);
    var _b = useViewportInfo(), media = _b.media, isAndroid = _b.isAndroid, isIOS = _b.isIOS;
    var handleChange = useCallback(function (e, data) {
        var option = selectOptions({
            options: options,
            allOptions: allOptions,
            value: value,
            preserveCurrentValue: preserveCurrentValue,
            currentValueToText: currentValueToText,
        }).find(function (o) { return JSON.stringify(o.value) === data.value; });
        var selectedValue = option ? option.value : null;
        if (onChange) {
            onChange(e, __assign(__assign({}, data), { value: selectedValue }));
        }
    }, [options, allOptions, value, preserveCurrentValue, currentValueToText, onChange]);
    var nativeDropdownRef = useRef(null);
    var handleSetRef = useCallback(function (e) {
        if (!setRef) {
            return;
        }
        if (e && e.ref && e.ref.current) {
            setRef(e.ref.current);
        }
        else {
            setRef(null);
        }
    }, [setRef]);
    var _c = __read(selectSerializedOptions({
        options: options,
        allOptions: allOptions,
        value: value,
        preserveCurrentValue: preserveCurrentValue,
        currentValueToText: currentValueToText,
    }), 2), nativeSelectedOptions = _c[0], selectedOptions = _c[1];
    var isMobile = media === 'mobile' || media === 'mobileLandscape' || isAndroid || isIOS;
    return (React.createElement("div", { className: styles.dropdownContainer },
        isMobile && React.createElement(NativeDropdown, __assign({ className: cn('ui selection dropdown', styles.dropdown, { small: small }, className, styles.nativeDropdown) }, rest, { value: value ? JSON.stringify(value) : '', options: nativeSelectedOptions, onChange: handleChange, selectRef: nativeDropdownRef, disabled: disabled })),
        React.createElement(SemanticUiDropdown, __assign({ className: cn(styles.dropdown, (_a = { small: small,
                    greyOut: greyOut,
                    short: short }, _a[styles.disablePointerEvents] = isMobile, _a[styles.disabled] = disabled, _a[styles.iosDropdown] = (browserInfo === null || browserInfo === void 0 ? void 0 : browserInfo.os) === 'iOS', _a[styles.safariDropdown] = (browserInfo === null || browserInfo === void 0 ? void 0 : browserInfo.name) === 'safari' && (browserInfo === null || browserInfo === void 0 ? void 0 : browserInfo.os) === 'Mac OS', _a), className) }, rest, { value: value ? JSON.stringify(value) : '', options: selectedOptions, onChange: handleChange, disabled: disabled, ref: handleSetRef }))));
}
var DropdownBase = /** @class */ (function (_super) {
    __extends(DropdownBase, _super);
    function DropdownBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e, data) {
            var _a = _this.props, onChange = _a.onChange, input = _a.input;
            input.onChange(data.value);
            input.onBlur();
            if (onChange) {
                onChange(e, data);
            }
        };
        return _this;
    }
    DropdownBase.prototype.render = function () {
        var _a = this.props, value = _a.value, input = _a.input, meta = _a.meta, rest = __rest(_a, ["value", "input", "meta"]);
        var currentValue = value || input.value;
        return React.createElement(Dropdown, __assign({}, rest, { error: meta.touched && !!meta.error, value: currentValue, onChange: this.handleChange }, t(input.name.replace('.', '-'))));
    };
    return DropdownBase;
}(Component));
export { DropdownBase };
export function DropdownField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var field = useField(name, { allowNull: true });
    return React.createElement(DropdownBase, __assign({}, props, field));
}

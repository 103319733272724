var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { fetchLocationEvents, getLocationSearchParams, loadFullLocationsData, loadLastNextLocationEvent, loadLocationDetails, setLocationsSSRFlag, } from '@logic/locations';
import { generateHeaderDetails } from '@containers/DetailsPages/utils';
import { usePrevious } from 'client/containers/hooks/usePrevious.hook';
export var fetchData = function (_, dispatch, match, isSSR) {
    var _a = match.params, countryId = _a.countryId, cityId = _a.cityId;
    if (!countryId)
        return;
    dispatch(loadFullLocationsData({ countryId: countryId, cityId: cityId }));
    if (isSSR) {
        dispatch(setLocationsSSRFlag(true));
    }
};
export var useDetailsLocation = function () {
    var _a = useRouteMatch().params, countryId = _a.countryId, cityId = _a.cityId;
    var dispatch = useDispatch();
    var detailsLoading = useSelector(function (gs) { return gs.locations.isLoadingDetails; });
    var eventsIsLoading = useSelector(function (gs) { return gs.locations.isLoadingEvents; });
    var events = useSelector(function (gs) { return gs.locations.locationEvents; });
    var countryDetails = useSelector(function (gs) { return gs.locations.countryDetails; });
    var cityDetails = useSelector(function (gs) { return gs.locations.cityDetails; });
    var shouldTriggerLoadEvents = useSelector(function (gs) { return gs.locations.shouldTriggerLoadEvents; });
    var lastNextEvent = useSelector(function (gs) { return gs.locations.locationLastNextEvent; });
    var searchParams = useSelector(function (gs) { return gs.locations.searchParams; });
    var eventsCount = useSelector(function (gs) { return gs.locations.eventsCount; });
    var loadedWithSSR = useSelector(function (gs) { return gs.locations.loadedWithSSR; });
    var headerDetails = useMemo(function () {
        return generateHeaderDetails(cityDetails ? cityDetails : countryDetails);
    }, [countryDetails, cityDetails]);
    var prevCountryId = usePrevious(countryId);
    var prevCityId = usePrevious(cityId);
    useEffect(function () {
        if (loadedWithSSR) {
            dispatch(setLocationsSSRFlag(false));
            return;
        }
        if (countryId !== prevCountryId || cityId !== prevCityId) {
            dispatch(loadLocationDetails(countryId, cityId));
        }
    }, [dispatch, cityId, countryId, loadedWithSSR, prevCountryId, prevCityId]);
    useEffect(function () {
        if (shouldTriggerLoadEvents) {
            dispatch(fetchLocationEvents(__assign(__assign({}, getLocationSearchParams(countryDetails, cityDetails)), { offset: 0 })));
            if (countryDetails) {
                dispatch(loadLastNextLocationEvent(countryDetails === null || countryDetails === void 0 ? void 0 : countryDetails.name, cityDetails === null || cityDetails === void 0 ? void 0 : cityDetails.name));
            }
        }
    }, [dispatch, countryDetails, cityDetails, shouldTriggerLoadEvents]);
    return { countryDetails: countryDetails, cityDetails: cityDetails, detailsLoading: detailsLoading, eventsIsLoading: eventsIsLoading, events: events, headerDetails: headerDetails, lastNextEvent: lastNextEvent, searchParams: searchParams, eventsCount: eventsCount };
};

import { useCallback, useRef } from 'react';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
export var PageSectionEnum;
(function (PageSectionEnum) {
    PageSectionEnum["Events"] = "Events";
    PageSectionEnum["Bio"] = "Bio";
    PageSectionEnum["Tickets"] = "Tickets";
    PageSectionEnum["About"] = "About";
    PageSectionEnum["Venue_Information"] = "Venue Information";
    PageSectionEnum["FAQs"] = "FAQs";
    PageSectionEnum["Share"] = "Share";
})(PageSectionEnum || (PageSectionEnum = {}));
export var scrollTo = function (scrollTo, isMobile, eventsRef, bioRef, ticketsRef, venueInformationRef, faqRef) {
    var _a;
    var navbarOffset = isMobile ? 128 : 158;
    var sections = [{ name: PageSectionEnum.Events, ref: eventsRef },
        { name: PageSectionEnum.Bio, ref: bioRef },
        { name: PageSectionEnum.About, ref: bioRef },
        { name: PageSectionEnum.Tickets, ref: ticketsRef },
        { name: PageSectionEnum.Venue_Information, ref: venueInformationRef },
        { name: PageSectionEnum.FAQs, ref: faqRef }];
    var selectedSectionRef = (_a = sections.find(function (section) { return section.name === scrollTo; })) === null || _a === void 0 ? void 0 : _a.ref;
    selectedSectionRef && window.scrollTo({ top: selectedSectionRef.current.offsetTop - navbarOffset, behavior: 'smooth' });
};
export var useScrollTo = function () {
    var bioRef = useRef();
    var eventsRef = useRef();
    var ticketsRef = useRef();
    var venueInformationRef = useRef();
    var faqRef = useRef();
    var media = useViewportInfo().media;
    var executeScroll = useCallback(function (section) {
        return scrollTo(section, media === 'mobile', eventsRef, bioRef, ticketsRef, venueInformationRef, faqRef);
    }, [media]);
    return { bioRef: bioRef, eventsRef: eventsRef, ticketsRef: ticketsRef, executeScroll: executeScroll, venueInformationRef: venueInformationRef, faqRef: faqRef };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { slugifyName } from './helpers/location';
import request from './helpers/request';
import { formatDateWithoutTZ } from '@logic/helpers/date';
import { routes } from 'src/client/routing/routes';
export var search = createAction('search', function (phrase) { return ({
    phrase: phrase,
}); });
export var inCache = createAction('search phrase in cache', function (phrase) { return ({
    phrase: phrase,
}); });
export var searchLoaded = createAction('search results loading succeed', function (data) { return data; });
export var searchLoadingFail = createAction('search failed', function (error) { return ({ error: error }); });
var initialState = {
    isLoading: false,
    results: {},
    prevSuccessSearch: '',
};
var rawEventSearchRowToSearchRow = function (raw) {
    var slugifyEventName = slugifyName(raw.event);
    var slugifyCity = slugifyName(raw.city);
    var slugifyDate = slugifyName(formatDateWithoutTZ(new Date(raw.date)));
    return __assign(__assign({}, raw), { title: raw.name, date: new Date(raw.date), collection: 'events', route: routes.event(raw.id, slugifyEventName, slugifyCity, slugifyDate, 'buy') });
};
var rawPerformerSearchRowToSearchRow = function (raw) {
    var rest = __rest(raw, []);
    var route = routes.performer(raw.id, slugifyName(raw.name));
    return __assign(__assign({}, rest), { title: raw.name, collection: 'performers', route: route });
};
var rawVenueSearchRowToSearchRow = function (raw) {
    var rest = __rest(raw, []);
    var route = routes.venue(slugifyName(raw.venue), raw.id);
    return __assign(__assign({}, rest), { title: raw.name, collection: 'venues', route: route });
};
var rawSearchResultToSearchResult = function (raw) {
    return {
        events: raw.events.map(rawEventSearchRowToSearchRow),
        venues: raw.venues.map(function (row) { return rawVenueSearchRowToSearchRow(row); }),
        performers: raw.performers.map(function (row) { return rawPerformerSearchRowToSearchRow(row); }),
    };
};
export var searchReducer = createReducer((_a = {},
    _a[search.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: true })); },
    _a[inCache.getType()] = function (state, _a) {
        var phrase = _a.phrase;
        return (__assign(__assign({}, state), { isLoading: false, prevSuccessSearch: phrase }));
    },
    _a[searchLoaded.getType()] = function (state, _a) {
        var _b;
        var phrase = _a.phrase, results = _a.results;
        return (__assign(__assign({}, state), { results: __assign(__assign({}, state.results), (_b = {}, _b[phrase] = rawSearchResultToSearchResult(results), _b)), isLoading: false, prevSuccessSearch: phrase }));
    },
    _a[searchLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false })); },
    _a), initialState);
export var checkPhraseCached = function (state, phrase) {
    return !!state.search.results[phrase];
};
export var searchApi = {
    search: function (phrase, limit) { return request("/api/homesearch/" + phrase + "/0/" + limit); },
};
export function onSearch(_a) {
    var isCached, results, e_1;
    var phrase = _a.payload.phrase;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 10]);
                if (phrase === '') {
                    phrase = '*';
                }
                return [4 /*yield*/, select(checkPhraseCached, phrase)];
            case 1:
                isCached = _b.sent();
                if (!isCached) return [3 /*break*/, 3];
                return [4 /*yield*/, put(inCache(phrase))];
            case 2:
                _b.sent();
                return [2 /*return*/];
            case 3: return [4 /*yield*/, delay(300)];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(searchApi.search, phrase, 3)];
            case 5:
                results = _b.sent();
                return [4 /*yield*/, put(searchLoaded({
                        phrase: phrase,
                        results: results,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 10];
            case 7:
                e_1 = _b.sent();
                return [4 /*yield*/, put(searchLoadingFail(e_1))];
            case 8:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot search')];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function searchSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(search.getType(), onSearch)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PriceButton } from '@components/priceButton/priceButton';
import { t } from '@containers/helpers/tests';
import { Images } from '@logic/images';
import { getBuyCosts, getSellCosts } from '@logic/transactions';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'src/client/routing/routes';
import { TxLazyImage } from '../../components/image/lazyImage';
import styles from '../events/events.less';
import { slugifyName } from '@logic/helpers/location';
export var PerformerCard = function (_a) {
    var performer = _a.performer, userSettings = _a.userSettings;
    var performerSlugifyName = slugifyName(performer.name);
    var minSellPrice = useMemo(function () {
        if (!performer.minSellPrice) {
            return null;
        }
        var ticketFee = getBuyCosts(performer.minSellPrice.value, 'any', 1, userSettings, false).ticketFee; // todo?
        return {
            value: performer.minSellPrice.value + ticketFee,
            currency: performer.minSellPrice.currency
        };
    }, [performer.minSellPrice, userSettings]);
    var maxBuyPrice = useMemo(function () {
        if (!performer.maxBuyPrice) {
            return null;
        }
        var ticketFee = getSellCosts(performer.maxBuyPrice.value, 'any', 1, userSettings, false).ticketFee; // todo?
        return {
            value: performer.maxBuyPrice.value - ticketFee,
            currency: performer.maxBuyPrice.currency
        };
    }, [performer.maxBuyPrice, userSettings]);
    return React.createElement("div", __assign({ className: cn(styles.Event, styles.Card) }, t('PerformerCard')),
        React.createElement(Link, { to: routes.performer(performer.id, performerSlugifyName) },
            React.createElement(TxLazyImage, { useLargeAspectRatio: true, className: styles.imageSize, src: performer.imageUrl + "/" + Images.hottest(performer.name), mobileSrc: performer.imageUrl + "/" + Images.mobile(performer.name) })),
        React.createElement("div", { className: styles['Event-Details'] },
            React.createElement("div", { className: styles['Event-Name'] },
                React.createElement(Link, { to: routes.performer(performer.id, performerSlugifyName) },
                    performer.name,
                    " Tickets"))),
        React.createElement("div", { className: styles['Event-Actions'] },
            React.createElement(Link, { to: routes.performer(performer.id, performerSlugifyName) },
                React.createElement(PriceButton, { price: minSellPrice !== null && minSellPrice !== void 0 ? minSellPrice : null, transactionSide: 'buy', center: true, round: 'up' })),
            React.createElement(Link, { to: routes.performer(performer.id, performerSlugifyName) },
                React.createElement(PriceButton, { price: maxBuyPrice !== null && maxBuyPrice !== void 0 ? maxBuyPrice : null, transactionSide: 'sell', center: true, round: 'down' }))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styles from '@components/image/image.less';
import cn from 'classnames';
import { useStaticContext } from '@components/useStaticContext';
export var Image = function (_a) {
    var _b;
    var _c, _d, _e;
    var src = _a.src, alt = _a.alt, className = _a.className, rest = __rest(_a, ["src", "alt", "className"]);
    (_e = (_d = (_c = useStaticContext()) === null || _c === void 0 ? void 0 : _c.imagesToPush) === null || _d === void 0 ? void 0 : _d.add) === null || _e === void 0 ? void 0 : _e.call(_d, src);
    return (React.createElement("img", __assign({ className: cn(styles.image, className, (_b = {}, _b[styles.pointer] = !!rest.onClick, _b)), src: src, alt: alt }, rest)));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { routes } from 'src/client/routing/routes';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { createAsyncAction, getType } from 'typesafe-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from './helpers/request';
import { tryMapError } from './errorCodeMapper';
export var actions = {
    loadBankAccounts: createAsyncAction('load bank accounts', 'load bank accounts succeed', 'load bank accounts failure')(),
    createBankAccount: createAsyncAction('create bank account', 'create bank account succeed', 'create bank account failure')(),
    updateBankAccount: createAsyncAction('update bank account', 'update bank account succeed', 'update bank account failure')(),
    deleteBankAccount: createAsyncAction('delete bank account', 'delete bank account succeed', 'delete bank account failure')(),
    setAsDefaultBankAccount: createAsyncAction('set as default bank account', 'set as default bank account succeed', 'set as default bank account failure')()
};
var initialState = {
    isPending: false,
    data: [],
    errors: [],
};
export var bankAccountsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case getType(actions.loadBankAccounts.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.loadBankAccounts.success): return __assign(__assign({}, state), { isPending: false, data: action.payload, errors: [] });
        case getType(actions.loadBankAccounts.failure): return __assign(__assign({}, state), { isPending: false, data: [], errors: action.payload });
        case getType(actions.createBankAccount.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.createBankAccount.success): return __assign(__assign({}, state), { isPending: false, data: __spreadArray(__spreadArray([], __read(state.data)), [action.payload]) });
        case getType(actions.createBankAccount.failure): return __assign(__assign({}, state), { isPending: false, errors: action.payload });
        case getType(actions.updateBankAccount.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.updateBankAccount.success): return __assign(__assign({}, state), { isPending: false, data: state.data.map(function (account) { return account.id === action.payload.id ? action.payload : account; }) });
        case getType(actions.updateBankAccount.failure): return __assign(__assign({}, state), { isPending: false, errors: action.payload });
        case getType(actions.deleteBankAccount.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.deleteBankAccount.success): return __assign(__assign({}, state), { isPending: false, data: state.data.filter(function (account) { return account.id !== action.payload; }) });
        case getType(actions.deleteBankAccount.failure): return __assign(__assign({}, state), { isPending: false, errors: action.payload });
        case getType(actions.setAsDefaultBankAccount.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.setAsDefaultBankAccount.success): return __assign(__assign({}, state), { isPending: false, data: state.data.map(function (bankAccount) {
                return bankAccount.id === action.payload
                    ? __assign(__assign({}, bankAccount), { isDefault: true }) : bankAccount.isDefault
                    ? __assign(__assign({}, bankAccount), { isDefault: false }) : bankAccount;
            }) });
        case getType(actions.setAsDefaultBankAccount.failure): return __assign(__assign({}, state), { isPending: false, errors: action.payload });
        default: return state;
    }
};
export var bankAccountsApi = {
    loadBankAccounts: function () { return request('/api/paymentmethods/bankaccounts'); },
    createBankAccount: function (bankAccount) { return request('/api/paymentmethods/createbankaccount', bankAccount); },
    updateBankAccount: function (bankAccount) { return request('/api/paymentmethods/updatebankaccount', bankAccount); },
    deleteBankAccount: function (id) { return request('/api/paymentmethods/deletebankaccount/', { id: id }, { method: 'delete' }); },
    setAsDefault: function (id) { return request('/api/paymentmethods/setasdefaultbankaccount', { id: id }); },
};
export function onLoadBankAccounts(_) {
    var bankAccountsData, e_1, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(bankAccountsApi.loadBankAccounts)];
            case 1:
                bankAccountsData = _b.sent();
                return [4 /*yield*/, put(actions.loadBankAccounts.success(bankAccountsData))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _b.sent();
                errorMsg = (_a = tryMapError(e_1)) !== null && _a !== void 0 ? _a : 'Cannot load bank accounts.';
                return [4 /*yield*/, put(actions.loadBankAccounts.failure([errorMsg]))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onCreateBankAccount(_a) {
    var bankAccount, createBankAccountDto, createdBankAccount, e_2, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                bankAccount = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 8]);
                createBankAccountDto = {
                    accountOwnerName: bankAccount.accountOwnerName,
                    accountNumber: bankAccount.accountNumber,
                    sortCode: bankAccount.sortCode
                };
                return [4 /*yield*/, call(bankAccountsApi.createBankAccount, createBankAccountDto)];
            case 2:
                createdBankAccount = _c.sent();
                return [4 /*yield*/, put(actions.createBankAccount.success(createdBankAccount))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(push(routes.userBankAccounts()))];
            case 4:
                _c.sent();
                return [3 /*break*/, 8];
            case 5:
                e_2 = _c.sent();
                errorMsg = (_b = tryMapError(e_2)) !== null && _b !== void 0 ? _b : 'We have been unable to add this bank account. Please check that the details you have entered are correct and try again or alternatively please try another card.';
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 6:
                _c.sent();
                return [4 /*yield*/, put(actions.createBankAccount.failure([errorMsg]))];
            case 7:
                _c.sent();
                return [2 /*return*/, false];
            case 8: return [2 /*return*/, true];
        }
    });
}
export function onUpdateBankAccount(_a) {
    var bankAccount, updatedBankAccount, e_3, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                bankAccount = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call(bankAccountsApi.updateBankAccount, bankAccount)];
            case 2:
                updatedBankAccount = _c.sent();
                return [4 /*yield*/, put(actions.updateBankAccount.success(updatedBankAccount))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(push(routes.userBankAccounts()))];
            case 4:
                _c.sent();
                return [3 /*break*/, 8];
            case 5:
                e_3 = _c.sent();
                errorMsg = (_b = tryMapError(e_3)) !== null && _b !== void 0 ? _b : 'We have been unable to update this bank account. Please try again later.';
                return [4 /*yield*/, put(actions.updateBankAccount.failure([errorMsg]))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onDeleteBankAccount(_a) {
    var bankAccountId, e_4, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                bankAccountId = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 7]);
                return [4 /*yield*/, call(bankAccountsApi.deleteBankAccount, bankAccountId)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(actions.deleteBankAccount.success(bankAccountId))];
            case 3:
                _c.sent();
                return [3 /*break*/, 7];
            case 4:
                e_4 = _c.sent();
                errorMsg = (_b = tryMapError(e_4)) !== null && _b !== void 0 ? _b : 'Cannot delete bank account';
                return [4 /*yield*/, put(actions.deleteBankAccount.failure([errorMsg]))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onSetAsDefaultBankAccount(_a) {
    var bankAccountId, e_5, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                bankAccountId = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 7]);
                return [4 /*yield*/, call(bankAccountsApi.setAsDefault, bankAccountId)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(actions.setAsDefaultBankAccount.success(bankAccountId))];
            case 3:
                _c.sent();
                return [3 /*break*/, 7];
            case 4:
                e_5 = _c.sent();
                errorMsg = (_b = tryMapError(e_5)) !== null && _b !== void 0 ? _b : 'Cannot set bank account as default';
                return [4 /*yield*/, put(actions.setAsDefaultBankAccount.failure([errorMsg]))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function bankAccountsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadBankAccounts.request), onLoadBankAccounts)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.createBankAccount.request), onCreateBankAccount)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.updateBankAccount.request), onUpdateBankAccount)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.deleteBankAccount.request), onDeleteBankAccount)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.setAsDefaultBankAccount.request), onSetAsDefaultBankAccount)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from 'classnames';
import React from 'react';
import { Image } from 'semantic-ui-react';
import styles from './icons.less';
import icoApply from './icons/apply.svg';
import { ReactComponent as IcoAnyTicket } from './icons/ico-any-ticket.svg';
import { ReactComponent as IcoEvent } from './icons/ico-calendar.svg';
import icoChevron from './icons/ico-chevron.svg';
import icoChisel from './icons/ico-chisel.svg';
import icoClone from './icons/ico-clone.svg';
import { ReactComponent as IcoClose } from './icons/ico-close-w.svg';
import { ReactComponent as IcoShare } from './icons/ico-share.svg';
import { ReactComponent as IcoEdit } from './icons/ico-edit.svg';
import { ReactComponent as IcoETicket } from './icons/ico-eticket.svg';
import { ReactComponent as IcoFilter } from './icons/ico-filter.svg';
import { ReactComponent as IcoFlashsale } from './icons/ico-flashsale.svg';
import { ReactComponent as IcoHelpMePrice } from './icons/ico-help-me-price.svg';
import { ReactComponent as IcoOutline } from './icons/ico-info-outline.svg';
import { ReactComponent as IcoOutlineBlack } from './icons/ico-info-outline-black.svg';
import { ReactComponent as IcoInfoS } from './icons/ico-info-s.svg';
import { ReactComponent as IcoInfo } from './icons/ico-info.svg';
import { ReactComponent as IcoInstantETicket } from './icons/ico-instantticket.svg';
import { ReactComponent as IcoIsBusiness } from './icons/ico-is-business.svg';
import { ReactComponent as IcoMenu } from './icons/ico-menu.svg';
import { ReactComponent as IcoMobileTransfer } from './icons/ico-mobile-transfer-ticket.svg';
import { ReactComponent as IcoQRCode } from './icons/ico-qrcode-ticket.svg';
import { ReactComponent as IcoPaperTicket } from './icons/ico-paperticket.svg';
import { ReactComponent as IcoPerformer } from './icons/ico-performer.svg';
import { ReactComponent as IcoSearch } from './icons/ico-search.svg';
import { ReactComponent as IcoDownload } from './icons/ico-download.svg';
import { ReactComponent as IcoUpload } from './icons/ico-upload.svg';
import { ReactComponent as IcoEnvelope } from './icons/ico-envelope.svg';
import { ReactComponent as IcoEye } from './icons/ico-eye.svg';
import icoTrash from './icons/ico-trash.svg';
import icoUser from './icons/ico-user.svg';
import { ReactComponent as IcoVenueS } from './icons/ico-venue-s.svg';
import { ReactComponent as IcoVenue } from './icons/ico-venues.svg';
import { ReactComponent as IcoPoint } from './icons/ico-point.svg';
import { ReactComponent as IcoPlay } from './icons/ico-play.svg';
import { ReactComponent as ArrowLeft } from './icons/ico-arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/ico-arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/ico-arrow-up.svg';
import { ReactComponent as ArrowDown } from './icons/ico-arrow-down.svg';
import { ReactComponent as CaretDown } from './icons/ico-caret-down.svg';
import { ReactComponent as CaretRight } from './icons/ico-caret-right.svg';
import { ReactComponent as CaretLeft } from './icons/ico-caret-left.svg';
import { ReactComponent as CaretUp } from './icons/ico-caret-up.svg';
import { ReactComponent as IcoZoom } from './icons/ico-zoom.svg';
import { ReactComponent as IcoCreditCard } from './icons/ico-credit-card.svg';
import { ReactComponent as IcoReverse } from './icons/ico-reverse.svg';
import { ReactComponent as IcoCopy } from './icons/ico-copy.svg';
import { ReactComponent as IcoExpand } from './icons/ico-expand.svg';
import { ReactComponent as SortAscending } from './icons/ico-sort-asceding.svg';
import { ReactComponent as SortDescending } from './icons/ico-sort-descending.svg';
import { ReactComponent as IcoMinus } from './icons/ico-minus.svg';
import { ReactComponent as IcoPlus } from './icons/ico-plus.svg';
import { ReactComponent as IcoMinusOutlined } from './icons/ico-minus-outlined.svg';
import { ReactComponent as IcoPlusOutlined } from './icons/ico-plus-outlined.svg';
import { ReactComponent as IcoStadium } from './icons/ico-stadium.svg';
import { ReactComponent as IcoFilterThin } from './icons/ico-filter-thin.svg';
var icons = {
    performers: IcoPerformer,
    venues: IcoVenue,
    venue: IcoVenueS,
    copy: IcoCopy,
    share: IcoShare,
    events: IcoEvent,
    search: IcoSearch,
    zoom: IcoZoom,
    info: IcoInfo,
    'info-s': IcoInfoS,
    'info-outline': IcoOutline,
    'info-outline-black': IcoOutlineBlack,
    download: IcoDownload,
    upload: IcoUpload,
    envelope: IcoEnvelope,
    view: IcoEye,
    'credit-card': IcoCreditCard,
    close: IcoClose,
    eticket: IcoETicket,
    'instant-eticket': IcoInstantETicket,
    'flashsale-ticket': IcoFlashsale,
    'paper-ticket': IcoPaperTicket,
    'mobile-transfer-ticket': IcoMobileTransfer,
    'any-ticket': IcoAnyTicket,
    'qrcode-ticket': IcoQRCode,
    clone: icoClone,
    edit: IcoEdit,
    'help-me-price': IcoHelpMePrice,
    chisel: icoChisel,
    apply: icoApply,
    trash: icoTrash,
    user: icoUser,
    menu: IcoMenu,
    chevron: icoChevron,
    'is-business': IcoIsBusiness,
    filter: IcoFilter,
    point: IcoPoint,
    play: IcoPlay,
    arrowRight: ArrowRight,
    arrowLeft: ArrowLeft,
    arrowDown: ArrowDown,
    arrowUp: ArrowUp,
    caretDown: CaretDown,
    caretUp: CaretUp,
    caretRight: CaretRight,
    caretLeft: CaretLeft,
    sortAscending: SortAscending,
    sortDescending: SortDescending,
    reverse: IcoReverse,
    expand: IcoExpand,
    'ico-minus': IcoMinus,
    'ico-plus': IcoPlus,
    'ico-minus-outlined': IcoMinusOutlined,
    'ico-plus-outlined': IcoPlusOutlined,
    'stadium': IcoStadium,
    'filterThin': IcoFilterThin
};
export function Icon(_a) {
    var _b, _c;
    var icon = _a.icon, className = _a.className, style = _a.style, rest = __rest(_a, ["icon", "className", "style"]);
    var IconPath = icons[icon];
    if (typeof IconPath === 'string') {
        return (React.createElement(Image, __assign({ src: IconPath, alt: icon, inline: true, style: style, className: cn((_b = {},
                _b[styles.pointer] = !!rest.onClick,
                _b), className) }, rest)));
    }
    return (React.createElement(IconPath, __assign({ style: style, className: cn((_c = {},
            _c[styles.pointer] = !!rest.onClick,
            _c), className) }, rest)));
}

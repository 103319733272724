var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { useCallback, useState } from 'react';
import { defaultOrdering } from '@containers/DetailsPages/components/eventsTable/sorting/sorting';
export var useSorting = function (events) {
    var _a = __read(useState('date'), 2), sort = _a[0], changeSort = _a[1];
    var _b = __read(useState(defaultOrdering), 2), order = _b[0], changeOrder = _b[1];
    var ordered = orderBy(events, [{
            date: 'eventDate',
            event: function (event) { return event.name.toLowerCase(); },
            onSaleDate: 'onSaleDate',
            // [DM] TODO this is still incorrect, because we do not take currency under consideration, but it's better than before. #564
            sellPrice: 'maxBuyPrice.value',
            buyPrice: 'minSellPrice.value',
        }[sort]], [{
            ascending: 'asc',
            descending: 'desc',
        }[order]]);
    var results = partition(ordered, function (es) { return es.status !== 'postponed'; }).flat();
    var changeSorting = useCallback(function (by, direction) {
        changeSort(by);
        changeOrder(direction);
    }, [changeSort, changeOrder]);
    return { sort: sort, order: order, changeSorting: changeSorting, results: results };
};

import { useIntervalRefresh } from '@components/forms';
import { formatDate, formatDateTime, formatTime } from '@logic/helpers/date';
import cn from 'classnames';
import { differenceInMilliseconds } from 'date-fns';
import React from 'react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/PreOnSale/preOnSale.less';
function NumberWrapper(_a) {
    var value = _a.value, name = _a.name;
    return (React.createElement("div", { className: styles.singleNumberWrapper },
        React.createElement("div", { className: styles.singleNumber }, ('0' + value).slice(-2)),
        React.createElement("div", { className: styles.name }, name)));
}
function Separator() {
    return React.createElement("div", { className: styles.separator }, ":");
}
function Counter(_a) {
    var onSaleDate = _a.onSaleDate;
    var dt = useIntervalRefresh(100, function () { return Math.abs(differenceInMilliseconds(onSaleDate, new Date())); });
    if (!dt) {
        return null;
    }
    return (React.createElement("div", { className: styles.counter },
        React.createElement(NumberWrapper, { value: Math.floor(dt / 1000 / 60 / 60 / 24), name: 'days' }),
        React.createElement(Separator, null),
        React.createElement(NumberWrapper, { value: Math.floor(dt / 1000 / 60 / 60) % 24, name: 'hours' }),
        React.createElement(Separator, null),
        React.createElement(NumberWrapper, { value: Math.floor(dt / 1000 / 60) % 60, name: 'minutes' }),
        React.createElement(Separator, null),
        React.createElement(NumberWrapper, { value: Math.floor(dt / 1000) % 60, name: 'seconds' })));
}
export function PreOnSale(_a) {
    var _b;
    var onSaleDate = _a.onSaleDate, transactionSide = _a.transactionSide, timezone = _a.timezone, notifyOnSaleNotification = _a.notifyOnSaleNotification;
    if (onSaleDate.getTime() < Date.now()) {
        return null;
    }
    if (transactionSide === 'sell') {
        return (React.createElement("div", { className: cn(styles.saleText, (_b = {},
                _b["notification" + notifyOnSaleNotification % 2] = notifyOnSaleNotification > 0,
                _b)) },
            "This event opens for transactions on: ",
            formatDateTime(onSaleDate, timezone),
            " local time"));
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: styles.text }, "This event will go on sale on:"),
        React.createElement("div", { className: styles.text }, formatDate(onSaleDate, timezone)),
        React.createElement("div", { className: styles.text },
            formatTime(onSaleDate, timezone),
            " local time"),
        React.createElement(Counter, { onSaleDate: onSaleDate })));
}

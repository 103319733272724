var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import get from 'lodash/get';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from './helpers/request';
import { login } from './user';
import { toast } from 'react-toastify';
export var registerUser = createAction('register user', function (data) { return data; });
export var registerReset = createAction('reset previous register');
export var userRegistered = createAction('registration user success', function (response) { return response; });
export var userRegistrationProblem = createAction('registration user problem', function (problems) { return problems; });
export var userRegistrationFail = createAction('registration user failed', function (error) { return ({
    error: error,
}); });
var initialState = {
    isLoading: false,
    succeeded: false,
    messages: [],
};
export var registrationReducer = createReducer((_a = {},
    _a[registerUser.getType()] = function (state) { return (__assign(__assign({}, state), { messages: [], succeeded: false, isLoading: true })); },
    _a[registerReset.getType()] = function (state) { return (__assign(__assign({}, state), { messages: [], succeeded: false })); },
    _a[userRegistered.getType()] = function (state) { return (__assign(__assign({}, state), { succeeded: true, isLoading: false })); },
    _a[userRegistrationProblem.getType()] = function (state, messages) { return (__assign(__assign({}, state), { messages: messages, isLoading: false })); },
    _a[userRegistrationFail.getType()] = function (state) { return (__assign(__assign({}, state), { messages: [], isLoading: false })); },
    _a), initialState);
export var authApi = {
    register: function (payload) { return request('/api/auth/register', payload); },
    changePassword: function (payload) { return request('/api/auth/ChangePassword', payload); },
    login: function (payload, errorHandler) { return request('/api/auth/Token', payload, { errorHandler: errorHandler }); },
    facebookLogin: function (payload, errorHandler) { return request('/api/auth/Facebook', payload, { errorHandler: errorHandler }); },
    googleLogin: function (payload, errorHandler) { return request('/api/auth/Google', payload, { errorHandler: errorHandler }); },
    refreshToken: function (payload) { return request('/api/auth/RefreshToken', payload); },
    updateUserDetails: function (details) { return request('/api/auth/updateUserDetails', details); },
    changeEmail: function (newEmail, password) { return request('/api/auth/changeEmail', {
        newEmail: newEmail,
        password: password,
    }); },
    requestResetPassword: function (email, type) { return request('/api/auth/requestResetPassword', {
        email: email,
        type: type
    }); },
    resetPassword: function (email, token, password, confirmPassword) { return request('/api/auth/resetPassword', {
        email: email,
        token: token,
        password: password,
        confirmPassword: confirmPassword,
    }); },
    verifyResetPasswordToken: function (email, token) { return request('/api/auth/verifyResetPasswordToken', { email: email, token: token }); },
};
export function onUserRegistration(_a) {
    var response, email, password, redirect, e_1, data, problems, updatedProblems;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 11]);
                return [4 /*yield*/, call(authApi.register, payload)];
            case 1:
                response = _b.sent();
                email = payload.email, password = payload.password, redirect = payload.redirect;
                return [4 /*yield*/, put(userRegistered(response))];
            case 2:
                _b.sent();
                if (!password) return [3 /*break*/, 5];
                return [4 /*yield*/, put(login({
                        email: email,
                        password: password,
                        redirect: redirect
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Congratulations! Your account has been successfully created.')];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 11];
            case 6:
                e_1 = _b.sent();
                if (!(get(e_1, 'status') === 400)) return [3 /*break*/, 8];
                data = get(e_1, 'data');
                problems = Array.isArray(data) ? data : [data];
                updatedProblems = problems
                    .filter(function (f) { return f.code !== 'DuplicateUserName'; })
                    .map(function (f) { return f.code === 'PasswordTooShort' ? __assign(__assign({}, f), { description: 'Your password must contain at least 6 characters' }) : f; });
                return [4 /*yield*/, put(userRegistrationProblem(updatedProblems))];
            case 7:
                _b.sent();
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, put(userRegistrationFail(JSON.stringify(e_1)))];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function registrationSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(registerUser.getType(), onUserRegistration)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import cn from 'classnames';
import memoize from 'lodash/memoize';
var getUsedListingsFn = function (listings) {
    return listings.map(function (l) { return l.seatingInfo; })
        .reduce(function (agg, si) { return __spreadArray(__spreadArray([], __read(agg)), __read(si)); }, [])
        .reduce(function (agg, _a) {
        var category = _a.category, section = _a.section, matchAny = _a.matchAny;
        if (matchAny) {
            agg.any = { any: 1 };
            return agg;
        }
        agg[category] = agg[category] || {};
        var key = section === '' ? 'any' : section;
        agg[category][key] = 1;
        return agg;
    }, {});
};
export var usedListings = memoize(getUsedListingsFn);
export var usedFilteredListings = memoize(getUsedListingsFn);
export var hasListing = function (section, listings, transactionSide, getUsedListings) {
    var used = getUsedListings(listings);
    if (used.any && transactionSide === 'sell') {
        return true;
    }
    if (used[section.category] && used[section.category].any && (transactionSide === 'sell' || section.section === '')) {
        return true;
    }
    return !!used[section.category] && !!used[section.category][section.section];
};
/** Description here: https://gitlab.com/ticketex/ticketex/issues/1295#note_238476057
 *
 * Section can have following states (first matching state is selected):
 * - invisible: section is not enabled in AP
 * - outlined: section is selected by user (by click on given one)
 * - inactive (change to transparent): section has no listing* available at all
 * - solid color:
 *    - user is hovering pointer over section
 *    - user is hovering pointer over category with that section (regardless of whether any listings have been filtered out)
 *    - user is hovering over listing with that section*
 *    - all of the following are fulfilled:
 *      - user isn't hovering over any section/category/listing*
 *      - none of the section is selected
 *      - none of the categories are selected OR selected is category that contains given section
 *      - there is at least one listing with matching section*
 * - semi-transparent: any other case
 *
 * * for buy side only exact match is correct; for sell side section matches to the listing if:
 *  - it's exact match between section and listing section
 *  - section is under category X and listing is for generic category X
 *  - there is listing for any category
 */
export var mapColor = function (props) {
    var categoryIdx = props.categoryIdx, category = props.category, section = props.section, sections = props.sections, categories = props.categories, hoveredListing = props.hoveredListing, listings = props.listings, filteredListings = props.filteredListings, transactionSide = props.transactionSide, hoveredSection = props.hoveredSection, hoveredCategory = props.hoveredCategory, isEditListingPage = props.isEditListingPage;
    var isThisSectionActive = sections.some(function (s) { return s.category === category && s.section === section && s.isActive; });
    var idx = categoryIdx % 10;
    if (isThisSectionActive) {
        return "color" + idx + "-outlined";
    }
    if (!isEditListingPage && !hasListing({ category: category, section: section }, listings, transactionSide, usedListings)) {
        return cn("color" + idx + " inactive");
    }
    var color = "color" + idx;
    var semiColor = "color" + idx + "-semi-opaque";
    var anySectionActive = sections.some(function (s) { return s.isActive; });
    var anyCategoryActive = categories.some(function (c) { return c.isActive; });
    var isThisSectionHovered = hoveredSection && hoveredSection.category === category && hoveredSection.section === section;
    var isListingHovered = hoveredListing && hoveredListing.seatingInfo.some(function (nsi) {
        return (nsi.matchAny && transactionSide === 'sell')
            || (nsi.category === category && nsi.section === '' && transactionSide === 'sell')
            || (nsi.category === category && nsi.section === section);
    });
    var isThisCategorySelected = categories.some(function (c) { return c.category === category && c.isActive; });
    var isThisCategoryHovered = hoveredCategory && hoveredCategory.category === category;
    if (isThisSectionHovered) {
        return color;
    }
    if (isThisCategoryHovered) {
        return color;
    }
    if (isListingHovered) {
        return color;
    }
    var listingForThisSection = hasListing({ category: category, section: section }, filteredListings, transactionSide, usedFilteredListings);
    if (!hoveredCategory && !hoveredSection && !hoveredListing
        && !anySectionActive
        && (!anyCategoryActive || isThisCategorySelected)
        && (isEditListingPage || listingForThisSection)) {
        return color;
    }
    return semiColor;
};
export var invisibleSections = function (_a) {
    var seatingPlan = _a.seatingPlan, foundSections = _a.foundSections;
    if (!seatingPlan) {
        return [];
    }
    return foundSections
        .filter(function (_a) {
        var category = _a.category, section = _a.section;
        var cat = seatingPlan.structure.categories.find(function (c) { return c.name === category; });
        return !cat || (section && cat.sections.indexOf(section) === -1);
    })
        .map(function (_a) {
        var category = _a.category, section = _a.section;
        return ({
            category: category,
            section: section || undefined,
            className: 'invisible',
        });
    });
};
export var calculateItems = function (props) {
    var seatingPlan = props.seatingPlan;
    if (!seatingPlan) {
        return [];
    }
    var colored = seatingPlan.structure.categories.map(function (_a, idx) {
        var name = _a.name, sections = _a.sections;
        var sectionsList = !sections || !sections.length ? [undefined] : sections;
        return sectionsList.map(function (section) { return ({
            category: name,
            section: section,
            className: mapColor(__assign(__assign({}, props), { categoryIdx: idx, category: name, section: section || '' })),
        }); });
    }).reduce(function (a, i) { return __spreadArray(__spreadArray([], __read(a)), __read(i)); }, []);
    var invisible = invisibleSections(props);
    return colored.concat(invisible);
};

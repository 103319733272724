import { Modal } from '@components/forms';
import styles from '@containers/DetailsPages/components/shareSocial/shareSocial.less';
import { TxLazyImage } from '@components/image/lazyImage';
import { Images } from '@logic/images';
import React from 'react';
import { ShareSocial } from '@containers/DetailsPages/components/shareSocial/shareSocial';
export var ShareSocialDesktop = function (_a) {
    var _b, _c;
    var links = _a.links, details = _a.details, handleClose = _a.handleClose, isOpen = _a.isOpen, detailsSubtitle = _a.detailsSubtitle;
    return (React.createElement(Modal, { open: isOpen, onClose: handleClose, contentClassName: 'layoutPadding', close: 'icon' },
        React.createElement("div", { className: styles.linkDetails },
            (details === null || details === void 0 ? void 0 : details.imageUrl) &&
                React.createElement("div", { className: styles.performerImageContainer },
                    React.createElement(TxLazyImage, { useLargeAspectRatio: true, src: details.imageUrl + "/" + Images.eventList((details === null || details === void 0 ? void 0 : details.name) || ''), mobileSrc: '', visibleByDefault: true })),
            React.createElement("div", { className: styles.performerHeader },
                React.createElement("div", { className: styles.performerTitle }, (_b = details === null || details === void 0 ? void 0 : details.name) !== null && _b !== void 0 ? _b : ''),
                detailsSubtitle && React.createElement("div", { className: styles.detailsSubtitle }, detailsSubtitle)),
            React.createElement("span", { className: styles.performerDescription }, (_c = details === null || details === void 0 ? void 0 : details.description) !== null && _c !== void 0 ? _c : '')),
        React.createElement(ShareSocial, { links: links })));
};

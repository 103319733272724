import { slugifyName } from '@logic/helpers/location';
import { eventLinkSlugToEventRoute, routes } from 'src/client/routing/routes';
import { formatDateTime } from '@logic/helpers/date';
import { getBuyCosts } from '@logic/transactions';
import { Images } from '../images';
import { CATEGORY_TYPES } from '@logic/categories';
export var mapCategoryToEventType = function (_a) {
    var category = _a.category, subcategory = _a.subcategory;
    switch (category) {
        case CATEGORY_TYPES.concert:
            return 'MusicEvent';
        case CATEGORY_TYPES.festival:
            return 'Festival';
        case CATEGORY_TYPES.sport:
            return 'SportsEvent';
        case CATEGORY_TYPES.theatre_and_comedy:
            if (subcategory.toUpperCase() === 'COMEDY') {
                return 'ComedyEvent';
            }
            return 'TheatreEvent';
        default:
            return '';
    }
};
var mapEventStatus = function (event) {
    switch (event.status) {
        case 'cancelled': return 'https://schema.org/EventCancelled';
        case 'postponed': return 'https://schema.org/EventPostponed';
        case 'rescheduled': return 'https://schema.org/EventRescheduled';
        default: return 'https://schema.org/EventScheduled';
    }
};
export var prepareRawJsonLdData = function (events, userSettings, url) {
    var hostname = url.hostname;
    return events.map(function (val) {
        var _a, _b;
        return ({
            '@type': mapCategoryToEventType(val),
            eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
            location: {
                '@type': 'Place',
                name: val.venueName,
                address: {
                    addressLocality: val.venueCity,
                    streetAddress: val.venueStreet,
                    addressCountry: val.addressCountry,
                    postalCode: val.postalCode
                },
                sameAs: "" + hostname + routes.venue(slugifyName(val.venueName), val.venueId)
            },
            description: val.name + " Tickets | " + val.venueName + ", " + val.venueCity + " | " + formatDateTime(val.eventDate, val.timezone),
            image: val.imageUrl + "/" + Images.details(val.name),
            startDate: formatDateTime(val.eventDate, val.timezone, "yyyy-MM-dd'T'HH:mm:ss"),
            previousStartDate: val.previousEventDate ? formatDateTime(val.previousEventDate, val.timezone, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
            url: "" + hostname + eventLinkSlugToEventRoute(val.eventLinkSlug, 'buy'),
            name: val.name + " | " + val.venueCity,
            eventStatus: mapEventStatus(val),
            offers: {
                '@type': 'Offer',
                category: 'Secondary',
                url: "" + hostname + eventLinkSlugToEventRoute(val.eventLinkSlug, 'buy'),
                price: val.minSellPrice ? getBuyCosts(val.minSellPrice.value, null, 1, userSettings, false).total / 100 : 0,
                priceCurrency: (_b = (_a = val.minSellPrice) === null || _a === void 0 ? void 0 : _a.currency) !== null && _b !== void 0 ? _b : 'GBP',
                availability: val.minSellPrice ? 'http://schema.org/InStock' : ' https://schema.org/OutOfStock',
                validFrom: formatDateTime(val.onSaleDate, val.timezone, "yyyy-MM-dd'T'HH:mm:ss"),
                validThrough: formatDateTime(val.eventDate, val.timezone, "yyyy-MM-dd'T'HH:mm:ss")
            },
            performer: val.performers.map(function (val) { return ({
                name: val.performerName,
                '@type': 'Person',
                sameAs: "" + hostname + routes.performer(val.performerId, slugifyName(val.performerName))
            }); })
        });
    });
};
export var prepareRawJsonLdBreadcrumb = function (breadcrumbs, hostname) {
    return ({
        '@type': 'BreadcrumbList',
        'itemListElement': breadcrumbs.map(function (breadcrumb, index) { return ({
            '@type': 'ListItem',
            'position': index + 1,
            'name': breadcrumb.name,
            'item': "" + hostname + breadcrumb.route
        }); })
    });
};
export var prepareRawJsonLdFAQ = function (faqs) {
    return {
        '@type': 'FAQPage',
        'mainEntity': faqs.map(function (faq) { return ({
            '@type': 'Question',
            'name': faq.question,
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': faq.answer
            },
        }); })
    };
};
export var prepareJsonLdData = function (events, userSettings, url, additionalOptions) {
    var rawJsonLdData = prepareRawJsonLdData(events, userSettings, url);
    if (additionalOptions.breadcrumbs && additionalOptions.breadcrumbs.length) {
        rawJsonLdData.push(prepareRawJsonLdBreadcrumb(additionalOptions.breadcrumbs, url.hostname));
    }
    if (additionalOptions.faqs && additionalOptions.faqs.length) {
        rawJsonLdData.push(prepareRawJsonLdFAQ(additionalOptions.faqs));
    }
    return JSON.stringify(rawJsonLdData);
};

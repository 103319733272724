var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallbackRef } from '@components/forms';
import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import styles from './pleaseWait.less';
export function PleaseWait() {
    var _a = __read(useCallbackRef(null), 2), ref = _a[0], changeRef = _a[1];
    var _b = __read(useState({ height: '100vh' }), 2), style = _b[0], changeStyle = _b[1];
    useEffect(function () {
        var _a, _b;
        var top = (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.top) !== null && _b !== void 0 ? _b : 0;
        changeStyle({ height: "calc(100vh - " + top + "px)" });
    }, [ref]);
    return (React.createElement("div", { className: styles.pleaseWait, ref: changeRef, style: style },
        React.createElement("p", null, "Please wait"),
        React.createElement("p", null, "Checking for availability..."),
        React.createElement(Loader, { active: true, inline: true })));
}

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
export var anySection = { category: 'Any', section: '' };
export var stringifySection = function (_a) {
    var category = _a.category, section = _a.section;
    return section ? section + " - " + category : category;
};
export var seatingPlanToSections = function (sp, allCategories) {
    if (!sp || !sp.structure) {
        return [];
    }
    var categoriesOnly = sp.structure.categories
        .filter(function (_a) {
        var sections = _a.sections, isReservedSeating = _a.isReservedSeating;
        return !isReservedSeating || !sections.length || allCategories;
    })
        .map(function (category) { return ({
        category: category.name,
        section: '',
    }); });
    var categoriesWithSections = sp.structure.categories.filter(function (category) { return category.sections && category.sections.length; }).map(function (category) {
        return category.sections.map(function (section) { return ({
            category: category.name,
            section: section,
        }); });
    }).reduce(function (a, b) { return a.concat.apply(a, __spreadArray([], __read(b))); }, []);
    return categoriesWithSections.length === 1 ? categoriesWithSections : categoriesOnly.concat.apply(categoriesOnly, __spreadArray([], __read(categoriesWithSections)));
};
export var normalizeSellSeatingInfo = function (_a, seatRange) {
    var category = _a.category, row = _a.row, startingSeat = _a.startingSeat;
    return {
        matchAny: false,
        category: category.name,
        section: category.section || '',
        row: row,
        startingSeat: startingSeat || null,
        seatRange: seatRange,
    };
};
export var normalizeBuySeatingInfo = function (_a) {
    var matchAny = _a.matchAny, categories = _a.categories;
    if (matchAny) {
        return [{
                matchAny: true,
                category: '',
                section: '',
                row: null,
                startingSeat: null,
                seatRange: null,
            }];
    }
    return categories.map(function (category) {
        if (category.sections && category.sections.length) {
            return category.sections.map(function (section) { return ({
                matchAny: false,
                category: category.name,
                section: section,
                row: null,
                startingSeat: null,
                seatRange: null,
            }); });
        }
        return [{
                matchAny: false,
                category: category.name,
                section: '',
                row: null,
                startingSeat: null,
                seatRange: null,
            }];
    }).reduce(function (agg, arr) { return __spreadArray(__spreadArray([], __read(agg)), __read(arr)); }, []);
};
export var normalizeTransactionSeatingInfo = function (_a) {
    var category = _a.category, section = _a.section, row = _a.row, startingSeat = _a.startingSeat, seatRange = _a.seatRange;
    return {
        matchAny: category === 'any',
        category: category,
        section: section || '',
        row: row,
        startingSeat: parseInt(startingSeat || '', 10) || null,
        seatRange: seatRange,
    };
};
export var stringifyNormalizedSection = function (info) {
    if (info.matchAny) {
        return 'Any';
    }
    if (info.section) {
        return info.section + " - " + info.category;
    }
    return "" + info.category;
};
export var stringifyNormalizedRow = function (info) { return info.row ? "Row " + info.row : ''; };
export var stringifySeatsRange = function (info) {
    if (!info.startingSeat || !info.seatRange || info.seatRange <= 0) {
        return '';
    }
    if (info.seatRange === 1) {
        return "" + info.startingSeat;
    }
    return info.startingSeat + " - " + (info.startingSeat + info.seatRange - 1);
};
export var stringifyNormalizedSeats = function (info) {
    var seatsRange = stringifySeatsRange(info);
    var noun = info.seatRange === 1 ? 'Seat' : 'Seats';
    return seatsRange ? noun + " " + seatsRange : '';
};
export var normalizedSeatingInfoParts = function (info) {
    var section = stringifyNormalizedSection(info);
    var row = stringifyNormalizedRow(info);
    var seats = stringifyNormalizedSeats(info);
    return [section, row, seats];
};
export var stringifyNormalizedSeatingInfo = function (info) { return normalizedSeatingInfoParts(info).filter(function (_) { return !!_; }).join(', '); };
export var normalizedSeatingSectionAndRowParts = function (info) {
    var section = stringifyNormalizedSection(info);
    var row = stringifyNormalizedRow(info);
    return [section, row];
};
export var stringifyNormalizedSeatingSectionAndRowInfo = function (info) { return normalizedSeatingSectionAndRowParts(info).filter(function (_) { return !!_; }).join(', '); };
export var stringifySellSeatingInformation = function (seatingInfo, seatRange) {
    return stringifyNormalizedSeatingInfo(normalizeSellSeatingInfo(seatingInfo, seatRange));
};
export var stringifyBuySeatingInformation = function (seatingInfo) {
    return normalizeBuySeatingInfo(seatingInfo).map(stringifyNormalizedSeatingInfo).join(',');
};
export function isSellSeatingInformation(seatingInfo) {
    return !!seatingInfo.category;
}
export function stringifySeatingInfo(seatingInfo, seatRange) {
    return isSellSeatingInformation(seatingInfo) ? stringifySellSeatingInformation(seatingInfo, seatRange) : stringifyBuySeatingInformation(seatingInfo);
}
export function normalizeSeatingInfo(seatingInfo, seatRange) {
    return isSellSeatingInformation(seatingInfo)
        ? normalizeSellSeatingInfo(seatingInfo, seatRange)
        : normalizeBuySeatingInfo(seatingInfo)[0];
}

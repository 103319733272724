import { windowIsDefined } from '../utils';
export var useWindowWatcher = function (callback, poolInterval, maxRetries, getter) {
    if (!windowIsDefined()) {
        return; // Do nothing if SSR
    }
    var windowAsAny = window;
    var retryCount = 0;
    var watcher = function () {
        var obj = getter(windowAsAny);
        if (obj) {
            callback(obj);
        }
        else {
            retryCount += 1;
            if (retryCount < maxRetries) {
                setTimeout(watcher, poolInterval);
            }
        }
    };
    watcher();
};

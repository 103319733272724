import { Label, RadioField } from '@components/forms';
import cn from 'classnames';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Description, Entry } from '../../utils';
import styles from './yesNoEntry.less';
export function YesNoEntry(_a) {
    var _b;
    var name = _a.name, description = _a.description, detailedDescription = _a.detailedDescription, helpMsg = _a.helpMsg, readonly = _a.readonly;
    var descriptionCmp = (React.createElement(Description, { isRequired: true, width: 16, help: helpMsg }, description));
    return (React.createElement(Grid.Row, null,
        React.createElement(Entry, { name: name, className: styles.yesNoEntry },
            React.createElement(Label, { fill: true },
                !!detailedDescription && descriptionCmp,
                React.createElement(Grid, { stackable: true },
                    React.createElement(Grid.Row, { className: styles.descriptionRow },
                        React.createElement(Grid.Column, { className: cn(styles.description, (_b = {}, _b[styles.detailedDescription] = detailedDescription, _b)), width: 11 }, detailedDescription || descriptionCmp),
                        React.createElement(Grid.Column, { className: styles.yesNo, width: '5' },
                            React.createElement(RadioField, { name: name, label: 'Yes', radioValue: true, readOnly: readonly }),
                            React.createElement(RadioField, { name: name, label: 'No', radioValue: false, readOnly: readonly }))))))));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { t } from '@containers/helpers/tests';
import { formatPrice } from '@logic/helpers/currency';
import { getBuyCosts } from '@logic/transactions';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { eventLinkSlugToEventRoute } from 'src/client/routing/routes';
import styles from '@containers/DetailsPages/pages/EventDetails/components/Tabs/tabs.less';
function From(_a) {
    var price = _a.price;
    if (!price) {
        return null;
    }
    return (React.createElement("div", { className: styles.fromContainer },
        React.createElement("span", { className: "currency" }, formatPrice(price, 'onlyName', price.value > 1000000)),
        React.createElement("span", { className: "price" }, formatPrice(price, false, price.value > 1000000))));
}
export function Tabs(_a) {
    var transactionSide = _a.transactionSide, eventSlug = _a.eventSlug, buyListings = _a.buyListings, sellListings = _a.sellListings;
    var userSettings = useSelector(function (gs) { var _a; return (_a = gs.userSettings.userSettings) !== null && _a !== void 0 ? _a : gs.userSettings.globalSettings; });
    var sellPrice = useMemo(function () {
        var buyListingPrices = buyListings.map(function (listing) { return listing.price.value; });
        var sellPriceValue = Math.max.apply(Math, __spreadArray(__spreadArray([], __read(buyListingPrices)), [-Infinity]));
        return buyListings.length
            ? { value: sellPriceValue, currency: buyListings[0].price.currency }
            : null;
    }, [buyListings]);
    var buyPrice = useMemo(function () {
        var sellListingPrices = sellListings.map(function (listing) {
            if (listing.ownedByMe || !userSettings) {
                return listing.price.value;
            }
            var ticketFee = getBuyCosts(listing.price.value, 'any', 1, userSettings, false).ticketFee; // todo?
            return listing.price.value + ticketFee;
        });
        var buyPriceValue = Math.min.apply(Math, __spreadArray(__spreadArray([], __read(sellListingPrices)), [Infinity]));
        return sellListings.length
            ? { value: buyPriceValue, currency: sellListings[0].price.currency }
            : null;
    }, [sellListings, userSettings]);
    return (React.createElement("div", { className: styles.tabs },
        React.createElement(Link, __assign({ className: cn('buy', { active: transactionSide === 'buy' }), to: eventLinkSlugToEventRoute(eventSlug, 'buy') }, t('EventDetails-tab-buy')),
            React.createElement("div", { className: "verticalBorder" }),
            React.createElement(React.Fragment, null,
                transactionSide === 'buy' && React.createElement("div", { className: "topBorder" }),
                React.createElement("div", { className: styles.tabColorHeader },
                    React.createElement("span", null, "You BUY Tickets " + ((buyPrice === null || buyPrice === void 0 ? void 0 : buyPrice.value) ? 'from' : ''))),
                React.createElement(From, { price: buyPrice }),
                transactionSide === 'sell' && React.createElement("div", { className: "bottomBorder" }))),
        React.createElement(Link, __assign({ className: cn('sell', { active: transactionSide === 'sell' }), to: eventLinkSlugToEventRoute(eventSlug, 'sell') }, t('EventDetails-tab-sell')),
            React.createElement(React.Fragment, null,
                transactionSide === 'sell' && React.createElement("div", { className: "topBorder" }),
                React.createElement("div", { className: styles.tabColorHeader },
                    React.createElement("span", null, "You SELL Tickets " + ((sellPrice === null || sellPrice === void 0 ? void 0 : sellPrice.value) ? 'from' : ''))),
                React.createElement(From, { price: sellPrice }),
                transactionSide === 'buy' && React.createElement("div", { className: "bottomBorder" })),
            React.createElement("div", { className: "verticalBorder" }),
            React.createElement("div", { className: "verticalBorderRight" }))));
}

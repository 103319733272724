var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/FiltersSection/filtersSection.less';
import { Icon } from '@components/icon';
import { Modal } from '@components/forms';
import { VenueMapFilters } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMapFilters';
import { VenueMap } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap';
import { actions, initialTicketTypes } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { useDispatch } from 'react-redux';
import { actions as eventsActions } from '@logic/events';
export var FiltersSection = function (props) {
    var modalOpen = props.modalOpen, onOpenModal = props.onOpenModal, onCloseModal = props.onCloseModal, hoveredCategory = props.hoveredCategory, changeHoveredCategory = props.changeHoveredCategory, eventId = props.eventId, isLoadingSeatingPlan = props.isLoadingSeatingPlan, categories = props.categories, hoveredListing = props.hoveredListing, listings = props.listings, filteredListings = props.filteredListings, sections = props.sections, seatingPlan = props.seatingPlan, transactionSide = props.transactionSide, ticketTypes = props.ticketTypes, availableTicketTypes = props.availableTicketTypes, stateDispatch = props.stateDispatch, onPanAndZoomInstanceChange = props.onPanAndZoomInstanceChange, handleResetFilters = props.handleResetFilters, svgPanAndZoomInstance = props.svgPanAndZoomInstance, selectedCategories = props.selectedCategories, selectedTicketTypes = props.selectedTicketTypes, setSelectedCategories = props.setSelectedCategories, setSelectedTicketTypes = props.setSelectedTicketTypes, selectedSections = props.selectedSections, setSelectedSections = props.setSelectedSections, foundSections = props.foundSections, changeHasUserSelectedSections = props.changeHasUserSelectedSections, handleSvgParsed = props.handleSvgParsed;
    var _a = __read(useState('Filters'), 2), modalType = _a[0], setModalType = _a[1];
    var handleClickClear = function () {
        handleResetFilters();
        setSelectedTicketTypes(initialTicketTypes);
        modalType === 'Map' && svgPanAndZoomInstance.reset();
    };
    var handleSelectTicketType = function (_, c) { return setSelectedTicketTypes(function (prev) {
        var _a;
        return __assign(__assign({}, prev), (_a = {}, _a[c.label] = c.checked, _a));
    }); };
    var handleClickApply = function () {
        stateDispatch(actions.toggleCategoryTicketTypeSection({ categories: selectedCategories, ticketTypes: selectedTicketTypes, sections: selectedSections }));
        changeHasUserSelectedSections(true);
        onCloseModal();
    };
    var handleClickCancel = function () {
        onCloseModal();
        setSelectedCategories(categories);
        setSelectedTicketTypes(ticketTypes);
        setSelectedSections(sections);
    };
    var modal = function (content) { return (React.createElement(Modal, { className: styles.venueMap, contentClassName: styles.seatingPlanContent, open: modalOpen, onClose: handleClickCancel, handleClickClear: handleClickClear, handleClickApply: handleClickApply }, content)); };
    var handleOpenModal = function (type) {
        onOpenModal();
        setModalType(type);
    };
    var dispatch = useDispatch();
    useEffect(function () {
        if (!seatingPlan || !seatingPlan.svgFile) {
            dispatch(eventsActions.loadSeatingPlan.request(eventId));
        }
        setSelectedCategories(categories);
        setSelectedSections(sections);
    }, [dispatch, eventId, seatingPlan, categories, setSelectedCategories, sections, setSelectedSections]);
    var amountFilters = function () {
        var amountSelectedCategories = Object.values(categories).reduce(function (a, item) { return a + (Number(item.isActive)); }, 0);
        var amountSelectedTicketTypes = Object.values(ticketTypes).reduce(function (a, item) { return a + (Number(item)); }, 0);
        return amountSelectedCategories + amountSelectedTicketTypes;
    };
    var amountSections = function () {
        var amountSelectedSections = Object.values(selectedSections).reduce(function (a, item) { return a + (Number(item.isActive)); }, 0);
        return amountSelectedSections;
    };
    return (React.createElement("div", { className: styles.filtersSectionWrapper },
        React.createElement("div", { className: 'filterButton', onClick: function () { return handleOpenModal('Filters'); } },
            React.createElement(Icon, { icon: 'filterThin', height: 16 }),
            React.createElement("span", null, "Filters"),
            React.createElement("div", { className: 'amountFilters' }, amountFilters())),
        React.createElement("div", { className: 'filterButton', onClick: function () { return handleOpenModal('Map'); } },
            React.createElement(Icon, { icon: 'stadium', height: 20 }),
            React.createElement("span", null, "Seating map"),
            React.createElement("div", { className: 'amountFilters' }, amountSections())),
        modal(modalType === 'Filters' ?
            React.createElement(VenueMapFilters, { categories: categories, ticketTypes: ticketTypes, availableTicketTypes: availableTicketTypes, stateDispatch: stateDispatch, changeHoveredCategory: changeHoveredCategory, handleSelectTicketType: handleSelectTicketType, selectedTicketTypes: selectedTicketTypes, selectedCategories: selectedCategories, setSelectedCategories: setSelectedCategories, selectedSections: selectedSections, setSelectedSections: setSelectedSections, transactionSide: transactionSide, listings: listings })
            : React.createElement(VenueMap, { eventId: eventId, isLoadingSeatingPlan: isLoadingSeatingPlan, transactionSide: transactionSide, hoveredListing: hoveredListing, listings: listings, filteredListings: filteredListings, sections: selectedSections, categories: categories, seatingPlan: seatingPlan, stateDispatch: stateDispatch, ticketTypes: ticketTypes, hoveredCategory: hoveredCategory, onPanAndZoomInstanceChange: onPanAndZoomInstanceChange, setSelectedCategories: setSelectedCategories, selectedCategories: selectedCategories, selectedSections: selectedSections, setSelectedSections: setSelectedSections, foundSections: foundSections, handleSvgParsed: handleSvgParsed }))));
};

import { useRoutingSetter } from '@components/useStaticContext';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { routes } from 'src/client/routing/routes';
import { Container, withPageOptions } from '../Page';
import { SearchBox } from '../SearchBox/searchBox';
import styles from './error404.less';
export var Error404 = function () {
    useRoutingSetter({ statusCode: 404 });
    return (React.createElement(Container, { className: styles.notFound, size: "full" },
        React.createElement("div", { className: styles.header },
            React.createElement("h1", null,
                "Hello!",
                React.createElement("br", null),
                "Is it me you're looking\u00A0for?")),
        React.createElement("footer", { className: styles.footer },
            React.createElement("h3", null,
                "If not, then I'm afraid we're stumped!",
                React.createElement("br", null),
                "Please use the searchbox below to find what you're looking for, or go back ",
                React.createElement(NavLink, { to: routes.index() }, "Home"),
                "."),
            React.createElement(Container, null,
                React.createElement(SearchBox, { asIconOnMobile: false })))));
};
export default compose(withPageOptions({
    containerSize: 'full',
    search: false,
}))(Error404);

import React from 'react';
import styles from './similarPerformers.less';
import { useSelector } from 'react-redux';
import { Image } from '@components/image/image';
import { Images } from '@logic/images';
import { Icon } from '@components/icon';
import cn from 'classnames';
import { routes } from 'src/client/routing/routes';
import { Link } from 'react-router-dom';
import { slugifyName } from '@logic/helpers/location';
import { blobFile } from '@logic/helpers/request';
export var SimilarPerformers = function (props) {
    var _a, _b;
    var linkedPerformer = useSelector(function (gs) { return gs.performer.performerDetails; });
    var className = props.className, event = props.event;
    var performer = event || linkedPerformer;
    var title = (performer === null || performer === void 0 ? void 0 : performer.category) === 'Concert' ? 'Artists' : 'Events';
    return (React.createElement("div", { className: cn(styles.container, className) },
        React.createElement("h2", null,
            "Similar ",
            title),
        ((_a = performer === null || performer === void 0 ? void 0 : performer.similarPerformers) === null || _a === void 0 ? void 0 : _a.length)
            ? (_b = performer === null || performer === void 0 ? void 0 : performer.similarPerformers) === null || _b === void 0 ? void 0 : _b.map(function (performer, index) {
                var _a;
                return (React.createElement(Link, { className: styles.similarArtistRow, key: "similar-artist-" + index, draggable: false, to: routes.performer(performer.id, slugifyName(performer.name)) },
                    React.createElement("div", { className: styles.avatar }, performer.imageUrl &&
                        React.createElement(Image, { src: blobFile((_a = performer.imageUrl) !== null && _a !== void 0 ? _a : '') + "/" + Images.details((performer === null || performer === void 0 ? void 0 : performer.name) || ''), alt: performer.name, draggable: false })),
                    React.createElement("div", { className: styles.concertDetails },
                        React.createElement("div", { className: styles.artistName }, performer.name),
                        React.createElement("div", { className: styles.eventCounter },
                            performer.eventCount,
                            " ",
                            performer.category === 'Concert' ? 'Concerts' : 'Events')),
                    React.createElement(Icon, { height: 16, width: 16, icon: 'caretRight' })));
            })
            : React.createElement("div", null)));
};

import { utcToZonedTime } from 'date-fns-tz';
import { differenceInMilliseconds, isBefore, isAfter, parse, format, differenceInDays } from 'date-fns';
export var DATE_FORMAT = 'eee dd MMM yyyy';
export var TIME_FORMAT = 'HH:mm';
export var DATE_TIME_FORMAT = DATE_FORMAT + " " + TIME_FORMAT;
export var DATE_TIME_FOR_EXPORT_FORMAT = 'yyyy-MM-dd\'T\'HH-mm-ss';
export var DATE_TIME_CSV_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export var DATE_CSV_FORMAT = 'yyyy-MM-dd';
export function formatDateTime(date, timezone, dateTimeFormat) {
    if (dateTimeFormat === void 0) { dateTimeFormat = DATE_TIME_FORMAT; }
    if (!date) {
        return '-';
    }
    return format(utcToZonedTime(date, timezone), dateTimeFormat);
}
export function formatDateTimeWithoutTZ(date, dateTimeFormat) {
    if (dateTimeFormat === void 0) { dateTimeFormat = DATE_TIME_FORMAT; }
    if (!date) {
        return '-';
    }
    return format(date, dateTimeFormat);
}
export function formatTime(date, timezone) {
    if (!date) {
        return '';
    }
    return format(utcToZonedTime(date, timezone), TIME_FORMAT);
}
export function formatDate(date, timezone, dateFormat) {
    if (dateFormat === void 0) { dateFormat = DATE_FORMAT; }
    if (!date) {
        return '-';
    }
    return format(utcToZonedTime(date, timezone), dateFormat);
}
export function formatDateWithoutTZ(date) {
    if (!date) {
        return '-';
    }
    return format(date, DATE_FORMAT);
}
export function formatRemainingTime(endDate, currentDate) {
    if (currentDate === void 0) { currentDate = new Date(); }
    if (!endDate) {
        return '-';
    }
    var miliseconds = endDate.getTime() - currentDate.getTime() + 1000;
    return miliseconds < 0 ? '00:00' : format(new Date(miliseconds), 'mm:ss');
}
export var minutes = 60 * 1000;
export var hours = 60 * minutes;
export var days = 24 * hours;
export var weeks = 7 * days;
export var months = 30 * days;
var plural = function (val, base, suffix) { return ((val === 1 ? val + " " + base + " " + (suffix !== null && suffix !== void 0 ? suffix : '') : val + " " + base + "s " + (suffix !== null && suffix !== void 0 ? suffix : '')).trim()); };
export function formatDisplayDate(date, currentDate) {
    if (currentDate === void 0) { currentDate = new Date(); }
    if (!date) {
        return '-';
    }
    var dateWithoutTime = new Date(date.toDateString());
    var currentDateWithoutTime = new Date(currentDate.toDateString());
    var dt = differenceInDays(dateWithoutTime, currentDateWithoutTime) || 0;
    var diff = Math.abs(dt);
    var overdue = dt <= 0;
    var suffix = overdue ? 'ago' : '';
    if (diff >= 30) {
        return plural(Math.floor(diff / 30), 'month', suffix);
    }
    if (diff >= 7) {
        return plural(Math.floor(diff / 7), 'week', suffix);
    }
    if (diff > 0) {
        return plural(Math.floor(diff), 'day', suffix);
    }
    return 'Today!';
}
export function formatSendByDateTime(date, overdueText, currentDate) {
    if (overdueText === void 0) { overdueText = null; }
    if (currentDate === void 0) { currentDate = new Date(); }
    if (!date) {
        return '-';
    }
    var dt = differenceInMilliseconds(date, currentDate) || 0;
    var diff = Math.abs(dt);
    var overdue = dt <= 0;
    if (overdue && !!overdueText) {
        return overdueText;
    }
    if (diff > 4 * weeks) {
        return plural(Math.ceil(diff / months), 'month');
    }
    if (diff > 7 * days) {
        return plural(Math.ceil(diff / weeks), 'week');
    }
    if (diff >= 24 * hours) {
        return plural(Math.ceil(diff / days), 'day');
    }
    if (diff > 0) {
        return plural(Math.ceil(diff / hours), 'hour');
    }
    return '';
}
export function isInRange(date, from, to) {
    if (from && isBefore(date, from)) {
        return false;
    }
    if (to && isAfter(date, to)) {
        return false;
    }
    return true;
}
export function parseDate(date) {
    if (typeof date === 'string') {
        var parsed = [
            parse(date.slice(4), DATE_FORMAT.slice(4), new Date()),
            parse(date, DATE_FORMAT.slice(4), new Date()),
            parse(date, 'yyyy-MM-dd', new Date()),
        ];
        return parsed.find(function (d) { return !isNaN(d.getTime()); });
    }
    return date;
}

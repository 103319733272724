import { cardsReducer as cards } from '@logic/cards';
import { bankAccountsReducer as bankAccounts } from '@logic/bankAccounts';
import { eventsReducer as events } from '@logic/events';
import { financialsReducer as financials } from '@logic/financials';
import { listingsReducer as listings } from '@logic/listings';
import { localizationReducer as localization } from '@logic/localization';
import { manualChiselReducer as manualChisel } from '@logic/manualChisel';
import { marketDataReducer as marketData } from '@logic/marketData';
import { newListingReducer as newListing } from '@logic/newListing';
import { performerReducer as performer } from '@logic/performer';
import { registrationReducer as registration } from '@logic/registration';
import { browserReducer as browser } from '@logic/browser';
import { categoriesReducer as categories } from '@logic/categories';
import { locationsReducer as locations } from '@logic/locations';
import { reservationsReducer as reservations } from '@logic/reservations';
import { searchReducer as search } from '@logic/search';
import { settingsReducer as settings } from '@logic/settings';
import { suspendedUserReducer as suspendedUser } from '@logic/suspendedUser';
import { transactionConfirmationReducer as transactionConfirmation, } from '@logic/transactionConfirmation';
import { transactionsReducer as transactions } from '@logic/transactions';
import { uploadImageFilesReducer as uploadImageFiles } from '@logic/uploadImageFiles';
import { clearStore, userReducer as user } from '@logic/user';
import { userSettingsReducer as userSettings } from '@logic/userSettings';
import { venueReducer as venue } from '@logic/venue';
import { workItemsReducer as workItems } from '@logic/workItems';
import { sitemapReducer as sitemap } from '@logic/sitemap';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
// TODO: DM: replace react-act by something more typescript friendly.
var appReducer = function (history) { return combineReducers({
    router: connectRouter(history),
    browser: browser,
    categories: categories,
    locations: locations,
    cards: cards,
    bankAccounts: bankAccounts,
    uploadImageFiles: uploadImageFiles,
    events: events,
    financials: financials,
    listings: listings,
    localization: localization,
    manualChisel: manualChisel,
    marketData: marketData,
    newListing: newListing,
    registration: registration,
    search: search,
    settings: settings,
    user: user,
    userSettings: userSettings,
    performer: performer,
    reservations: reservations,
    sitemap: sitemap,
    suspendedUser: suspendedUser,
    transactionConfirmation: transactionConfirmation,
    transactions: transactions,
    venue: venue,
    workItems: workItems,
}); };
// TODO any!
export var reducer = function (history) { return function (state, action) {
    var newState = action && action.type === clearStore.getType()
        ? { localization: state === null || state === void 0 ? void 0 : state.localization, categories: state === null || state === void 0 ? void 0 : state.categories, locations: state === null || state === void 0 ? void 0 : state.locations }
        : state;
    return appReducer(history)(newState, action);
}; };

import 'array.prototype.flatmap/auto';
import 'es6-symbol/implement';
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/Object.create';
import 'mdn-polyfills/Object.entries';
import 'mdn-polyfills/Object.values';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/String.prototype.startsWith';
import 'object.fromentries/auto';
import 'paymentfont/css/paymentfont.min.css';
import 'promise-polyfill/src/polyfill';
import 'react-day-picker/lib/style.css';
import 'string.fromcodepoint';
import 'regenerator-runtime/runtime.js';
import 'typeface-roboto';
import * as smoothScroll from 'smoothscroll-polyfill';
import { loadableReady } from '@loadable/component';
import { render } from './start'; // this import has to be before semantic.less
import './index.less';
import './theme/semantic.less';
smoothScroll.polyfill();
loadableReady(function () {
    render();
});
if (module.hot) {
    module.hot.accept();
}

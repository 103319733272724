var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isInViewport } from '@components/forms';
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import identity from 'lodash/identity';
import React, { memo, useEffect, useRef } from 'react';
import { Table } from 'semantic-ui-react';
import styles from './genericTable.less';
export var RowBase = function (props) {
    var _a;
    var _b;
    var headers = props.headers, record = props.record, rowClass = props.rowClass, stickyType = props.stickyType, isPinned = props.isPinned, pinnedClassName = props.pinnedClassName, colorClass = props.colorClass, nonRepeatableColumns = props.nonRepeatableColumns, isFirstDistinct = props.isFirstDistinct;
    var ref = useRef(null);
    useEffect(function () {
        if (isPinned && ref.current && !isInViewport(ref.current)) {
            ref.current.scrollIntoView(false);
        }
    }, [ref, isPinned]);
    var rowClasses = cn(rowClass && rowClass(record), colorClass, (_a = {},
        _a[styles.snapFirstColumn] = stickyType === 'topAndFirstColumn',
        _a[isPinned && ((_b = pinnedClassName === null || pinnedClassName === void 0 ? void 0 : pinnedClassName(record)) !== null && _b !== void 0 ? _b : styles.pinned)] = isPinned,
        _a));
    return (React.createElement(Table.Row, __assign({}, t('Row'), { className: rowClasses }),
        headers.map(function (_a) {
            var render = _a.render, path = _a.path, columnKey = _a.columnKey, _b = _a.format, format = _b === void 0 ? identity : _b, _c = _a.className, className = _c === void 0 ? function () { return null; } : _c, centered = _a.centered;
            var value = path(record);
            var cellValue = render ? render(value) : format(value);
            var renderedCellValue = !isFirstDistinct && (nonRepeatableColumns !== null && nonRepeatableColumns !== void 0 ? nonRepeatableColumns : []).includes(columnKey) ? null : cellValue;
            return React.createElement(Table.Cell, { key: columnKey, className: cn(className(record), { centered: centered }) }, renderedCellValue);
        }),
        React.createElement(Table.Cell, { className: styles.dimmer, collapsing: true, style: { verticalAlign: 'bottom' } },
            React.createElement("div", { ref: ref, style: { height: '100%' } }))));
};
export var Row = memo(RowBase);

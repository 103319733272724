var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useCallbackRef, useScrollTop } from '@components/forms';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import styles from './genericTable.less';
import { HeaderCell } from './headerCell';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
export function Header(_a) {
    var _b;
    var headers = _a.headers, isLoading = _a.isLoading, tableState = _a.tableState, handleSort = _a.handleSort, scrollLeft = _a.scrollLeft, top = _a.top, stickyType = _a.stickyType, stickThreshold = _a.stickThreshold;
    var _c = __read(useCallbackRef(null), 2), refNormalRow = _c[0], refNormalRowChange = _c[1];
    var _d = __read(useCallbackRef(null), 2), refFixedRow = _d[0], refFixedRowChange = _d[1];
    var _e = __read(useCallbackRef(null), 2), refFixedSingleColumn = _e[0], refFixedSingleColumnChange = _e[1];
    var media = useViewportInfo().media;
    var scrollTop = useScrollTop();
    useEffect(function () {
        if (stickyType === 'top') {
            // it is handled by css position sticky.
            return;
        }
        //  We need to sync cells width, because it is not preserved for fixed rows.
        if (refNormalRow && refFixedRow) {
            var normalThElements = __spreadArray([], __read(refNormalRow.getElementsByTagName('th')));
            var fixedThElements_1 = __spreadArray([], __read(refFixedRow.getElementsByTagName('th')));
            var fixedSingleColumnThElements_1 = refFixedSingleColumn ? __spreadArray([], __read(refFixedSingleColumn.getElementsByTagName('th'))) : [];
            normalThElements.forEach(function (th, idx) {
                var fixedTh = fixedThElements_1[idx];
                var fixedSingleTh = fixedSingleColumnThElements_1[idx];
                if (fixedTh) {
                    fixedTh.style.minWidth = th.clientWidth + "px";
                    fixedTh.style.maxWidth = th.clientWidth + "px";
                }
                if (fixedSingleTh) {
                    fixedSingleTh.style.minWidth = th.clientWidth + "px";
                    fixedSingleTh.style.maxWidth = th.clientWidth + "px";
                }
            });
        }
    }, [headers, tableState, refNormalRow, refFixedRow, refFixedSingleColumn, stickyType, isLoading]);
    useEffect(function () {
        if (stickyType === 'top') {
            // it is handled by css position sticky, just make it visible .
            if (refNormalRow) {
                refNormalRow.style.visibility = 'visible';
            }
            return;
        }
        // we swap visibility of the 'normal' and the sticky/fixed row when we cross some certain thereshold
        var thereshold = stickyType === 'topAndFirstColumn' ? stickThreshold : 0;
        if (refFixedRow && refNormalRow && thereshold <= scrollTop) {
            refNormalRow.style.visibility = 'hidden';
            refFixedRow.style.visibility = 'visible';
            refFixedRow.style.display = 'table-row';
            if (refFixedSingleColumn) {
                refFixedSingleColumn.style.visibility = 'visible';
            }
        }
        else if (refFixedRow && refNormalRow) {
            refFixedRow.style.visibility = 'hidden';
            refFixedRow.style.display = 'none';
            refNormalRow.style.visibility = 'visible';
            if (refFixedSingleColumn) {
                refFixedSingleColumn.style.visibility = 'hidden';
            }
        }
        var table = refNormalRow && refNormalRow.parentElement && refNormalRow.parentElement.parentElement;
        if (refFixedRow && refNormalRow && table && scrollTop >= table.clientHeight) {
            refFixedRow.style.visibility = 'hidden';
            refNormalRow.style.visibility = 'hidden';
            if (refFixedSingleColumn) {
                refFixedSingleColumn.style.visibility = 'hidden';
            }
        }
    }, [top, scrollTop, refFixedRow, refNormalRow, refFixedSingleColumn, stickyType, stickThreshold]);
    var rowClass = cn((_b = {}, _b[styles.snapFirstColumn] = stickyType === 'topAndFirstColumn', _b));
    var stickyRowStype = useMemo(function () { return ({ marginLeft: -scrollLeft, top: top }); }, [scrollLeft, top]);
    var firstColumnHeaderStyle = useMemo(function () { return ({ left: 0, top: top }); }, [top]);
    return (React.createElement(Table.Header, null,
        React.createElement("tr", { ref: refNormalRowChange, className: rowClass },
            headers.map(function (header, idx) { return React.createElement(HeaderCell, { key: idx, header: header, tableState: tableState, handleSort: handleSort, top: stickyType === 'topAndFirstColumn' ? 0 : top }); }),
            isLoading && React.createElement(Table.HeaderCell, { collapsing: true, className: styles.dimmer })),
        stickyType !== 'top' &&
            React.createElement("tr", { ref: refFixedRowChange, className: cn(isLikeMobile(media) ? styles.fixedRow : styles.stickyRow, rowClass), style: stickyRowStype }, headers.map(function (header, idx) { return React.createElement(HeaderCell, { key: idx, header: header, tableState: tableState, handleSort: handleSort, top: top }); })),
        stickyType === 'topAndFirstColumn' &&
            React.createElement("tr", { ref: refFixedSingleColumnChange, className: cn(styles.fixedRow, rowClass), style: firstColumnHeaderStyle },
                React.createElement(HeaderCell, { header: headers[0], tableState: tableState, handleSort: handleSort, top: top }))));
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Checkbox, Dropdown, Label, Modal, ModalContentRow } from '@components/forms';
import { t } from '@containers/helpers/tests';
import { formatDateTime } from '@logic/helpers/date';
import { loadUserListings, patchBuyListing, patchSellListing, ticketTypeSimplifiedMapping, } from '@logic/listings';
import { loadMarketData } from '@logic/marketData';
import { formatVenueData } from '@logic/venue';
import cn from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CreationDateColumn, Listings, PriceColumn } from './listings';
import styles from './marketData.less';
import { DateColumn, Transactions } from './transactions';
import { withViewportInfo } from '@components/viewPortInfo/viewportInfo';
import { isMobileScreenSize } from '@components/viewPortInfo/viewPort.utils';
var emptyListings = [];
var emptyTransactions = [];
var anySection = {
    category: 'any',
    section: '',
};
var MarketDataComponent = /** @class */ (function (_super) {
    __extends(MarketDataComponent, _super);
    function MarketDataComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            tab: 'buy',
            ticketType: 'paper',
            sectionFilter: 'any',
            showPaper: false,
            showETickets: false,
            showMobile: false,
            sellTableState: {
                search: '',
                sortColumns: [
                    { sortColumn: PriceColumn, sortReverse: false, primary: true },
                    { sortColumn: CreationDateColumn, sortReverse: false, primary: false }
                ],
                filters: {},
            },
            buyTableState: {
                search: '',
                sortColumns: [
                    { sortColumn: PriceColumn, sortReverse: true, primary: true },
                    { sortColumn: CreationDateColumn, sortReverse: false, primary: false }
                ],
                filters: {},
            },
            transactionsTableState: {
                search: '',
                sortColumns: [
                    { sortColumn: DateColumn, sortReverse: false, primary: true }
                ],
                filters: {},
            },
        };
        _this.refresh = function () {
            var listingSection = _this.props.listingSection;
            var _a = _this.state, showPaper = _a.showPaper, showETickets = _a.showETickets, showMobile = _a.showMobile, sectionFilter = _a.sectionFilter;
            var ticketTypes = (!showPaper && !showETickets && !showMobile) || (showPaper && showETickets && showMobile)
                ? undefined
                : __spreadArray(__spreadArray(__spreadArray([], __read((showPaper ? ['Paper'] : []))), __read((showETickets ? ['e-Ticket'] : []))), __read((showMobile ? ['Mobile'] : [])));
            var section = sectionFilter === 'any'
                ? anySection
                : sectionFilter === 'category'
                    ? {
                        category: listingSection.category,
                        section: '',
                    }
                    : listingSection;
            _this.props.actions.loadMarketData(_this.props.eventSummary.id, section, ticketTypes);
        };
        _this.getSectionOptions = function () {
            var listingSection = _this.props.listingSection;
            var categoryOption = listingSection.category !== 'any'
                ? [{
                        key: 'category',
                        text: listingSection.category,
                        value: 'category',
                    }]
                : [];
            var sectionOption = listingSection.section
                ? [{
                        key: 'section',
                        text: listingSection.section + " - " + listingSection.category,
                        value: 'section',
                    }]
                : [];
            return __spreadArray(__spreadArray([{
                    key: 'any',
                    text: 'Any',
                    value: 'any',
                }], __read(categoryOption)), __read(sectionOption));
        };
        _this.handleRefresh = function () {
            _this.refresh();
            _this.props.actions.loadUserListings();
        };
        _this.handleBuy = function () { return _this.setState({ tab: 'buy' }); };
        _this.handleSell = function () { return _this.setState({ tab: 'sell' }); };
        _this.handleTransaction = function () { return _this.setState({ tab: 'transaction' }); };
        _this.handleSecitonFilterChange = function (_, _a) {
            var value = _a.value;
            _this.setState({
                sectionFilter: value,
            }, _this.refresh);
        };
        _this.handleShowPaper = function (_, _a) {
            var checked = _a.checked;
            _this.setState({
                showPaper: !!checked,
            }, _this.refresh);
        };
        _this.handleShowETicket = function (_, _a) {
            var checked = _a.checked;
            _this.setState({
                showETickets: !!checked,
            }, _this.refresh);
        };
        _this.handleShowMobile = function (_, _a) {
            var checked = _a.checked;
            _this.setState({
                showMobile: !!checked,
            }, _this.refresh);
        };
        _this.handleUpdateBuyTableState = function (buyTableState) {
            _this.setState({ buyTableState: buyTableState });
        };
        _this.handleUpdateSellTableState = function (sellTableState) {
            _this.setState({ sellTableState: sellTableState });
        };
        _this.handleUpdateTransactionsTableState = function (transactionsTableState) {
            _this.setState({ transactionsTableState: transactionsTableState });
        };
        return _this;
    }
    MarketDataComponent.prototype.componentDidMount = function () {
        var initialTab = this.props.initialTab;
        this.setState({ tab: initialTab });
        if (this.props.open) {
            this.refresh();
        }
    };
    MarketDataComponent.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.open && !prevProps.open) {
            this.refresh();
        }
    };
    MarketDataComponent.prototype.render = function () {
        var _a, _b, _c;
        var _d = this.props, open = _d.open, actions = _d.actions, isPending = _d.isPending, eventSummary = _d.eventSummary;
        var tab = this.state.tab;
        return (React.createElement(Modal, { open: open, className: styles.helpMePrice, onClose: this.props.handleClose, close: 'icon', medium: true },
            React.createElement("div", __assign({}, t('MarketDataModal')),
                !isMobileScreenSize() && React.createElement(ModalContentRow, null,
                    React.createElement("div", { className: styles.eventName }, eventSummary.name),
                    React.createElement("div", { className: styles.eventVenue }, formatVenueData(eventSummary)),
                    React.createElement("div", { className: styles.eventDate }, formatDateTime(eventSummary.date, eventSummary.timezone))),
                React.createElement(ModalContentRow, null,
                    React.createElement(Label, { className: styles.section },
                        "Section",
                        React.createElement(Dropdown, { options: this.getSectionOptions(), search: true, selection: true, value: this.state.sectionFilter, onChange: this.handleSecitonFilterChange, className: styles.dropdown })),
                    React.createElement(Label, { className: styles.ticketType },
                        "Ticket type",
                        React.createElement("div", { className: styles.checkboxFilters },
                            React.createElement(Checkbox, { label: ticketTypeSimplifiedMapping.eTicket, checked: this.state.showETickets, onChange: this.handleShowETicket }),
                            React.createElement(Checkbox, { label: ticketTypeSimplifiedMapping.paper, checked: this.state.showPaper, onChange: this.handleShowPaper }),
                            React.createElement(Checkbox, { label: ticketTypeSimplifiedMapping.mobileTransfer, checked: this.state.showMobile, onChange: this.handleShowMobile })))),
                React.createElement("div", { className: styles.tabs },
                    React.createElement("div", { className: cn(styles.tab, (_a = {}, _a[styles.active] = tab === 'buy', _a)), onClick: this.handleBuy }, "Buy Listings"),
                    React.createElement("div", { className: cn(styles.tab, (_b = {}, _b[styles.active] = tab === 'sell', _b)), onClick: this.handleSell }, "Sell Listings"),
                    React.createElement("div", { className: cn(styles.tab, (_c = {}, _c[styles.active] = tab === 'transaction', _c)), onClick: this.handleTransaction }, "Transactions")),
                React.createElement("div", { className: styles.scrollable },
                    tab === 'buy' &&
                        React.createElement(Listings, { type: 'buy', tableState: this.state.buyTableState, onUpdateTableState: this.handleUpdateBuyTableState, listings: this.props.buyListings || emptyListings, patchBuyListing: actions.patchBuyListing, patchSellListing: actions.patchSellListing, isPending: isPending }),
                    tab === 'sell' &&
                        React.createElement(Listings, { type: 'sell', tableState: this.state.sellTableState, onUpdateTableState: this.handleUpdateSellTableState, listings: this.props.sellListings || emptyListings, patchBuyListing: actions.patchBuyListing, patchSellListing: actions.patchSellListing, isPending: isPending }),
                    tab === 'transaction' &&
                        React.createElement(Transactions, { tableState: this.state.transactionsTableState, onUpdateTableState: this.handleUpdateTransactionsTableState, transactions: this.props.transactions || emptyTransactions, isPending: isPending })))));
    };
    return MarketDataComponent;
}(Component));
export { MarketDataComponent };
var mapStateToProps = function (state, _) {
    return {
        sellListings: state.marketData.sellListings,
        buyListings: state.marketData.buyListings,
        transactions: state.marketData.transactions,
        isPending: state.marketData.isPending,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({
            loadMarketData: loadMarketData,
            patchBuyListing: patchBuyListing,
            patchSellListing: patchSellListing,
            loadUserListings: loadUserListings,
        }, dispatch),
    };
};
export var MarketData = connect(mapStateToProps, mapDispatchToProps)(withViewportInfo(MarketDataComponent));

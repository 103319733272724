var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import styles from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation.less';
import { Container } from '@containers/Page';
import React, { useState } from 'react';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { useLocation } from 'react-router';
import { useToggle } from '@components/forms';
import { ShareSocialDesktop, } from '@containers/DetailsPages/components/shareSocial/shareSocialDesktop';
import { ShareSocialMobile } from '@containers/DetailsPages/components/shareSocial/shareSocialMobile';
import cn from 'classnames';
import { Icon } from '@components/icon';
import { useHostname } from '@components/useStaticContext';
import { PageSectionEnum } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { isLikeMobile } from '@containers/components/viewPortInfo/viewPort.utils';
export var DetailsPageNavigation = function (_a) {
    var details = _a.details, scrollTo = _a.scrollTo, bioHeader = _a.bioHeader, isFullWide = _a.isFullWide, detailsSubtitle = _a.detailsSubtitle, _b = _a.isEventPage, isEventPage = _b === void 0 ? false : _b, _c = _a.hasFAQs, hasFAQs = _c === void 0 ? false : _c;
    var location = useLocation();
    var media = useViewportInfo().media;
    var hostname = useHostname();
    var isMobile = isLikeMobile(media);
    var urlPath = "" + hostname + location.pathname;
    var selectSubHeaders = function (hasFAQs, isEventPage) {
        var aboutSection = (details === null || details === void 0 ? void 0 : details.description)
            ? [PageSectionEnum.About]
            : [];
        var bioSection = (details === null || details === void 0 ? void 0 : details.description)
            ? [bioHeader !== null && bioHeader !== void 0 ? bioHeader : PageSectionEnum.Bio]
            : [];
        var subHeaders = isEventPage
            ? __spreadArray(__spreadArray([PageSectionEnum.Tickets], __read(aboutSection)), [PageSectionEnum.Venue_Information]) : __spreadArray([PageSectionEnum.Events], __read(bioSection));
        var subHeaderFaq = hasFAQs ? [PageSectionEnum.FAQs] : [];
        return __spreadArray(__spreadArray([], __read(subHeaders)), __read(subHeaderFaq));
    };
    var _d = __read(useState(selectSubHeaders(hasFAQs, isEventPage)[0]), 2), active = _d[0], setActive = _d[1];
    var _e = useToggle(false), isOpen = _e.state, handleOpen = _e.activate, handleClose = _e.deactivate;
    var shareOnMobile = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleClose();
                    if (!navigator.share) return [3 /*break*/, 2];
                    return [4 /*yield*/, navigator.share({
                            title: 'Beeyay',
                            url: urlPath
                        })
                            .catch(function (error) {
                            // Differentiate between user 'AbortError' and internal errors.
                            // E.g. Internal error: could not connect to Web Share interface.
                            if (error.message.startsWith('Internal error:')) {
                                error.name = 'InternalError';
                            }
                            console.error(error);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, null];
                case 2: return [2 /*return*/, React.createElement(ShareSocialMobile, { links: links, handleClose: handleClose })];
            }
        });
    }); };
    var links = {
        originalUrl: urlPath,
        twitterLink: "http://twitter.com/share?url=" + urlPath,
        /*
        this link is sexier, but app_id seems to be not configured on local so i couldn't test it properly
        "https://www.facebook.com/dialog/share?
                       app_id=520641785548151
                       &quote={{quote}}
                       &href=hrefUri
                       &redirect_uri=redirectUri
                       "
        */
        facebookLink: "https://www.facebook.com/sharer/sharer.php?u=" + urlPath,
        linkedinLink: "https://www.linkedin.com/shareArticle?mini=true&url=" + urlPath,
        pinterestLink: "https://pinterest.com/pin/create/button/?url=" + urlPath + "&media=&description=",
    };
    var openShareSocial = function () { return media === 'mobile'
        ? React.createElement(React.Fragment, null, shareOnMobile())
        // I remove the ShareSocial Modal on Desktop but Client wanted to comment code instead remove. So I left the logic and function name like was before
        : React.createElement(ShareSocialDesktop, { details: details, detailsSubtitle: detailsSubtitle, links: links, handleClose: handleClose, isOpen: isOpen }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.performerNavigation },
            React.createElement(Container, { className: styles.beeyayLayoutContainer, size: isFullWide ? 'wide' : 'normal' },
                selectSubHeaders(hasFAQs, isEventPage).map(function (subHeader) {
                    var _a;
                    return React.createElement("div", { key: subHeader, onClick: function (e) {
                            e.preventDefault();
                            scrollTo(subHeader);
                            setActive(subHeader);
                        }, className: cn((_a = {}, _a[styles.isActive] = active === subHeader, _a)) }, subHeader);
                }),
                isMobile ?
                    React.createElement("div", { onClick: handleOpen },
                        React.createElement(Icon, { className: styles.shareIcon, width: 16, height: 16, icon: 'share' })) :
                    null)),
        isOpen && openShareSocial()));
};

import { Seo } from '@components/seo';
import React from 'react';
import { routes, BUY_ONLY, eventLinkSlugToEventRoute } from 'src/client/routing/routes';
import { matchPath, useLocation } from 'react-router-dom';
import { getEventMainPerformer } from '@logic/events';
import { formatDateTime } from '@logic/helpers/date';
export var EventSeo = function (_a) {
    var event = _a.event, transactionSide = _a.transactionSide, buyOnly = _a.buyOnly, seoTitle = _a.seoTitle, seoDescription = _a.seoDescription;
    var pathname = useLocation().pathname;
    var isMovedPermanently = !!(matchPath(pathname, { path: routes.eventD() }) || matchPath(pathname, { path: routes.eventWithoutTypeD() }));
    var title = seoTitle
        ? seoTitle
        : getEventMainPerformer(event).name + " Tickets | " + event.venue.address.city + " - " + formatDateTime(event.eventDate, event.timezone) + " | Buy cheap tickets on Beeyay";
    var description = seoDescription
        ? seoDescription
        : "Tickets for " + event.name + " at " + event.venue.name + " on Beeyay, an innovative marketplace for fans to buy and sell tickets. No gimmicks, no pressure selling, just complete transparency. All purchases 100% protected by the Beeyay Guarantee.";
    return (React.createElement(Seo, { title: title, description: description, keywords: event.name + " tickets, " + event.venue.address.city + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", canonical: eventLinkSlugToEventRoute(event.eventLinkSlug, 'buy'), redirection: eventLinkSlugToEventRoute(event.eventLinkSlug, buyOnly ? BUY_ONLY : transactionSide), noIndex: buyOnly, isMovedPermanently: isMovedPermanently }));
};

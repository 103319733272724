import cn from 'classnames';
import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';
export function HeaderCell(_a) {
    var header = _a.header, tableState = _a.tableState, handleSort = _a.handleSort, top = _a.top;
    var sort = header.sort, name = header.name, node = header.node, columnKey = header.columnKey, headerClassName = header.headerClassName, centered = header.centered;
    var sortColumns = tableState.sortColumns;
    var sortData = sortColumns === null || sortColumns === void 0 ? void 0 : sortColumns.find(function (sc) { return sc.sortColumn === name; });
    var sorted = (sort && sortData && sortData.primary) ? (sortData.sortReverse ? 'descending' : 'ascending') : undefined;
    var style = useMemo(function () { return ({ top: top }); }, [top]);
    return (React.createElement(Table.HeaderCell, { key: columnKey, sorted: sorted, onClick: handleSort(name, sort), className: cn(headerClassName, { centered: centered }), collapsing: true, style: style }, node ? node : name));
}

import React from 'react';
import styles from './breadcrumb.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import cn from 'classnames';
export var EMPTY_BREADCRUMB = {
    breadcrumbPaths: null
};
export var Breadcrumb = function (_a) {
    var breadcrumbPaths = _a.breadcrumbPaths, classname = _a.classname;
    var media = useViewportInfo().media;
    if (breadcrumbPaths === null) {
        return null;
    }
    var desktopPaths = breadcrumbPaths.desktopPaths, mobilePaths = breadcrumbPaths.mobilePaths;
    return (React.createElement("div", { className: cn(styles.container, classname) }, isLikeMobile(media)
        ? React.createElement(React.Fragment, null, mobilePaths)
        : React.createElement(React.Fragment, null, desktopPaths)));
};

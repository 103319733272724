import { Seo } from '@components/seo';
import { slugifyName } from '@logic/helpers/location';
import React from 'react';
import { routes, BUY_ONLY } from 'src/client/routing/routes';
import { hasNoIndex } from '@containers/DetailsPages/pages/seo/common';
import { matchPath, useLocation } from 'react-router-dom';
export var PerformerSeo = function (_a) {
    var details = _a.details, events = _a.events, eventsLoading = _a.eventsLoading, city = _a.city, buyOnly = _a.buyOnly;
    var pathname = useLocation().pathname;
    if (!details) {
        return null;
    }
    var description = details.name + " tickets are available now with low prices. Want even cheaper? Name your price! All purchases 100% protected by the Beeyay Guarantee.";
    var tour = details.category === 'Concerts';
    var time = events && events.length ? events.map(function (e) { return e.eventDate.getTime(); }).sort(function (a, b) { return a < b ? -1 : 1; })[0] : undefined;
    var titleDescriptionPart = time ? "" + new Date(time).getFullYear() + (tour ? ' tour' : '') + " dates available to buy now" : "Tickets and upcoming " + (tour ? ' tour' : '') + " dates";
    var title = details.name + " Tickets | " + titleDescriptionPart + " | Beeyay";
    var noIndex = buyOnly || hasNoIndex(details.description, events, eventsLoading);
    var shouldRedirectToCity = city && (eventsLoading || (events && events.some(function (e) { return slugifyName(e.venueCity) === city; })));
    var isMovedPermanently = !!matchPath(pathname, { path: routes.performerD() });
    return (React.createElement(Seo, { title: title, description: description, keywords: details.name + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", isMovedPermanently: isMovedPermanently, canonical: routes.performer(details.id, details.slug), redirection: routes.performer(details.id, details.slug, shouldRedirectToCity ? city : undefined, buyOnly ? BUY_ONLY : undefined), noIndex: noIndex }));
};

import { toast } from 'react-toastify';
import countBy from 'lodash/countBy';
import sortBy from 'lodash/sortBy';
import { matchPath } from 'react-router';
import { routes } from 'src/client/routing/routes';
import { isBetweenMobileAndThreshold, SUPER_LARGE, SUPER_LARGE_TABLE_VIEW, } from '@components/viewPortInfo/viewPort.utils';
export var copyToClipboard = function (text) {
    if (text === void 0) { text = ''; }
    navigator.clipboard.writeText(text);
    toast.success("'" + text + "' copied to clipboard");
};
export var findMostPopular = function (values, by) {
    var countedByValues = countBy(values, function (el) { return el[by]; });
    var mostPopularValue = sortBy(Object.entries(countedByValues), [function (cc) { return cc[1]; }])
        .reverse()[0];
    return mostPopularValue;
};
export var countDOMLines = function (DOMElementID) {
    if (typeof document === 'undefined') {
        return 0;
    }
    var el = document.getElementById(DOMElementID);
    if (!el) {
        return 0;
    }
    var computedStyle = window.getComputedStyle(el);
    var divHeight = el.offsetHeight;
    var lineHeight = parseInt(computedStyle.lineHeight);
    return Math.round(divHeight / lineHeight);
};
var globalScrollbarConfig = [
    { pathname: routes.userListings(), threshold: SUPER_LARGE_TABLE_VIEW },
    { pathname: routes.userPurchases(), threshold: SUPER_LARGE },
    { pathname: routes.userSales(), threshold: SUPER_LARGE },
];
export var hasGlobalPageScrollBar = function (path, media, width) {
    var foundPathname = globalScrollbarConfig.find(function (reg) { return matchPath(path, reg.pathname); });
    if (!foundPathname) {
        return false;
    }
    return isBetweenMobileAndThreshold(media, width, foundPathname.threshold);
};
export var windowIsDefined = function () { return typeof window !== 'undefined'; };

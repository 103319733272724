var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from './helpers/request';
import orderBy from 'lodash/orderBy';
export var getFinancials = createAction('get financials');
export var getFinancialsSuccess = createAction('get financials success', function (data) { return data; });
export var getFinancialsFailure = createAction('get financials failure', function (e) { return e; });
export var mapRecordTypeToString = {
    PaymentOut: 'Sale Proceeds',
    DeliveryCharge: 'Delivery Charge',
    Commission: 'Commission',
    TicketCost: 'Ticket Cost',
    ListingCharge: 'Listing Charge',
    NonFulfilment: 'Non-Fulfilment',
    DeliveryChangedCharge: 'Delivery Method Change',
    SellerCredit: 'Seller Credit',
    BuyerCredit: 'Buyer Credit',
    PaymentOutReversal: 'Sale Proceeds Reversal',
};
function mapRawFinancialInfoToFinancialInfo(raw) {
    var cancelledDate = raw.cancelledDate, createdTimestamp = raw.createdTimestamp, paymentDate = raw.paymentDate, updatedTimestamp = raw.updatedTimestamp, event = raw.event, rest = __rest(raw, ["cancelledDate", "createdTimestamp", "paymentDate", "updatedTimestamp", "event"]);
    return __assign({ cancelledDate: new Date(cancelledDate), createdTimestamp: new Date(createdTimestamp), paymentDate: new Date(paymentDate), updatedTimestamp: new Date(updatedTimestamp), event: __assign(__assign({}, event), { date: new Date(event.date) }) }, rest);
}
var initialState = {
    isLoading: false,
    financials: [],
};
export var financialsReducer = createReducer((_a = {},
    _a[getFinancials.getType()] = function (state) { return (__assign(__assign({}, state), { financials: [], isLoading: true })); },
    _a[getFinancialsSuccess.getType()] = function (state, financials) { return (__assign(__assign({}, state), { financials: financials, isLoading: false })); },
    _a[getFinancialsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { financials: [], isLoading: false })); },
    _a), initialState);
export var financialsApi = {
    getForUser: function () {
        return request('/api/financials/user/0/0');
    },
};
export function onLoadFinancials() {
    var data, financials, sortedByRecordType, sortedFinancials, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(financialsApi.getForUser)];
            case 1:
                data = _a.sent();
                financials = data.map(mapRawFinancialInfoToFinancialInfo);
                sortedByRecordType = sortBy(financials, function (fi) { return "" + (fi.recordType.case === 'SellerCredit' ? '0' : '') + mapRecordTypeToString[fi.recordType.case]; });
                sortedFinancials = orderBy(sortedByRecordType, ['event.name', 'event.venueName', 'orderId'], ['asc', 'asc', 'desc']);
                return [4 /*yield*/, put(getFinancialsSuccess(sortedFinancials))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(getFinancialsFailure(e_1))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load financials data')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function financialsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getFinancials.getType(), onLoadFinancials)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

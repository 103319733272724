import styles from '@containers/DetailsPages/components/shareSocial/shareSocial.less';
import { Icon } from '@components/icon';
import React from 'react';
import { ShareSocial } from '@containers/DetailsPages/components/shareSocial/shareSocial';
export var ShareSocialMobile = function (_a) {
    var links = _a.links, handleClose = _a.handleClose;
    return React.createElement("div", { className: styles.overlayContainer },
        React.createElement("div", { className: styles.overlayBackground, onClick: handleClose }),
        React.createElement("div", { className: styles.overlayContent },
            React.createElement("div", { className: styles.overlayActions },
                React.createElement(Icon, { icon: 'close', width: 24, height: 24, onClick: handleClose })),
            React.createElement(ShareSocial, { links: links })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var getSafely = function (name) {
    try {
        var item = localStorage.getItem(name);
        if (item) {
            return JSON.parse(item);
        }
    }
    catch (e) { /** */ }
    return null;
};
var setSafely = function (name, item) {
    localStorage.setItem(name, JSON.stringify(item));
};
var removeSafely = function (name) {
    localStorage.removeItem(name);
};
export var getSession = function () { return getSafely('session'); };
export var setSession = function (item) { return setSafely('session', item); };
export var removeSession = function () { return removeSafely('session'); };
export var getUser = function () { return getSafely('user'); };
export var setUser = function (item) { return setSafely('user', item); };
export var removeUser = function () { return removeSafely('user'); };
export var getReservation = function () {
    var data = getSafely('reservation');
    if (!data) {
        return;
    }
    data.endTime = new Date(data.endTime);
    return data;
};
export var setReservation = function (item) { return setSafely('reservation', item); };
export var removeReservation = function () { return removeSafely('reservation'); };
var getEventsFilters = function () {
    return getSafely('eventQuantityFilters');
};
export var setEventQuantityFilter = function (event, side, quantity) {
    var _a;
    var _b;
    var filters = getEventsFilters();
    var filtersWithoutEvent = (filters !== null && filters !== void 0 ? filters : []).filter(function (f) { return f.id !== event.id; })
        .sort(function (a, b) { var _a, _b; return ((_a = a.lastModification) !== null && _a !== void 0 ? _a : 0) > ((_b = b.lastModification) !== null && _b !== void 0 ? _b : 0) ? -1 : 1; })
        .slice(0, 100); // we keep only last 100 filters
    var eventFilters = (_b = (filters !== null && filters !== void 0 ? filters : []).find(function (f) { return f.id === event.id; })) !== null && _b !== void 0 ? _b : { id: event.id, buyQuantity: null, sellQuantity: null };
    var newFilter = __assign(__assign({}, eventFilters), (_a = {}, _a[side === 'buy' ? 'buyQuantity' : 'sellQuantity'] = quantity, _a.lastModification = Date.now(), _a));
    setSafely('eventQuantityFilters', __spreadArray([newFilter], __read(filtersWithoutEvent)));
};
export var getEventFilter = function (event) {
    var _a, _b, _c;
    return (_c = (_b = (_a = getEventsFilters()) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, function (f) { return f.id === event.id; })) !== null && _c !== void 0 ? _c : { id: event.id, buyQuantity: null, sellQuantity: null };
};

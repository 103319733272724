var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { push } from 'connected-react-router';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { routes } from 'src/client/routing/routes';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { tryMapError } from './errorCodeMapper';
import { downloadLabelSuccess, filteringSelect, isPaperDeliveryType, loadPurchaseSuccess, loadSale, rawTransactionToTransaction, transactionApi, updatePurchaseOnList, updateSaleOnList, } from './transactions';
import { getUserDetails, hasValidDeliveryAddress, selectUserDetails } from './user';
import get from 'lodash/get';
export var loadPurchase = createAction('load purchase', function (id) { return ({ id: id }); });
export var loadPurchaseFailure = createAction('load purchase failure', function (error) { return ({ error: error }); });
export var loadSaleSuccess = createAction('load sale success', function (sale) { return ({ sale: sale }); });
export var loadSaleFailure = createAction('load sale failure', function (error) { return ({ error: error }); });
export var retryPayment = createAction('retry payment', function (id, orderId, costs, stripe, elements, data, cardId, complete) { return ({
    id: id,
    orderId: orderId,
    costs: costs,
    stripe: stripe,
    data: data,
    cardId: cardId,
    complete: complete,
    elements: elements,
}); });
export var retryPaymentSuccess = createAction('retry payment success');
export var retryPaymentFailure = createAction('retry payment failure');
export var updateDeliveryAddress = createAction('update delivery address', function (transactionId, address, deliveryType, callback) { return ({ transactionId: transactionId, address: address, deliveryType: deliveryType, callback: callback }); });
export var updateDeliveryAddressSuccess = createAction('update delivery address success', function (purchase) { return ({ purchase: purchase }); });
export var updateDeliveryAddressFailure = createAction('update delivery address failure');
export var changeDeliveryMethod = createAction('change delivery method', function (transactionId, deliveryMethod, ticketsInfo, andThen) { return ({ transactionId: transactionId, deliveryMethod: deliveryMethod, ticketsInfo: ticketsInfo, andThen: andThen }); });
export var changeDeliveryMethodSuccess = createAction('change delivery method success', function (sale) { return ({ sale: sale }); });
export var changeDeliveryMethodFailure = createAction('change delivery method failure');
export var updateContactInfo = createAction('update contact info', function (transactionId, guestToken, userEmail, contactInfo, transactionSide, andThen) { return ({ transactionId: transactionId, guestToken: guestToken, userEmail: userEmail, contactInfo: contactInfo, transactionSide: transactionSide, andThen: andThen }); });
export var updateContactInfoSuccess = createAction('update contact info success', function (transaction, transactionSide) { return ({ transaction: transaction, transactionSide: transactionSide }); });
export var updateContactInfoFailure = createAction('update contact info failure');
export var updateRecipientInfo = createAction('update recipient info', function (transactionId, recipientInfo, andThen) { return ({ transactionId: transactionId, recipientInfo: recipientInfo, andThen: andThen }); });
export var updateRecipientInfoSuccess = createAction('update recipient info success', function (purchase) { return ({ purchase: purchase }); });
export var updateRecipientInfoFailure = createAction('update recipient info failure');
var initialState = {
    purchase: null,
    isLoadingPurchase: true,
    sale: null,
    isLoadingSale: true,
    retryPaymentPending: false,
};
export var transactionConfirmationReducer = createReducer((_a = {},
    _a[loadPurchase.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPurchase: true })); },
    _a[loadPurchaseSuccess.getType()] = function (state, _a) {
        var purchase = _a.purchase;
        return (__assign(__assign({}, state), { purchase: purchase, isLoadingPurchase: false }));
    },
    _a[loadPurchaseFailure.getType()] = function (state) { return (__assign(__assign({}, state), { purchase: null, isLoadingPurchase: false })); },
    _a[loadSale.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSale: true })); },
    _a[loadSaleSuccess.getType()] = function (state, _a) {
        var sale = _a.sale;
        return (__assign(__assign({}, state), { sale: sale, isLoadingSale: false }));
    },
    _a[loadSaleFailure.getType()] = function (state) { return (__assign(__assign({}, state), { sale: null, isLoadingSale: false })); },
    _a[retryPayment.getType()] = function (state) { return (__assign(__assign({}, state), { retryPaymentPending: true })); },
    _a[retryPaymentSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { retryPaymentPending: false })); },
    _a[retryPaymentFailure.getType()] = function (state) { return (__assign(__assign({}, state), { retryPaymentPending: false })); },
    _a[updateDeliveryAddress.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPurchase: true })); },
    _a[updateDeliveryAddressSuccess.getType()] = function (state, _a) {
        var purchase = _a.purchase;
        return (__assign(__assign({}, state), { purchase: purchase, isLoadingPurchase: false }));
    },
    _a[updateDeliveryAddressFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPurchase: false })); },
    _a[downloadLabelSuccess.getType()] = function (_a, transaction) {
        var sale = _a.sale, state = __rest(_a, ["sale"]);
        return (__assign(__assign({}, state), { sale: sale && sale.transactionId === transaction.transactionId
                ? transaction
                : sale }));
    },
    _a[changeDeliveryMethodSuccess.getType()] = function (state, _a) {
        var sale = _a.sale;
        return (__assign(__assign({}, state), { sale: __assign(__assign({}, state.sale), sale) }));
    },
    _a[updateContactInfoSuccess.getType()] = function (state, _a) {
        var transaction = _a.transaction, transactionSide = _a.transactionSide;
        return (__assign(__assign({}, state), { sale: transactionSide === 'sell' ? __assign(__assign({}, state.sale), transaction) : state.sale, purchase: transactionSide === 'buy' ? __assign(__assign({}, state.purchase), transaction) : state.purchase }));
    },
    _a[updateRecipientInfoSuccess.getType()] = function (state, _a) {
        var purchase = _a.purchase;
        return (__assign(__assign({}, state), { purchase: __assign(__assign({}, state.purchase), purchase) }));
    },
    _a), initialState);
export function onLoadPurchase(_a) {
    var salesFilters, rawPurchases, purchase, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 10]);
                return [4 /*yield*/, select(filteringSelect)];
            case 1:
                salesFilters = _b.sent();
                return [4 /*yield*/, call(transactionApi.getUserPurchases, salesFilters)];
            case 2:
                rawPurchases = _b.sent();
                purchase = rawPurchases.results.find(function (p) { return p.orderId === payload.id; });
                if (!(purchase == null)) return [3 /*break*/, 5];
                return [4 /*yield*/, put(loadPurchaseFailure('Cannot load purchase'))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load transaction')];
            case 4:
                _b.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, put(loadPurchaseSuccess(rawTransactionToTransaction('buy')(purchase)))];
            case 6:
                _b.sent();
                return [3 /*break*/, 10];
            case 7:
                e_1 = _b.sent();
                return [4 /*yield*/, put(loadPurchaseFailure(e_1))];
            case 8:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load transaction')];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function onLoadSale(_a) {
    var sale, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(transactionApi.getUserSale, payload.id)];
            case 1:
                sale = _b.sent();
                return [4 /*yield*/, put(loadSaleSuccess(rawTransactionToTransaction('sell')(sale)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_2 = _b.sent();
                return [4 /*yield*/, put(loadSaleFailure(e_2))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load transaction')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onRetryPayment(_a) {
    var paymentMethodId, result, transactionResponse, intentResponse, retriedTransactionResponse, e_3;
    var _b, _c;
    var _d = _a.payload, id = _d.id, orderId = _d.orderId, costs = _d.costs, stripe = _d.stripe, cardId = _d.cardId, data = _d.data, complete = _d.complete, elements = _d.elements;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!!stripe) return [3 /*break*/, 3];
                return [4 /*yield*/, call(toast.error, 'Problem with payment provider. Please try later')];
            case 1:
                _e.sent();
                return [4 /*yield*/, put(retryPaymentFailure())];
            case 2:
                _e.sent();
                return [2 /*return*/];
            case 3:
                _e.trys.push([3, 26, , 31]);
                paymentMethodId = cardId;
                if (!!paymentMethodId) return [3 /*break*/, 8];
                return [4 /*yield*/, stripe.createPaymentMethod({ type: 'card', card: elements, billing_details: pickBy(data, identity) })];
            case 4:
                result = _e.sent();
                if (!result.error) return [3 /*break*/, 7];
                return [4 /*yield*/, call(toast.error, (_b = tryMapError(result.error)) !== null && _b !== void 0 ? _b : result.error.message)];
            case 5:
                _e.sent();
                return [4 /*yield*/, put(retryPaymentFailure())];
            case 6:
                _e.sent();
                return [2 /*return*/];
            case 7:
                paymentMethodId = result.paymentMethod.id;
                _e.label = 8;
            case 8: return [4 /*yield*/, call(transactionApi.retryPayments, id, costs, { paymentMethodId: paymentMethodId })];
            case 9:
                transactionResponse = _e.sent();
                if (!transactionResponse.actionRequired) return [3 /*break*/, 19];
                return [4 /*yield*/, call(stripe.handleCardAction, transactionResponse.paymentIntentClientSecret)];
            case 10:
                intentResponse = _e.sent();
                if (!intentResponse.error) return [3 /*break*/, 13];
                return [4 /*yield*/, call(toast.error, (_c = tryMapError(intentResponse.error)) !== null && _c !== void 0 ? _c : intentResponse.error.message)];
            case 11:
                _e.sent();
                return [4 /*yield*/, put(retryPaymentFailure())];
            case 12:
                _e.sent();
                return [2 /*return*/];
            case 13: return [4 /*yield*/, call(transactionApi.retryPayments, id, costs, { paymentIntentId: intentResponse.paymentIntent.id })];
            case 14:
                retriedTransactionResponse = _e.sent();
                if (!!retriedTransactionResponse.actionRequired) return [3 /*break*/, 16];
                return [4 /*yield*/, put(loadPurchaseSuccess(rawTransactionToTransaction('buy')(retriedTransactionResponse.transaction)))];
            case 15:
                _e.sent();
                return [3 /*break*/, 18];
            case 16: return [4 /*yield*/, call(toast.error, 'Transaction is not confirmed, try again later')];
            case 17:
                _e.sent();
                return [2 /*return*/];
            case 18: return [3 /*break*/, 21];
            case 19: return [4 /*yield*/, put(loadPurchaseSuccess(rawTransactionToTransaction('buy')(transactionResponse.transaction)))];
            case 20:
                _e.sent();
                _e.label = 21;
            case 21: return [4 /*yield*/, put(retryPaymentSuccess())];
            case 22:
                _e.sent();
                return [4 /*yield*/, put(push(routes.transactionConfirmation('buy', "" + orderId)))];
            case 23:
                _e.sent();
                if (!complete) return [3 /*break*/, 25];
                return [4 /*yield*/, call(complete, 'success')];
            case 24:
                _e.sent();
                _e.label = 25;
            case 25: return [3 /*break*/, 31];
            case 26:
                e_3 = _e.sent();
                return [4 /*yield*/, call(toast.error, get(e_3, 'message') || get(e_3, ['data', 'message']))];
            case 27:
                _e.sent();
                return [4 /*yield*/, put(retryPaymentFailure())];
            case 28:
                _e.sent();
                if (!complete) return [3 /*break*/, 30];
                return [4 /*yield*/, call(complete, 'failure')];
            case 29:
                _e.sent();
                _e.label = 30;
            case 30: return [3 /*break*/, 31];
            case 31: return [2 /*return*/];
        }
    });
}
export function onUpdateDeliveryAddress(_a) {
    var transactionId, address, deliveryType, callback, rawPurchase, purchase, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                transactionId = payload.transactionId, address = payload.address, deliveryType = payload.deliveryType, callback = payload.callback;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 10]);
                return [4 /*yield*/, call(transactionApi.updateDelivery, transactionId, address, undefined, deliveryType, undefined)];
            case 2:
                rawPurchase = _b.sent();
                purchase = rawTransactionToTransaction('buy')(rawPurchase);
                return [4 /*yield*/, put(updateDeliveryAddressSuccess(purchase))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Delivery address has been changed')];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(push(routes.userPurchase(rawPurchase.orderId)))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(updatePurchaseOnList(purchase))];
            case 6:
                _b.sent();
                return [3 /*break*/, 10];
            case 7:
                e_4 = _b.sent();
                return [4 /*yield*/, put(updateDeliveryAddressFailure())];
            case 8:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'We\'re sorry your delivery address cannot be changed. Please contact us.')];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [4 /*yield*/, call(callback)];
            case 11:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onUpdateRecipientInfo(_a) {
    var transactionId, recipientInfo, andThen, rawPurchase, purchase, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                transactionId = payload.transactionId, recipientInfo = payload.recipientInfo, andThen = payload.andThen;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call(transactionApi.updateDelivery, transactionId, undefined, recipientInfo)];
            case 2:
                rawPurchase = _b.sent();
                purchase = rawTransactionToTransaction('buy')(rawPurchase);
                return [4 /*yield*/, put(updateRecipientInfoSuccess(purchase))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Recipient information has been changed')];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_5 = _b.sent();
                return [4 /*yield*/, put(updateRecipientInfoFailure())];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'We\'re sorry recipient information cannot be changed. Please contact us.')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8:
                if (!andThen) return [3 /*break*/, 10];
                return [4 /*yield*/, call(andThen, true)];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [2 /*return*/];
        }
    });
}
export function onChangeDeliveryMethod(_a) {
    var transactionId, deliveryMethod, ticketsInfo, andThen, rawSale, sale, currentDeliveryMethod, hasAddress, requiresManualChange, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                transactionId = payload.transactionId, deliveryMethod = payload.deliveryMethod, ticketsInfo = payload.ticketsInfo, andThen = payload.andThen;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 11, , 16]);
                return [4 /*yield*/, call(transactionApi.updateDelivery, transactionId, undefined, undefined, deliveryMethod, ticketsInfo)];
            case 2:
                rawSale = _b.sent();
                sale = rawTransactionToTransaction('sell')(rawSale);
                currentDeliveryMethod = sale.delivery.deliveryType;
                hasAddress = hasValidDeliveryAddress(sale.delivery.deliveryAddress);
                requiresManualChange = !isPaperDeliveryType(currentDeliveryMethod) && isPaperDeliveryType(deliveryMethod) && !hasAddress;
                return [4 /*yield*/, put(changeDeliveryMethodSuccess(sale))];
            case 3:
                _b.sent();
                if (!requiresManualChange) return [3 /*break*/, 5];
                return [4 /*yield*/, call(toast.success, 'Delivery method change has been requested')];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, call(toast.success, 'Delivery method has been changed')];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4 /*yield*/, put(updateSaleOnList(sale))];
            case 8:
                _b.sent();
                if (!andThen) return [3 /*break*/, 10];
                return [4 /*yield*/, call(andThen, true)];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [3 /*break*/, 16];
            case 11:
                e_6 = _b.sent();
                return [4 /*yield*/, put(changeDeliveryMethodFailure())];
            case 12:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'We\'re sorry delivery method cannot be changed. Please contact us.')];
            case 13:
                _b.sent();
                if (!andThen) return [3 /*break*/, 15];
                return [4 /*yield*/, call(andThen, false)];
            case 14:
                _b.sent();
                _b.label = 15;
            case 15: return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
export function onUpdateContactInfo(_a) {
    var transactionId, guestToken, userEmail, contactInfo, transactionSide, andThen, isLogged, rawTransaction, _b, transaction, e_7;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                transactionId = payload.transactionId, guestToken = payload.guestToken, userEmail = payload.userEmail, contactInfo = payload.contactInfo, transactionSide = payload.transactionSide, andThen = payload.andThen;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 13, , 18]);
                return [4 /*yield*/, select(selectUserDetails)];
            case 2:
                isLogged = _c.sent();
                if (!isLogged) return [3 /*break*/, 4];
                return [4 /*yield*/, call(transactionApi.updateUserContactInfo, transactionId, contactInfo)];
            case 3:
                _b = _c.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(transactionApi.updateGuestContactInfo, transactionId, guestToken !== null && guestToken !== void 0 ? guestToken : '', userEmail !== null && userEmail !== void 0 ? userEmail : '', contactInfo)];
            case 5:
                _b = _c.sent();
                _c.label = 6;
            case 6:
                rawTransaction = _b;
                transaction = rawTransactionToTransaction(transactionSide)(rawTransaction);
                return [4 /*yield*/, put(updateContactInfoSuccess(transaction, transactionSide))];
            case 7:
                _c.sent();
                return [4 /*yield*/, call(toast.success, 'User details have been changed')];
            case 8:
                _c.sent();
                if (!isLogged) return [3 /*break*/, 10];
                return [4 /*yield*/, put(getUserDetails())];
            case 9:
                _c.sent();
                _c.label = 10;
            case 10:
                if (!andThen) return [3 /*break*/, 12];
                return [4 /*yield*/, call(andThen, true)];
            case 11:
                _c.sent();
                _c.label = 12;
            case 12: return [3 /*break*/, 18];
            case 13:
                e_7 = _c.sent();
                return [4 /*yield*/, put(updateContactInfoFailure())];
            case 14:
                _c.sent();
                return [4 /*yield*/, call(toast.error, 'We\'re sorry user details cannot be changed. Please contact us.')];
            case 15:
                _c.sent();
                if (!andThen) return [3 /*break*/, 17];
                return [4 /*yield*/, call(andThen, false)];
            case 16:
                _c.sent();
                _c.label = 17;
            case 17: return [3 /*break*/, 18];
            case 18: return [2 /*return*/];
        }
    });
}
export function transactionConfirmationSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadPurchase.getType(), onLoadPurchase)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadSale.getType(), onLoadSale)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(retryPayment.getType(), onRetryPayment)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateDeliveryAddress.getType(), onUpdateDeliveryAddress)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateContactInfo.getType(), onUpdateContactInfo)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateRecipientInfo.getType(), onUpdateRecipientInfo)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(changeDeliveryMethod.getType(), onChangeDeliveryMethod)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

import React from 'react';
import { Table } from 'semantic-ui-react';
import { Header } from './header';
import { Row } from './row';
import styles from './genericTable.less';
export function DataTable(props) {
    var rows = props.rows, handleSort = props.handleSort, scrollLeft = props.scrollLeft, isLoading = props.isLoading, headers = props.headers, tableState = props.tableState, rowClass = props.rowClass, stickyType = props.stickyType, top = props.top, isPinned = props.isPinned, idGetter = props.idGetter, stickThreshold = props.stickThreshold, pinnedClassName = props.pinnedClassName, rowDistinctor = props.rowDistinctor, nonRepeatableColumns = props.nonRepeatableColumns;
    var renderRows = function () {
        var defaultColor = false;
        var rowColor = function () { return !rowDistinctor || defaultColor ? styles.defaultRow : styles.distinctRow; };
        var result = rows.map(function (record, index) {
            var isFirstDistinct = !rowDistinctor || (rowDistinctor && rowDistinctor(record, rows[index - 1]));
            defaultColor = isFirstDistinct ? !defaultColor : defaultColor;
            return React.createElement(Row, { key: idGetter(record), headers: headers, record: record, rowClass: rowClass, stickyType: stickyType, isPinned: isPinned(record), pinnedClassName: pinnedClassName, colorClass: rowColor(), nonRepeatableColumns: nonRepeatableColumns, isFirstDistinct: isFirstDistinct });
        });
        return result;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sortable: true, unstackable: true },
            React.createElement(Header, { tableState: tableState, headers: headers, handleSort: handleSort, isLoading: isLoading, scrollLeft: scrollLeft, top: top, stickyType: stickyType, stickThreshold: stickThreshold }),
            React.createElement(Table.Body, null, renderRows()))));
}

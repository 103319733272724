var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Images } from '@logic/images';
import React, { useMemo } from 'react';
import { TxLazyImage } from '@components/image/lazyImage';
import { getEventMainPerformer } from '@logic/events';
import styles from '@containers/DetailsPages/components/detailsHeader/detailsHeader.less';
import { CategoriesNavigation } from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigation';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { DetailsHeaderContainer } from '@containers/DetailsPages/components/detailsHeader/detailsHeader';
import cn from 'classnames';
import { formatDateTime } from '@logic/helpers/date';
import { formatVenue } from '@logic/venue';
import { Popup } from 'semantic-ui-react';
import { Icon } from '@components/icon';
import { NotesRestrictions } from '@components/eventNotesAndRestrictions';
import { BUY_ONLY, routes } from 'src/client/routing/routes';
import { Link } from 'react-router-dom';
/*This sentence has exactly 147 characters, including spaces. It consists of 24 words, 3 sentences, and 23 spaces. the same as:
Beeyay is an innovative secondary ticketing marketplace. Ticket buyers and sellers can set their own prices which may be above or below face value.

i'm using this lorem ipsum to imitate height of the header, so that the page doesn't jump when route changed.
*/
// const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum neque nec felis placerat eleifend. Lorem ipsum dolor sit ame it as null.';
var displayEventDate = function (event) {
    switch (event.status) {
        case 'active':
            return formatDateTime(event.eventDate, event.timezone);
        case 'postponed':
            return 'Postponed - New Data TBA';
        case 'rescheduled':
            return React.createElement("div", { className: styles.eventPostponedNewDate },
                formatDateTime(event.eventDate, event.timezone),
                React.createElement(Popup, { trigger: React.createElement("div", null, "*"), hoverable: true, content: React.createElement("div", { className: styles.eventPostponedPopup },
                        React.createElement("p", null, "This event was previously rescheduled"),
                        React.createElement("p", null, "The original date was:"),
                        React.createElement("p", null, formatDateTime(event.previousEventDate, event.timezone))) }));
        default:
            return formatDateTime(event.eventDate, event.timezone);
    }
};
export var DetailsEventHeader = function (_a) {
    var event = _a.event, containerSize = _a.containerSize, buyOnly = _a.buyOnly, scrollRef = _a.scrollRef, breadcrumb = _a.breadcrumb, rest = __rest(_a, ["event", "containerSize", "buyOnly", "scrollRef", "breadcrumb"]);
    var media = useViewportInfo().media;
    var isMobile = isLikeMobile(media);
    var hasRestrictionsOrNotes = event.notes || event.restrictions;
    var mainPerformer = useMemo(function () { return getEventMainPerformer(event); }, [event]);
    return (React.createElement(React.Fragment, null,
        !isMobile && React.createElement(CategoriesNavigation, null),
        React.createElement(DetailsHeaderContainer, { containerSize: containerSize },
            breadcrumb,
            React.createElement("div", __assign({ className: cn(styles.detailsHeaderGrid, styles.detailsHeaderGrid_EventPage) }, rest),
                React.createElement("div", { className: styles.detailsImageContainer },
                    React.createElement(TxLazyImage, { useLargeAspectRatio: true, src: event.imageUrl + "/" + Images.eventList(event.name || ''), mobileSrc: '', visibleByDefault: true })),
                React.createElement("div", { className: styles.detailsHeader },
                    React.createElement(Link, { className: styles.detailsTitle, to: routes.performer(mainPerformer.id, mainPerformer.slug, buyOnly ? BUY_ONLY : undefined) }, event.name),
                    React.createElement(Link, { className: styles.detailsSubtitle, to: routes.venue(event.venue.slug, event.venue.id) }, formatVenue(event.venue)),
                    React.createElement("div", { className: styles.detailsSubtitle }, displayEventDate(event))),
                React.createElement("span", { ref: scrollRef, className: cn(styles.detailsDescription, styles.notesColor) },
                    hasRestrictionsOrNotes && React.createElement(Popup, { trigger: React.createElement(Icon, { className: styles.detailsIcon, width: 16, height: 16, icon: 'info' }), hoverable: true, className: styles.notesDetails, content: React.createElement(NotesRestrictions, { event: event, media: media }) }),
                    event.restrictions && React.createElement("span", { className: styles.notesColor }, event.restrictions),
                    event.notes && React.createElement("span", { className: styles.notesColor }, event.notes),
                    !hasRestrictionsOrNotes && React.createElement("span", { className: styles.notesColor },
                        " ",
                        event.description,
                        " "))))));
};

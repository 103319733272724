var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import cn from 'classnames';
import Slider from 'infinite-react-carousel';
import range from 'lodash/range';
import shuffle from 'lodash/shuffle';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TrustpilotLogo } from '../trustpilotLogo/trustpilotLogo';
import styles from './customTrustboxSlider.less';
import { ReactComponent as EmptyStar } from './empty-star.svg';
import { ReactComponent as HalfStar } from './half-star.svg';
import { ReactComponent as IconNext } from './next.svg';
import { ReactComponent as IconPrev } from './prev.svg';
import { ReactComponent as Star } from './star.svg';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { windowIsDefined } from '@containers/utils';
var reviews = [
    {
        rating: 5,
        header: 'Brilliant be buying again for more...',
        body: 'Brilliant be buying again for more concerts',
        author: 'STEVEN HANRATTY'
    },
    {
        rating: 5,
        header: 'Excellent company',
        body: 'Excellent company, very quick communication and have good policies in place that protect both buyer and seller. Would use again.',
        author: 'Karl Prime'
    },
    {
        rating: 5,
        header: 'New company great service',
        body: 'New company for resale event tickets, and a brilliant one at that. Tickets cheaper than other resale sites and the service is great, sent a query yesterday evening and got a reply this morning! They even have buyer listings, so you can advertise what you want and the price you’re willing to pay and the sellers will come to you! Can’t fault at all and recommend very highly.',
        author: 'Bilo'
    },
    {
        rating: 5,
        header: 'Easy and smooth transfer of tickets..',
        body: 'Really easy to use and tickets transferred easily.',
        author: 'Mark Shufflebotham'
    },
    {
        rating: 4,
        header: 'Easy to order and received tickets…',
        body: 'Easy to order and received tickets quickly',
        author: 'Donna Stewart'
    },
    {
        rating: 5,
        header: 'Really impressive service',
        body: 'I used Beeyay for the first time last week and I was very impressed. The pricing is all very straight forward (no pesky hidden charges) and it\'s really easy to understand what tickets are available and where. I am thrilled to have my hands on a couple of Andrea Bocelli tickets for October (Covid-19 allowing). The e-tickets were in my AXS account 24 hours after purchasing the tickets on the Beeyay platform. I couldn\'t ask for a more speedy service! I will definitely be using the platform in future. Thanks, Beeyay!',
        author: 'Michelle'
    },
    {
        rating: 5,
        header: 'Great customer service. Refund straight away',
        body: 'Our tickets for Duran Duran were cancelled because of coronavirus. Beeyay gave us a full refund on the day it was announced. A shame about the concert but it wasn’t their fault. Hopefully we can go next year.',
        author: 'Nathalie'
    }
];
export var SingleWidget = function (_a) {
    var review = _a.review;
    var fullStars = Math.floor(review.rating);
    var emptyStars = Math.floor(5 - review.rating);
    var halfStars = 5 - fullStars - emptyStars;
    return (React.createElement("div", { className: styles.widget },
        React.createElement("div", { className: styles.stars },
            range(0, fullStars).map(function (i) { return React.createElement(Star, { key: i }); }),
            range(0, halfStars).map(function (i) { return React.createElement(HalfStar, { key: i }); }),
            range(0, emptyStars).map(function (i) { return React.createElement(EmptyStar, { key: i }); })),
        React.createElement("div", { className: styles.header }, review.header),
        React.createElement("div", { className: styles.comment }, review.body),
        React.createElement("div", { className: styles.author }, review.author)));
};
var getShouldShuffleReviews = function () { var _a, _b; return (_b = (windowIsDefined() && ((_a = window) === null || _a === void 0 ? void 0 : _a.SHOULD_SHUFFLE_REVIEWS))) !== null && _b !== void 0 ? _b : true; };
export var CustomTrustboxSlider = function (_a) {
    var className = _a.className;
    var ref = useRef(null);
    var width = useViewportInfo().width;
    var slidesToShow = width > 974 ? 3 : width > 640 ? 2 : 1;
    var shouldShuffleReviews = getShouldShuffleReviews();
    var shuffledReviews = useMemo(function () { return shouldShuffleReviews ? shuffle(reviews) : reviews; }, [shouldShuffleReviews]);
    var _b = __read(useState(false), 2), renderSlider = _b[0], setRenderSlider = _b[1];
    useLayoutEffect(function () {
        // Delay slider rendering until whole markup is ready. This is due to some missing elements in the DOM tree.
        // This way we can protect ourselves against errors from the Slider library.
        var id = setTimeout(function () {
            setRenderSlider(true);
        }, 100);
        return function () { return clearTimeout(id); };
    }, []);
    return (React.createElement("div", { className: cn(styles.trustbox, className) },
        React.createElement("div", { className: styles.sliderWrapper },
            React.createElement(IconPrev, { className: styles.prev, onClick: function () { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.slickPrev(); } }),
            renderSlider &&
                React.createElement(Slider, { className: styles.slider, ref: ref, slidesToShow: slidesToShow, arrows: false }, shuffledReviews.map(function (review, idx) { return React.createElement(SingleWidget, { key: idx, review: review }); })),
            React.createElement(IconNext, { className: styles.next, onClick: function () { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.slickNext(); } })),
        React.createElement(TrustpilotLogo, null)));
};

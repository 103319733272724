var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import loadable from '@loadable/component';
import cn from 'classnames';
import noop from 'lodash/noop';
import React, { useMemo, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import venueMapStyle from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMap.less';
import styles from '@containers/DetailsPages/pages/EventDetails/components/SeatingMapOnListing/seatingMapOnListing.less';
var SeatingPlan = loadable(function () { return import(/* webpackChunkName: "vm-seating-plan-wrapper" */ '@containers/DetailsPages/pages/EventDetails/components/VenueMap/seating-plan-wrapper'); }, {
    fallback: React.createElement(Loader, { active: true }),
    ssr: false,
});
var getColor = function (category, seatingPlan) { var _a, _b; return "color" + ((_b = (_a = seatingPlan === null || seatingPlan === void 0 ? void 0 : seatingPlan.structure) === null || _a === void 0 ? void 0 : _a.categories) === null || _b === void 0 ? void 0 : _b.findIndex(function (c) { return c.name === category; })); };
var useHighlightedItems = function (seatingPlan, listing) {
    var _a = __read(useState(), 2), tree = _a[0], updateTree = _a[1];
    var highlightedItems = useMemo(function () {
        var _a, _b;
        if (!tree) {
            return [];
        }
        var listingSeatingInfo = listing.seatingInfo[0];
        if (listing.type === 'sell') {
            return [{ category: listingSeatingInfo.category, section: listingSeatingInfo.section, className: getColor(listingSeatingInfo.category, seatingPlan) }];
        }
        else {
            if (listingSeatingInfo.matchAny) {
                return Object.entries(tree).map(function (_a) {
                    var _b = __read(_a, 2), name = _b[0], sections = _b[1];
                    if (sections.length === 0) {
                        return [{ category: name, section: '', className: getColor(name, seatingPlan) }];
                    }
                    return sections.map(function (section) { return ({ category: name, section: section, className: getColor(name, seatingPlan) }); });
                }).reduce(function (prev, curr) { return __spreadArray(__spreadArray([], __read(prev)), __read(curr)); }, []);
            }
            else if (!listingSeatingInfo.section) {
                var sections = (_b = (_a = Object.entries(tree).find(function (_a) {
                    var _b = __read(_a, 1), name = _b[0];
                    return name === listingSeatingInfo.category;
                })) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : [];
                return sections.length
                    ? sections.map(function (section) { return ({ category: listingSeatingInfo.category, section: section, className: getColor(listingSeatingInfo.category, seatingPlan) }); })
                    : [{ category: listingSeatingInfo.category, section: '', className: getColor(listingSeatingInfo.category, seatingPlan) }];
            }
            else {
                return [{ category: listingSeatingInfo.category, section: listingSeatingInfo.section, className: getColor(listingSeatingInfo.category, seatingPlan) }];
            }
        }
    }, [seatingPlan, listing, tree]);
    return { highlightedItems: highlightedItems, updateTree: updateTree };
};
export function SeatingMapOnListing(_a) {
    var _b;
    var isLoadingSeatingPlan = _a.isLoadingSeatingPlan, listing = _a.listing, seatingPlan = _a.seatingPlan, onOpenFilter = _a.onOpenFilter;
    var _c = useHighlightedItems(seatingPlan, listing), highlightedItems = _c.highlightedItems, updateTree = _c.updateTree;
    return (React.createElement("div", { className: styles.seatingPlanContainer, onClick: onOpenFilter }, isLoadingSeatingPlan || !(seatingPlan === null || seatingPlan === void 0 ? void 0 : seatingPlan.svgFile)
        ? React.createElement(Loader, { active: true })
        : React.createElement(SeatingPlan, { svg: (_b = seatingPlan === null || seatingPlan === void 0 ? void 0 : seatingPlan.svgFile) !== null && _b !== void 0 ? _b : '', onSvgParsed: updateTree, onMouseEnter: noop, onMouseLeave: noop, onClick: noop, onPanAndZoomChange: noop, highlightedItems: highlightedItems, className: cn(venueMapStyle.venueMap), delimiter: '||', disablePanAndZoom: true, onPanAndZoomInstanceChange: noop })));
}

/* KeyboardEvent has to be explicit only natural number */
export var onlyNaturalNumber = function (event) {
    var target = event.currentTarget;
    if (!'0123456789'.includes(event.key) || (target.value.length === 4 && target.selectionStart === target.selectionEnd)) {
        event.preventDefault();
    }
};
/* KeyboardEvent has to be explicit only natural number or alphabet [a-Z] */
export var onlyNumberOrCharacter = function (event) {
    var target = event.currentTarget;
    if (!event.key.match('^[a-zA-Z0-9]$') || (target.value.length === 3 && target.selectionStart === target.selectionEnd)) {
        event.preventDefault();
    }
};

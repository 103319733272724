var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import qs from 'qs';
import { matchPath } from 'react-router';
export var BUY_ONLY = 'buy-only';
export var eventLinkSlugToEventRoute = function (slug, transactionSide) {
    return routes.event(slug.id, slug.eventName, slug.venueCity, slug.eventDate, transactionSide);
};
export var routesD = {
    venueD: function (id) {
        if (id === void 0) { id = ':id'; }
        return "/venue/" + id;
    },
    performerD: function (id, city, buyOnly) {
        if (id === void 0) { id = ':id'; }
        return "/performer/" + id + (city ? "/" + city : '') + (buyOnly ? "/" + buyOnly : '');
    },
    eventWithoutTypeD: function (id) {
        if (id === void 0) { id = ':id'; }
        return "/event/" + id;
    },
    eventD: function (id, transactionSide) {
        if (id === void 0) { id = ':id'; }
        if (transactionSide === void 0) { transactionSide = ':transactionSide'; }
        return "/event/" + id + "/" + transactionSide;
    },
};
export var userRoutesD = {
    user: function () { return '/user'; },
    userProfileD: function () { return userRoutesD.user() + "/profile"; },
    userPasswordD: function () { return userRoutesD.user() + "/password"; },
    userAddressesD: function () { return userRoutesD.user() + "/address"; },
    userAddressD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.user() + "/address/" + id;
    },
    userCardsD: function () { return userRoutesD.user() + "/card"; },
    userCardD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.user() + "/card/" + id;
    },
    userBankAccountsD: function () { return userRoutesD.user() + "/bankAccounts"; },
    userBankAccountD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.userBankAccountsD() + "/" + id;
    },
    userPreferencesD: function () { return userRoutesD.user() + "/preferences"; },
    userListingsD: function () { return userRoutesD.user() + "/listings"; },
    userPurchasesD: function () { return userRoutesD.user() + "/purchases"; },
    userSalesD: function () { return userRoutesD.user() + "/sales"; },
    userWorkItemsD: function () { return userRoutesD.user() + "/workitems"; },
    userWorkItemD: function (id) {
        if (id === void 0) { id = ':id?'; }
        return userRoutesD.userWorkItemsD() + "/" + id;
    },
    userFinancialsD: function () { return userRoutesD.user() + "/financials"; },
    userListingsWithFiltersD: function (filter) { return filter ? { pathname: userRoutesD.userListingsD(), search: filter } : userRoutesD.userListingsD(); },
    userPurchasesWithFilterD: function (filter) { return filter ? { pathname: userRoutesD.userPurchasesD(), search: filter } : userRoutesD.userPurchasesD(); },
    userPurchaseD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.userPurchasesD() + "/" + id;
    },
    userPurchaseWithFilterD: function (id, filter) {
        if (id === void 0) { id = ':id'; }
        return filter ? { pathname: userRoutesD.userPurchaseD(id), search: filter } : userRoutesD.userPurchaseD(id);
    },
    userPurchasePaymentD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.userPurchaseD(id) + "/payment";
    },
    userSalesWithFilterD: function (filter) { return filter ? { pathname: userRoutesD.userSalesD(), search: filter } : userRoutesD.userSalesD(); },
    userSaleD: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesD.userSalesD() + "/" + id;
    },
    userSaleWithFilterD: function (id, filter) {
        if (id === void 0) { id = ':id'; }
        return filter ? { pathname: userRoutesD.userSaleD(id), search: filter } : userRoutesD.userSaleD(id);
    },
    userSaleETicketsD: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutesD.userSalesD() + "/" + id + "/etickets" + (requestedType ? "/" + requestedType : '');
    },
    userSaleETicketsFiltersD: function (id, requestedType, filter) {
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return filter
            ? { pathname: userRoutesD.userSaleETicketsD(id, requestedType), search: filter }
            : userRoutesD.userSaleETicketsD(id, requestedType);
    },
    userSaleMobileTransferD: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutesD.userSalesD() + "/" + id + "/transfer" + (requestedType ? "/" + requestedType : '');
    },
    userSaleMobileTransferFiltersD: function (id, requestedType, filter) {
        return filter
            ? { pathname: userRoutesD.userSaleMobileTransferD(id, requestedType), search: filter }
            : userRoutesD.userSaleMobileTransferD(id, requestedType);
    },
    userSaleRoyalMailD: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutesD.userSalesD() + "/" + id + "/delivery" + (requestedType ? "/" + requestedType : '');
    },
    userSaleRoyalMailFiltersD: function (id, requestedType, filter) {
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return filter
            ? { pathname: userRoutesD.userSaleRoyalMailD(id, requestedType), search: filter }
            : userRoutesD.userSaleRoyalMailD(id, requestedType);
    },
    userFinancialsWithFiltersD: function (filter) { return filter ? { pathname: userRoutesD.userFinancialsD(), search: filter } : userRoutesD.userFinancialsD(); },
};
export var userRoutes = {
    userProfile: function () { return '/profile'; },
    userPassword: function () { return '/password'; },
    userAddresses: function () { return '/addresses'; },
    userAddress: function (id) {
        if (id === void 0) { id = ':id'; }
        return "/addresses/" + id;
    },
    userCards: function () { return '/buyer-payment-methods'; },
    userCard: function (id) {
        if (id === void 0) { id = ':id'; }
        return "/buyer-payment-methods/" + id;
    },
    userBankAccounts: function () { return '/seller-payment-methods'; },
    userPreferences: function () { return '/preferences'; },
    userListings: function () { return '/listings'; },
    userPurchases: function () { return '/purchases'; },
    userSales: function () { return '/sales'; },
    userWorkItems: function () { return '/messages'; },
    userFinancials: function () { return '/payouts'; },
};
export var userRoutesAdvanced = {
    userBankAccount: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutes.userBankAccounts() + "/" + id;
    },
    userListingsWithFilters: function (filter) { return filter ? { pathname: userRoutes.userListings(), search: filter } : userRoutes.userListings(); },
    userPurchasesWithFilter: function (filter) { return filter ? { pathname: userRoutes.userPurchases(), search: filter } : userRoutes.userPurchases(); },
    userPurchase: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutes.userPurchases() + "/" + id;
    },
    userPurchaseWithFilter: function (id, filter) {
        if (id === void 0) { id = ':id'; }
        return filter ? { pathname: userRoutesAdvanced.userPurchase(id), search: filter } : userRoutesAdvanced.userPurchase(id);
    },
    userPurchasePayment: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutesAdvanced.userPurchase(id) + "/payment";
    },
    userSalesWithFilter: function (filter) { return filter ? { pathname: userRoutes.userSales(), search: filter } : userRoutes.userSales(); },
    userSale: function (id) {
        if (id === void 0) { id = ':id'; }
        return userRoutes.userSales() + "/" + id;
    },
    userSaleWithFilter: function (id, filter) {
        if (id === void 0) { id = ':id'; }
        return filter ? { pathname: userRoutesAdvanced.userSale(id), search: filter } : userRoutesAdvanced.userSale(id);
    },
    userSaleETickets: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutes.userSales() + "/" + id + "/etickets" + (requestedType ? "/" + requestedType : '');
    },
    userSaleETicketsFilters: function (id, requestedType, filter) {
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return filter
            ? { pathname: userRoutesAdvanced.userSaleETickets(id, requestedType), search: filter }
            : userRoutesAdvanced.userSaleETickets(id, requestedType);
    },
    userSaleMobileTransfer: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutes.userSales() + "/" + id + "/transfer" + (requestedType ? "/" + requestedType : '');
    },
    userSaleMobileTransferFilters: function (id, requestedType, filter) {
        return filter
            ? { pathname: userRoutesAdvanced.userSaleMobileTransfer(id, requestedType), search: filter }
            : userRoutesAdvanced.userSaleMobileTransfer(id, requestedType);
    },
    userSaleRoyalMail: function (id, requestedType) {
        if (id === void 0) { id = ':id'; }
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return userRoutes.userSales() + "/" + id + "/delivery" + (requestedType ? "/" + requestedType : '');
    },
    userSaleRoyalMailFilters: function (id, requestedType, filter) {
        if (requestedType === void 0) { requestedType = ':requested?'; }
        return filter
            ? { pathname: userRoutesAdvanced.userSaleRoyalMail(id, requestedType), search: filter }
            : userRoutesAdvanced.userSaleRoyalMail(id, requestedType);
    },
    userFinancialsWithFilters: function (filter) { return filter ? { pathname: routes.userFinancials(), search: filter } : routes.userFinancials(); },
    createUserWorkItem: function (basePath, type, id) {
        if (type === void 0) { type = ':witype'; }
        if (id === void 0) { id = ':biid?'; }
        return "" + basePath + (basePath.endsWith('/') ? '' : '/') + "wicreate/" + type + "/" + id;
    },
    revertCreateUserWorkItem: function (path) { return path.replace(/\/wicreate\/.*$/, ''); },
    createUserWorkItemFilter: function (basePath, type, id, search) {
        if (type === void 0) { type = ':witype'; }
        if (id === void 0) { id = ':biid?'; }
        return ({ pathname: routes.createUserWorkItem(basePath, type, id), search: search });
    },
    userWorkItem: function (id) {
        if (id === void 0) { id = ':id?'; }
        return userRoutes.userWorkItems() + "/" + id;
    },
};
export var routes = __assign(__assign(__assign(__assign(__assign({}, routesD), userRoutesD), userRoutes), userRoutesAdvanced), { index: function () { return '/'; }, eventWithoutType: function (id, eventName, venueCity, eventDate) {
        if (id === void 0) { id = ':id'; }
        if (eventName === void 0) { eventName = ':eventName'; }
        if (venueCity === void 0) { venueCity = ':venueCity'; }
        if (eventDate === void 0) { eventDate = ':eventDate'; }
        return "/" + eventName + "/" + venueCity + "-" + eventDate + "/" + id + "/event";
    }, event: function (id, eventName, venueCity, eventDate, transactionSide) {
        if (id === void 0) { id = ':id'; }
        if (eventName === void 0) { eventName = ':eventName'; }
        if (venueCity === void 0) { venueCity = ':venueCity'; }
        if (eventDate === void 0) { eventDate = ':eventDate'; }
        if (transactionSide === void 0) { transactionSide = ':transactionSide'; }
        return "/" + eventName + "/" + venueCity + "-" + eventDate + "/" + id + "/event/" + transactionSide;
    }, listing: function (type, eventId, listingId) {
        if (eventId === void 0) { eventId = ':id'; }
        if (listingId === void 0) { listingId = ':listingId'; }
        return "/event/" + eventId + "/" + type + "/" + listingId;
    }, listingFromPage: function (type, eventId, listingId, from, purchaseId) {
        if (eventId === void 0) { eventId = ':id'; }
        if (listingId === void 0) { listingId = ':listingId'; }
        return ({
            pathname: routes.listing(type, eventId, listingId),
            search: qs.stringify({ from: from, purchaseId: purchaseId }),
        });
    }, sellListingETickets: function (root, eventId, listingId) {
        if (root === void 0) { root = ':root(event|user-listing)'; }
        if (eventId === void 0) { eventId = ':id'; }
        if (listingId === void 0) { listingId = ':listingId'; }
        return "/" + root + "/" + eventId + "/sell/" + listingId + "/etickets";
    }, reservation: function (type, page) {
        if (type === void 0) { type = ':type'; }
        if (page === void 0) { page = ':page'; }
        return "/" + type + "/reservation/" + page;
    }, transactionConfirmation: function (type, id) {
        if (id === void 0) { id = ':id'; }
        return "/" + type + "/transaction/" + id + "}";
    }, transactionConfirmationWithParams: function (type, id, params) {
        if (id === void 0) { id = ':id'; }
        return params
            ? { pathname: routes.transactionConfirmation(type, id), search: qs.stringify(params) }
            : routes.transactionConfirmation(type, id);
    }, performer: function (id, performerName, city, buyOnly) {
        if (id === void 0) { id = ':id'; }
        if (performerName === void 0) { performerName = ':performerName'; }
        return "/" + performerName + "-tickets/" + id + "/performer" + (city ? "/" + city : '') + (buyOnly ? "/" + buyOnly : '');
    }, venue: function (venueName, id) {
        if (venueName === void 0) { venueName = ':venueName'; }
        if (id === void 0) { id = ':id'; }
        return "/" + venueName + "/" + id + "/venue";
    }, login: function (reason) { return "/login" + (reason != null ? "/" + reason : ''); }, logout: function (reason) { return "/logout" + (reason != null ? "/" + reason : ''); }, register: function (reason) { return "/register" + (reason != null ? "/" + reason : ''); }, resetPassword: function () { return '/requestResetPassword'; }, resetPasswordSent: function () { return '/requestResetPassword/sent'; }, resetPasswordAction: function () { return '/resetPassword'; }, setPassword: function () { return '/request-set-password'; }, setPasswordSent: function () { return '/set-password/sent'; }, setPasswordAction: function () { return '/set-password'; }, setPasswordActionWithParams: function (token, email, next) { return ({ pathname: routes.setPasswordAction(), search: qs.stringify({ token: token, email: email, next: stringifyLocationDescriptor(next) }) }); }, search: function (phrase) {
        if (phrase === void 0) { phrase = ':phrase'; }
        return (phrase === ':phrase' ? '/search' : "/search?q=" + phrase);
    }, newEventRequest: function (basePath) { return basePath + "/requestEvent"; }, revertNewEventRequest: function (path) { return path.replace(/\/requestEvent$/, ''); }, newEventRequestWithFilter: function (basePath, search) { return ({ pathname: routes.newEventRequest(basePath), search: search }); }, country: function (countryId) {
        if (countryId === void 0) { countryId = ':countryId'; }
        return "/" + countryId + "-tickets/location";
    }, city: function (countryId, cityId) {
        if (countryId === void 0) { countryId = ':countryId'; }
        if (cityId === void 0) { cityId = ':city'; }
        return "/" + countryId + "/" + cityId + "-tickets/location";
    }, category: function (categoryId, city) {
        if (categoryId === void 0) { categoryId = ':categoryId'; }
        return "/" + categoryId + "-tickets/category" + (city ? "/" + city : '');
    }, subcategory: function (categoryId, subcategoryId, city) {
        if (categoryId === void 0) { categoryId = ':categoryId'; }
        if (subcategoryId === void 0) { subcategoryId = ':subcategoryId'; }
        return "/" + categoryId + "-tickets/" + subcategoryId + "/sub-cat" + (city ? "/" + city : '');
    }, e404: function () { return '/404'; }, terms: function () { return '/terms'; }, faq: function () { return '/faq'; }, privacyPolicy: function () { return '/privacy-policy'; }, cookiesPolicy: function () { return '/cookies-policy'; }, companyDetailsPage: function () { return '/company'; }, whyUseBeeyay: function () { return '/why-use-beeyay'; }, beeyayGuarantee: function () { return '/beeyay-guarantee'; }, disputeResolutionPolicy: function () { return '/dispute-resolution-policy'; }, howToBuy: function () { return '/how-to-buy'; }, howToSell: function () { return '/how-to-sell'; }, coronavirusAdvicePage: function () { return '/coronavirus-advice-page'; }, uiDevComponents: function () { return '/ui'; }, sitemap: function () { return '/sitemap'; } });
/** location.search always starts with `?` sign. To be able to parse it corrctly, we need to remove this character */
export var getQueryString = function (l) { return l.search.slice(1); };
export var stringifyLocationDescriptor = function (ld) { return typeof ld === 'string' ? ld : ld.pathname + "?" + ld.search; };
export var defaultState = qs.stringify({ default: true });
export var genSalesFilter = function (search) { return "" + qs.stringify({ search: search }); };
export var genPurchaseFilter = function (search) { return "" + qs.stringify({ search: search }); };
export var editPageRedirections = [
    function (path) { return matchPath(path, routes.listing('sell')) && routes.userListingsWithFilters(defaultState); },
    function (path) { return matchPath(path, routes.listing('buy')) && routes.userListingsWithFilters(defaultState); },
    function (path) { return matchPath(path, routes.userProfile()) && routes.index(); },
    function (path) { return matchPath(path, routes.userPassword()) && routes.index(); },
    function (path) { return matchPath(path, routes.sellListingETickets()) && routes.userListingsWithFilters(defaultState); },
    function (path) { return matchPath(path, routes.userAddress()) && routes.userAddresses(); },
    function (path) { return matchPath(path, routes.userCard()) && routes.userCards(); },
    function (path) { return matchPath(path, routes.createUserWorkItem(':anything', ':witype', ':biid')) && routes.userWorkItems(); },
    function (path) { return matchPath(path, routes.userWorkItem()) && routes.userWorkItems(); },
];

import React, { useCallback } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import styles from './treeDropdown.less';
export function TreeDropdown(props) {
    var data = props.data, onChange = props.onChange, onNodeToggle = props.onNodeToggle;
    var handleChange = useCallback(function (currentNode, selectedNodes) {
        if (onChange) {
            onChange(currentNode, selectedNodes);
        }
    }, [onChange]);
    var handleNodeToggle = useCallback(function (currentNode) {
        if (onNodeToggle) {
            onNodeToggle(currentNode);
        }
    }, [onNodeToggle]);
    return (React.createElement(DropdownTreeSelect, { className: styles.treeDropdown, data: data, showPartiallySelected: true, onChange: handleChange, onNodeToggle: handleNodeToggle }));
}

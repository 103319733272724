import React, { useRef } from 'react';
import styles from './topEvents.less';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Image } from '@components/image/image';
import { venueInfoToDescription } from '@logic/performer';
import { routes } from 'src/client/routing/routes';
import { Link } from 'react-router-dom';
import { Images } from '@logic/images';
import { Icon } from '@components/icon';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { InfiniteScrollLoop } from '@components/infiniteScrollLoop/infiniteScrollLoop';
// naming its messed up a bit.
// This section should contain top events, but do promoted Performers instead
// It's temprorary solution till backend will support top events properly.
export var TopEvents = function () {
    var _a;
    var media = useViewportInfo().media;
    var isMobile = isLikeMobile(media);
    var topPerformer = useSelector(function (gs) { return gs.performer.promotedPerformers; });
    var topEventsRef = useRef();
    var performerElementWidth = 256;
    var scrollBy = function (scrollOffset) {
        return topEventsRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    };
    return (React.createElement("div", { className: styles.topEvents },
        React.createElement("h1", null, "Top Events"),
        React.createElement(InfiniteScrollLoop, { scrollRef: topEventsRef, className: cn(styles.hs, (_a = {}, _a[styles.noScrollbarOnMobile] = true, _a)), childSize: performerElementWidth, direction: 'horizontal' }, topPerformer.map(function (performer, index) { return (React.createElement(Link, { key: "trending-event2-" + index, draggable: false, to: routes.performer(performer.id, performer.slug) },
            React.createElement("div", { className: styles.item },
                performer.imageUrl &&
                    React.createElement(Image, { src: performer.imageUrl + "/" + Images.details((performer === null || performer === void 0 ? void 0 : performer.name) || ''), alt: performer.description, draggable: false }),
                React.createElement("div", { className: styles.topEventSubTitles },
                    React.createElement("div", { className: styles.eventTitle }, performer.name),
                    React.createElement("div", { className: styles.eventDescription }, venueInfoToDescription(performer)))))); })),
        !isMobile &&
            React.createElement("div", { className: styles.navButtons },
                React.createElement("div", { className: cn(styles.navButton, styles.navButtonLeft), onClick: function () { return scrollBy(-performerElementWidth); } },
                    React.createElement(Icon, { height: 16, width: 16, icon: 'arrowLeft' })),
                React.createElement("div", { className: cn(styles.navButton, styles.navButtonRight), onClick: function () { return scrollBy(performerElementWidth); } },
                    React.createElement(Icon, { height: 16, width: 16, icon: 'arrowRight' })))));
};

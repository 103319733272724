var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { actions } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import styles from '@containers/DetailsPages/pages/EventDetails/components/QuantityFilter/quantityFilter.less';
import { Icon } from '@components/icon';
import { usePrevious } from '@containers/hooks/usePrevious.hook';
import { useInitialValues } from '@containers/components/forms';
var SCROLL_THRESHOLD = 90;
var MAX_FILTER_VALUE = 50;
export var QuantityFilter = function (props) {
    var _a;
    var filterRef = useRef(null);
    var prevPropsRef = useRef();
    prevPropsRef.current = usePrevious(props);
    useEffect(function () {
        var _a;
        var filterValue = props.filterValue, notifyRequireFilter = props.notifyRequireFilter, transactionSide = props.transactionSide;
        if (filterValue === 'any' &&
            transactionSide === 'buy' &&
            notifyRequireFilter &&
            notifyRequireFilter !== ((_a = prevPropsRef === null || prevPropsRef === void 0 ? void 0 : prevPropsRef.current) === null || _a === void 0 ? void 0 : _a.notifyRequireFilter) &&
            filterRef.current) {
            var top = filterRef.current.getBoundingClientRect().top;
            if (top < SCROLL_THRESHOLD) {
                filterRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [filterRef, props]);
    var notifyRequireFilter = props.notifyRequireFilter, transactionSide = props.transactionSide, buyOnly = props.buyOnly, dispatch = props.dispatch, sellListings = props.sellListings;
    var listings = useInitialValues(sellListings, [transactionSide]);
    var filterValueDefault = transactionSide === 'buy' ? ((listings === null || listings === void 0 ? void 0 : listings.some((function (sellListing) { return sellListing.treadableQuantities.includes(2); }))) ? 2 : 'any') : 'any';
    var _b = __read(useState(filterValueDefault), 2), filterValue = _b[0], changeFilterValue = _b[1];
    var notificationClass = notifyRequireFilter && transactionSide === 'buy'
        ? "notification" + notifyRequireFilter % 2
        : '';
    var onClickIcon = function (icon, filterValue) {
        if (icon === 'plus' && (filterValue === 'any' || filterValue < MAX_FILTER_VALUE)) {
            filterValue === 'any' ? changeFilterValue(1) : changeFilterValue(filterValue + 1);
        }
        else if (icon === 'minus' && filterValue !== 'any') {
            filterValue === 1 ? changeFilterValue('any') : changeFilterValue(filterValue - 1);
        }
    };
    useEffect(function () { return dispatch(actions.changeFilter(filterValue)); }, [dispatch, filterValue]);
    useEffect(function () { return changeFilterValue(filterValueDefault); }, [filterValueDefault, transactionSide]);
    return (React.createElement("div", { className: cn(styles.quantityFilterWrapper, (_a = {},
            _a[styles.buyOnly] = buyOnly,
            _a)) },
        React.createElement("div", { className: cn(styles.quantityFilter, notificationClass), ref: filterRef },
            React.createElement("p", null, transactionSide === 'buy'
                ? 'How many tickets are you buying?'
                : 'How many tickets are you selling?'),
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'ticketsAmount' },
                    React.createElement(Icon, { icon: 'ico-minus', className: "" + (filterValue === 'any' ? 'disabledIcon' : ''), "data-test": 'QuantityFilter-minus', onClick: function () { return typeof filterValue === 'number' && filterValue > 0 && onClickIcon('minus', filterValue); } }),
                    React.createElement("div", null,
                        React.createElement("p", { "data-test": 'QuantityFilter-filterValue' }, filterValue === 'any' ? 'Any' : filterValue)),
                    " ",
                    React.createElement(Icon, { icon: 'ico-plus', className: "" + (filterValue === MAX_FILTER_VALUE ? 'disabledIcon' : ''), "data-test": 'QuantityFilter-plus', onClick: function () { return onClickIcon('plus', filterValue); } }))))));
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { ControlledGenericTable, createHeader } from '@components/genericTable';
import { TicketIcon } from '@components/icon';
import { formatPrice } from '@logic/helpers/currency';
import { stringifyNormalizedSeatingSectionAndRowInfo } from '@logic/seatingInfo';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import React from 'react';
import styles from './marketData.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
var empty = [];
export var DateColumn = 'Date';
var idGetter = function (t) { return t.toString(); };
export function Transactions(_a) {
    var transactions = _a.transactions, isPending = _a.isPending, tableState = _a.tableState, onUpdateTableState = _a.onUpdateTableState;
    var media = useViewportInfo().media;
    var beforeHeader = createHeader('', function () { return ''; }, {
        className: function () { return styles.noBorder; },
        columnKey: 'before',
        centered: true,
        sort: false,
        filter: false,
    });
    var afterHeader = createHeader('', function () { return ''; }, {
        className: function () { return styles.noBorder; },
        columnKey: 'after',
        centered: true,
        sort: false,
        filter: false,
    });
    var headers = __spreadArray(__spreadArray(__spreadArray([], __read((media !== 'mobile' && media !== 'mobileLandscape' ? [beforeHeader] : []))), [
        createHeader('Ticket type', function (t) { return t; }, {
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            render: function (t) { return React.createElement(TicketIcon, { ticketType: t.ticketType, hasTicketsAssigned: t.ticketsAssigned }); },
            format: function (t) { return t.ticketType; },
            centered: true,
        }),
        // This part may be reintroduced later on
        // createHeader(DateColumn, (t) => t.orderDate, {
        //   centered: true,
        //   className: (el) => el.ownedByMe ? styles.myCell : null,
        //   format: formatDateTimeWithoutTZ,
        //   orderBy: (dt) => dt,
        // }),
        createHeader('Section', function (t) { return t; }, {
            centered: true,
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            format: function (t) { return stringifyNormalizedSeatingSectionAndRowInfo(t.seatingInfo); },
            render: function (t) {
                return React.createElement("div", null,
                    stringifyNormalizedSeatingSectionAndRowInfo(t.seatingInfo),
                    React.createElement("div", { className: styles.restrictions }, Object.keys(t.restrictions)
                        .filter(function (k) { return !!t.restrictions[k]; })
                        .map(function (k) { return capitalize(startCase(k)); })
                        .join(', ')));
            },
        }),
        createHeader('Quantity', function (t) { return t.quantity; }, {
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            centered: true,
        }),
        createHeader('Price', function (t) { return t; }, {
            format: function (_a) {
                var ticketCost = _a.ticketCost;
                return formatPrice(ticketCost);
            },
            centered: true,
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            orderBy: function (_a) {
                var ticketCost = _a.ticketCost;
                return ticketCost.value;
            },
            render: function (t) { return React.createElement("span", { className: styles.transactionPrice }, formatPrice(t.ticketCost)); },
        })
    ]), __read((media !== 'mobile' && media !== 'mobileLandscape' ? [afterHeader] : [])));
    return React.createElement(ControlledGenericTable, { tableState: tableState, onUpdateTableState: onUpdateTableState, data: transactions, filters: empty, useFiltering: false, useSorting: true, isLoading: isPending, headers: headers, rowClass: function (row) { return row.ownedByMe ? styles.myTransactionRow : null; }, omitFilteringRow: true, top: 280, stickyType: 'top', idGetter: idGetter, stickThreshold: 0 });
}

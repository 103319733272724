var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { genOptions } from '@components/forms';
import { slugifyName } from '@logic/helpers/location';
import { push } from 'connected-react-router';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { routes, BUY_ONLY } from 'src/client/routing/routes';
export var getRoute = function (c, location) {
    var sanitizedLocation = location === 'all' ? undefined : slugifyName(location);
    console.log(c);
    switch (c.variant) {
        case 'category':
            return routes.category(c.category, sanitizedLocation);
        case 'subcategory':
            return routes.subcategory(c.category, c.subcategory, sanitizedLocation);
        case 'performer':
        default:
            return routes.performer(c.id, c.slug, sanitizedLocation, c.buyOnly ? BUY_ONLY : undefined);
    }
};
export var useLocationSelector = function (config) {
    var dispatch = useDispatch();
    var handleLocationChange = function (location) {
        var route = getRoute(config, location);
        dispatch(push(route));
    };
    var locationOptions = useMemo(function () {
        var _a;
        var cities = sortBy(uniq(((_a = config.events) !== null && _a !== void 0 ? _a : []).map(function (e) { return e.venueCity; })));
        var locationOptions = genOptions.apply(void 0, __spreadArray([['all', 'All']], __read(cities.map(function (c) { return ([slugifyName(c), c]); }))));
        return locationOptions;
    }, [config.events]);
    return { handleLocationChange: handleLocationChange, locationOptions: locationOptions };
};

import { Icon } from '@components/icon';
import { formatPrice } from '@logic/helpers/currency';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/Fee/fee.less';
import cn from 'classnames';
var infoMsg = {
    buy: function (value) { return "Per ticket transaction fee including VAT. Delivey costs are not included and will be calculated based on delivery method and location. Full breakdown of total cost will be shown at next stage. Face value per ticket (excluding fees) is " + value; },
    sell: function (value) { return "Per ticket transaction fee including VAT. Full breakdown of total proceeds will be shown at next stage. Minimum face value per ticket (excluding fees) required is " + value; },
};
var handlingInfoMsg = function (value) { return "The handling fee includes the cost of securely shipping paper tickets, or supporting the technology required to safely transfer electronic tickets. Includes VAT where applicable. Face value per ticket is " + value + "."; };
export var Fee = function (_a) {
    var ownedByMe = _a.ownedByMe, faceValue = _a.faceValue, fee = _a.fee, transactionSide = _a.transactionSide, disabled = _a.disabled;
    if (!ownedByMe && transactionSide === 'buy') {
        return (React.createElement("div", { className: cn(styles.fee, { disabled: disabled }) },
            React.createElement(Popup, { trigger: React.createElement(Icon, { icon: 'info-s', width: 16, height: 16, className: cn(styles.icon, { disabled: disabled }) }), hoverable: true, content: handlingInfoMsg(formatPrice(faceValue)), basic: true }),
            fee.value > 0 ? 'Handling fee may apply' : 'Includes all fees'));
    }
    return (React.createElement("div", { className: styles.fee },
        React.createElement(Popup, { trigger: React.createElement(Icon, { icon: 'info-s', width: 16, height: 16, className: styles.icon }), hoverable: true, content: infoMsg[transactionSide](formatPrice(faceValue)), basic: true }),
        transactionSide === 'sell' ? '－' : '＋',
        " ",
        formatPrice(fee),
        " Fee"));
};

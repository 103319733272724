var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import { eventsApi } from './events';
import { listingsApi } from './listings';
import { normalizeBuySeatingInfo, normalizeSellSeatingInfo } from './seatingInfo';
export var fetchChiselInfo = createAction('fetch chisel information', function (eventId, listingId, type) { return ({
    eventId: eventId,
    listingId: listingId,
    type: type,
}); });
export var fetchChiselInfoSuccess = createAction('fetch chisel information success', function (anySectionPrice, category, section, seatingInfo, listingValue) { return ({
    anySectionPrice: anySectionPrice,
    category: category,
    section: section,
    seatingInfo: seatingInfo,
    listingValue: listingValue
}); });
export var fetchChiselInfoFailure = createAction('fetch chisel information failure');
var initilaState = {
    pending: true,
    anySectionPrice: null,
    category: null,
    section: null,
    seatingInfo: null,
    listingValue: { value: 0, currency: 'GBP' },
    counter: 0,
};
export var manualChiselReducer = createReducer((_a = {},
    _a[fetchChiselInfo.getType()] = function (s) { return (__assign(__assign({}, s), { pending: true, anySectionPrice: null, category: null, section: null, normalizedSeatingInfo: null, counter: s.counter + 1 })); },
    _a[fetchChiselInfoSuccess.getType()] = function (s, _a) {
        var anySectionPrice = _a.anySectionPrice, category = _a.category, section = _a.section, seatingInfo = _a.seatingInfo, listingValue = _a.listingValue;
        return (__assign(__assign({}, s), { pending: false, anySectionPrice: anySectionPrice,
            category: category,
            section: section,
            seatingInfo: seatingInfo,
            listingValue: listingValue, counter: s.counter + 1 }));
    },
    _a[fetchChiselInfoFailure.getType()] = function (s) { return (__assign(__assign({}, s), { pending: false, anySectionPrice: null, category: null, section: null, seatingInfo: null, counter: s.counter + 1 })); },
    _a), initilaState);
export function onFetchChiselInfo(_a) {
    var eventId, listingId, type, result, sections_1, listingValue_1, listing, listing, isAny, isCategory, isSection, bestCmpFn, chooseBest, listings, categories_1, bestAny, bestCategory, bestSection, _b, currency, e_1;
    var _c, _d;
    var payload = _a.payload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                eventId = payload.eventId, listingId = payload.listingId, type = payload.type;
                _e.label = 1;
            case 1:
                _e.trys.push([1, 8, , 10]);
                return [4 /*yield*/, call(eventsApi.getWithListings, eventId)];
            case 2:
                result = _e.sent();
                sections_1 = [];
                listingValue_1 = Number.NaN;
                if (!(type === 'buy')) return [3 /*break*/, 4];
                return [4 /*yield*/, call(listingsApi.getBuyListingById, listingId)];
            case 3:
                listing = _e.sent();
                if (listing) {
                    sections_1 = normalizeBuySeatingInfo(listing.seatingInformation);
                    listingValue_1 = (_c = listing.transactablePrice) !== null && _c !== void 0 ? _c : listing.buyPrice;
                }
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(listingsApi.getSellListingById, listingId)];
            case 5:
                listing = _e.sent();
                if (listing) {
                    sections_1 = [normalizeSellSeatingInfo(listing.seatingInformation, null)];
                    listingValue_1 = (_d = listing.transactablePrice) !== null && _d !== void 0 ? _d : listing.sellPrice;
                }
                _e.label = 6;
            case 6:
                isAny = sections_1.some(function (s) { return s.matchAny; });
                isCategory = !isAny && sections_1.every(function (s) { return s.section === ''; });
                isSection = !isAny && !isCategory;
                bestCmpFn = type === 'buy' ? Math.max : Math.min;
                chooseBest = type === 'buy'
                    ? (function (bestFound) { return listingValue_1 <= bestFound ? bestFound : Number.POSITIVE_INFINITY; })
                    : (function (bestFound) { return listingValue_1 >= bestFound ? bestFound : Number.NEGATIVE_INFINITY; });
                listings = [];
                if (type === 'buy') {
                    listings = result.buyListings.filter(function (l) { return !l.ownedByMe; }).map(function (l) { return ({
                        matchAny: l.seatingInformation.matchAny,
                        category: l.seatingInformation.categories.map(function (c) { return c.name; })[0] || '',
                        section: l.seatingInformation.categories.map(function (c) { return c.sections[0] || ''; })[0] || '',
                        price: l.buyPrice,
                    }); });
                }
                else {
                    listings = result.sellListings.filter(function (l) { return !l.ownedByMe; }).map(function (l) { return ({
                        matchAny: l.seatingInformation.category.name === 'any',
                        category: l.seatingInformation.category.name !== 'any' ? l.seatingInformation.category.name : '',
                        section: l.seatingInformation.category.name !== 'any' ? l.seatingInformation.category.section || '' : '',
                        price: l.sellPrice,
                    }); });
                }
                categories_1 = sections_1.map(function (s) { return s.category; });
                bestAny = chooseBest(bestCmpFn.apply(void 0, __spreadArray([], __read(listings.map(function (l) { return l.price; })))));
                bestCategory = chooseBest(bestCmpFn.apply(void 0, __spreadArray([], __read(listings.filter(function (l) { return categories_1.includes(l.category); }).map(function (l) { return l.price; })))));
                bestSection = chooseBest(bestCmpFn.apply(void 0, __spreadArray([], __read(listings.filter(function (l) { return sections_1.find(function (s) { return l.category === s.category && l.section === s.section; }); }).map(function (l) { return l.price; })))));
                _b = result.event.currency, currency = _b === void 0 ? 'GBP' : _b;
                return [4 /*yield*/, put(fetchChiselInfoSuccess({ currency: currency, value: bestAny }, isCategory || isSection
                        ? { currency: currency, value: bestCategory }
                        : null, isSection
                        ? { currency: currency, value: bestSection }
                        : null, sections_1, { currency: currency, value: listingValue_1 }))];
            case 7:
                _e.sent();
                return [3 /*break*/, 10];
            case 8:
                e_1 = _e.sent();
                return [4 /*yield*/, call(toast.error, 'Something went wrong')];
            case 9:
                _e.sent();
                return [2 /*return*/, put(fetchChiselInfoFailure())];
            case 10: return [2 /*return*/];
        }
    });
}
export function manualChiselSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(fetchChiselInfo.getType(), onFetchChiselInfo)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

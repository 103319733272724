var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSorting } from '../components/eventsTable/sorting';
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { t } from '@containers/helpers/tests';
import { combineEventsToCategoryAndDateTitle } from '@logic/events';
import { formatVenue, loadVenueEvents } from '@logic/venue';
import React, { useMemo } from 'react';
import { routes } from 'src/client/routing/routes';
import { JsonLd } from '@components/jsonLD';
import { Error404 } from '@containers/Error404/error404';
import { Container, withFetchData, withPageOptions } from '@containers/Page';
import { VenueSeo } from '@containers/DetailsPages/pages/seo/venueSeo';
import { NotAvailableAction } from '@components/notAvailableAction/notAvailableAction';
import styles from '@containers/DetailsPages/sharedStyles.less';
import { LastNextEventComponent } from '@containers/DetailsPages/components/lastNextEvent/lastNextEvent.component';
import { DetailsPageNavigation } from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation';
import { EventsTable } from '@containers/DetailsPages/components/eventsTable/eventsTable';
import { Biography } from '../components/biography/biography';
import { getImage } from '@containers/DetailsPages/utils';
import { NoDataMessage } from '../components/eventsTable/utils/utilsComponents';
import { PageSectionEnum, useScrollTo } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { DetailsPageOptions } from '@containers/DetailsPages/constants';
import { fetchData, useDetailPageBase } from '@containers/DetailsPages/hooks/useDetailsPageBase.hook';
import { createVenueBreadcrumb } from '@containers/DetailsPages/breadcrumbFactory.utils';
import { Breadcrumb, EMPTY_BREADCRUMB } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { DetailsHeader } from '@containers/DetailsPages/components/detailsHeader/detailsHeader';
import { GoogleMap } from '@containers/DetailsPages/components/googleMap/googleMap';
import { codeToCountry } from '@logic/helpers/enumerators';
import { Faq } from '@containers/DetailsPages/components/faq/faq';
import { MoreResultsButton } from '@containers/components/moreResultsButton/moreResultsButton';
import { SANE_LIMIT } from '@logic/helpers/request';
export var VenueDetails = function () {
    var _a, _b, _c;
    var locations = useSelector(function (gs) { return gs.locations.locations; });
    var _d = useDetailPageBase('venue'), details = _d.details, detailsLoading = _d.detailsLoading, eventsIsLoading = _d.eventsIsLoading, events = _d.events, lastNextEvent = _d.lastNextEvent, eventsCount = _d.eventsCount, searchParams = _d.searchParams;
    var sortedEvents = useSorting(events).results;
    var detailsHeaderSubtitle = combineEventsToCategoryAndDateTitle(events);
    var _e = useScrollTo(), bioRef = _e.bioRef, eventsRef = _e.eventsRef, executeScroll = _e.executeScroll, faqRef = _e.faqRef;
    var dispatch = useDispatch();
    var breadcrumb = useMemo(function () { return details && locations
        ? createVenueBreadcrumb(details, locations)
        : EMPTY_BREADCRUMB; }, [details, locations]);
    var headerDetails = useMemo(function () {
        if (!details) {
            return undefined;
        }
        return __assign(__assign({}, details), { name: formatVenue(details) });
    }, [details]);
    if (!details) {
        return detailsLoading
            ? React.createElement(PleaseWait, null)
            : React.createElement(Error404, null);
    }
    var googleMapData = { venueName: details.name, placeId: details.address.placeId, country: codeToCountry[details.address.countryCode], city: details.address.city };
    var onMoreResultsClick = function () {
        return dispatch(loadVenueEvents(details.id, (searchParams.offset || 0) + SANE_LIMIT));
    };
    switch (details.status) {
        case 'cancelled':
            return React.createElement(Error404, null);
        case 'suspended':
            return (React.createElement(React.Fragment, null,
                React.createElement(VenueSeo, { details: details, events: events, eventsLoading: !!eventsIsLoading }),
                React.createElement(DetailsHeader, __assign({ detailsSubtitle: detailsHeaderSubtitle, details: headerDetails }, t('Venue'))),
                React.createElement(NotAvailableAction, { topMargin: true, message: 'This venue is currently under review.', details: 'We\'re working on some small changes to this venue. Please try again later, or return Home.', to: routes.index(), actionText: 'Go back Home' })));
        case 'active':
            return (React.createElement(React.Fragment, null,
                React.createElement(VenueSeo, { details: details, events: events, eventsLoading: !!eventsIsLoading }),
                React.createElement(JsonLd, { events: events, breadcrumbJsonLd: (_a = breadcrumb.breadcrumbPaths) === null || _a === void 0 ? void 0 : _a.jsonLD, faqs: details.faqs }),
                React.createElement(DetailsHeader, __assign({ details: headerDetails }, t('Venue'), { detailsSubtitle: detailsHeaderSubtitle, breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }), scrollToCallback: function () { return executeScroll(PageSectionEnum.Bio); } })),
                React.createElement(DetailsPageNavigation, { bioHeader: PageSectionEnum.About, details: headerDetails, isFullWide: false, scrollTo: executeScroll, hasFAQs: !!((_b = details === null || details === void 0 ? void 0 : details.faqs) === null || _b === void 0 ? void 0 : _b.length) }),
                React.createElement(Container, { className: styles.simplifiedContainer },
                    React.createElement("div", null,
                        React.createElement(EventsTable, { sortingHeader: React.createElement("h1", { ref: eventsRef }, "Events"), events: sortedEvents, eventsIsLoading: eventsIsLoading, tableVariant: 'simple', getImage: getImage, noDataMessage: React.createElement(NoDataMessage, { baseRoute: routes.venue() }) }),
                        React.createElement(MoreResultsButton, { onMoreResultsClick: onMoreResultsClick, events: events, eventsCount: eventsCount })),
                    React.createElement(Biography, { details: details, bioRef: bioRef }),
                    React.createElement(GoogleMap, { data: googleMapData, zoom: 16 }),
                    React.createElement(LastNextEventComponent, { lastNextEvent: lastNextEvent }),
                    ((_c = details === null || details === void 0 ? void 0 : details.faqs) === null || _c === void 0 ? void 0 : _c.length) ? React.createElement(Faq, { data: details.faqs, faqRef: faqRef }) : null)));
    }
};
export default withPageOptions(DetailsPageOptions)(withFetchData(fetchData('venue'))(VenueDetails));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button } from '@components/forms';
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import React, { useCallback, useEffect, useState } from 'react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/CreateFrame/createFrame.less';
import { PushableImage } from '@containers/PushableImage';
export function CreateFrame(_a) {
    var _b;
    var type = _a.type, listingsCount = _a.listingsCount, filterValue = _a.filterValue, onListingNew = _a.onListingNew;
    var handleNew = useCallback(function () { return onListingNew(type); }, [type, onListingNew]);
    var _c = __read(useState(0), 2), noListingsCount = _c[0], updateNoListingsCount = _c[1];
    useEffect(function () { return updateNoListingsCount(function (n) { return n + 1; }); }, [filterValue]);
    return (React.createElement("div", { className: cn(styles.createFrame, (_b = {},
            _b["notification" + noListingsCount % 2] = !listingsCount,
            _b)) },
        React.createElement("div", { className: 'blackBox' }),
        React.createElement(PushableImage, { className: 'backgroundImage', src: '/listing-box.jpeg', alt: 'Can\'t find the tickets' }),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'header' }, type === 'buy' ?
                React.createElement("div", { className: 'title' }, "Can't find the tickets you are looking for?") :
                React.createElement("div", { className: 'title' }, "Can't find a buyer for your tickets?")),
            React.createElement("div", { className: 'actions' },
                React.createElement(Button, __assign({}, t("Listing-" + type + "-new"), { className: cn('createButton', type), onClick: handleNew }),
                    "Create ",
                    capitalize(type),
                    " Listing")))));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { getEventMainPerformer, mapEventToEventSearch } from '@logic/events';
import { routes } from 'src/client/routing/routes';
import { formatDate } from '@logic/helpers/date';
import { NotAvailableAction } from '@components/notAvailableAction';
import { JsonLd } from '@components/jsonLD';
import { Breadcrumb } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { EventSeo } from '@containers/DetailsPages/pages/seo/eventSeo';
import { DetailsEventHeader } from '@containers/DetailsPages/components/detailsHeader/detailsEventHeader';
import { t } from '@containers/helpers/tests';
export var PastEventDetails = function (_a) {
    var _b;
    var event = _a.event, buyOnly = _a.buyOnly, breadcrumb = _a.breadcrumb;
    var performer = getEventMainPerformer(event);
    var title = performer.name + " Tickets - " + event.venue.address.city + " - " + formatDate(event.eventDate, event.timezone) + " - this event has already happend";
    var description = performer.name + " at " + event.venue.name + "  - this event has already happened. Search for other " + performer.name + " tickets on Beeyay today. Beeyay is an innovative marketplace for fans to buy and sell tickets. No gimmicks, no pressure selling, just complete transparency. All purchases 100% protected by the Beeyay Guarantee.";
    return (React.createElement(React.Fragment, null,
        React.createElement(EventSeo, { event: event, buyOnly: buyOnly, transactionSide: 'buy', seoTitle: title, seoDescription: description }),
        React.createElement(JsonLd, { events: [mapEventToEventSearch(event, null)], breadcrumbJsonLd: (_b = breadcrumb.breadcrumbPaths) === null || _b === void 0 ? void 0 : _b.jsonLD }),
        React.createElement(DetailsEventHeader, __assign({ event: event, containerSize: 'wide', buyOnly: buyOnly }, t('EventDetailsHeader'), { breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }) })),
        React.createElement(NotAvailableAction, { message: 'Oops - this event has already happened!', details: 'It looks like you missed this one. Click the link below to make sure you don\'t miss out next time!', to: routes.performer(performer.id, performer.slug), actionText: "All " + event.performers[0].name + " Tickets" })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Link } from '@components/forms';
import { PushableImage } from '@containers/PushableImage';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { routes } from 'src/client/routing/routes';
import Footer from '../Footer';
import Navigation from '../Navigation';
import Badge from './beeyay-badge.png';
import styles from './page.less';
import { Remaining } from './remaining';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isPathAllowedToWorkWithReservationPopup, PresentReservationPopup } from '@containers/PresentReservationPopup/presentReservationPopup';
import { useUserAgent } from '@components/useUserAgent';
export var Page = function (_a) {
    var _b;
    var _c;
    var className = _a.className, search = _a.search, children = _a.children, containerSize = _a.containerSize, extraNavElement = _a.extraNavElement, hideMenu = _a.hideMenu, hideFooter = _a.hideFooter, showSecondaryMarketplaceBanner = _a.showSecondaryMarketplaceBanner, showBeeyayGuarantee = _a.showBeeyayGuarantee, hideFooterMargin = _a.hideFooterMargin, noMargin = _a.noMargin, showBackToReservation = _a.showBackToReservation, showCategoriesNavigation = _a.showCategoriesNavigation;
    var location = useLocation();
    var isBot = useUserAgent().isBot;
    var media = useViewportInfo().media;
    var _d = __read(useState(false), 2), showReservationPopup = _d[0], setShowReservationPopup = _d[1];
    var bannerAboveContainer = media === 'largeScreen' || media === 'superLarge';
    var reservation = useSelector(function (gs) { return gs.reservations.reservation; });
    var _e = __read(useSelector(function (gs) { return gs.reservations.reservationState; }), 2), reservationId = _e[0], reservationUrl = _e[1];
    var showCancelButton = showBackToReservation
        && !!reservation
        && (media === 'mobile' || media === 'mobileLandscape');
    useEffect(function () {
        var _a, _b;
        // trigger reservationPopup if path doesn't match reservation url and reservation endtime is greater than current date
        setShowReservationPopup(!isPathAllowedToWorkWithReservationPopup(location.pathname) && ((_b = (_a = reservation === null || reservation === void 0 ? void 0 : reservation.endTime) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) > Date.now());
        setTimeout(function () {
            var elem = document.getElementById(location.hash.slice(1));
            elem === null || elem === void 0 ? void 0 : elem.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }, [reservation, location]);
    var url = reservationId === (reservation === null || reservation === void 0 ? void 0 : reservation.id) && reservationUrl
        ? reservationUrl
        : routes.reservation((_c = reservation === null || reservation === void 0 ? void 0 : reservation.type) !== null && _c !== void 0 ? _c : 'buy', 'summary');
    return (React.createElement(React.Fragment, null,
        showReservationPopup && !isBot && React.createElement(PresentReservationPopup, null),
        React.createElement(Navigation, { search: search === false ? false : search || true, extraNavElement: extraNavElement || React.createElement(Remaining, null), hideMenu: hideMenu, showCategoriesNavigation: showCategoriesNavigation }),
        showBeeyayGuarantee &&
            React.createElement("div", { className: styles.beeyayGurantee },
                React.createElement("div", null,
                    React.createElement("div", null,
                        "Your purchase is covered by",
                        React.createElement("br", null),
                        "the ",
                        React.createElement(Link, { className: styles.guaranteeLink, medium: true, to: routes.beeyayGuarantee() }, "Beeyay Guarantee")),
                    React.createElement(PushableImage, { src: Badge }))),
        React.createElement("div", { className: styles.contentWrapper },
            !!showCancelButton && React.createElement(Link, { className: styles.cancelButton, to: url }, "x Close"),
            React.createElement(Container, { size: containerSize, className: cn(styles.pageContainer, styles.mobileWithoutSearch, (_b = {},
                    _b[styles.noTopMargin] = !!noMargin,
                    _b[styles.withMargin] = containerSize && containerSize !== 'full',
                    _b[styles.withMinimalMargin] = containerSize === 'full' && !(showSecondaryMarketplaceBanner && !bannerAboveContainer),
                    _b[styles.showCancelButton] = showCancelButton,
                    _b[styles.withCategoriesNavigation] = showCategoriesNavigation,
                    _b), className) }, children),
            !hideFooter && React.createElement(Footer, { hideFooterMargin: hideFooterMargin }))));
};
export function withFetchData(fetchData) {
    return function (component) {
        component.fetchData = fetchData;
        return component;
    };
}
export function withPageOptions(options) {
    return function (Wrapped) {
        var wrapper = function (props) { return (React.createElement(Page, __assign({}, options),
            React.createElement(Wrapped, __assign({}, props)))); };
        wrapper.WrappedComponent = Wrapped;
        return wrapper;
    };
}
export var withPage = withPageOptions(Object.freeze({}));
export var Container = function (_a) {
    var _b;
    var children = _a.children, className = _a.className, _c = _a.size, size = _c === void 0 ? 'normal' : _c, rest = __rest(_a, ["children", "className", "size"]);
    return (React.createElement("div", __assign({ className: cn((_b = {},
            _b[styles.container] = size === 'normal',
            _b[styles.containerWide] = size === 'wide',
            _b[styles.full] = size === 'full',
            _b), className) }, rest), children));
};

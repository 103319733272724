import { getSelectedRestrictions, restrictionsNames } from '@logic/listings';
import cn from 'classnames';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/RestrictionsInfo/restrictionsInfo.less';
var format = function (r) { return restrictionsNames[r]; };
export function RestrictionsInfo(_a) {
    var restrictions = _a.restrictions, className = _a.className;
    var selectedRestrictions = getSelectedRestrictions(restrictions);
    if (selectedRestrictions.length === 0) {
        return null;
    }
    var classes = cn(styles.restrictions, className);
    if (selectedRestrictions.length === 1) {
        return React.createElement("div", { className: classes }, format(selectedRestrictions[0]));
    }
    var content = (React.createElement("ul", null, selectedRestrictions.map(function (restr) { return React.createElement("li", { key: restr }, format(restr)); })));
    return (React.createElement("div", null,
        React.createElement(Popup, { content: content, hoverable: true, trigger: React.createElement("div", { className: classes }, "Ticket restrictions on use or view") })));
}

import { loadLastNextPerformerEvent, loadPerformerDetails, loadPerformerEvents, setPerformerSSRFlag } from '@logic/performer';
import { loadLastNextVenueEvent, loadVenueDetails, loadVenueEvents, setVenueSSRFlag } from '@logic/venue';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePrevious } from '@containers/hooks/usePrevious.hook';
import { getSlug } from '@containers/DetailsPages/utils';
var VenueSelector = function () {
    var _a = useSelector(function (gs) { return gs.venue; }), details = _a.venueDetails, detailsLoading = _a.isLoadingDetails, events = _a.venueEvents, eventsIsLoading = _a.isLoadingEvents, lastNextEvent = _a.venueLastNextEvent, loadedWithSSR = _a.loadedWithSSR, searchParams = _a.searchParams, eventsCount = _a.eventsCount;
    return { details: details, detailsLoading: detailsLoading, eventsIsLoading: eventsIsLoading, events: events, lastNextEvent: lastNextEvent, searchParams: searchParams, eventsCount: eventsCount, loadedWithSSR: loadedWithSSR };
};
var PerformerSelector = function () {
    var _a = useSelector(function (gs) { return gs.performer; }), details = _a.performerDetails, lastNextEvent = _a.performerLastNextEvent, detailsLoading = _a.isLoadingDetails, events = _a.performerEvents, eventsIsLoading = _a.isLoadingEvents, loadedWithSSR = _a.loadedWithSSR, searchParams = _a.searchParams, eventsCount = _a.eventsCount;
    return { details: details, detailsLoading: detailsLoading, eventsIsLoading: eventsIsLoading, events: events, lastNextEvent: lastNextEvent, searchParams: searchParams, eventsCount: eventsCount, loadedWithSSR: loadedWithSSR };
};
var selectors = {
    performer: PerformerSelector,
    venue: VenueSelector
};
export var loadDataActions = {
    performer: [loadPerformerDetails, loadPerformerEvents, loadLastNextPerformerEvent],
    venue: [loadVenueDetails, loadVenueEvents, loadLastNextVenueEvent],
};
export var ssrFlagActions = {
    performer: setPerformerSSRFlag,
    venue: setVenueSSRFlag
};
export var fetchData = function (pageVariant) { return function (_, dispatch, match, isSSR) {
    var _a;
    var id = Number((_a = match.params) === null || _a === void 0 ? void 0 : _a.id);
    if (isNaN(id))
        return;
    loadDataActions[pageVariant].forEach(function (action) { return dispatch(action(id, 0)); });
    if (isSSR) {
        dispatch(ssrFlagActions[pageVariant](true));
    }
}; };
var useDetailsPageSelector = function (key) {
    return selectors[key]();
};
export var useDetailPageBase = function (pageVariant) {
    var dispatch = useDispatch();
    var match = useRouteMatch();
    var id = getSlug(match);
    var _a = useDetailsPageSelector(pageVariant), details = _a.details, detailsLoading = _a.detailsLoading, eventsIsLoading = _a.eventsIsLoading, events = _a.events, lastNextEvent = _a.lastNextEvent, searchParams = _a.searchParams, eventsCount = _a.eventsCount, loadedWithSSR = _a.loadedWithSSR;
    var prevId = usePrevious(id);
    useEffect(function () {
        if (loadedWithSSR) {
            dispatch(ssrFlagActions[pageVariant](false));
            return;
        }
        if (id !== prevId) {
            loadDataActions[pageVariant].forEach(function (action) { return dispatch(action(id, 0)); });
        }
    }, [id, prevId, dispatch, pageVariant, details, loadedWithSSR]);
    return { details: details, lastNextEvent: lastNextEvent, detailsLoading: detailsLoading, events: events, eventsIsLoading: eventsIsLoading, eventsCount: eventsCount, searchParams: searchParams };
};

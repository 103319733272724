var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import request, { SANE_LIMIT } from '@logic/helpers/request';
import { eventsApi, rawEventSearchToEventSearch, rawLastNextEventToLastNextEvent } from '@logic/events';
import { slugifyName } from '@logic/helpers/location';
import { toast } from 'react-toastify';
import get from 'lodash/get';
export var findCategoryDetails = function (categories, categoryId) {
    var foundCategoryDetails = categories.find(function (category) { return category.localId === categoryId; });
    return foundCategoryDetails !== null && foundCategoryDetails !== void 0 ? foundCategoryDetails : null;
};
export var getCategorySearchParams = function (categoryDetails, subcategoryDetails) {
    if (!categoryDetails) {
        return { category: [] };
    }
    if (subcategoryDetails) {
        // fetch specific category events
        return {
            category: [{
                    category: categoryDetails.name,
                    subcategories: [subcategoryDetails.name]
                }
            ]
        };
    }
    else {
        // fetch all events
        return {
            category: [{
                    category: categoryDetails.name,
                    subcategories: []
                }]
        };
    }
};
export var CATEGORY_TYPES;
(function (CATEGORY_TYPES) {
    CATEGORY_TYPES["concert"] = "Concert";
    CATEGORY_TYPES["festival"] = "Festival";
    CATEGORY_TYPES["sport"] = "Sport";
    CATEGORY_TYPES["theatre_and_comedy"] = "Theatre & Comedy";
})(CATEGORY_TYPES || (CATEGORY_TYPES = {}));
export var rawCategoriesToCategories = function (rawCategories) {
    return rawCategories
        .map(function (rawCategory) { return (__assign(__assign({}, rawCategory), { subcategories: rawCategory.subcategories.map(function (rawSubcategory) { return (__assign(__assign({}, rawSubcategory), { localId: slugifyName(rawSubcategory.name) })); }), localId: slugifyName(rawCategory.name) })); } // needs to be fixed
    );
};
var initialState = {
    isLoadingEvents: true,
    isLoadingCategories: true,
    isLoadingDetails: true,
    categories: [],
    categoryDetails: null,
    subcategoryDetails: null,
    searchParams: {
        buyNow: false,
        dateFrom: new Date(),
        limit: SANE_LIMIT,
        orderBy: 'eventDate',
        category: [],
        orderByDesc: false,
        sellNow: false,
        offset: 0,
    },
    categoryEvents: [],
    eventsCount: 0,
    categoryLastNextEvent: null,
    shouldTriggerLoadEvents: false,
    loadedWithSSR: false
};
export var searchParamsSelect = function (s) { return s.categories.searchParams; };
export var categoriesSelect = function (s) { return s.categories.categories; };
export var categoriesApi = {
    getCategoriesTree: function () { return request('/api/portalCategories'); }
};
export var fetchCategories = createAction('fetch initial categories');
export var fetchCategoriesSuccess = createAction('fetch initial categories succeeded', function (categories) { return ({ categories: categories }); });
export var fetchCategoriesFailure = createAction('fetch initial categories failed', function (e) { return e; });
export var loadCategoryDetails = createAction('load category details', function (categoryId, subcategoryId) { return ({ categoryId: categoryId, subcategoryId: subcategoryId }); });
export var loadCategoryDetailsSuccess = createAction('load category details succeeded', function (category, subcategory) { return ({ category: category, subcategory: subcategory }); });
export var loadCategoryDetailsFailure = createAction('load category details failed', function (e) { return e; });
export var loadLastNextCategoryEvent = createAction('load last next category event', function (categoryId, subcategoryId) { return ({ categoryId: categoryId, subcategoryId: subcategoryId }); });
export var lastNextCategoryEventLoaded = createAction('fetching last next category event succeed', function (lastNextEvent) { return lastNextEvent; });
export var lastNextCategoryLoadingFailed = createAction('fetching last next category event failed', function (error) { return ({ error: error }); });
export var fetchCategoryEvents = createAction('fetch category events', function (payload) { return (payload); });
export var fetchCategoryEventsSuccess = createAction('fetch category events succeeded', function (result) { return ({ events: result.events, count: result.count, searchParams: result.searchParams }); });
export var fetchCategoryEventsFailure = createAction('fetch category events failed', function (e) { return e; });
export var removeCategoryDetails = createAction('remove category details');
export var loadFullCategoriesData = createAction('load full ssr data', function (_a) {
    var categoryId = _a.categoryId, subcategoryId = _a.subcategoryId;
    return ({ categoryId: categoryId, subcategoryId: subcategoryId });
});
export var setCategoriesSSRFlag = createAction('set categories SSR flag', function (flag) { return ({ flag: flag }); });
export var categoriesReducer = createReducer((_a = {},
    _a[fetchCategories.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingCategories: true })); },
    _a[fetchCategoriesSuccess.getType()] = function (state, payload) { return (__assign(__assign({}, state), { isLoadingCategories: false, categories: payload.categories })); },
    _a[fetchCategoriesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingCategories: false, categories: [] })); },
    _a[fetchCategoryEvents.getType()] = function (state) {
        return (__assign(__assign({}, state), { isLoadingEvents: true }));
    },
    _a[fetchCategoryEventsSuccess.getType()] = function (state, payload) {
        var _a, _b, _c, _d, _e, _f, _g;
        var isTheSameCategory = ((_b = (_a = payload.searchParams) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.length) && ((_c = payload.searchParams.category[0]) === null || _c === void 0 ? void 0 : _c.category) === ((_d = state.categoryDetails) === null || _d === void 0 ? void 0 : _d.name);
        var isTheSameSubcategory = ((_f = (_e = payload.searchParams) === null || _e === void 0 ? void 0 : _e.category) === null || _f === void 0 ? void 0 : _f.length) && (payload.searchParams.category[0].subcategories[0] === ((_g = state.subcategoryDetails) === null || _g === void 0 ? void 0 : _g.name) || (!payload.searchParams.category[0].subcategories.length) && !state.subcategoryDetails);
        return (__assign(__assign({}, state), { categoryEvents: isTheSameCategory && isTheSameSubcategory ? __spreadArray(__spreadArray([], __read(state.categoryEvents)), __read(payload.events)) : payload.events, eventsCount: payload.count, isLoadingEvents: false, shouldTriggerLoadEvents: false, searchParams: __assign(__assign({}, state.searchParams), payload.searchParams) }));
    },
    _a[fetchCategoryEventsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { categoryEvents: [], isLoadingEvents: false, shouldTriggerLoadEvents: false })); },
    _a[removeCategoryDetails.getType()] = function (state) { return (__assign(__assign({}, state), { categoryDetails: null, subcategoryDetails: null })); },
    _a[loadCategoryDetails.getType()] = function (state) { return (__assign(__assign({}, state), { categoryDetails: null, subcategoryDetails: null, isLoadingDetails: true, categoryEvents: [] })); },
    _a[loadCategoryDetailsSuccess.getType()] = function (state, payload) { return (__assign(__assign({}, state), { categoryDetails: payload.category, subcategoryDetails: payload.subcategory, isLoadingDetails: false, shouldTriggerLoadEvents: true })); },
    _a[loadCategoryDetailsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { categoryDetails: null, subcategoryDetails: null, isLoadingDetails: false })); },
    _a[loadLastNextCategoryEvent.getType()] = function (state) { return (__assign(__assign({}, state), { categoryLastNextEvent: null })); },
    _a[lastNextCategoryEventLoaded.getType()] = function (state, lastNextEvents) {
        return __assign(__assign({}, state), { categoryLastNextEvent: lastNextEvents });
    },
    _a[lastNextCategoryLoadingFailed.getType()] = function (state) { return (__assign(__assign({}, state), { categoryLastNextEvent: null })); },
    _a[setCategoriesSSRFlag.getType()] = function (state, _a) {
        var flag = _a.flag;
        return (__assign(__assign({}, state), { loadedWithSSR: flag }));
    },
    _a), initialState);
function onInitialFetchCategories() {
    var rawCategories, categories, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(categoriesApi.getCategoriesTree)];
            case 1:
                rawCategories = (_a.sent()).categories;
                categories = rawCategoriesToCategories(rawCategories);
                return [4 /*yield*/, put(fetchCategoriesSuccess(categories))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                console.error(e_1);
                return [4 /*yield*/, put(fetchCategoriesFailure(e_1))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch categories')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function onFetchCategoryEvents(_a) {
    var params, updatedSearchParams, _b, items, count, e_2;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 4, , 7]);
                return [4 /*yield*/, select(searchParamsSelect)];
            case 1:
                params = _c.sent();
                updatedSearchParams = __assign(__assign({}, params), payload);
                return [4 /*yield*/, call(eventsApi.search, updatedSearchParams)];
            case 2:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(fetchCategoryEventsSuccess({ events: items.map(rawEventSearchToEventSearch), count: count, searchParams: updatedSearchParams }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 7];
            case 4:
                e_2 = _c.sent();
                console.error(e_2);
                return [4 /*yield*/, put(fetchCategoryEventsFailure(e_2))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch category events')];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function onLoadCategoryDetails(_a) {
    var categories, categoryDetails, subcategoryDetails, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, select(categoriesSelect)];
            case 1:
                categories = _b.sent();
                categoryDetails = findCategoryDetails(categories, payload.categoryId);
                subcategoryDetails = payload.subcategoryId && (categoryDetails === null || categoryDetails === void 0 ? void 0 : categoryDetails.subcategories)
                    ? findCategoryDetails(categoryDetails.subcategories, payload.subcategoryId)
                    : null;
                return [4 /*yield*/, put(loadCategoryDetailsSuccess(categoryDetails, subcategoryDetails))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_3 = _b.sent();
                console.error(e_3);
                return [4 /*yield*/, put(loadCategoryDetailsFailure(e_3))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load category details')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function onLoadCategoryLastNextEvent(_a) {
    var rawLastNextEvent, _b, lastNextEvent, e_4;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 10]);
                if (!payload.subcategoryId) return [3 /*break*/, 2];
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'subcategory', payload.categoryId, payload.subcategoryId)];
            case 1:
                _b = _c.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, call(eventsApi.lastNextEvent, 'category', payload.categoryId)];
            case 3:
                _b = _c.sent();
                _c.label = 4;
            case 4:
                rawLastNextEvent = _b;
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextCategoryEventLoaded(lastNextEvent))];
            case 5:
                _c.sent();
                return [3 /*break*/, 10];
            case 6:
                e_4 = _c.sent();
                return [4 /*yield*/, put(lastNextCategoryLoadingFailed(e_4))];
            case 7:
                _c.sent();
                if (!(get(e_4, 'status') !== 404)) return [3 /*break*/, 9];
                return [4 /*yield*/, call(toast.error, 'Cannot pull last and next event for Category')];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
// TODO: note, for some reasons calling actions directly from SSR saga did not trigger them properly, so i had to copy paste all source code. What my looks messy
//  However, it works ok in venue/performer, so there is a space to clean code for the future
function onLoadFullCategoriesData(_a) {
    var categoryId, subcategoryId, rawCategories, categories, categoryDetails, subcategoryDetails, rawLastNextEvent, _b, lastNextEvent, params, categoryParams, updatedSearchParams, _c, items, count, e_5;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 12, , 13]);
                categoryId = payload.categoryId, subcategoryId = payload.subcategoryId;
                return [4 /*yield*/, call(categoriesApi.getCategoriesTree)];
            case 1:
                rawCategories = (_d.sent()).categories;
                categories = rawCategoriesToCategories(rawCategories);
                categoryDetails = findCategoryDetails(categories, categoryId);
                subcategoryDetails = subcategoryId && (categoryDetails === null || categoryDetails === void 0 ? void 0 : categoryDetails.subcategories)
                    ? findCategoryDetails(categoryDetails.subcategories, subcategoryId)
                    : null;
                return [4 /*yield*/, put(loadCategoryDetailsSuccess(categoryDetails, subcategoryDetails))];
            case 2:
                _d.sent();
                if (!categoryDetails) return [3 /*break*/, 8];
                if (!subcategoryDetails) return [3 /*break*/, 4];
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'subcategory', categoryDetails.name, subcategoryDetails === null || subcategoryDetails === void 0 ? void 0 : subcategoryDetails.name)];
            case 3:
                _b = _d.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(eventsApi.lastNextEvent, 'category', categoryDetails.name)];
            case 5:
                _b = _d.sent();
                _d.label = 6;
            case 6:
                rawLastNextEvent = _b;
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextCategoryEventLoaded(lastNextEvent))];
            case 7:
                _d.sent();
                _d.label = 8;
            case 8: return [4 /*yield*/, select(searchParamsSelect)];
            case 9:
                params = _d.sent();
                categoryParams = getCategorySearchParams(categoryDetails, subcategoryDetails);
                updatedSearchParams = __assign(__assign({}, params), categoryParams);
                return [4 /*yield*/, call(eventsApi.search, updatedSearchParams)];
            case 10:
                _c = _d.sent(), items = _c.items, count = _c.count;
                return [4 /*yield*/, put(fetchCategoryEventsSuccess({ events: items.map(rawEventSearchToEventSearch), count: count, searchParams: updatedSearchParams }))];
            case 11:
                _d.sent();
                return [3 /*break*/, 13];
            case 12:
                e_5 = _d.sent();
                console.error(e_5);
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
export function categoriesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(fetchCategories.getType(), onInitialFetchCategories)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(fetchCategoryEvents.getType(), onFetchCategoryEvents)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadCategoryDetails.getType(), onLoadCategoryDetails)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadLastNextCategoryEvent.getType(), onLoadCategoryLastNextEvent)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadFullCategoriesData.getType(), onLoadFullCategoriesData)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

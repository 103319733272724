var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRouteMatch } from 'react-router-dom';
import { fetchCategoryEvents, getCategorySearchParams, loadCategoryDetails, loadFullCategoriesData, loadLastNextCategoryEvent, setCategoriesSSRFlag } from '@logic/categories';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { generateHeaderDetails } from '@containers/DetailsPages/utils';
import { usePrevious } from 'client/containers/hooks/usePrevious.hook';
export var fetchData = function (_, dispatch, match, isSSR) {
    var _a, _b;
    var categoryId = (_a = match.params) === null || _a === void 0 ? void 0 : _a.categoryId;
    var subcategoryId = (_b = match.params) === null || _b === void 0 ? void 0 : _b.subcategoryId;
    if (!categoryId)
        return;
    dispatch(loadFullCategoriesData({ categoryId: categoryId, subcategoryId: subcategoryId }));
    if (isSSR) {
        dispatch(setCategoriesSSRFlag(true));
    }
};
export var loadDataActions = [loadCategoryDetails, fetchCategoryEvents, loadLastNextCategoryEvent];
export var useDetailsCategory = function () {
    var _a = useRouteMatch().params, categoryId = _a.categoryId, subcategoryId = _a.subcategoryId;
    var dispatch = useDispatch();
    var detailsLoading = useSelector(function (gs) { return gs.categories.isLoadingDetails; });
    var eventsIsLoading = useSelector(function (gs) { return gs.categories.isLoadingEvents; });
    var events = useSelector(function (gs) { return gs.categories.categoryEvents; });
    var eventsCount = useSelector(function (gs) { return gs.categories.eventsCount; });
    var searchParams = useSelector(function (gs) { return gs.categories.searchParams; });
    var subcategoryDetails = useSelector(function (gs) { return gs.categories.subcategoryDetails; });
    var categoryDetails = useSelector(function (gs) { return gs.categories.categoryDetails; });
    var lastNextEvent = useSelector(function (gs) { return gs.categories.categoryLastNextEvent; });
    var shouldTriggerLoadEvents = useSelector(function (gs) { return gs.categories.shouldTriggerLoadEvents; });
    var loadedWithSSR = useSelector(function (gs) { return gs.categories.loadedWithSSR; });
    var headerDetails = useMemo(function () {
        return generateHeaderDetails(subcategoryDetails ? subcategoryDetails : categoryDetails);
    }, [categoryDetails, subcategoryDetails]);
    var prevCategoryId = usePrevious(categoryId);
    var prevSubcategoryId = usePrevious(subcategoryId);
    useEffect(function () {
        if (loadedWithSSR) {
            dispatch(setCategoriesSSRFlag(false));
            return;
        }
        if (categoryId !== prevCategoryId || subcategoryId !== prevSubcategoryId) {
            dispatch(loadCategoryDetails(categoryId, subcategoryId));
        }
    }, [categoryId, subcategoryId, dispatch, prevCategoryId, prevSubcategoryId, loadedWithSSR]);
    useEffect(function () {
        if (shouldTriggerLoadEvents) {
            dispatch(fetchCategoryEvents(__assign(__assign({}, getCategorySearchParams(categoryDetails, subcategoryDetails)), { offset: 0 })));
            if (categoryDetails) {
                dispatch(loadLastNextCategoryEvent(categoryDetails === null || categoryDetails === void 0 ? void 0 : categoryDetails.name, subcategoryDetails === null || subcategoryDetails === void 0 ? void 0 : subcategoryDetails.name));
            }
        }
    }, [categoryDetails, subcategoryDetails, dispatch, shouldTriggerLoadEvents]);
    return { lastNextEvent: lastNextEvent, categoryDetails: categoryDetails, subcategoryDetails: subcategoryDetails, detailsLoading: detailsLoading, eventsIsLoading: eventsIsLoading, events: events, eventsCount: eventsCount, headerDetails: headerDetails, searchParams: searchParams };
};

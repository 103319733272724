var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@containers/helpers/tests';
import get from 'lodash/get';
import React, { Component } from 'react';
import { useField, useFormState } from 'react-final-form';
import { Checkbox } from './checkbox';
import { useFirstNotNullableValue } from './useFirstNotNullableValue';
var RadioFieldBase = /** @class */ (function (_super) {
    __extends(RadioFieldBase, _super);
    function RadioFieldBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e, data) {
            var _a = _this.props, onChange = _a.onChange, radioValue = _a.radioValue, input = _a.input;
            input.onChange(radioValue);
            input.onBlur();
            if (onChange) {
                onChange(e, data);
            }
        };
        return _this;
    }
    RadioFieldBase.prototype.render = function () {
        var _a = this.props, onChange = _a.onChange, fieldApi = _a.fieldApi, radioValue = _a.radioValue, checked = _a.checked, field = _a.field, input = _a.input, rest = __rest(_a, ["onChange", "fieldApi", "radioValue", "checked", "field", "input"]);
        var currentChecked = checked != null ? checked : input.value === radioValue;
        return React.createElement(Checkbox, __assign({}, rest, { checked: currentChecked, radio: true, onChange: this.handleChange }, t(input.name + "-" + radioValue)));
    };
    return RadioFieldBase;
}(Component));
export { RadioFieldBase };
export function RadioField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var state = useFormState();
    var initialValue = useFirstNotNullableValue(function () { return get(state.values, name); });
    var field = useField(name, { initialValue: initialValue });
    return React.createElement(RadioFieldBase, __assign({}, props, field));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styles from '@containers/DetailsPages/components/eventsTable/details.less';
import React from 'react';
import cn from 'classnames';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import { SortingItem } from './index';
export var SortingRow = function (props) {
    var _a, _b, _c, _d, _e;
    var buyOnly = props.buyOnly, locationSelector = props.locationSelector, variant = props.variant, hasSortingDropdown = props.hasSortingDropdown;
    var media = useViewportInfo().media;
    var displayLocationColumn = function () {
        var _a;
        return React.createElement(React.Fragment, null, locationSelector
            ? React.createElement(SortingItem, __assign({ className: styles.locationDropdown }, props),
                " ",
                locationSelector)
            : React.createElement(React.Fragment, null,
                React.createElement(SortingItem, __assign({ className: cn(styles.date, (_a = {}, _a[styles.noPaddingLeft] = variant === 'simple', _a)), name: 'date' }, props), "Event Date"),
                React.createElement(SortingItem, __assign({ name: 'event', className: styles.image }, props), " Event")));
    };
    return (React.createElement(React.Fragment, null, isLikeMobile(media) && variant !== 'simpleWithLocationFilter'
        ? React.createElement(React.Fragment, null)
        : React.createElement("div", { className: cn(styles.sortingRow, (_a = {}, _a[styles.venueTable] = variant === 'simple', _a), (_b = {}, _b[styles.performerTable] = variant === 'simpleWithLocationFilter', _b), (_c = {}, _c[styles.advancedTable] = variant === 'advanced', _c), (_d = {}, _d[styles.withSortingDropdown] = hasSortingDropdown, _d)) },
            displayLocationColumn(),
            React.createElement(SortingItem, __assign({ className: styles.onSaleDate, name: 'onSaleDate' }, props), "On sale date"),
            React.createElement(SortingItem, __assign({ name: 'buyPrice', className: cn(styles.buyPrice, (_e = {}, _e[styles.spannedBuyPrice] = buyOnly, _e)) }, props), "Buy Tickets From"),
            !buyOnly && React.createElement(SortingItem, __assign({ className: styles.sellPrice, name: 'sellPrice' }, props), "Sell Tickets From"),
            React.createElement(SortingItem, __assign({ className: styles.marketData }, props)))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from 'classnames';
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import styles from './button.less';
export function LinkButton(_a) {
    var className = _a.className, primary = _a.primary, secondary = _a.secondary, ternary = _a.ternary, big = _a.big, as = _a.as, disabled = _a.disabled, grayedDisabled = _a.grayedDisabled, rest = __rest(_a, ["className", "primary", "secondary", "ternary", "big", "as", "disabled", "grayedDisabled"]);
    var Component = as || 'button';
    return (React.createElement(Component, __assign({ className: cn(styles.linkButton, {
            primary: primary,
            secondary: secondary,
            ternary: ternary,
            big: big,
            disabled: disabled,
            grayedDisabled: grayedDisabled,
        }, className), disabled: disabled || grayedDisabled }, rest)));
}
export function Link(_a) {
    var className = _a.className, primary = _a.primary, secondary = _a.secondary, ternary = _a.ternary, big = _a.big, asButton = _a.asButton, medium = _a.medium, disabled = _a.disabled, grayedDisabled = _a.grayedDisabled, rest = __rest(_a, ["className", "primary", "secondary", "ternary", "big", "asButton", "medium", "disabled", "grayedDisabled"]);
    var classes = cn(styles.linkButton, {
        primary: primary,
        secondary: secondary,
        ternary: ternary,
        big: big,
        asButton: asButton,
        medium: medium,
        disabled: disabled,
        grayedDisabled: grayedDisabled,
    }, className);
    return !disabled && !grayedDisabled
        ? React.createElement(RRLink, __assign({ className: classes }, rest))
        : React.createElement("div", { className: classes },
            " ",
            rest.children);
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import get from 'lodash/get';
import React, { Component } from 'react';
import { useField, useFormState } from 'react-final-form';
import { Icon, Input as SemanticUiInput } from 'semantic-ui-react';
import { useFirstNotNullableValue } from '../useFirstNotNullableValue';
import { useToggle } from '../useToggle';
import styles from './input.less';
export function Input(_a) {
    var className = _a.className, uppercase = _a.uppercase, large = _a.large, small = _a.small, superSmall = _a.superSmall, icon = _a.icon, fill = _a.fill, children = _a.children, setRef = _a.setRef, rest = __rest(_a, ["className", "uppercase", "large", "small", "superSmall", "icon", "fill", "children", "setRef"]);
    return (React.createElement(SemanticUiInput, __assign({ className: cn(styles.input, { fill: fill, uppercase: uppercase, large: large, small: small, superSmall: superSmall, noIcon: !icon && !children }, className), icon: icon, ref: setRef }, rest), children));
}
export function PasswordInput(props) {
    var _a = useToggle(false), state = _a.state, toggle = _a.toggle;
    return (React.createElement("div", { className: cn(styles.passwordInput, props.className) },
        React.createElement(Input, __assign({}, props, { type: state ? 'text' : 'password' })),
        React.createElement("a", { className: styles.showPassword, onClick: toggle },
            state ? 'hide password' : 'show password',
            " ",
            React.createElement("br", null),
            React.createElement(Icon, { name: state ? 'eye slash outline' : 'eye' }))));
}
var InputBase = /** @class */ (function (_super) {
    __extends(InputBase, _super);
    function InputBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e, data) {
            var _a = _this.props, onChange = _a.onChange, input = _a.input;
            input.onChange(data.value);
            if (onChange) {
                onChange(e, data);
            }
        };
        _this.handleOnBlur = function (e) {
            var _a = _this.props, onBlur = _a.onBlur, input = _a.input;
            input.onBlur(e);
            if (onBlur) {
                onBlur(e);
            }
        };
        return _this;
    }
    InputBase.prototype.render = function () {
        var _a = this.props, Cmp = _a.Cmp, onChange = _a.onChange, value = _a.value, error = _a.error, field = _a.field, input = _a.input, meta = _a.meta, forceValue = _a.forceValue, className = _a.className, rest = __rest(_a, ["Cmp", "onChange", "value", "error", "field", "input", "meta", "forceValue", "className"]);
        var currentValue = value != null ? value : input.value;
        return React.createElement(Cmp, __assign({}, rest, { error: meta.touched && !!meta.error, value: !currentValue && currentValue !== 0 ? '' : currentValue, onChange: this.handleChange, onBlur: this.handleOnBlur, className: cn(styles.field, className) }, t(input.name.replace('.', '-'))));
    };
    return InputBase;
}(Component));
export { InputBase };
export function InputField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var state = useFormState();
    var initialValue = useFirstNotNullableValue(function () { return get(state.values, name); });
    var field = useField(name, { initialValue: initialValue });
    return React.createElement(InputBase, __assign({}, props, field, { Cmp: Input }));
}
export function PasswordInputField(_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var state = useFormState();
    var initialValue = useFirstNotNullableValue(function () { return get(state.values, name); });
    var field = useField(name, { initialValue: initialValue });
    return React.createElement(InputBase, __assign({}, props, field, { Cmp: PasswordInput }));
}

import { EMPTY_BREADCRUMB, } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { routes } from 'src/client/routing/routes';
import { getEventMainPerformer } from '@logic/events';
import { codeToCountry } from '@logic/helpers/enumerators';
import { slugifyName } from '@logic/helpers/location';
import React from 'react';
import BreadcrumbLink from '@containers/DetailsPages/components/breadcrumb/BreadcrumbLink';
export var nameToNameWithId = function (name, slugifiedId) {
    return ({
        localId: slugifiedId ? slugifiedId : slugifyName(name),
        name: name
    });
};
export var createCategoryBreadcrumb = function (categoryDetails, subCategoryDetails) {
    var categoryWithLocalId = nameToNameWithId(categoryDetails.name);
    if (!subCategoryDetails) {
        return {
            breadcrumbPaths: {
                mobilePaths: [
                    React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: null, route: routes.index, separator: '<' })
                ],
                desktopPaths: [
                    React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                    React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [categoryWithLocalId], route: routes.category, separator: '/', extraText: 'Tickets' })
                ],
                jsonLD: [
                    { name: 'Home', route: routes.index() },
                    { name: categoryWithLocalId.name, route: routes.category(categoryWithLocalId.localId) }
                ]
            }
        };
    }
    var subcategoryWithLocalId = nameToNameWithId(subCategoryDetails.name);
    return {
        breadcrumbPaths: {
            mobilePaths: [
                React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [categoryWithLocalId], route: routes.category, separator: '<', extraText: 'Tickets' })
            ],
            desktopPaths: [
                React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [categoryWithLocalId], route: routes.category, separator: '/' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-3', values: [categoryWithLocalId, subcategoryWithLocalId], route: routes.subcategory, separator: '/', extraText: 'Tickets' })
            ],
            jsonLD: [
                { name: 'Home', route: routes.index() },
                { name: categoryWithLocalId.name, route: routes.category(categoryWithLocalId.localId) },
                { name: subcategoryWithLocalId.name + " Tickets", route: routes.subcategory(categoryWithLocalId.localId, subcategoryWithLocalId.localId), }
            ]
        }
    };
};
export var createCountryCityBreadcrumb = function (countryDetails, cityDetails) {
    var countryWithLocalId = nameToNameWithId(countryDetails.name);
    if (!cityDetails) {
        return {
            breadcrumbPaths: {
                mobilePaths: [
                    React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: null, route: routes.index, separator: '<' })
                ],
                desktopPaths: [
                    React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                    React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [countryWithLocalId], route: routes.country, separator: '/', extraText: 'Tickets' })
                ],
                jsonLD: [
                    { name: 'Home', route: routes.index() },
                    { name: countryWithLocalId.name + " Tickets", route: routes.country(countryWithLocalId.localId) },
                ]
            }
        };
    }
    var cityWithLocalId = nameToNameWithId(cityDetails.name);
    return {
        breadcrumbPaths: {
            mobilePaths: [
                React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [countryWithLocalId], route: routes.country, separator: '<', extraText: 'Tickets' })
            ],
            desktopPaths: [
                React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [countryWithLocalId], route: routes.country, separator: '/' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-3', values: [countryWithLocalId, cityWithLocalId], route: routes.city, separator: '/', extraText: 'Tickets' }),
            ],
            jsonLD: [
                { name: 'Home', route: routes.index() },
                { name: "" + countryWithLocalId.name, route: routes.country(countryWithLocalId.localId) },
                { name: cityWithLocalId.name + " Tickets", route: routes.city(countryWithLocalId.localId, cityWithLocalId.localId) },
            ]
        }
    };
};
export var createPerformerBreadcrumb = function (performer) {
    var nameWithLocalId = nameToNameWithId(performer.name, performer.slug);
    var categoryWithLocalId = nameToNameWithId(performer.category);
    var subcategoryWithLocalId = nameToNameWithId(performer.subcategory);
    return {
        breadcrumbPaths: {
            mobilePaths: [
                React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [categoryWithLocalId, subcategoryWithLocalId], route: routes.subcategory, separator: '<' })
            ],
            desktopPaths: [
                React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [categoryWithLocalId], route: routes.category, separator: '/', extraText: 'Tickets' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-3', values: [categoryWithLocalId, subcategoryWithLocalId], route: routes.subcategory, separator: '/' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-4', values: [nameWithLocalId], route: function (localId) { return routes.performer(performer.id, localId); }, separator: '/' })
            ],
            jsonLD: [
                { name: 'Home', route: routes.index() },
                { name: categoryWithLocalId.name + " Tickets", route: routes.category(categoryWithLocalId.localId) },
                { name: "" + subcategoryWithLocalId.name, route: routes.subcategory(categoryWithLocalId.localId, subcategoryWithLocalId.localId) },
                { name: "" + nameWithLocalId.name, route: routes.performer(performer.id, nameWithLocalId.localId) },
            ]
        }
    };
};
export var createEventBreadcrumb = function (event) {
    if (!event.performers.length) {
        return EMPTY_BREADCRUMB;
    }
    var performer = getEventMainPerformer(event);
    var nameWithLocalId = nameToNameWithId(performer.name, performer.slug);
    var categoryWithLocalId = nameToNameWithId(event.category);
    var subcategoryWithLocalId = nameToNameWithId(event.subcategory);
    return {
        breadcrumbPaths: {
            mobilePaths: [
                React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [nameWithLocalId], route: function (localId) { return routes.performer(performer.id, localId); }, separator: '<' })
            ],
            desktopPaths: [
                React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [categoryWithLocalId], route: routes.category, separator: '/', extraText: 'Tickets' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-3', values: [categoryWithLocalId, subcategoryWithLocalId], route: routes.subcategory, separator: '/' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-4', values: [nameWithLocalId], route: function (localId) { return routes.performer(performer.id, localId); }, separator: '/' }),
            ],
            jsonLD: [
                { name: 'Home', route: routes.index() },
                { name: categoryWithLocalId.name + " Tickets", route: routes.category(categoryWithLocalId.localId) },
                { name: "" + subcategoryWithLocalId.name, route: routes.subcategory(categoryWithLocalId.localId, subcategoryWithLocalId.localId) },
                { name: "" + nameWithLocalId.name, route: routes.performer(performer.id, nameWithLocalId.localId) },
            ]
        }
    };
};
export var createVenueBreadcrumb = function (venue, locations) {
    // check if city/country page exist
    var _a = venue.address, countryCode = _a.countryCode, city = _a.city;
    var codes = codeToCountry;
    var country = codes[countryCode];
    var cityWithLocalId = nameToNameWithId(city);
    var countryWithLocalId = nameToNameWithId(country);
    var countryPageExist = locations
        .find(function (country) { return country.localId === countryWithLocalId.localId; });
    var cityPageExist = !countryPageExist
        ? false
        : countryPageExist.cities.some(function (location) { return location.localId === cityWithLocalId.localId; });
    // create paths
    var createMobilePaths = function () {
        return cityPageExist
            ? [React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [countryWithLocalId, cityWithLocalId], route: routes.city, separator: '<' })]
            : countryPageExist
                ? [React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: [countryWithLocalId], route: routes.country, separator: '<' })]
                : [React.createElement(BreadcrumbLink, { key: 'mobile-link-1', values: null, route: routes.index, separator: '<' })];
    };
    var createDesktopPaths = function () {
        return cityPageExist
            ? [React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [countryWithLocalId], route: routes.country, separator: '/' }),
                React.createElement(BreadcrumbLink, { key: 'desktop-link-3', values: [countryWithLocalId, cityWithLocalId], route: routes.city, separator: '/', extraText: 'Tickets' }),
            ]
            : countryPageExist
                ? [React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' }),
                    React.createElement(BreadcrumbLink, { key: 'desktop-link-2', values: [countryWithLocalId], route: routes.country, separator: '/' })]
                : [React.createElement(BreadcrumbLink, { key: 'desktop-link-1', values: null, route: routes.index, separator: '' })];
    };
    var createJsonLd = function () {
        return cityPageExist
            ? [
                { name: 'Home', route: routes.index() },
                { name: "" + countryWithLocalId.name, route: routes.country(countryWithLocalId.localId) },
                { name: cityWithLocalId.name + " Tickets", route: routes.city(countryWithLocalId.localId, cityWithLocalId.localId) },
            ]
            : countryPageExist
                ? [
                    { name: 'Home', route: routes.index() },
                    { name: "" + countryWithLocalId.name, route: routes.country(countryWithLocalId.localId) },
                ]
                : [{ name: 'Home', route: routes.index() }];
    };
    return {
        breadcrumbPaths: {
            mobilePaths: createMobilePaths(),
            desktopPaths: createDesktopPaths(),
            jsonLD: createJsonLd(),
        }
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styles from 'client/containers/DetailsPages/components/categoriesNavigation/categoriesNavigation.less';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from 'src/client/routing/routes';
import { Icon } from '@components/icon';
import { t } from '@containers/helpers/tests';
var generateSubcategoryDropdown = function (category) {
    if (!category.subcategories.length) {
        return;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.subcategoryOverlay }),
        React.createElement("div", __assign({ className: styles.subcategoryContainer }, t('Subcategory-panel-desktop')), category.subcategories.map(function (subcategory, index) {
            return React.createElement(Link, { to: routes.subcategory(category.localId, subcategory.localId), key: index + "-subcategory-navigation", className: styles.categoryButton }, subcategory.name);
        }))));
};
var generateCategoryDropdowns = function (categories) {
    return categories.map(function (category, index) {
        return React.createElement("div", { key: index + "-category-navigation", className: styles.navButton },
            React.createElement(Link, { className: styles.categoryButton, to: routes.category(category.localId) },
                " ",
                category.name,
                " ",
                React.createElement(Icon, { icon: 'caretDown', height: 16, width: 16 })),
            generateSubcategoryDropdown(category));
    });
};
export var CategoriesNavigation = function () {
    var categories = useSelector(function (gi) { return gi.categories.categories; });
    return (React.createElement("div", __assign({ className: styles.categoriesNavigationContainer }, t('Category-panel-desktop')), generateCategoryDropdowns(categories)));
};

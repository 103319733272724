export var cardBrandToPfClass = {
    visa: 'pf-visa',
    mastercard: 'pf-mastercard',
    amex: 'pf-american-express',
    discover: 'pf-discover',
    diners: 'pf-diners',
    jcb: 'pf-jcb',
    unknown: 'pf-credit-card',
};
export var isBrand = function (s) {
    return Object.prototype.hasOwnProperty.call(cardBrandToPfClass, s);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { useFormState } from 'react-final-form';
import { Label } from 'semantic-ui-react';
import styles from './entry.less';
export function RawEntry(_a) {
    var children = _a.children, className = _a.className, error = _a.error, touched = _a.touched, name = _a.name;
    var showError = touched && !!error;
    var classes = cn(styles.entry, className, {
        entry: true,
        error: touched && error && !!error.error,
        warning: touched && error && !!error.warning && !error.error,
        earlyError: !!error,
    });
    var icon = error && error.error ? 'remove circle' : 'warning';
    var color = error && error.error ? 'red' : 'orange';
    var content = (error && (error.error || error.warning)) || '';
    return (React.createElement("div", __assign({ className: classes }, t("entry-" + name)),
        children,
        showError && React.createElement(Label, __assign({ basic: true, color: color, icon: icon, content: content, className: 'msg-label' }, t('error')))));
}
export function Entry(props) {
    var state = useFormState();
    var error = get(state.errors, props.name);
    var touched = !!get((state.touched || {}), props.name);
    return React.createElement(RawEntry, __assign({}, props, { error: error, touched: touched }));
}

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { clearStrings } from '@logic/helpers/location';
import fromPairs from 'lodash/fromPairs';
import flow from 'lodash/flow';
import fpFilter from 'lodash/fp/filter';
import fpIdentity from 'lodash/fp/identity';
import fpOrderBy from 'lodash/fp/orderBy';
import identity from 'lodash/identity';
import partition from 'lodash/partition';
import { useEffect, useMemo, useState } from 'react';
export function computePinnedRows(idGetter, pinnedRows, data) {
    // this is quite naive implementation, but it is straightforward and it works
    if (!pinnedRows.length) {
        return data;
    }
    return pinnedRows.reduce(function (dt, pin) {
        var _a = __read(pin, 2), pinnedId = _a[0], pinToId = _a[1];
        var pinToIndex = dt.findIndex(function (r) { return idGetter(r) === pinToId; });
        var pinned = dt.find(function (r) { return idGetter(r) === pinnedId; });
        if (pinToIndex === -1 || !pinned) {
            return dt;
        }
        var newDt = dt.map(function (r) { return idGetter(r) !== pinnedId ? r : null; });
        newDt.splice(pinToIndex + 1, 0, pinned);
        return newDt.filter(function (x) { return x != null; });
    }, data);
}
var rowContent = function (record, headers) {
    return clearStrings(headers.map(function (_a) {
        var _b = _a.format, format = _b === void 0 ? identity : _b, path = _a.path;
        return format(path(record));
    }).join(' '));
};
var id = fpIdentity;
var computeRowSearchContent = function (data, headers, idGetter) {
    return fromPairs(data.map(function (r) { return ([idGetter === null || idGetter === void 0 ? void 0 : idGetter(r), rowContent(r, headers)]); }));
};
var sortData = function (data, headers, sortColumns) {
    var sortHeaders = sortColumns
        ? sortColumns.map(function (sc) { return headers.find(function (h) { return h.name === sc.sortColumn; }); }).filter(function (sc) { return !!sc; })
        : [];
    var sortOrders = sortColumns
        ? sortColumns.map(function (sc) { return sc.sortReverse ? 'desc' : 'asc'; })
        : [];
    var comparator = function (_a) {
        var format = _a.format, path = _a.path, orderBy = _a.orderBy;
        return function (dt) { return orderBy
            ? orderBy(path(dt))
            : format
                ? format(path(dt)).toLowerCase()
                : typeof path(dt) === 'number'
                    ? path(dt)
                    : ("" + path(dt)).toLowerCase(); };
    };
    var sorter = sortHeaders.length
        ? fpOrderBy(sortHeaders.map(function (h) { return comparator(h); }), sortOrders)
        : id;
    return sorter(data);
};
var filterData = function (data, searchPhrase, filters, filtersValues, idGetter, rowSearchContent) {
    var search = searchPhrase ? clearStrings(searchPhrase).split(/\s+/) : null;
    return flow(fpFilter(function (d) { return filters.every(function (f) { return f.predicate(d, filtersValues[f.title] || []); }); }), search
        ? fpFilter(function (record) { return search.every(function (s) { var _a, _b; return ((_b = rowSearchContent[(_a = idGetter === null || idGetter === void 0 ? void 0 : idGetter(record)) !== null && _a !== void 0 ? _a : '']) === null || _b === void 0 ? void 0 : _b.indexOf(s)) !== -1; }); })
        : id)(data);
};
export var computeAllProcessedData = function (data, headers, tableState, filters, idGetter) {
    var sorted = sortData(data, headers, tableState.sortColumns);
    var rowSearchContent = computeRowSearchContent(data, headers, idGetter);
    return filterData(sorted, tableState.search, filters, tableState.filters, idGetter, rowSearchContent);
};
export function useProcessedData(headers, tableState, filters, useFiltering, useSorting, pinnedRows, idGetter, data, limit, rowPartitioner) {
    var _a = __read(useState({}), 2), rowSearchContent = _a[0], changeRowSearchContent = _a[1];
    useEffect(function () {
        changeRowSearchContent(computeRowSearchContent(data, headers, idGetter));
    }, [data, headers, idGetter]);
    var sortedRows = useMemo(function () {
        return useSorting ? sortData(data, headers, tableState.sortColumns) : data;
    }, [data, headers, tableState.sortColumns, useSorting]);
    var filteredRows = useMemo(function () {
        return useFiltering ? filterData(sortedRows, tableState.search, filters, tableState.filters, idGetter, rowSearchContent) : sortedRows;
    }, [useFiltering, sortedRows, tableState.search, tableState.filters, filters, idGetter, rowSearchContent]);
    var partitionedRows = useMemo(function () {
        return rowPartitioner ? partition(filteredRows, function (row) { return rowPartitioner(row, tableState); }).flat() : filteredRows;
    }, [filteredRows, rowPartitioner, tableState]);
    var rows = useMemo(function () {
        var result = pinnedRows && idGetter ? computePinnedRows(idGetter, pinnedRows, partitionedRows) : partitionedRows;
        return {
            rows: limit > 0 ? result.slice(0, limit) : result,
            hasMore: limit > 0 && result.length > limit
        };
    }, [pinnedRows, idGetter, limit, partitionedRows]);
    return rows;
}

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAction, getType } from 'typesafe-actions';
import { takeLatest, call } from 'redux-saga/effects';
import { download } from './helpers/request';
import { DATE_TIME_FOR_EXPORT_FORMAT, formatDateTimeWithoutTZ } from '@logic/helpers/date';
// this is work around to be typesafe when call (actions.downloadCsv) and be able to use th `getType` method without Object.assign
// more info here: https://github.com/piotrwitek/typesafe-actions/issues/92
var downloadCsvRealAction = createAction('download CSV file')();
export var actions = {
    downloadCsv: function (payload) { return downloadCsvRealAction(payload); },
};
export function onDownloadCsv(_a) {
    var escapeCsvValue, headersLabels, rows, blob, date;
    var _b = _a.payload, headers = _b.headers, data = _b.data, name = _b.name;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                escapeCsvValue = function (s) { return "\"" + ("" + s).replace('"', '""') + "\""; };
                headersLabels = headers.map(function (h) { return escapeCsvValue(h.name); });
                rows = data.map(function (fi) { return headers.map(function (h) { return escapeCsvValue(h.csvFormat ? h.csvFormat(h.path(fi)) : h.path(fi)); }).join(','); }).join('\n');
                blob = new Blob([headersLabels + '\n' + rows], { type: 'text/plain' });
                date = formatDateTimeWithoutTZ(new Date(), DATE_TIME_FOR_EXPORT_FORMAT);
                return [4 /*yield*/, call(download, blob, date + "-" + name + ".csv")];
            case 1:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function csvDownloadSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(downloadCsvRealAction), onDownloadCsv)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

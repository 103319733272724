import { Icon } from '@components/icon';
import React from 'react';
import { Grid, Popup } from 'semantic-ui-react';
import styles from './description.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { useToggle } from '@components/forms';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
export var labelWidth = 6;
export function Description(_a) {
    var children = _a.children, isRequired = _a.isRequired, isLegalRequirement = _a.isLegalRequirement, width = _a.width, help = _a.help;
    var legalInfo = isLegalRequirement
        ? React.createElement(Popup, { trigger: React.createElement(Icon, { icon: 'info-s' }), content: "If this information is known it is a legal requirement to enter it now. If you do not yet know\n          this information please provide as soon as known", basic: true })
        : null;
    var helpMsg = help ? React.createElement(InfoIcon, { helpMessage: help }) : null;
    var required = isRequired ? '*' : null;
    return (React.createElement(Grid.Column, { width: width, verticalAlign: 'middle' },
        React.createElement("span", { className: styles.nowrap },
            children,
            " ",
            required,
            " ",
            legalInfo,
            " ",
            helpMsg)));
}
Description.defaultProps = {
    bold: true,
    isLegalRequirement: false,
    isRequired: false,
    width: labelWidth,
};
export function InfoIcon(_a) {
    var helpMessage = _a.helpMessage;
    var media = useViewportInfo().media;
    var _b = useToggle(false), open = _b.state, toggle = _b.toggle;
    if (isLikeMobile(media)) {
        return React.createElement(Popup, { open: open, trigger: React.createElement(Icon, { onClick: toggle, icon: 'info-outline', width: 16, height: 16 }), content: helpMessage, basic: true });
    }
    return React.createElement(Popup, { trigger: React.createElement(Icon, { icon: 'info-outline', width: 16, height: 16 }), content: helpMessage, basic: true });
}

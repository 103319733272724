var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { Container, withFetchData, withPageOptions } from '@containers/Page';
import { fetchCategoryEvents, getCategorySearchParams } from '@logic/categories';
import { EventsTable, LocationSelector, NoDataMessage } from '@containers/DetailsPages/components/eventsTable';
import { t } from '@containers/helpers/tests';
import { DetailsPageNavigation } from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation';
import styles from '@containers/DetailsPages/sharedStyles.less';
import { getImage } from '@containers/DetailsPages/utils';
import { routes } from 'src/client/routing/routes';
import { Biography } from '../components/biography/biography';
import { useSorting } from '../components/eventsTable/sorting';
import { SubcategorySeo } from '@containers/DetailsPages/pages/seo/subcategorySeo';
import { JsonLd } from '@components/jsonLD';
import { Breadcrumb, EMPTY_BREADCRUMB } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { PageSectionEnum, useScrollTo } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { useRouteMatch } from 'react-router';
import { DetailsPageOptions } from '@containers/DetailsPages/constants';
import { useLocationSelector } from '@containers/DetailsPages/hooks/useLocationSelector';
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { Error404 } from '@containers/Error404/error404';
import { fetchData, useDetailsCategory } from '@containers/DetailsPages/hooks/useDetailsCategory.hook';
import { createCategoryBreadcrumb } from '@containers/DetailsPages/breadcrumbFactory.utils';
import { DetailsHeader } from '@containers/DetailsPages/components/detailsHeader/detailsHeader';
import { LastNextEventComponent } from '@containers/DetailsPages/components/lastNextEvent/lastNextEvent.component';
import { MoreResultsButton } from '@containers/components/moreResultsButton/moreResultsButton';
import { useDispatch } from 'react-redux';
import { SANE_LIMIT } from '@logic/helpers/request';
var SubcategoryDetails = function () {
    var _a, _b;
    var _c = useDetailsCategory(), categoryDetails = _c.categoryDetails, events = _c.events, eventsIsLoading = _c.eventsIsLoading, detailsLoading = _c.detailsLoading, headerDetails = _c.headerDetails, subcategoryDetails = _c.subcategoryDetails, lastNextEvent = _c.lastNextEvent, eventsCount = _c.eventsCount, searchParams = _c.searchParams;
    var match = useRouteMatch();
    var dispatch = useDispatch();
    var location = (_a = match.params.city) !== null && _a !== void 0 ? _a : 'all';
    var sortedEvents = useSorting(events).results;
    var _d = useScrollTo(), bioRef = _d.bioRef, eventsRef = _d.eventsRef, executeScroll = _d.executeScroll;
    var _e = useLocationSelector({
        variant: 'subcategory',
        category: categoryDetails === null || categoryDetails === void 0 ? void 0 : categoryDetails.localId,
        subcategory: subcategoryDetails === null || subcategoryDetails === void 0 ? void 0 : subcategoryDetails.localId,
        events: events
    }), locationOptions = _e.locationOptions, handleLocationChange = _e.handleLocationChange;
    var breadcrumb = useMemo(function () { return categoryDetails
        ? createCategoryBreadcrumb(categoryDetails, subcategoryDetails)
        : EMPTY_BREADCRUMB; }, [categoryDetails, subcategoryDetails]);
    if (!subcategoryDetails) {
        return detailsLoading
            ? React.createElement(PleaseWait, null)
            : React.createElement(Error404, null);
    }
    var onMoreResultsClick = function () {
        return dispatch(fetchCategoryEvents(__assign(__assign({}, getCategorySearchParams(categoryDetails, subcategoryDetails)), { offset: (searchParams.offset || 0) + SANE_LIMIT })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SubcategorySeo, { eventsLoading: eventsIsLoading, events: events, details: categoryDetails, subcategoryDetails: subcategoryDetails }),
        React.createElement(JsonLd, { events: sortedEvents, breadcrumbJsonLd: (_b = breadcrumb.breadcrumbPaths) === null || _b === void 0 ? void 0 : _b.jsonLD }),
        React.createElement(DetailsHeader, __assign({ details: headerDetails }, t('Subcategory'), { breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }), scrollToCallback: function () { return executeScroll(PageSectionEnum.Bio); } })),
        React.createElement(DetailsPageNavigation, { bioHeader: PageSectionEnum.About, details: headerDetails, isFullWide: false, scrollTo: executeScroll }),
        React.createElement(Container, { className: styles.simplifiedContainer },
            React.createElement("div", null,
                React.createElement(EventsTable, { sortingHeader: React.createElement("h1", { ref: eventsRef }, subcategoryDetails === null || subcategoryDetails === void 0 ? void 0 :
                        subcategoryDetails.name,
                        " Events"), events: sortedEvents, eventsIsLoading: eventsIsLoading, locationSelector: React.createElement(LocationSelector, { locationOptions: locationOptions, location: location, onLocationChange: handleLocationChange }), tableVariant: 'simpleWithLocationFilter', getImage: getImage, noDataMessage: React.createElement(NoDataMessage, { baseRoute: routes.subcategory() }) }),
                React.createElement(MoreResultsButton, { onMoreResultsClick: function () { return onMoreResultsClick(); }, events: events, eventsCount: eventsCount })),
            React.createElement(Biography, { details: subcategoryDetails, bioRef: bioRef }),
            React.createElement(LastNextEventComponent, { eventCategory: categoryDetails === null || categoryDetails === void 0 ? void 0 : categoryDetails.name, lastNextEvent: lastNextEvent }))));
};
export default withPageOptions(DetailsPageOptions)(withFetchData(fetchData)(SubcategoryDetails));

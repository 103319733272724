var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Banners } from '@components/banners';
import { Seo } from '@components/seo';
import AdvancedSearch from '@containers/AdvancedSearch';
import Authentication from '@containers/Authentication';
import Error404 from '@containers/Error404';
import EventDetails from '@containers/DetailsPages/pages/EventDetails';
import HomePage from '@containers/HomePage';
import Login from '@containers/Login';
import Logout from '@containers/Login/logout';
import Notifications from '@containers/Notifications';
import PerformerDetails from '@containers/DetailsPages/pages/performerDetails';
import CategoryDetails from '@containers/DetailsPages/pages/categoryDetails';
import SubcategoryDetails from '@containers/DetailsPages/pages/subcategoryDetails';
import CountryDetails from '@containers/DetailsPages/pages/countryDetails';
import CityDetails from '@containers/DetailsPages/pages/cityDetails';
import Register from '@containers/Register';
import Reset from '@containers/Reset';
import ResetSent from '@containers/Reset/resetSent';
import ResetPasswordAction from '@containers/ResetPasswordAction';
import VenueDetails from '@containers/DetailsPages/pages/venueDetails';
import loadable from '@loadable/component';
import { requireAuth, requireNoAuth } from '@logic/helpers/authentication';
import { getRedirectToPrevious } from '@logic/helpers/location';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { routes } from 'client/routing/routes';
import PrivateRoute from 'src/client/routing/privateRoute';
import { useDispatch } from 'react-redux';
import { initializeBrowserInfo } from '@logic/browser';
var options = {
    fallback: React.createElement(Loader, { active: true }),
};
// the magic comments (https://webpack.js.org/api/module-methods/#magic-comments) are using to have a predictable part in the name of code chunks - it used by bundle size test
var TermsOfService = loadable(function () { return import(/* webpackChunkName: "TermsOfService" */ '@containers/StaticPages/TermsOfService'); }, options);
var PrivacyPolicy = loadable(function () { return import(/* webpackChunkName: "PrivacyPolicy" */ '@containers/StaticPages/PrivacyPolicy'); }, options);
var CookiesPolicy = loadable(function () { return import(/* webpackChunkName: "CookiesPolicy" */ '@containers/StaticPages/CookiesPolicy'); }, options);
var FAQ = loadable(function () { return import(/* webpackChunkName: "FAQ" */ '@containers/StaticPages/FAQ'); }, options);
var CompanyDetailsPage = loadable(function () { return import(/* webpackChunkName: "CompanyDetailsPage" */ '@containers/StaticPages/CompanyDetailsPage'); }, options);
var WhyUseBeeyay = loadable(function () { return import(/* webpackChunkName: "WhyUseBeeyay" */ '@containers/StaticPages/WhyUseBeeyay'); }, options);
var BeeyayGuarantee = loadable(function () { return import(/* webpackChunkName: "BeeyayGuarantee" */ '@containers/StaticPages/BeeyayGuarantee'); }, options);
var DisputeResolutionPolicy = loadable(function () { return import(/* webpackChunkName: "DisputeResolutionPolicy" */ '@containers/StaticPages/DisputeResolutionPolicy'); }, options);
var HowToBuy = loadable(function () { return import(/* webpackChunkName: "HowToBuy" */ '@containers/StaticPages/HowToPages/HowToBuy'); }, options);
var HowToSell = loadable(function () { return import(/* webpackChunkName: "HowToSell" */ '@containers/StaticPages/HowToPages/HowToSell'); }, options);
var CoronavirusAdvicePage = loadable(function () { return import(/* webpackChunkName: "CoronavirusAdvicePage" */ '@containers/StaticPages/CoronavirusAdvicePage'); }, options);
var BuyQuickEntry = loadable(function () { return import(/* webpackChunkName: "BuyQuickEntry" */ '@containers/ListingQuickEntry/BuyQuickEntry'); }, options);
var SellQuickEntry = loadable(function () { return import(/* webpackChunkName: "SellQuickEntry" */ '@containers/ListingQuickEntry/SellQuickEntry'); }, options);
var NewBuyListing = loadable(function () { return import(/* webpackChunkName: "buyNewListing" */ '@containers/ListingQuickEntry/BuyQuickEntry/buyNewListing'); }, options);
var NewSellListing = loadable(function () { return import(/* webpackChunkName: "sellNewListing" */ '@containers/ListingQuickEntry/SellQuickEntry/sellNewListing'); }, options);
var ListingETickets = loadable(function () { return import(/* webpackChunkName: "listingETickets" */ '@containers/Uploader/listingETickets'); }, options);
var SaleETickets = loadable(function () { return import(/* webpackChunkName: "saleETickets" */ '@containers/Uploader/saleETickets'); }, options);
var SaleMobileTransfer = loadable(function () { return import(/* webpackChunkName: "saleMobileTransfer" */ '@containers/Uploader/saleMobileTransfer'); }, options);
var SaleRoyalMail = loadable(function () { return import(/* webpackChunkName: "saleRoyalMail" */ '@containers/Uploader/saleRoyalMail'); }, options);
var BuyingFlow = loadable(function () { return import(/* webpackChunkName: "buyingFlow" */ '@containers/TransactionsFlow/buyingFlow'); }, options);
var PurchaseConfirmation = loadable(function () { return import(/* webpackChunkName: "purchaseConfirmation" */ '@containers/TransactionsFlow/purchaseConfirmation'); }, options);
var SaleConfirmation = loadable(function () { return import(/* webpackChunkName: "saleConfirmation" */ '@containers/TransactionsFlow/saleConfirmation'); }, options);
var SalesFlow = loadable(function () { return import(/* webpackChunkName: "salesFlow" */ '@containers/TransactionsFlow/salesFlow'); }, options);
var PurchasePayment = loadable(function () { return import(/* webpackChunkName: "purchasePayment" */ '@containers/Payment/purchasePayment'); }, options);
var User = loadable(function () { return import(/* webpackChunkName: "User" */ '@containers/User/user/user'); }, options);
var ProfileDetails = loadable(function () { return import(/* webpackChunkName: "ProfileDetails" */ '@containers/User/profileDetails/profileDetails'); }, options);
var ChangePassword = loadable(function () { return import(/* webpackChunkName: "ChangePassword" */ '@containers/User/changePassword/changePassword'); }, options);
var Address = loadable(function () { return import(/* webpackChunkName: "Address" */ '@containers/User/address/address'); }, options);
var Addresses = loadable(function () { return import(/* webpackChunkName: "Addresses" */ '@containers/User/addresses/addresses'); }, options);
var Preferences = loadable(function () { return import(/* webpackChunkName: "Preferences" */ '@containers/User/preferences/preferences'); }, options);
var Listings = loadable(function () { return import(/* webpackChunkName: "Listings" */ '@containers/UserListings/listings'); }, options);
var Card = loadable(function () { return import(/* webpackChunkName: "Card" */ '@containers/User/card/card'); }, options);
var Cards = loadable(function () { return import(/* webpackChunkName: "Cards" */ '@containers/User/cards/cards'); }, options);
var BankAccount = loadable(function () { return import(/* webpackChunkName: "BankAccount" */ '@containers/User/bankAccount/bankAccount'); }, options);
var BankAccounts = loadable(function () { return import(/* webpackChunkName: "BankAccounts" */ '@containers/User/bankAccounts/bankAccounts'); }, options);
var Purchases = loadable(function () { return import(/* webpackChunkName: "Purchases" */ '@containers/Transactions/Purchases/purchases'); }, options);
var Sales = loadable(function () { return import(/* webpackChunkName: "Sales" */ '@containers/Transactions/Sales/sales'); }, options);
var WorkItems = loadable(function () { return import(/* webpackChunkName: "WorkItems" */ '@containers/WorkItems'); }, options);
var UserFinancials = loadable(function () { return import(/* webpackChunkName: "UserFinancials" */ '@containers/UserFinancials/userFinancials'); }, options);
var Sitemap = loadable(function () { return import(/* webpackChunkName: "Sitemap" */ './containers/Sitemap/index'); }, options);
var UiDemo = loadable(function () { return import(/* webpackChunkName: "UiDemo" */ '@containers/UiDemo'); }, options);
var DEPRECATED_ROUTES = [
    React.createElement(Route, { key: "8", path: routes.user(), component: User }),
    React.createElement(Route, { key: "27_2", path: routes.performerD(':id', ':city?', ':buyOnly?'), component: PerformerDetails }),
    React.createElement(Route, { key: "28_2", path: routes.venueD(), component: VenueDetails }),
    React.createElement(Route, { key: "14_2", exact: true, path: routes.eventWithoutTypeD(), component: EventDetails }),
    React.createElement(Route, { key: "15_2", exact: true, path: routes.eventD(), component: EventDetails }),
];
export var ROUTES = __spreadArray(__spreadArray([], __read(DEPRECATED_ROUTES)), [
    React.createElement(PrivateRoute, { key: "1", exact: true, path: routes.login(':reason?'), component: Login, authenticated: requireNoAuth, redirect: routes.index(), getRedirect: getRedirectToPrevious }),
    React.createElement(PrivateRoute, { key: "2", exact: true, path: routes.register(':reason?'), component: Register, authenticated: requireNoAuth, redirect: routes.index(), getRedirect: getRedirectToPrevious }),
    React.createElement(PrivateRoute, { key: "3", exact: true, path: routes.resetPassword(), component: Reset, authenticated: requireNoAuth, redirect: routes.index(), getRedirect: getRedirectToPrevious }),
    React.createElement(PrivateRoute, { key: "3_1", exact: true, path: routes.setPassword(), component: Reset, authenticated: requireNoAuth, redirect: routes.index(), getRedirect: getRedirectToPrevious }),
    React.createElement(Route, { key: "4", exact: true, path: routes.resetPasswordSent(), component: ResetSent }),
    React.createElement(Route, { key: "4_1", exact: true, path: routes.setPasswordSent(), component: ResetSent }),
    React.createElement(PrivateRoute, { key: "5", exact: true, path: routes.userSaleETickets(), component: SaleETickets, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "6", exact: true, path: routes.userSaleMobileTransfer(), component: SaleMobileTransfer, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "6_1", exact: true, path: routes.userSaleRoyalMail(), component: SaleRoyalMail, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "7", path: routes.userPurchasePayment(), component: PurchasePayment, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_1", path: routes.userProfile(), component: ProfileDetails, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_2", path: routes.userPassword(), component: ChangePassword, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_3", path: routes.userAddress(), component: Address, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_4", path: routes.userAddresses(), component: Addresses, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_5", path: routes.userPreferences(), component: Preferences, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_6", path: routes.userListings(), component: Listings, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_7", path: routes.userCard(), component: Card, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_8", path: routes.userCards(), component: Cards, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_9", path: routes.userBankAccount(), component: BankAccount, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_10", path: routes.userBankAccounts(), component: BankAccounts, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_11", path: routes.userPurchases(), component: Purchases, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_12", path: routes.userSales(), component: Sales, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_13", path: routes.userWorkItems(), component: WorkItems, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "8_14", path: routes.userFinancials(), component: UserFinancials, authenticated: requireAuth }),
    React.createElement(Route, { key: "9", exact: true, path: routes.resetPasswordAction(), component: ResetPasswordAction }),
    React.createElement(Route, { key: "9_1", exact: true, path: routes.setPasswordAction(), component: ResetPasswordAction }),
    React.createElement(Route, { key: "10", exact: true, path: routes.logout(':reason?'), component: Logout }),
    React.createElement(Route, { key: "11", path: routes.search(), component: AdvancedSearch }),
    React.createElement(Redirect, { key: "14", exact: true, from: routes.eventWithoutType(), to: routes.event(undefined, undefined, undefined, undefined, 'buy') }),
    React.createElement(Route, { key: "15", exact: true, path: routes.event(), component: EventDetails }),
    React.createElement(Route, { key: "16", exact: true, path: routes.createUserWorkItem(routes.event()), component: EventDetails }),
    React.createElement(PrivateRoute, { key: "17", exact: true, path: routes.listing('sell', undefined, 'new'), component: NewSellListing, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "18", exact: true, path: routes.sellListingETickets(), component: ListingETickets, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "19", path: routes.listing('sell'), component: SellQuickEntry, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "20", exact: true, path: routes.listing('buy', undefined, 'new'), component: NewBuyListing, authenticated: requireAuth }),
    React.createElement(PrivateRoute, { key: "21", path: routes.listing('buy'), component: BuyQuickEntry, authenticated: requireAuth }),
    React.createElement(Route, { key: "22", path: routes.reservation('buy', 'summary'), component: BuyingFlow }),
    React.createElement(Route, { key: "23", path: routes.reservation('buy', 'payment'), component: BuyingFlow }),
    React.createElement(PrivateRoute, { key: "24", path: routes.reservation('sell', 'summary'), component: SalesFlow, authenticated: requireAuth, redirect: routes.register('sell') }),
    React.createElement(PrivateRoute, { key: "25", path: routes.transactionConfirmation('sell'), component: SaleConfirmation, authenticated: requireAuth }),
    React.createElement(Route, { key: "26", path: routes.transactionConfirmation('buy'), component: PurchaseConfirmation }),
    React.createElement(Route, { key: "27", path: routes.performer(':id', ':performerName', ':city?', ':buyOnly?'), component: PerformerDetails }),
    React.createElement(Route, { key: "28", path: routes.venue(':venueName', ':id'), component: VenueDetails }),
    process.env.NODE_ENV === 'development' && React.createElement(Route, { key: "30", exact: true, path: routes.uiDevComponents(), component: UiDemo }),
    React.createElement(Route, { key: "31", path: routes.terms(), component: TermsOfService }),
    React.createElement(Route, { key: "32", path: routes.privacyPolicy(), component: PrivacyPolicy }),
    React.createElement(Route, { key: "33", path: routes.cookiesPolicy(), component: CookiesPolicy }),
    React.createElement(Route, { key: "34", path: routes.faq(), component: FAQ }),
    React.createElement(Route, { key: "35", path: routes.companyDetailsPage(), component: CompanyDetailsPage }),
    React.createElement(Route, { key: "36", path: routes.whyUseBeeyay(), component: WhyUseBeeyay }),
    React.createElement(Route, { key: "37", path: routes.beeyayGuarantee(), component: BeeyayGuarantee }),
    React.createElement(Route, { key: "38", path: routes.disputeResolutionPolicy(), component: DisputeResolutionPolicy }),
    React.createElement(Route, { key: "39", path: routes.howToBuy(), component: HowToBuy }),
    React.createElement(Route, { key: "40", path: routes.howToSell(), component: HowToSell }),
    React.createElement(Route, { key: "41", path: routes.coronavirusAdvicePage(), component: CoronavirusAdvicePage }),
    React.createElement(Route, { key: "12", path: routes.index(), exact: true, component: HomePage }),
    React.createElement(Route, { key: "43", path: routes.category(':categoryId', ':city?'), component: CategoryDetails }),
    React.createElement(Route, { key: "44", path: routes.subcategory(':categoryId', ':subcategoryId', ':city?'), component: SubcategoryDetails }),
    React.createElement(Route, { key: "45", path: routes.country(':countryId'), component: CountryDetails }),
    React.createElement(Route, { key: "46", path: routes.city(':countryId', ':cityId'), component: CityDetails }),
    React.createElement(Route, { key: "47", path: routes.sitemap(), component: Sitemap }),
    React.createElement(Route, { key: "42", path: routes.index(), component: Error404 }),
    React.createElement(Route, { key: "121", path: routes.createUserWorkItem(routes.index()), exact: true, component: HomePage }),
]).filter(function (x) { return !!x; });
export var App = function () {
    var _a;
    var history = useHistory();
    var dispatch = useDispatch();
    useEffect(function () {
        // this is a hacky way to defer loading of AI after rehydrate the application
        var action = function () { return __awaiter(void 0, void 0, void 0, function () {
            var ai;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(/* webpackChunkName: "application-insights" */ './applicationInsightsClient')];
                    case 1:
                        ai = _a.sent();
                        ai.initializeApplicationInsights(history);
                        return [2 /*return*/];
                }
            });
        }); };
        dispatch(initializeBrowserInfo());
        action();
    }, [history, dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Authentication, null,
            React.createElement(Helmet, null, typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test((_a = navigator === null || navigator === void 0 ? void 0 : navigator.platform) !== null && _a !== void 0 ? _a : '') && React.createElement("meta", { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' })),
            React.createElement(Seo, null),
            React.createElement(Switch, null, ROUTES)),
        React.createElement(Banners, null),
        React.createElement(Notifications, null)));
};

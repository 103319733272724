var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Modal, ModalActions, ModalContentRow } from '@components/forms';
import { formatRemainingTime } from '@logic/helpers/date';
import { getRedirectTo } from '@logic/helpers/location';
import { logout, restoreSession } from '@logic/user';
import { push } from 'connected-react-router';
import isString from 'lodash/isString';
import { useEffect, useState, Fragment } from 'react';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { editPageRedirections, routes } from 'src/client/routing/routes';
import { useLocation } from 'react-router';
var TIME_TO_LOGOUT = 1000 * 60;
var IDLE_TIME_TO_SHOW_NOTIFICATION = 1000 * 60 * 60 * 8;
export var Authentication = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var _b = __read(useState(false), 2), showModal = _b[0], setShowModal = _b[1];
    var _c = __read(useState(TIME_TO_LOGOUT), 2), logoutTimer = _c[0], setLogoutTimer = _c[1];
    var _d = useIdleTimer({
        timeout: IDLE_TIME_TO_SHOW_NOTIFICATION,
        crossTab: true,
        onIdle: function () { return setShowModal(true); },
    }), start = _d.start, pause = _d.pause, reset = _d.reset;
    var location = useLocation();
    var userDetails = useSelector(function (gs) { return gs.user.userDetails; });
    useEffect(function () {
        dispatch(restoreSession());
    }, [dispatch]);
    useEffect(function () {
        if (userDetails) {
            start();
        }
        else {
            pause();
        }
    }, [start, pause, userDetails]);
    useEffect(function () {
        // case1: modal was closed, reset timer and ignore rest of it.
        if (!showModal) {
            setLogoutTimer(TIME_TO_LOGOUT);
            return;
        }
        // case2: time has expired, redirect to logout screen with proper reason and redirection set
        if (logoutTimer <= 0) {
            setShowModal(false);
            setLogoutTimer(TIME_TO_LOGOUT);
            var forcedRedirectionPathname = editPageRedirections.map(function (fn) { var _a; return fn(isString(location) ? location : (_a = location === null || location === void 0 ? void 0 : location.pathname) !== null && _a !== void 0 ? _a : '/'); }).find(function (_) { return !!_; });
            var redirectionLocation = forcedRedirectionPathname
                ? (isString(forcedRedirectionPathname) ? { pathname: forcedRedirectionPathname } : forcedRedirectionPathname)
                : location;
            dispatch(push(getRedirectTo({
                redirect: routes.logout('idle'),
                location: redirectionLocation,
            })));
            return;
        }
        // case3: we shows the modal, so count down and go for sleep
        var timerId = setTimeout(function () { return setLogoutTimer(function (time) { return time - 500; }); }, 500);
        return function () { return clearTimeout(timerId); };
    }, [logoutTimer, dispatch, showModal, location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: showModal },
            React.createElement(ModalContentRow, null, "You are about to be logged out due to inactivity, do you wish to continue?"),
            React.createElement(ModalContentRow, { center: true }, formatRemainingTime(new Date(Date.now() + logoutTimer))),
            React.createElement(ModalActions, null,
                React.createElement(Button, { onClick: function () { return dispatch(logout()); }, ternary: true, medium: true }, "Logout"),
                React.createElement(Button, { onClick: function () { reset(); setShowModal(false); }, ternary: true, medium: true }, "Continue"))),
        React.createElement(Fragment, { key: userDetails ? 'logged' : 'not-logged' }, children)));
};
export default Authentication;

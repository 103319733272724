var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useMemo } from 'react';
import styles from '@containers/DetailsPages/components/otherDates/otherDates.less';
import carouselStyles from '@containers/components/topEvents/topEvents.less';
import { InfiniteScrollLoop } from '@containers/components/infiniteScrollLoop/infiniteScrollLoop';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import cn from 'classnames';
import { Icon } from '@containers/components/icon';
import { isMobile } from '@containers/components/viewPortInfo/viewPort.utils';
import { useViewportInfo } from '@containers/components/viewPortInfo/useViewportInfo.hook';
import { eventLinkSlugToEventRoute } from 'src/client/routing/routes';
import { t } from '@containers/helpers/tests';
var dateBoxWidth = 120;
var dateBoxMargin = 24;
var dateBoxScroll = dateBoxWidth + dateBoxMargin;
export var OtherDates = function (_a) {
    var _b;
    var data = _a.data;
    var performer = data.performer, allEvents = data.events;
    var id = useParams().id;
    var events = allEvents.filter(function (event) { return event.id !== Number(id); });
    var media = useViewportInfo().media;
    var isMobileDevice = isMobile(media);
    var otherDatesRef = useRef();
    var otherDatesContainer = typeof document !== 'undefined' ? document.getElementById('otherDatesContainer') : null;
    var scrollBy = function (scrollOffset) {
        return otherDatesRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    };
    var otherDatesContainerWidth = (otherDatesContainer === null || otherDatesContainer === void 0 ? void 0 : otherDatesContainer.offsetWidth) || 0;
    var isListLongerThanContainer = useMemo(function () { return (events === null || events === void 0 ? void 0 : events.length) * (dateBoxMargin + dateBoxWidth) > otherDatesContainerWidth; }, [events === null || events === void 0 ? void 0 : events.length, otherDatesContainerWidth]);
    var dateCardBoxes = events === null || events === void 0 ? void 0 : events.map(function (event, index) { return (React.createElement(Link, { draggable: false, to: eventLinkSlugToEventRoute(event.eventLinkSlug, 'buy'), key: "trending-event2-" + index },
        React.createElement("div", { className: "dateCard" },
            React.createElement("p", null, format(event.eventDate, 'EEE')),
            React.createElement("p", null, format(event.eventDate, 'dd')),
            React.createElement("p", null, format(event.eventDate, 'LLL yyyy')),
            React.createElement("p", null, event.venueCity)))); });
    return (events === null || events === void 0 ? void 0 : events.length) ?
        React.createElement("div", __assign({ className: styles.otherDatesSection }, t('EventOtherDates'), { id: 'otherDatesContainer' }),
            React.createElement("p", { className: 'title' }, "Other " + (performer === null || performer === void 0 ? void 0 : performer.name) + " Dates"),
            isListLongerThanContainer
                ? React.createElement(InfiniteScrollLoop, { scrollRef: otherDatesRef, className: cn(styles.hs, (_b = {}, _b[carouselStyles.noScrollbarOnMobile] = true, _b)), childSize: dateBoxScroll, direction: 'horizontal' }, dateCardBoxes)
                : React.createElement("div", { className: "dateCardBoxesContainer" }, dateCardBoxes),
            !isMobileDevice && (events === null || events === void 0 ? void 0 : events.length) && isListLongerThanContainer ?
                React.createElement("div", { className: carouselStyles.navButtons },
                    React.createElement("div", { className: cn(carouselStyles.navButton, carouselStyles.navButtonLeft), onClick: function () { return scrollBy(-dateBoxScroll); } },
                        React.createElement(Icon, { height: 16, width: 16, icon: 'arrowLeft' })),
                    React.createElement("div", { className: cn(carouselStyles.navButton, carouselStyles.navButtonRight), onClick: function () { return scrollBy(dateBoxScroll); } },
                        React.createElement(Icon, { height: 16, width: 16, icon: 'arrowRight' })))
                : null)
        : null;
};

import cn from 'classnames';
import styles from './marketPlaceBanner.less';
import React from 'react';
import { bannerId } from '@containers/Navigation/navigation';
export var SecondaryMarketplaceBanner = function (_a) {
    var _b;
    var transparentBackground = _a.transparentBackground;
    return (React.createElement("div", { className: cn(styles.banner, (_b = {}, _b[styles.transparentBackground] = transparentBackground, _b)) },
        React.createElement("div", { id: bannerId, className: styles.bannerContent }, "Beeyay is an innovative secondary ticketing marketplace. Ticket buyers and sellers can set their own prices which may be above or below face value.")));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { Link } from '@components/forms';
import { replace } from 'connected-react-router';
import { getBuyCosts as getBuyCostsCommon, getSellCosts as getSellCostsCommon } from 'costs-compute';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import qs from 'qs';
import React from 'react';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { pushPurchaseInfo } from 'src/client/gtm';
import { routes } from 'src/client/routing/routes';
import { isBrand } from './cardBrand';
import { tryMapError } from './errorCodeMapper';
import { eventDetailsSelector, getEventFullNameFromArgs, rawEventSummaryToEventSummary } from './events';
import request, { download } from './helpers/request';
import { authApi } from './registration';
import { changeReservation, selectReservation } from './reservations';
import { normalizeTransactionSeatingInfo } from './seatingInfo';
import { clearFilesRepository } from './uploadImageFiles';
import { selectUserDetails } from './user';
import { selectGlobalSettings, selectSettings } from './userSettings';
import { formatVenueData } from './venue';
import { codeToCountry } from '@logic/helpers/enumerators';
import get from 'lodash/get';
import { hasProperty } from './helpers/hasProperty';
import isArray from 'lodash/isArray';
import { setGuestCheckoutEmail } from '@logic/userSettings';
export var asOrderId = function (id) { return id; };
export var asTransactionId = function (id) { return id; };
export var isPaperDeliveryType = function (type) { return type == 'ups' || type == 'royalMail'; };
export var isElectronicDeliveryType = function (type) { return type == 'eticket' || type == 'qrCode'; };
export var isTransferDeliveryType = function (type) { return type == 'mobileTransfer' || type == 'ticketmaster'; };
export var friendlyTransactionStatusMapping = {
    pending: 'Pending',
    active: 'Active',
    cancelled: 'Cancelled',
};
export var deliveryTypeDescriptionMapping = {
    eticket: 'e-Ticket',
    ups: 'UPS',
    royalMail: 'Royal Mail',
    mobileTransfer: 'Mobile Transfer',
    ticketmaster: 'Ticketmaster Mobile Transfer',
    qrCode: 'Mobile QR Code',
};
export var deliveryTypeSimplifiedMapping = {
    eticket: 'e-Ticket',
    ups: 'Paper',
    royalMail: 'Paper',
    mobileTransfer: 'Mobile',
    ticketmaster: 'Mobile',
    qrCode: 'Mobile'
};
export var deliveryStatusToSimplifiedMapping = {
    pending: 'Awaiting Delivery',
    awaitingDispatch: 'Awaiting Delivery',
    awaitingHandover: 'Awaiting Delivery',
    inTransit: 'Awaiting Delivery',
    delivered: 'Delivered',
    issue: 'Issue',
    awaitingUpload: 'Awaiting Delivery',
    uploaded: 'Delivered',
    downloaded: 'Delivered',
    awaitingTransfer: 'Awaiting Delivery',
    finalisingDelivery: 'Awaiting Delivery'
};
export var createSellTransaction = createAction('create sell transaction', function (ticketType, seatingInformation, sellRestrictions, faceValue) { return ({
    ticketType: ticketType,
    seatingInformation: seatingInformation,
    sellRestrictions: sellRestrictions,
    faceValue: faceValue,
}); });
export var createBuyTransaction = createAction('create buy transaction', function (ticketType, seatingInformation, stripe, elements, data, deliveryAddress, cardId, userEmail, userFullName, buyerUserData, confirm) { return ({
    ticketType: ticketType,
    stripe: stripe,
    data: data,
    seatingInformation: seatingInformation,
    cardId: cardId,
    deliveryAddress: deliveryAddress,
    userEmail: userEmail,
    userFullName: userFullName,
    buyerUserData: buyerUserData,
    confirm: confirm,
    elements: elements,
}); });
export var TransactionFilter;
(function (TransactionFilter) {
    TransactionFilter["OrderStatus"] = "Order Status";
    TransactionFilter["DeliveryMethod"] = "Delivery Method";
    TransactionFilter["DeliveryStatus"] = "Delivery Status";
    TransactionFilter["EventDate"] = "Event Date";
})(TransactionFilter || (TransactionFilter = {}));
export var confirmTransaction = createAction('confirm transaction', function (payload) { return payload; });
export var confirmTransactionSuccess = createAction('confirm transaction success');
export var confirmTransactionFailure = createAction('confirm transaction failure', function (error) { return ({ error: error }); });
export var updateTransactionFilters = createAction('update transaction filters', function (payload) { return payload; });
export var loadPurchases = createAction('load purchases');
export var silentLoadPurchases = createAction('silent load purchases');
export var loadPurchasesSuccess = createAction('load purchases success', function (purchases) { return ({ purchases: purchases }); });
export var loadPurchasesFailure = createAction('load purchases failure', function (error) { return ({ error: error }); });
export var updatePurchaseOnList = createAction('update purchase on list', function (purchase) { return purchase; });
export var loadSales = createAction('load sales');
export var silentLoadSales = createAction('silent load sales');
export var loadSalesSuccess = createAction('load sales success', function (sales) { return ({ sales: sales }); });
export var loadSalesFailure = createAction('load sales failure', function (error) { return ({ error: error }); });
export var updateSaleOnList = createAction('update sale on list', function (sale) { return sale; });
export var updateSelectedSalesIds = createAction('update selected sales ids', function (selectedSalesIds) { return selectedSalesIds; });
export var downloadTickets = createAction('download tickets for order', function (orderId) { return ({ orderId: orderId }); });
export var updateSaleETickets = createAction('update sale transaction etickets', function (orderId, filesInfo, redirect) { return ({
    orderId: orderId,
    filesInfo: filesInfo,
    redirect: redirect,
}); });
export var updateSaleTransferRecipt = createAction('update sale transaction transfer recipt', function (orderId, filesInfo, redirect) { return ({
    orderId: orderId,
    filesInfo: filesInfo,
    redirect: redirect,
}); });
export var updateSalesETickets = createAction('update sale transactions etickets', function (ticketsInfo, redirect) { return ({
    ticketsInfo: ticketsInfo,
    redirect: redirect,
}); });
export var updateSalesTransferReceipt = createAction('update sale transactions transfer receipts', function (ticketsInfo, redirect) { return ({
    ticketsInfo: ticketsInfo,
    redirect: redirect,
}); });
export var updateSaleTrackingNumber = createAction('update sale transaction tracking number', function (orderId, trackingNumber, redirect) { return ({
    orderId: orderId,
    trackingNumber: trackingNumber,
    redirect: redirect,
}); });
export var updateSalesTrackingNumbers = createAction('update sale transactions tracking numbers', function (trackingNumbers, redirect) { return ({
    trackingNumbers: trackingNumbers,
    redirect: redirect,
}); });
export var downloadLabel = createAction('download label for transaction', function (transactionId, orderId, done) { return ({ transactionId: transactionId, orderId: orderId, done: done }); });
export var downloadLabelSuccess = createAction('download label for transaction success', function (transaction) { return transaction; });
export var downloadLabelFailure = createAction('download label for transaction failure', function (error) { return ({ error: error }); });
export var downloadLabels = createAction('download labels for transactions', function (transactionIds, done) { return ({ transactionIds: transactionIds, done: done }); });
export var downloadLabelsSuccess = createAction('download labels for transactions success', function (transactions) { return transactions; });
export var downloadLabelsFailure = createAction('download labels for transactions failure', function (error) { return ({ error: error }); });
/* transactionConfirmation actions - moved here to avoid circular references */
export var loadSale = createAction('load sale', function (id) { return ({ id: id }); });
export var loadPurchaseSuccess = createAction('load purchase success', function (purchase) { return ({ purchase: purchase }); });
var initialState = {
    purchases: [],
    isLoadingPurchases: true,
    sales: [],
    isLoadingSales: true,
    createTransactionPending: false,
    isGeneratingLabel: false,
    guestEmail: '',
    selectedSalesIds: [],
    filtering: {
        filters: {
            searchQuery: '',
            orderStatus: [],
            deliveryMethod: [],
            deliveryStatus: []
        },
        pastEvents: false,
    }
};
export var filteringSelect = function (s) { return s.transactions.filtering; };
export var transactionsReducer = createReducer((_a = {},
    _a[loadPurchases.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPurchases: true })); },
    _a[loadPurchasesSuccess.getType()] = function (state, _a) {
        var purchases = _a.purchases;
        return (__assign(__assign({}, state), { isLoadingPurchases: false, purchases: purchases }));
    },
    _a[loadPurchasesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPurchases: false, purchases: [] })); },
    _a[updatePurchaseOnList.getType()] = function (state, purchase) { return (__assign(__assign({}, state), { purchases: state.purchases ? state.purchases.map(function (p) { return p.transactionId === purchase.transactionId ? purchase : p; }) : [] })); },
    _a[loadSales.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSales: true })); },
    _a[loadSalesSuccess.getType()] = function (state, _a) {
        var sales = _a.sales;
        return (__assign(__assign({}, state), { isLoadingSales: false, sales: sales }));
    },
    _a[updateSaleOnList.getType()] = function (state, sale) { return (__assign(__assign({}, state), { sales: state.sales ? state.sales.map(function (s) { return s.transactionId === sale.transactionId ? sale : s; }) : [] })); },
    _a[loadSalesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSales: false, sales: [] })); },
    _a[updateSelectedSalesIds.getType()] = function (state, selectedSalesIds) { return (__assign(__assign({}, state), { selectedSalesIds: selectedSalesIds })); },
    _a[createSellTransaction.getType()] = function (state) { return (__assign(__assign({}, state), { createTransactionPending: true })); },
    _a[createBuyTransaction.getType()] = function (state) { return (__assign(__assign({}, state), { createTransactionPending: true })); },
    _a[confirmTransactionSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { createTransactionPending: false })); },
    _a[confirmTransactionFailure.getType()] = function (state) { return (__assign(__assign({}, state), { createTransactionPending: false })); },
    _a[downloadLabel.getType()] = function (state) { return (__assign(__assign({}, state), { isGeneratingLabel: true })); },
    _a[downloadLabelSuccess.getType()] = function (state, transaction) { return (__assign(__assign({}, state), { sales: state.sales.map(function (sale) { return sale.transactionId !== transaction.transactionId
            ? sale
            : transaction; }), isGeneratingLabel: false })); },
    _a[downloadLabelFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isGeneratingLabel: false })); },
    _a[downloadLabels.getType()] = function (state) { return (__assign(__assign({}, state), { isGeneratingLabel: true })); },
    _a[downloadLabelsSuccess.getType()] = function (state, sales) { return (__assign(__assign({}, state), { sales: sales, selectedSalesIds: [], isGeneratingLabel: false })); },
    _a[downloadLabelsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isGeneratingLabel: false })); },
    _a[setGuestCheckoutEmail.getType()] = function (state, email) { return (__assign(__assign({}, state), { guestEmail: email })); },
    _a[updateSalesETickets.getType()] = function (state) { return (__assign(__assign({}, state), { selectedSalesIds: [] })); },
    _a[updateSalesTransferReceipt.getType()] = function (state) { return (__assign(__assign({}, state), { selectedSalesIds: [] })); },
    _a[updateSalesTrackingNumbers.getType()] = function (state) { return (__assign(__assign({}, state), { selectedSalesIds: [] })); },
    _a[updateTransactionFilters.getType()] = function (state, _a) {
        var _b, _c, _d, _e;
        var tableState = _a.tableState;
        return (__assign(__assign({}, state), { filtering: {
                pastEvents: !!((_b = tableState.filters[TransactionFilter.EventDate]) === null || _b === void 0 ? void 0 : _b.length),
                filters: {
                    deliveryStatus: (_c = tableState.filters[TransactionFilter.DeliveryStatus]) !== null && _c !== void 0 ? _c : [],
                    deliveryMethod: (_d = tableState.filters[TransactionFilter.DeliveryMethod]) !== null && _d !== void 0 ? _d : [],
                    orderStatus: (_e = tableState.filters[TransactionFilter.OrderStatus]) !== null && _e !== void 0 ? _e : [],
                    searchQuery: tableState.search
                }
            } }));
    },
    _a), initialState);
export var transactionApi = {
    create: function (body) { return request('/api/transactions/create', body); },
    getUserPurchases: function (params) { return request('/api/transactions/user/purchase', params); },
    getUserSales: function (params) { return request('/api/transactions/user/sell', params); },
    getUserSale: function (id) { return request("/api/transactions/user/sell/" + id); },
    downloadTickets: function (orderId) { return request("/api/transactions/getticket/" + orderId, undefined, { responseType: 'blob', mimeType: 'application/pdf' }); },
    downloadLabel: function (transactionId) { return request("/api/transactions/getlabel/" + transactionId, undefined, { responseType: 'blob' }); },
    downloadLabels: function (transactionIds) { return request('/api/transactions/combinelabels', { transactionIds: transactionIds }, { responseType: 'blob' }); },
    updateTicketsInfo: function (orderId, ticketsInfo) { return request('/api/transactions/tickets/assign', {
        orderId: orderId,
        ticketsInfo: ticketsInfo,
    }); },
    updateTicketsInfoBatched: function (tickets) { return request('/api/transactions/tickets/batched', {
        tickets: tickets,
    }); },
    updateTrackingNumber: function (orderId, trackingNumber) { return request('/api/transactions/tracking/assign', {
        orderId: orderId,
        trackingNumber: trackingNumber,
    }); },
    updateTrackingNumbersBatched: function (trackingNumbers) { return request('/api/transactions/tracking/batched', {
        trackingNumbers: trackingNumbers,
    }); },
    eventTransactions: function (eventId, category, section, ticketTypes, ticketsAssigned) {
        var params = qs.stringify({
            eventId: eventId,
            section: section,
            category: category,
            ticketTypes: ticketTypes,
            ticketsAssigned: ticketsAssigned,
        }, { arrayFormat: 'brackets', skipNulls: true });
        return request("/api/transactions/event?" + params);
    },
    retryPayments: function (transactionId, costs, paymentToken) { return request('/api/transactions/retrypayments', { transactionId: transactionId, costs: costs, paymentToken: paymentToken }); },
    updateUserContactInfo: function (transactionId, contactInfo) { return request('/api/transactions/updatecontactinfo/user', { transactionId: transactionId, contactInfo: contactInfo }); },
    updateGuestContactInfo: function (transactionId, guestToken, userEmail, contactInfo) { return request('/api/transactions/updatecontactinfo/guest', { transactionId: transactionId, guestToken: guestToken, userEmail: userEmail, contactInfo: contactInfo }); },
    updateDelivery: function (transactionId, deliveryAddress, transferRecipient, deliveryType, ticketsInfo) { return request('/api/transactions/updatedelivery', { transactionId: transactionId, deliveryAddress: deliveryAddress, transferRecipient: transferRecipient, deliveryType: deliveryType, ticketsInfo: ticketsInfo }); },
    updateContactInfo: function (transactionId, contactInfo) { return request('/api/transactions/updatecontactinfo', { transactionId: transactionId, contactInfo: contactInfo }); },
};
export var rawTransactionToTransaction = function (transactionSide) { return function (raw) {
    var orderId = raw.orderId, transactionId = raw.transactionId, _a = raw.delivery, shipBy = _a.shipBy, deliveryAddress = _a.deliveryAddress, transferRecipient = _a.transferRecipient, deliveryRest = __rest(_a, ["shipBy", "deliveryAddress", "transferRecipient"]), event = raw.event, orderDate = raw.orderDate, seatingInfo = raw.seatingInfo, payment = raw.payment, rest = __rest(raw, ["orderId", "transactionId", "delivery", "event", "orderDate", "seatingInfo", "payment"]);
    var delivery = __assign(__assign({}, deliveryRest), { deliveryAddress: deliveryAddress || null, transferRecipient: transferRecipient || null, shipBy: new Date(shipBy) });
    return __assign(__assign({}, rest), { orderId: asOrderId(orderId), transactionId: asTransactionId(transactionId), orderDate: new Date(orderDate), event: __assign(__assign({}, rawEventSummaryToEventSummary(event)), { ticketTypes: event.ticketTypes, venueCountry: codeToCountry[event.venueCountryCode] }), delivery: delivery,
        transactionSide: transactionSide, seatingInfo: normalizeTransactionSeatingInfo(seatingInfo), payment: __assign(__assign({}, payment), { brand: payment.paymentBrand && isBrand(payment.paymentBrand) ? payment.paymentBrand : undefined, date: payment.date ? new Date(payment.date) : undefined }) });
}; };
export var rawEventTransactionToEventTransaction = function (t) {
    var orderDate = t.orderDate, ticketCost = t.ticketCost, currency = t.currency, seatingInfo = t.seatingInfo, rest = __rest(t, ["orderDate", "ticketCost", "currency", "seatingInfo"]);
    return __assign({ orderDate: new Date(orderDate), ticketCost: {
            value: ticketCost,
            currency: currency,
        }, seatingInfo: {
            category: seatingInfo.category,
            section: seatingInfo.section || '',
            row: seatingInfo.row || null,
            startingSeat: seatingInfo.startingSeat || null,
            matchAny: seatingInfo.category === 'any',
            seatRange: seatingInfo.seatRange || null,
        } }, rest);
};
export var isInternationalShipping = function (userCountry, listingOwnerInfo, eventInfo) {
    var _a;
    var venueCountry = eventInfo && 'venue' in eventInfo
        ? eventInfo.venue.address.countryCode
        : null;
    var ownerCountry = (_a = listingOwnerInfo === null || listingOwnerInfo === void 0 ? void 0 : listingOwnerInfo.sellerDeliveryAddress) === null || _a === void 0 ? void 0 : _a.country;
    if (userCountry === 'GB' && (ownerCountry !== null && ownerCountry !== void 0 ? ownerCountry : venueCountry) === 'GB')
        return false;
    return true;
};
export var getBuyCosts = function (value, ticketType, quantity, userSettings, international) {
    return getBuyCostsCommon(value, ticketType || null, quantity, userSettings.vatRate.vatRate, userSettings.buyerCommissionRate.buyerCommissionPercent, userSettings.buyerCommissionRate.buyerCommissionFlat, userSettings.deliveryFees, international);
};
export var getSellCosts = function (value, _tt, quantity, userSettings, _i) {
    return getSellCostsCommon(value, quantity, userSettings.vatRate.vatRate, userSettings.sellerCommissionRate.sellerCommissionPercent, userSettings.sellerCommissionRate.sellerCommissionFlat);
};
export var summaryToCosts = function (_a) {
    var transactionFeeGross = _a.transactionFeeGross, ticketsCost = _a.ticketsCost, deliveryCostGross = _a.deliveryCostGross, total = _a.total;
    return {
        ticketCost: ticketsCost,
        transactionFee: transactionFeeGross,
        deliveryCost: deliveryCostGross,
        total: total,
    };
};
export function onCreateSellTransaction(_a) {
    var _b = _a.payload, ticketType = _b.ticketType, seatingInformation = _b.seatingInformation, sellRestrictions = _b.sellRestrictions, faceValue = _b.faceValue;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, put(confirmTransaction({ ticketType: ticketType, seatingInformation: seatingInformation, sellRestrictions: sellRestrictions, faceValue: faceValue }))];
            case 1:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function onCreateBuyTransaction(_a) {
    var userDetails, e_1, paymentMethodId, result, confirmWithClear, e_2, errorMsg;
    var _b, _c;
    var _d = _a.payload, ticketType = _d.ticketType, seatingInformation = _d.seatingInformation, stripe = _d.stripe, data = _d.data, cardId = _d.cardId, deliveryAddress = _d.deliveryAddress, userEmail = _d.userEmail, userFullName = _d.userFullName, buyerUserData = _d.buyerUserData, confirm = _d.confirm, elements = _d.elements;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!!stripe) return [3 /*break*/, 4];
                return [4 /*yield*/, call(toast.error, 'Problem with payment provider. Please try later')];
            case 1:
                _e.sent();
                return [4 /*yield*/, put(confirmTransactionFailure('Problem with payment provider. Please try later'))];
            case 2:
                _e.sent();
                return [4 /*yield*/, call(confirm, 'fail')];
            case 3:
                _e.sent();
                return [2 /*return*/];
            case 4:
                _e.trys.push([4, 17, , 21]);
                return [4 /*yield*/, select(selectUserDetails)];
            case 5:
                userDetails = _e.sent();
                if (!!userDetails) return [3 /*break*/, 9];
                _e.label = 6;
            case 6:
                _e.trys.push([6, 8, , 9]);
                return [4 /*yield*/, call(authApi.register, {
                        fullName: userFullName,
                        email: userEmail
                    })];
            case 7:
                _e.sent();
                return [3 /*break*/, 9];
            case 8:
                e_1 = _e.sent();
                if (!(hasProperty(e_1, 'data') && isArray(e_1.data) && e_1.data.some(function (c) { return (c === null || c === void 0 ? void 0 : c.code) === 'DuplicateEmail'; }))) {
                    throw e_1;
                }
                return [3 /*break*/, 9];
            case 9:
                paymentMethodId = cardId;
                if (!!paymentMethodId) return [3 /*break*/, 15];
                return [4 /*yield*/, stripe.createPaymentMethod({ type: 'card', card: elements, billing_details: omitBy(data, isNil) })];
            case 10:
                result = _e.sent();
                if (!result.error) return [3 /*break*/, 14];
                return [4 /*yield*/, call(toast.error, (_b = tryMapError(result.error)) !== null && _b !== void 0 ? _b : result.error.message)];
            case 11:
                _e.sent();
                return [4 /*yield*/, put(confirmTransactionFailure(result.error.message || ''))];
            case 12:
                _e.sent();
                return [4 /*yield*/, call(confirm, 'fail')];
            case 13:
                _e.sent();
                return [2 /*return*/];
            case 14:
                paymentMethodId = result.paymentMethod.id;
                _e.label = 15;
            case 15:
                confirmWithClear = function (status) {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(status === 'success')) return [3 /*break*/, 2];
                                return [4 /*yield*/, put(setGuestCheckoutEmail(''))];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [4 /*yield*/, call(confirm, status)];
                            case 3:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                };
                return [4 /*yield*/, put(confirmTransaction({ ticketType: ticketType, seatingInformation: seatingInformation, stripe: stripe, paymentToken: { paymentMethodId: paymentMethodId }, deliveryAddress: deliveryAddress, userEmail: userEmail, userFullName: userFullName, buyerUserData: buyerUserData, confirm: confirmWithClear }))];
            case 16:
                _e.sent();
                return [3 /*break*/, 21];
            case 17:
                e_2 = _e.sent();
                return [4 /*yield*/, call(confirm, 'fail')];
            case 18:
                _e.sent();
                errorMsg = (_c = tryMapError(e_2)) !== null && _c !== void 0 ? _c : get(e_2, 'message');
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 19:
                _e.sent();
                return [4 /*yield*/, put(confirmTransactionFailure(errorMsg))];
            case 20:
                _e.sent();
                return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
export function onConfirmTransaction(_a) {
    var ticketType, seatingInformation, reservation, globalSettings, userSettings, event, confirm, listingSnapshot, quantity, buyPayload, sellPayload, costFn, userCountry, isInternational, costsSummary, costs, transactionResponse, intentResponse, transaction, params, sellerCommissionRate, gtmPurchaseInfo, e_3, defaultErrorMsg;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var payload = _a.payload;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                ticketType = payload.ticketType, seatingInformation = payload.seatingInformation;
                return [4 /*yield*/, select(selectReservation)];
            case 1:
                reservation = _l.sent();
                return [4 /*yield*/, select(selectGlobalSettings)];
            case 2:
                globalSettings = _l.sent();
                return [4 /*yield*/, select(selectSettings)];
            case 3:
                userSettings = _l.sent();
                return [4 /*yield*/, select(eventDetailsSelector)];
            case 4:
                event = _l.sent();
                confirm = 'confirm' in payload ? payload.confirm : function () { };
                _l.label = 5;
            case 5:
                _l.trys.push([5, 31, , 37]);
                if (!reservation || !userSettings || !globalSettings) {
                    // DM TODO: handle such case.
                    throw Error('Lack of reservation');
                }
                if (!(reservation.endTime.getTime() < Date.now())) return [3 /*break*/, 10];
                return [4 /*yield*/, call(toast.error, 'This reservation has expired')];
            case 6:
                _l.sent();
                return [4 /*yield*/, put(confirmTransactionFailure('This reservation has expired'))];
            case 7:
                _l.sent();
                return [4 /*yield*/, put(changeReservation(null))];
            case 8:
                _l.sent();
                return [4 /*yield*/, call(confirm, 'fail')];
            case 9:
                _l.sent();
                return [3 /*break*/, 30];
            case 10:
                listingSnapshot = reservation.listingSnapshot, quantity = reservation.quantity;
                buyPayload = 'stripe' in payload ? payload : {};
                sellPayload = !('stripe' in payload) ? payload : {};
                costFn = reservation.type === 'buy'
                    ? getBuyCosts
                    : getSellCosts;
                userCountry = ((_b = buyPayload.deliveryAddress) === null || _b === void 0 ? void 0 : _b.country) || null;
                isInternational = reservation.type === 'buy' && ticketType === 'paper' && !!userCountry
                    ? isInternationalShipping(userCountry, (_c = reservation.listingSnapshot) === null || _c === void 0 ? void 0 : _c.ownerInfo, event)
                    : false;
                costsSummary = costFn(listingSnapshot.value, ticketType || listingSnapshot.ticketType, quantity, userSettings, isInternational);
                costs = summaryToCosts(costsSummary);
                return [4 /*yield*/, call(transactionApi.create, {
                        reservationId: reservation.id,
                        ticketType: ticketType || listingSnapshot.ticketType,
                        costs: costs,
                        paymentToken: buyPayload.paymentToken,
                        seatingInformation: seatingInformation,
                        sellRestrictions: sellPayload.sellRestrictions,
                        faceValue: sellPayload.faceValue,
                        deliveryAddress: buyPayload.deliveryAddress,
                        transferRecipient: undefined,
                        userEmail: buyPayload.userEmail || ''
                    })];
            case 11:
                transactionResponse = _l.sent();
                if (!transactionResponse.actionRequired) return [3 /*break*/, 20];
                if (!!('stripe' in payload)) return [3 /*break*/, 13];
                return [4 /*yield*/, put(confirmTransactionFailure('No stripe object'))];
            case 12:
                _l.sent();
                return [2 /*return*/];
            case 13: return [4 /*yield*/, call(payload.stripe.handleCardAction, transactionResponse.paymentIntentClientSecret)];
            case 14:
                intentResponse = _l.sent();
                if (!intentResponse.error) return [3 /*break*/, 18];
                return [4 /*yield*/, call(toast.error, (_d = tryMapError(intentResponse.error)) !== null && _d !== void 0 ? _d : intentResponse.error.message)];
            case 15:
                _l.sent();
                return [4 /*yield*/, put(confirmTransactionFailure(intentResponse.error))];
            case 16:
                _l.sent();
                return [4 /*yield*/, call(confirm, 'fail')];
            case 17:
                _l.sent();
                return [2 /*return*/];
            case 18: return [4 /*yield*/, call(onConfirmTransaction, confirmTransaction(__assign(__assign({}, payload), { paymentToken: { paymentIntentId: intentResponse.paymentIntent.id } })))];
            case 19:
                _l.sent();
                return [2 /*return*/];
            case 20: return [4 /*yield*/, put(changeReservation(null))];
            case 21:
                _l.sent();
                transaction = rawTransactionToTransaction(reservation.type)(transactionResponse.transaction);
                if (!(reservation.type === 'sell')) return [3 /*break*/, 23];
                return [4 /*yield*/, put(loadSale(transaction.orderId))];
            case 22:
                _l.sent();
                return [3 /*break*/, 25];
            case 23: return [4 /*yield*/, put(loadPurchaseSuccess(transaction))];
            case 24:
                _l.sent();
                _l.label = 25;
            case 25: return [4 /*yield*/, call(confirm, 'success')];
            case 26:
                _l.sent();
                params = transactionResponse.setPasswordToken || transactionResponse.transactionGuestToken
                    ? { ptoken: (_e = transactionResponse.setPasswordToken) !== null && _e !== void 0 ? _e : '', ttoken: (_f = transactionResponse.transactionGuestToken) !== null && _f !== void 0 ? _f : '', email: (_g = buyPayload.userEmail) !== null && _g !== void 0 ? _g : '', uname: (_h = buyPayload.userFullName) !== null && _h !== void 0 ? _h : '' }
                    : undefined;
                return [4 /*yield*/, put(replace(routes.transactionConfirmationWithParams(reservation.type, "" + transactionResponse.transaction.orderId, params)))];
            case 27:
                _l.sent();
                sellerCommissionRate = ((_j = transactionResponse.transaction.costs.timestamp) !== null && _j !== void 0 ? _j : 0) / 189456 / 10000;
                gtmPurchaseInfo = {
                    currencyCode: listingSnapshot.currency,
                    eventId: listingSnapshot.eventId,
                    fullEventName: getEventFullNameFromArgs(transaction.event.name, transaction.event.venueName, transaction.event.venueCity, transaction.event.date, transaction.event.timezone),
                    quantity: transaction.quantity,
                    category: [transaction.event.name, formatVenueData(transaction.event)],
                    transactionId: transaction.orderId,
                    costs: costsSummary,
                    cid: Math.round((sellerCommissionRate + userSettings.buyerCommissionRate.buyerCommissionPercent) / (1 + userSettings.vatRate.vatRate) * 100) / 100,
                    userData: buyPayload.buyerUserData
                };
                return [4 /*yield*/, call(pushPurchaseInfo, gtmPurchaseInfo)];
            case 28:
                _l.sent();
                return [4 /*yield*/, put(confirmTransactionSuccess())];
            case 29:
                _l.sent();
                _l.label = 30;
            case 30: return [3 /*break*/, 37];
            case 31:
                e_3 = _l.sent();
                console.error(e_3);
                return [4 /*yield*/, put(confirmTransactionFailure(e_3))];
            case 32:
                _l.sent();
                if (!(reservation && reservation.endTime.getTime() < Date.now())) return [3 /*break*/, 34];
                return [4 /*yield*/, put(changeReservation(null))];
            case 33:
                _l.sent();
                _l.label = 34;
            case 34: return [4 /*yield*/, call(confirm, 'fail')];
            case 35:
                _l.sent();
                defaultErrorMsg = !reservation
                    ? 'Reservation does not exist'
                    : reservation.type === 'buy'
                        ? 'We have been unable to process your card payment. Please check that the details you have entered are correct and try again or alternatively please try another card.'
                        : 'We have been unable to confirm this sale. Please try again or contact Beeyay Support if the issue persists.';
                return [4 /*yield*/, call(toast.error, (_k = tryMapError(e_3)) !== null && _k !== void 0 ? _k : defaultErrorMsg)];
            case 36:
                _l.sent();
                return [3 /*break*/, 37];
            case 37: return [2 /*return*/];
        }
    });
}
export function onLoadPurchases() {
    var filters, rawPurchases, purchases, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(filteringSelect)];
            case 1:
                filters = _a.sent();
                return [4 /*yield*/, call(transactionApi.getUserPurchases, filters)];
            case 2:
                rawPurchases = _a.sent();
                purchases = rawPurchases.results.map(rawTransactionToTransaction('buy'));
                return [4 /*yield*/, put(loadPurchasesSuccess(sortBy(purchases, function (p) { return -p.orderId; })))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_4 = _a.sent();
                return [4 /*yield*/, put(loadPurchasesFailure(e_4))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadSales() {
    var filters, rawSales, sales, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(filteringSelect)];
            case 1:
                filters = _a.sent();
                return [4 /*yield*/, call(transactionApi.getUserSales, filters)];
            case 2:
                rawSales = _a.sent();
                sales = rawSales.results.map(rawTransactionToTransaction('sell'));
                return [4 /*yield*/, put(loadSalesSuccess(orderBy(sales, ['event.date', 'event.name', 'orderId'])))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_5 = _a.sent();
                return [4 /*yield*/, put(loadSalesFailure(e_5))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onDownloadTickets(_a) {
    var orderId, blob, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                orderId = payload.orderId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(transactionApi.downloadTickets, orderId)];
            case 2:
                blob = _b.sent();
                return [4 /*yield*/, call(download, blob, "tickets_" + orderId + ".pdf")];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                e_6 = _b.sent();
                console.error(e_6);
                return [4 /*yield*/, call(toast.error, "Cannot download tickets for order " + orderId + ". Please try later")];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onDownloadLabel(_a) {
    var transactionId, orderId, done, generateGenericErrorMsg, userDetails, blob, sale, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                transactionId = payload.transactionId, orderId = payload.orderId, done = payload.done;
                generateGenericErrorMsg = React.createElement("span", null,
                    "It is not currently possible to generate a delivery label. Please ",
                    React.createElement(Link, { to: routes.createUserWorkItem(routes.userSales(), 'saleOrder', orderId) }, "Contact Us"));
                _b.label = 1;
            case 1:
                _b.trys.push([1, 15, , 18]);
                return [4 /*yield*/, select(selectUserDetails)];
            case 2:
                userDetails = _b.sent();
                if (!!userDetails) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, generateGenericErrorMsg)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelFailure(generateGenericErrorMsg))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(done)];
            case 5:
                _b.sent();
                return [2 /*return*/];
            case 6:
                if (!!userDetails.defaultDeliveryAddress) return [3 /*break*/, 10];
                return [4 /*yield*/, call(toast.error, (React.createElement("span", null,
                        "You must have saved an address to your account before you can print a delivery label. This is so that we know where the tickets are being sent from and can generate the correct label. Please enter your default address in ",
                        React.createElement(Link, { to: routes.userAddresses() }, "My Account"),
                        ".")))];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelFailure('You must have saved an address to your account before you can print a delivery label'))];
            case 8:
                _b.sent();
                return [4 /*yield*/, call(done)];
            case 9:
                _b.sent();
                return [2 /*return*/];
            case 10: return [4 /*yield*/, call(transactionApi.downloadLabel, transactionId)];
            case 11:
                blob = _b.sent();
                return [4 /*yield*/, call(transactionApi.getUserSale, orderId)];
            case 12:
                sale = _b.sent();
                return [4 /*yield*/, call(download, blob, "label_" + orderId + ".pdf")];
            case 13:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelSuccess(rawTransactionToTransaction('sell')(sale)))];
            case 14:
                _b.sent();
                return [3 /*break*/, 18];
            case 15:
                e_7 = _b.sent();
                console.error(e_7);
                return [4 /*yield*/, put(downloadLabelFailure(e_7))];
            case 16:
                _b.sent();
                return [4 /*yield*/, call(toast.error, generateGenericErrorMsg)];
            case 17:
                _b.sent();
                return [3 /*break*/, 18];
            case 18: return [4 /*yield*/, call(done)];
            case 19:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onDownloadLabels(_a) {
    var transactionIds, done, generateGenericErrorMsg, salesFilters, userDetails, blob, rawSales, sales, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                transactionIds = payload.transactionIds, done = payload.done;
                generateGenericErrorMsg = React.createElement("span", null, "It is not currently possible to generate a delivery label.");
                _b.label = 1;
            case 1:
                _b.trys.push([1, 16, , 19]);
                return [4 /*yield*/, select(filteringSelect)];
            case 2:
                salesFilters = _b.sent();
                return [4 /*yield*/, select(selectUserDetails)];
            case 3:
                userDetails = _b.sent();
                if (!!userDetails) return [3 /*break*/, 7];
                return [4 /*yield*/, call(toast.error, generateGenericErrorMsg)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelFailure(generateGenericErrorMsg))];
            case 5:
                _b.sent();
                return [4 /*yield*/, call(done)];
            case 6:
                _b.sent();
                return [2 /*return*/];
            case 7:
                if (!!userDetails.defaultDeliveryAddress) return [3 /*break*/, 11];
                return [4 /*yield*/, call(toast.error, (React.createElement("span", null,
                        "You must have saved an address to your account before you can print a delivery label. This is so that we know where the tickets are being sent from and can generate the correct label. Please enter your default address in ",
                        React.createElement(Link, { to: routes.userAddresses() }, "My Account"),
                        ".")))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelFailure('You must have saved an address to your account before you can print a delivery label'))];
            case 9:
                _b.sent();
                return [4 /*yield*/, call(done)];
            case 10:
                _b.sent();
                return [2 /*return*/];
            case 11: return [4 /*yield*/, call(transactionApi.downloadLabels, transactionIds)];
            case 12:
                blob = _b.sent();
                return [4 /*yield*/, call(transactionApi.getUserSales, salesFilters)];
            case 13:
                rawSales = _b.sent();
                sales = rawSales.results.map(rawTransactionToTransaction('sell'));
                return [4 /*yield*/, call(download, blob, 'labels.pdf')];
            case 14:
                _b.sent();
                return [4 /*yield*/, put(downloadLabelsSuccess(orderBy(sales, ['event.date', 'event.name', 'orderId'])))];
            case 15:
                _b.sent();
                return [3 /*break*/, 19];
            case 16:
                e_8 = _b.sent();
                console.error(e_8);
                return [4 /*yield*/, put(downloadLabelsFailure(e_8))];
            case 17:
                _b.sent();
                return [4 /*yield*/, call(toast.error, generateGenericErrorMsg)];
            case 18:
                _b.sent();
                return [3 /*break*/, 19];
            case 19: return [4 /*yield*/, call(done)];
            case 20:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onUpdateSaleETickets(_a) {
    var orderId, filesInfo, redirect, e_9;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                orderId = payload.orderId, filesInfo = payload.filesInfo, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(transactionApi.updateTicketsInfo, orderId, filesInfo)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSale(orderId))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(clearFilesRepository())];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                e_9 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update etickets on transaction')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onUpdateSaleTransferRecipt(_a) {
    var orderId, filesInfo, redirect, e_10;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                orderId = payload.orderId, filesInfo = payload.filesInfo, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(transactionApi.updateTicketsInfo, orderId, filesInfo)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSale(orderId))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(clearFilesRepository())];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                e_10 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update transfer recipt on transaction')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onUpdateSalesETickets(_a) {
    var ticketsInfo, redirect, e_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                ticketsInfo = payload.ticketsInfo, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(transactionApi.updateTicketsInfoBatched, ticketsInfo)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSales())];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(clearFilesRepository())];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                e_11 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update etickets on transaction')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onUpdateSalesTransferRecipt(_a) {
    var ticketsInfo, redirect, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                ticketsInfo = payload.ticketsInfo, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(transactionApi.updateTicketsInfoBatched, ticketsInfo)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSales())];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(clearFilesRepository())];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                e_12 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update transfer recipt on transaction')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onUpdateSaleTrackingNumber(_a) {
    var orderId, trackingNumber, redirect, e_13;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                orderId = payload.orderId, trackingNumber = payload.trackingNumber, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(transactionApi.updateTrackingNumber, orderId, trackingNumber)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSale(orderId))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                e_13 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update tracking number on transaction')];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onUpdateSalesTrackingNumbers(_a) {
    var trackingNumbers, redirect, e_14;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                trackingNumbers = payload.trackingNumbers, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(transactionApi.updateTrackingNumbersBatched, trackingNumbers)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(loadSales())];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(redirect)];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                e_14 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot update tracking numbers on transactions')];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onUpdateTransactionFilters(_a) {
    var e_15;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                if (!(payload.transactionSide === 'sell')) return [3 /*break*/, 2];
                return [4 /*yield*/, put(loadSales())];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                if (!(payload.transactionSide === 'buy')) return [3 /*break*/, 4];
                return [4 /*yield*/, put(loadPurchases())];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                e_15 = _b.sent();
                return [4 /*yield*/, call(toast.error, "Cannot load " + (payload.transactionSide === 'sell' ? 'sales' : 'purchases'))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function transactionsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(createBuyTransaction.getType(), onCreateBuyTransaction)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLeading(createSellTransaction.getType(), onCreateSellTransaction)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(confirmTransaction.getType(), onConfirmTransaction)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest([loadPurchases.getType(), silentLoadPurchases.getType()], onLoadPurchases)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest([loadSales.getType(), silentLoadSales.getType()], onLoadSales)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(downloadTickets.getType(), onDownloadTickets)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(downloadLabel.getType(), onDownloadLabel)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(downloadLabels.getType(), onDownloadLabels)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSaleETickets.getType(), onUpdateSaleETickets)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSaleTransferRecipt.getType(), onUpdateSaleTransferRecipt)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSalesETickets.getType(), onUpdateSalesETickets)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSalesTransferReceipt.getType(), onUpdateSalesTransferRecipt)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSaleTrackingNumber.getType(), onUpdateSaleTrackingNumber)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSalesTrackingNumbers.getType(), onUpdateSalesTrackingNumbers)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateTransactionFilters.getType(), onUpdateTransactionFilters)];
            case 15:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Label } from '@components/forms';
import { PasswordInputField } from '@components/forms/input';
import { t } from '@containers/helpers/tests';
import { resetUserPassword, verifyResetPasswordToken } from '@logic/user';
import qs from 'qs';
import React, { Component, Fragment, useCallback } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { Form as SemanticForm, Header } from 'semantic-ui-react';
import { getQueryString, routes } from 'src/client/routing/routes';
import styles from '../Login/login.less';
import { withPageOptions } from '../Page';
export function ResetPasswordActionForm(props) {
    var formApiHandleSubmit = props.formApi.handleSubmit;
    var handleSubmit = useCallback(function () { return formApiHandleSubmit(); }, [formApiHandleSubmit]);
    var location = useLocation();
    var isSetPassword = location.pathname === routes.setPasswordAction();
    var isPending = props.isPending, errors = props.errors;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", __assign({ className: styles.resetPassword }, t('resetPasswordAction')),
            React.createElement(Header, { as: 'h4', className: styles.header, textAlign: 'center' },
                isSetPassword ? 'Set' : 'Reset',
                " your password"),
            isSetPassword && React.createElement(Header, { as: 'h5', className: styles.subheader }, "In order to keep your information secure you are required to set a password before you can view the full information relating to your purchase."),
            React.createElement(SemanticForm, { loading: isPending, error: !!errors.length },
                React.createElement(Label, { fill: true },
                    "Password",
                    React.createElement(PasswordInputField, { name: 'password', placeholder: 'Password', large: true })),
                React.createElement(Label, { fill: true },
                    "Confirm Password",
                    React.createElement(PasswordInputField, { name: 'confirmPassword', placeholder: 'Confirm Password', large: true })),
                React.createElement("div", __assign({ className: styles.errorMessage }, t('error')), errors.map(function (e) { return React.createElement(Fragment, { key: e }, e); })),
                React.createElement(Button, { ternary: true, fill: true, type: "submit", onClick: handleSubmit, className: styles.button }, isSetPassword ? 'Set Password' : 'Change Password')))));
}
var ResetPasswordAction = /** @class */ (function (_super) {
    __extends(ResetPasswordAction, _super);
    function ResetPasswordAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleSubmit = function (values) {
            var _a = qs.parse(getQueryString(_this.props.location)), token = _a.token, email = _a.email, next = _a.next;
            var isSetPassword = location.pathname === routes.setPasswordAction();
            _this.props.actions.resetUserPassword(email != null ? "" + email : '', token != null ? "" + token : '', values.password, values.confirmPassword, isSetPassword ? 'set' : 'reset', next != null ? "" + next : '');
        };
        return _this;
    }
    ResetPasswordAction.prototype.componentDidMount = function () {
        var _a = qs.parse(getQueryString(this.props.location)), token = _a.token, email = _a.email;
        this.props.actions.verifyResetPasswordToken(email != null ? "" + email : '', token != null ? "" + token : '');
    };
    ResetPasswordAction.prototype.render = function () {
        var _this = this;
        return (React.createElement(Form, { onSubmit: this.handleSubmit }, function (formApi) { return React.createElement(ResetPasswordActionForm, { formApi: formApi, isPending: _this.props.isPending, errors: _this.props.errors }); }));
    };
    return ResetPasswordAction;
}(Component));
export { ResetPasswordAction };
var mapStateToProps = function (state) { return ({
    isPending: state.user.isPending,
    errors: state.user.resetPasswordErrors,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        resetUserPassword: resetUserPassword,
        verifyResetPasswordToken: verifyResetPasswordToken,
    }, dispatch),
}); };
export default compose(withPageOptions({ search: false }), connect(mapStateToProps, mapDispatchToProps))(ResetPasswordAction);

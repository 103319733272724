import React from 'react';
import styles from '@containers/DetailsPages/components/venueAddress/venueAddress.less';
import { Images } from '@logic/images';
export var VenueAddress = function (_a) {
    var data = _a.data, venueInformationRef = _a.venueInformationRef;
    var address = data.address, city = data.city, venueName = data.venueName, img = data.img;
    return React.createElement("div", { className: styles.venueAddressSection, ref: venueInformationRef },
        React.createElement("h2", { className: 'header' }, venueName + " in " + city),
        React.createElement("div", { className: 'line' }),
        React.createElement("div", { className: 'content' },
            React.createElement("img", { src: img + "/" + Images.eventList(venueName || ''), height: "94px", width: "94px" }),
            React.createElement("div", { className: 'address' },
                React.createElement("p", null, address.street1),
                React.createElement("p", null, city),
                React.createElement("p", null, address === null || address === void 0 ? void 0 : address.postCode))),
        React.createElement("div", { className: 'line' }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './categoriesNavigationHome.less';
import { CATEGORY_TYPES } from '@logic/categories';
import cn from 'classnames';
import { routes } from 'src/client/routing/routes';
import { Link } from 'react-router-dom';
var concateneteAndSanitizeCategories = function (categories) {
    return categories.map(function (category) {
        var name = category.name.split(' ')[0];
        if (name === CATEGORY_TYPES.festival || name === CATEGORY_TYPES.concert) {
            name += 's';
        }
        return __assign(__assign({}, category), { name: name });
    });
};
export var CategoriesHomeSearchLinks = function (_a) {
    var _b;
    var isFixedNavigation = _a.isFixedNavigation, hide = _a.hide;
    var categories = useSelector(function (gi) { return gi.categories.categories; });
    var dummyParsedCategory = concateneteAndSanitizeCategories(categories);
    return (React.createElement("div", { className: cn(styles.linksContainer, (_b = {}, _b[styles.navigationElement] = isFixedNavigation, _b[styles.hide] = hide, _b)) }, dummyParsedCategory.map(function (category, index) {
        return React.createElement(Link, { to: routes.category(category.localId), className: cn(styles.linkButton, category.localId), key: "category-link-button-" + index }, category.name);
    })));
};
export var CategoriesNavigationHome = function () {
    var categories = useSelector(function (gi) { return gi.categories.categories; });
    var dummyParsedCategory = concateneteAndSanitizeCategories(categories);
    return (React.createElement("div", { className: styles.navigationRow },
        React.createElement("div", { className: styles.buttonsContainer }, dummyParsedCategory.map(function (category, index) {
            return React.createElement(Link, { to: routes.category(category.localId), className: cn(styles.categoryButton, category.localId), key: "category-button-" + index }, category.name);
        }))));
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export var currencyMapping = Object.seal({
    GBP: {
        name: 'GBP',
        symbol: '£',
        begin: true,
    },
    USD: {
        name: 'USD',
        symbol: '$',
        begin: true,
    },
    PLN: {
        name: 'PLN',
        symbol: 'zł',
        begin: false,
    },
    EUR: {
        name: 'EUR',
        symbol: '€',
        begin: false,
    },
});
export function isCurrency(c) {
    return Object.prototype.hasOwnProperty.call(currencyMapping, c);
}
export var currencyEntries = Object
    .entries(currencyMapping)
    .map(function (_a) {
    var _b = __read(_a, 2), k = _b[0], v = _b[1];
    return [k, v.name];
});
export var toFloatPriceValue = function (value, trimDecimals) {
    if (trimDecimals === void 0) { trimDecimals = false; }
    return (value / 100).toFixed(trimDecimals ? 0 : 2).replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
};
export var formatPrice = function (_a, showCurrency, trimDecimals) {
    var value = _a.value, currency = _a.currency;
    if (showCurrency === void 0) { showCurrency = 'name'; }
    if (trimDecimals === void 0) { trimDecimals = false; }
    var formattedValue = toFloatPriceValue(value, trimDecimals);
    switch (showCurrency) {
        case 'name':
            return currencyMapping[currency].name + " " + formattedValue;
        case 'onlyName':
            return currencyMapping[currency].name;
        case 'symbol':
            return currencyMapping[currency].begin
                ? "" + currencyMapping[currency].symbol + formattedValue
                : formattedValue + " " + currencyMapping[currency].symbol;
        default:
            return formattedValue;
    }
};
export var toIntPriceValue = function (value) {
    return Math.round(parseFloat("" + value) * 100);
};

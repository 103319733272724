import { BUY_ONLY } from 'src/client/routing/routes';
export function getRouteParams(_a) {
    var match = _a.match;
    var slug = match.params.id;
    var id = parseInt(slug, 10);
    // in case of any other string we should fallback to 'buy' transaction side.
    var transactionSide = match.params.transactionSide === 'sell' ? 'sell' : 'buy';
    var buyOnly = match.params.transactionSide === BUY_ONLY;
    return { transactionSide: transactionSide, buyOnly: buyOnly, id: id };
}

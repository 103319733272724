var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import { getRedirectToWithReplace } from '@logic/helpers/location';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { routes } from 'src/client/routing/routes';
import { withPage } from '../Page';
import styles from './resetSent.less';
export function ResetSent() {
    var location = useLocation();
    var isSetPassword = location.pathname === routes.setPasswordSent();
    return (React.createElement("div", __assign({ className: styles.container }, t('CheckYourInbox')),
        React.createElement(Header, null, "Please check your email"),
        React.createElement("p", null,
            "We've sent you a secure link that will allow you to ",
            isSetPassword ? 'set' : 'reset',
            " your password."),
        React.createElement(Link, { className: styles.link, to: getRedirectToWithReplace({
                redirect: routes.index(),
                location: location,
            }), replace: true },
            React.createElement("span", __assign({}, t('toIndex')), "Go to main page"))));
}
export default withPage(ResetSent);

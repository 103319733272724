var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useWindowWatcher } from '@containers/hooks/useWindowWatcher';
import React, { useRef } from 'react';
import { useState } from 'react';
import Helmet from 'react-helmet';
var spotifyLinkToUri = function (spotifyLink) {
    var _a;
    var isLinkContainsEmbed = spotifyLink.includes('embed');
    var splitUrl = spotifyLink.split(isLinkContainsEmbed ? 'com/embed' : 'com');
    return "spotify" + ((_a = splitUrl[1]) === null || _a === void 0 ? void 0 : _a.replace(/\//g, ':'));
};
export var Spotify = function (props) {
    var spotifyLink = props.spotifyLink;
    var widgetHeight = 352;
    var _a = __read(useState(false), 2), spotifyEmbedReady = _a[0], setSpotifyEmbedReady = _a[1];
    var embedElement = useRef(null);
    var options = {
        uri: spotifyLinkToUri(spotifyLink),
        height: widgetHeight
    };
    var callback = function (EmbedController) {
        EmbedController.addListener('ready', function () { return setSpotifyEmbedReady(true); });
    };
    useWindowWatcher(function (SpotifyIFrameApi) {
        try {
            if (embedElement.current) {
                SpotifyIFrameApi.createController(embedElement.current, options, callback);
            }
        }
        catch (err) {
            console.error("Spotify embed API error: " + err);
        }
    }, 200, 25, function (window) { return window.SpotifyIFrameApi; });
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("script", { src: "https://open.spotify.com/embed/iframe-api/v1", defer: true }),
            React.createElement("script", null, "\n            window.onSpotifyIframeApiReady = (IFrameAPI) => {\n              window.SpotifyIFrameApi = IFrameAPI;\n            }\n          ")),
        React.createElement("div", { style: spotifyEmbedReady ? { visibility: 'visible' } : { visibility: 'hidden', height: 0, position: 'absolute' } },
            React.createElement("div", { ref: embedElement }))));
};

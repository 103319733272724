import { useEffect } from 'react';
// useEffect wrapper that allows complex types in dependency 
// array to trigger refresh on value change instead of reference
export function useComplexEffect(fn, refresh) {
    var stringifiedRefresh = refresh.map(function (r) {
        if (r instanceof Function) {
            return r;
        }
        return JSON.stringify(r);
    });
    useEffect(fn, stringifiedRefresh); // eslint-disable-line react-hooks/exhaustive-deps
}

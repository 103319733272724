import { Button } from '@components/forms';
import { ReactComponent as DownArrow } from '../../Navigation/img/downArrowSmall.svg';
import styles from './moreResultsButton.less';
import React from 'react';
export var MoreResultsButton = function (_a) {
    var onMoreResultsClick = _a.onMoreResultsClick, textContent = _a.textContent, events = _a.events, eventsCount = _a.eventsCount;
    var moreResultsLoadable = events.length > 0 && eventsCount > events.length;
    return (React.createElement(React.Fragment, null, moreResultsLoadable &&
        React.createElement("div", { className: styles.moreResults },
            React.createElement(Button, { ternary: true, className: styles.moreResultsButton, onClick: onMoreResultsClick },
                React.createElement(DownArrow, { className: styles.moreResultsArrow }), textContent !== null && textContent !== void 0 ? textContent : 'More results'))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _a;
import get from 'lodash/get';
import range from 'lodash/range';
import zip from 'lodash/zip';
import { createAction, createReducer } from 'redux-act';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import uuid from 'uuid/v1';
import { push } from 'connected-react-router';
import fileTypeLib from 'file-type/browser';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import { routes } from 'src/client/routing/routes';
import { asArrayBuffer, asDataUrl, readFileAsync } from './helpers/async';
import request from './helpers/request';
import { loadSalesSuccess } from './transactions';
import isEqual from 'lodash/isEqual';
export var loadPdfFile = createAction('load PDF pages from file and add to repostiory', function (holderId, file) { return ({ holderId: holderId, file: file }); });
export var loadImgFile = createAction('load img item from file and add to repostiory', function (holderId, file) { return ({ holderId: holderId, file: file }); });
export var addFiles = createAction('add tickets to repository', function (tickets) { return ({ tickets: tickets }); });
export var updateFile = createAction('update ticket data in repository', function (ticket) { return ({ ticket: ticket }); });
export var removeFile = createAction('remove ticket from repository', function (id) { return ({ id: id }); });
export var getFileImg = createAction('get ticket image by id', function (holderId, ticketInfo) { return ({ holderId: holderId, ticketInfo: ticketInfo }); });
export var getMultipleFileImg = createAction('get tickets images by id', function (holderId, ticketsInfo) { return ({ holderId: holderId, ticketsInfo: ticketsInfo }); });
export var uploadETicket = createAction('upload e tickets', function (ticket) { return ({ ticket: ticket }); });
export var changeHolder = createAction('change current etickets holder', function (holderIds) { return ({ holderIds: holderIds }); });
export var clearFilesRepository = createAction('clear files repository');
export var uploadTicketsFromConfirmation = createAction('upload etickets from confirmation page', function (transaction, files) { return ({ transaction: transaction, files: files }); });
export var uploadReceiptFromConfirmation = createAction('upload transfer receipt from confirmation page', function (transaction, files) { return ({ transaction: transaction, files: files }); });
var initialState = {
    repository: [],
    holderIds: [],
};
export var imgPlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';
function dataURLtoFile(dataurl, filename) {
    var _a = __read(dataurl.split(','), 2), mimePart = _a[0], bstrPart = _a[1];
    var rawMime = mimePart.match(/:(.*?);/);
    var mime = get(rawMime, '1', 'image/png');
    var bstr = atob(bstrPart);
    var charCodes = bstr.split('').map(function (char) { return char.charCodeAt(0); });
    var u8arr = Uint8Array.from(charCodes);
    return new File([u8arr], filename, {
        type: mime,
    });
}
export var eTicketsApi = {
    getTicketImage: function (id) { return request("/api/tickets/get/" + id, undefined, { responseType: 'blob' }); },
    uploadTicket: function (ticket) {
        var form = new FormData();
        var file = dataURLtoFile(ticket.data, ticket.name);
        form.append('file', file, ticket.name);
        return request('/api/tickets/upload', form);
    },
    uploadFile: function (file) {
        var form = new FormData();
        form.append('file', file, file.name);
        return request('/api/tickets/upload', form);
    },
};
export var uploadImageFilesReducer = createReducer((_a = {},
    _a[addFiles.getType()] = function (_a, _b) {
        var tickets = _b.tickets;
        var ticketsRepository = _a.repository, holderIds = _a.holderIds, rest = __rest(_a, ["repository", "holderIds"]);
        return (__assign(__assign({}, rest), { holderIds: holderIds, repository: __spreadArray(__spreadArray([], __read(ticketsRepository)), __read(tickets.filter(function (ticket) { return isEqual(holderIds.sort(), ticket.holderId.sort()); }))) }));
    },
    _a[updateFile.getType()] = function (state, _a) {
        var ticket = _a.ticket;
        var ticketsRepository = state.repository, holderIds = state.holderIds;
        if (!isEqual(holderIds, ticket.holderId)) {
            return state;
        }
        return __assign(__assign({}, state), { repository: ticketsRepository.map(function (t) { return t.id === ticket.id ? ticket : t; }) });
    },
    _a[removeFile.getType()] = function (_a, _b) {
        var id = _b.id;
        var ticketsRepository = _a.repository, rest = __rest(_a, ["repository"]);
        return (__assign(__assign({}, rest), { repository: ticketsRepository.filter(function (ticket) { return ticket.id !== id; }) }));
    },
    _a[changeHolder.getType()] = function (state, _a) {
        var holderIds = _a.holderIds;
        return isEqual(state.holderIds.sort(), holderIds.sort())
            ? state
            : {
                holderIds: holderIds,
                repository: []
            };
    },
    _a[clearFilesRepository.getType()] = function (state) { return (__assign(__assign({}, state), { repository: [] })); },
    _a), initialState);
export var renderPdfToPng = function (pdfPage) { return __awaiter(void 0, void 0, Promise, function () {
    var canvas, viewport, ctx;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                canvas = document.createElement('canvas');
                viewport = pdfPage.getViewport(2);
                ctx = canvas.getContext('2d');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                return [4 /*yield*/, pdfPage.render({
                        canvasContext: ctx,
                        viewport: viewport,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/, canvas.toDataURL('image/png')];
        }
    });
}); };
var getEmptyImageFile = function (holderId, name, id) { return ({
    id: id || uuid(),
    dbId: id,
    holderId: holderId,
    name: name,
    data: imgPlaceholder,
    status: 'Loading',
}); };
export var getPdfjs = memoizeOne(function () { return import(/* webpackChunkName: "pdfjs" */ 'pdfjs-dist'); });
export var getPdfDocument = function (data) { return getPdfjs().then(function (pdfjs) { return pdfjs.getDocument(data); }); };
export function onLoadPdfFile(_a) {
    var holderId, file, renderedTickets, buffer, data, pdf_1, emptyTickets, pdfPages, _b, _c, _d, pdfPage, emptyTicket, renderedPdf, ticket, e_1_1, uploadedTickets, _e, _f, _g, uploaded, rendered, e_2_1, e_3, renderedTickets_1, renderedTickets_1_1, t, e_4_1;
    var e_1, _h, e_2, _j, e_4, _k;
    var _l;
    var payload = _a.payload;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                holderId = payload.holderId, file = payload.file;
                renderedTickets = [];
                _m.label = 1;
            case 1:
                _m.trys.push([1, 24, , 34]);
                return [4 /*yield*/, call(readFileAsync, file, asArrayBuffer)];
            case 2:
                buffer = _m.sent();
                data = new Uint8Array(buffer);
                return [4 /*yield*/, call(getPdfDocument, data)];
            case 3:
                pdf_1 = _m.sent();
                emptyTickets = range(pdf_1.numPages).map(function (pageId) { return getEmptyImageFile(holderId, file.name + " (" + (pageId + 1) + " of " + pdf_1.numPages + ")"); });
                return [4 /*yield*/, put(addFiles(emptyTickets))];
            case 4:
                _m.sent();
                return [4 /*yield*/, all(range(pdf_1.numPages).map(function (pageId) { return pdf_1.getPage(pageId + 1); }))];
            case 5:
                pdfPages = _m.sent();
                _m.label = 6;
            case 6:
                _m.trys.push([6, 12, 13, 14]);
                _b = __values(zip(pdfPages, emptyTickets)), _c = _b.next();
                _m.label = 7;
            case 7:
                if (!!_c.done) return [3 /*break*/, 11];
                _d = __read(_c.value, 2), pdfPage = _d[0], emptyTicket = _d[1];
                if (!emptyTicket) {
                    return [3 /*break*/, 10];
                }
                return [4 /*yield*/, call(renderPdfToPng, pdfPage)];
            case 8:
                renderedPdf = _m.sent();
                ticket = __assign(__assign({}, emptyTicket), { data: renderedPdf, status: 'Uploading' });
                return [4 /*yield*/, put(updateFile(ticket))];
            case 9:
                _m.sent();
                renderedTickets.push(ticket);
                _m.label = 10;
            case 10:
                _c = _b.next();
                return [3 /*break*/, 7];
            case 11: return [3 /*break*/, 14];
            case 12:
                e_1_1 = _m.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 14];
            case 13:
                try {
                    if (_c && !_c.done && (_h = _b.return)) _h.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 14: return [4 /*yield*/, call(eTicketsApi.uploadFile, file)];
            case 15:
                uploadedTickets = _m.sent();
                _m.label = 16;
            case 16:
                _m.trys.push([16, 21, 22, 23]);
                _e = __values(zip(uploadedTickets, renderedTickets)), _f = _e.next();
                _m.label = 17;
            case 17:
                if (!!_f.done) return [3 /*break*/, 20];
                _g = __read(_f.value, 2), uploaded = _g[0], rendered = _g[1];
                if (!rendered) {
                    return [3 /*break*/, 19];
                }
                return [4 /*yield*/, put(updateFile(__assign(__assign({}, rendered), { status: 'Uploaded', dbId: uploaded === null || uploaded === void 0 ? void 0 : uploaded.id, name: (_l = uploaded === null || uploaded === void 0 ? void 0 : uploaded.fileName) !== null && _l !== void 0 ? _l : rendered.name })))];
            case 18:
                _m.sent();
                _m.label = 19;
            case 19:
                _f = _e.next();
                return [3 /*break*/, 17];
            case 20: return [3 /*break*/, 23];
            case 21:
                e_2_1 = _m.sent();
                e_2 = { error: e_2_1 };
                return [3 /*break*/, 23];
            case 22:
                try {
                    if (_f && !_f.done && (_j = _e.return)) _j.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
                return [7 /*endfinally*/];
            case 23: return [3 /*break*/, 34];
            case 24:
                e_3 = _m.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot process PDF file')];
            case 25:
                _m.sent();
                _m.label = 26;
            case 26:
                _m.trys.push([26, 31, 32, 33]);
                renderedTickets_1 = __values(renderedTickets), renderedTickets_1_1 = renderedTickets_1.next();
                _m.label = 27;
            case 27:
                if (!!renderedTickets_1_1.done) return [3 /*break*/, 30];
                t = renderedTickets_1_1.value;
                return [4 /*yield*/, put(removeFile(t.id))];
            case 28:
                _m.sent();
                _m.label = 29;
            case 29:
                renderedTickets_1_1 = renderedTickets_1.next();
                return [3 /*break*/, 27];
            case 30: return [3 /*break*/, 33];
            case 31:
                e_4_1 = _m.sent();
                e_4 = { error: e_4_1 };
                return [3 /*break*/, 33];
            case 32:
                try {
                    if (renderedTickets_1_1 && !renderedTickets_1_1.done && (_k = renderedTickets_1.return)) _k.call(renderedTickets_1);
                }
                finally { if (e_4) throw e_4.error; }
                return [7 /*endfinally*/];
            case 33: return [3 /*break*/, 34];
            case 34: return [2 /*return*/];
        }
    });
}
export function onGetMultipleFileImg(_a) {
    var holderId, ticketsInfo;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                holderId = payload.holderId, ticketsInfo = payload.ticketsInfo;
                return [4 /*yield*/, all(ticketsInfo.map(function (ti) { return put(getFileImg(holderId, ti)); }))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onLoadImgFile(_a) {
    var holderId, file, ticket, result, updatedTicket;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                holderId = payload.holderId, file = payload.file;
                ticket = getEmptyImageFile(holderId, file.name);
                return [4 /*yield*/, put(addFiles([ticket]))];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(readFileAsync, file, asDataUrl)];
            case 2:
                result = _b.sent();
                updatedTicket = __assign(__assign({}, ticket), { data: result.toString(), status: 'Uploading' });
                return [4 /*yield*/, put(updateFile(updatedTicket))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(uploadETicket(updatedTicket))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onUploadETicket(_a) {
    var ticket, _b, _c, info, _d, id, _e, status, updatedTicket;
    var payload = _a.payload;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                ticket = payload.ticket;
                return [4 /*yield*/, call(eTicketsApi.uploadTicket, ticket)];
            case 1:
                _b = __read.apply(void 0, [_f.sent(), 1]), _c = _b[0], info = _c === void 0 ? { id: null } : _c;
                _d = info.id, id = _d === void 0 ? '' : _d, _e = info.status, status = _e === void 0 ? 'Uploaded' : _e;
                updatedTicket = __assign(__assign({}, ticket), { status: status, dbId: id || '' });
                return [4 /*yield*/, put(updateFile(updatedTicket))];
            case 2:
                _f.sent();
                return [2 /*return*/];
        }
    });
}
var selectTicket = function (state, id) { return state.uploadImageFiles.repository.find(function (t) { return t.id === id; }); };
export function onGetFileImg(_a) {
    var holderId, ticketInfo, ticketId, ticketInState, ticket, image, result, fileType, data, pdf, pdfPage, updatedTicket, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                holderId = payload.holderId, ticketInfo = payload.ticketInfo;
                ticketId = ticketInfo.id;
                if (!ticketId) {
                    return [2 /*return*/];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 13, , 16]);
                return [4 /*yield*/, select(selectTicket, ticketId)];
            case 2:
                ticketInState = _b.sent();
                if (ticketInState) {
                    return [2 /*return*/];
                }
                ticket = getEmptyImageFile(holderId, ticketInfo.fileName || '', ticketId);
                return [4 /*yield*/, put(addFiles([ticket]))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(eTicketsApi.getTicketImage, ticketId)];
            case 4:
                image = _b.sent();
                return [4 /*yield*/, call(readFileAsync, image, asDataUrl)];
            case 5:
                result = _b.sent();
                return [4 /*yield*/, call(fileTypeLib.fromBlob, image)];
            case 6:
                fileType = _b.sent();
                data = '';
                if (!(fileType.mime === 'application/pdf')) return [3 /*break*/, 10];
                return [4 /*yield*/, call(getPdfDocument, result)];
            case 7:
                pdf = _b.sent();
                return [4 /*yield*/, pdf.getPage(1)];
            case 8:
                pdfPage = _b.sent();
                return [4 /*yield*/, call(renderPdfToPng, pdfPage)];
            case 9:
                data = _b.sent();
                return [3 /*break*/, 11];
            case 10:
                data = result.toString();
                _b.label = 11;
            case 11:
                updatedTicket = __assign(__assign({}, ticket), { data: data, status: 'Uploaded' });
                return [4 /*yield*/, put(updateFile(updatedTicket))];
            case 12:
                _b.sent();
                return [3 /*break*/, 16];
            case 13:
                e_5 = _b.sent();
                return [4 /*yield*/, put(removeFile(ticketId))];
            case 14:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load ticket')];
            case 15:
                _b.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
export function onUploadTicketsFromConfirmation(_a) {
    var files, transaction, files_1, files_1_1, file, e_6_1;
    var e_6, _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                files = payload.files, transaction = payload.transaction;
                return [4 /*yield*/, put(changeHolder([transaction.orderId]))];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(loadSalesSuccess([transaction]))];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3:
                _c.trys.push([3, 8, 9, 10]);
                files_1 = __values(files), files_1_1 = files_1.next();
                _c.label = 4;
            case 4:
                if (!!files_1_1.done) return [3 /*break*/, 7];
                file = files_1_1.value;
                return [4 /*yield*/, put(loadPdfFile([transaction.orderId], file))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6:
                files_1_1 = files_1.next();
                return [3 /*break*/, 4];
            case 7: return [3 /*break*/, 10];
            case 8:
                e_6_1 = _c.sent();
                e_6 = { error: e_6_1 };
                return [3 /*break*/, 10];
            case 9:
                try {
                    if (files_1_1 && !files_1_1.done && (_b = files_1.return)) _b.call(files_1);
                }
                finally { if (e_6) throw e_6.error; }
                return [7 /*endfinally*/];
            case 10: return [4 /*yield*/, put(push(routes.userSaleETickets([transaction.orderId])))];
            case 11:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function onUploadReceiptFromConfirmation(_a) {
    var files, transaction, files_2, files_2_1, file, e_7_1;
    var e_7, _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                files = payload.files, transaction = payload.transaction;
                return [4 /*yield*/, put(changeHolder([transaction.orderId]))];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(loadSalesSuccess([transaction]))];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3:
                _c.trys.push([3, 8, 9, 10]);
                files_2 = __values(files), files_2_1 = files_2.next();
                _c.label = 4;
            case 4:
                if (!!files_2_1.done) return [3 /*break*/, 7];
                file = files_2_1.value;
                return [4 /*yield*/, put(loadImgFile([transaction.orderId], file))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6:
                files_2_1 = files_2.next();
                return [3 /*break*/, 4];
            case 7: return [3 /*break*/, 10];
            case 8:
                e_7_1 = _c.sent();
                e_7 = { error: e_7_1 };
                return [3 /*break*/, 10];
            case 9:
                try {
                    if (files_2_1 && !files_2_1.done && (_b = files_2.return)) _b.call(files_2);
                }
                finally { if (e_7) throw e_7.error; }
                return [7 /*endfinally*/];
            case 10: return [4 /*yield*/, put(push(routes.userSaleMobileTransfer([transaction.orderId], transaction.delivery.deliveryType === 'mobileTransfer' ? 'mobileTransfer' : 'ticketmaster')))];
            case 11:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function uploadImageFilesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(loadPdfFile.getType(), onLoadPdfFile)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(loadImgFile.getType(), onLoadImgFile)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(uploadETicket.getType(), onUploadETicket)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getFileImg.getType(), onGetFileImg)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(getMultipleFileImg.getType(), onGetMultipleFileImg)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(uploadTicketsFromConfirmation.getType(), onUploadTicketsFromConfirmation)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(uploadReceiptFromConfirmation.getType(), onUploadReceiptFromConfirmation)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

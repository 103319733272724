var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { getRedirectToWithReplace } from '@logic/helpers/location';
import { logout } from '@logic/user';
import isString from 'lodash/isString';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { routes } from 'src/client/routing/routes';
var Logout = /** @class */ (function (_super) {
    __extends(Logout, _super);
    function Logout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Logout.prototype.componentDidMount = function () {
        this.props.actions.logout();
    };
    Logout.prototype.render = function () {
        var _a = this.props, location = _a.location, match = _a.match;
        var to = location && !isString(location) && location.state
            ? getRedirectToWithReplace({
                redirect: routes.login(match.params.reason),
                location: location,
            })
            : routes.login(match.params.reason);
        return React.createElement(Redirect, { to: to });
    };
    return Logout;
}(Component));
var mapStateToProps = function () { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        logout: logout,
    }, dispatch),
}); };
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Logout);

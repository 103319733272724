var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _a;
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import { tryMapError } from './errorCodeMapper';
import { eventsApi, rawBuyEventListingToEventListing, rawSellEventListingToEventListing } from './events';
import { reverseMap } from './helpers/collections';
import { ticketTypeSimplifiedMapping } from './listings';
import { rawEventTransactionToEventTransaction, transactionApi } from './transactions';
export var loadMarketData = createAction('load marketData', function (id, section, ticketTypes) { return ({
    id: id,
    section: section,
    ticketTypes: ticketTypes,
}); });
export var loadMarketDataSuccess = createAction('load marketData succeed', function (sellListings, buyListings, transactions) { return ({
    sellListings: sellListings,
    buyListings: buyListings,
    transactions: transactions,
}); });
export var loadMarketDataFailure = createAction('load marketData failed', function (errors) { return ({
    errors: errors,
}); });
var initialState = {
    isPending: false,
    sellListings: null,
    buyListings: null,
    transactions: null,
};
export var marketDataReducer = createReducer((_a = {},
    _a[loadMarketData.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[loadMarketDataSuccess.getType()] = function (state, _a) {
        var sellListings = _a.sellListings, buyListings = _a.buyListings, transactions = _a.transactions;
        return (__assign(__assign({}, state), { isPending: false, sellListings: sellListings,
            buyListings: buyListings,
            transactions: transactions }));
    },
    _a[loadMarketDataFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false, sellListings: null, buyListings: null, transactions: null })); },
    _a), initialState);
function fetchEventWithListingById(id) {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call(eventsApi.getWithListings, id)];
            case 1: return [2 /*return*/, (_a.sent())];
            case 2:
                e_1 = _a.sent();
                if (get(e_1, 'status') !== 404) {
                    throw e_1;
                }
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}
export function onLoadMarletData(_a) {
    var id, section, ticketTypes, filterByTicketType, filterBySection, filterByQuantity, eventResult, sellListings, buyListings, ticketTypesFilter, rawTransactions, transactions, e_2, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                id = payload.id, section = payload.section, ticketTypes = payload.ticketTypes;
                filterByTicketType = function (l) {
                    return !ticketTypes || l.ticketType === 'any' || ticketTypes.includes(ticketTypeSimplifiedMapping[l.ticketType]);
                };
                filterBySection = function (l) { return l.seatingInfo.some(function (nsi) {
                    if (section.category === 'any') {
                        return true;
                    }
                    if (section.section === '') {
                        return nsi.category === section.category;
                    }
                    return nsi.category === section.category && nsi.section === section.section;
                }); };
                filterByQuantity = function (l) { return l.maxTreadableQuantities !== 0; };
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 8]);
                return [5 /*yield**/, __values(fetchEventWithListingById(id))];
            case 2:
                eventResult = _c.sent();
                sellListings = eventResult ? eventResult.sellListings.map(rawSellEventListingToEventListing).filter(filterByTicketType).filter(filterBySection).filter(filterByQuantity) : [];
                buyListings = eventResult ? eventResult.buyListings.map(rawBuyEventListingToEventListing).filter(filterByTicketType).filter(filterBySection).filter(filterByQuantity) : [];
                ticketTypesFilter = ticketTypes
                    ? reverseMap(ticketTypes, ticketTypeSimplifiedMapping)
                    : undefined;
                return [4 /*yield*/, call(transactionApi.eventTransactions, id, section.category !== 'any' ? section.category : null, section.section ? section.section : null, ticketTypesFilter, undefined)];
            case 3:
                rawTransactions = _c.sent();
                transactions = rawTransactions.results.map(rawEventTransactionToEventTransaction);
                return [4 /*yield*/, put(loadMarketDataSuccess(sellListings, buyListings, transactions))];
            case 4:
                _c.sent();
                return [3 /*break*/, 8];
            case 5:
                e_2 = _c.sent();
                errorMsg = (_b = tryMapError(e_2)) !== null && _b !== void 0 ? _b : 'Cannot load Market Data.';
                return [4 /*yield*/, put(loadMarketDataFailure([errorMsg]))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function marketDataSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadMarketData.getType(), onLoadMarletData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { DayPicker, TreeDropdown, Checkbox, Button } from '@components/forms';
import { Divider } from 'semantic-ui-react';
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Form, Header } from 'semantic-ui-react';
import styles from './filters.less';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import { endOfDay, startOfDay } from 'date-fns';
import { isMobileScreenSize } from '@components/viewPortInfo/viewPort.utils';
export var DEBOUNCE_TIME = 1500;
export function Filters(props) {
    var _a;
    var _b = __read(useState(new Date()), 1), now = _b[0];
    var _c = __read(useState(props.from), 2), from = _c[0], setFrom = _c[1];
    var _d = __read(useState(props.to), 2), to = _d[0], setTo = _d[1];
    var _e = __read(useState(), 2), locationValues = _e[0], setLocationValues = _e[1];
    var _f = __read(useState(), 2), categoryValues = _f[0], setCategoryValues = _f[1];
    var _g = __read(useState(), 2), locationTree = _g[0], setLocationTree = _g[1];
    var _h = __read(useState(), 2), categoryTree = _h[0], setCategoryTree = _h[1];
    var _j = __read(useState(), 2), location = _j[0], setLocation = _j[1];
    var _k = __read(useState(), 2), category = _k[0], setCategory = _k[1];
    var _l = __read(useState(props.buyNow), 2), buyNow = _l[0], setBuyNow = _l[1];
    var _m = __read(useState(props.sellNow), 2), sellNow = _m[0], setSellNow = _m[1];
    var _o = __read(useState(props.recent), 2), recent = _o[0], setRecent = _o[1];
    var onChange = props.onChange, onApply = props.onApply, filtersPopup = props.filtersPopup;
    var refreshState = useCallback(function (_a) {
        var from = _a.from, to = _a.to, location = _a.location, category = _a.category, eventLocations = _a.eventLocations, eventCategories = _a.eventCategories, buyNow = _a.buyNow, sellNow = _a.sellNow, recent = _a.recent;
        setFrom(from);
        setTo(to);
        if (eventLocations.length) {
            var initLocation = location
                ? location
                : createLocations(eventLocations);
            setLocationValues(initLocation);
            if (!locationTree)
                setLocationTree(initLocation);
        }
        if (eventCategories.length) {
            var initCategory = category
                ? category
                : createCategories(eventCategories);
            setCategoryValues(initCategory);
            if (!categoryTree)
                setCategoryTree(initCategory);
        }
        setBuyNow(buyNow);
        setSellNow(sellNow);
        setRecent(recent);
    }, [locationTree, categoryTree]);
    var triggerChange = useCallback(function () {
        return debounce(function () {
            onChange({
                from: from,
                to: to,
                buyNow: buyNow,
                sellNow: sellNow,
                recent: recent,
                location: locationValues,
                category: categoryValues
            });
        }, DEBOUNCE_TIME / 5);
    }, [from, to, buyNow, sellNow, recent, locationValues, categoryValues, onChange]);
    useEffect(function () { return setLocation(mergeTreeWithValues(locationTree, locationValues)); }, [locationTree, locationValues]);
    useEffect(function () { return setCategory(mergeTreeWithValues(categoryTree, categoryValues)); }, [categoryTree, categoryValues]);
    useEffect(function () {
        refreshState({
            from: props.from,
            to: props.to,
            location: props.location,
            category: props.category,
            buyNow: props.buyNow,
            sellNow: props.sellNow,
            recent: props.recent,
            eventCategories: props.eventCategories,
            eventLocations: props.eventLocations
        });
    }, [props.buyNow, props.category, props.eventCategories, props.eventLocations, props.from, props.location, props.recent, props.sellNow, props.to, refreshState]);
    useEffect(function () {
        if (!isMobileScreenSize()) {
            var onChangeDebounced_1 = triggerChange();
            onChangeDebounced_1();
            return function () {
                onChangeDebounced_1.cancel();
            };
        }
    }, [from, to, buyNow, sellNow, recent, locationValues, categoryValues, triggerChange]);
    var clearAll = function () {
        setTo(undefined);
        setFrom(undefined);
        setBuyNow(false);
        setSellNow(false);
        setRecent(false);
        setLocationValues(createLocations(props.eventLocations));
        setCategoryValues(createCategories(props.eventCategories));
    };
    var onFromDateChange = function (setState) {
        return function (day) { return setState(startOfDay(day)); };
    };
    var onToDateChange = function (setState) {
        return function (day) { return setState(endOfDay(day)); };
    };
    var onCheckboxChange = function (setState) {
        return function (_, c) { return setState(!!c.checked); };
    };
    var onTreeNodeChange = function (setState, state) {
        return function (_, selectedNodes) {
            if (state) {
                setState(checkTreeNodes(state, selectedNodes));
            }
        };
    };
    var onTreeNodeToggle = function (setState, state) {
        return function (currentNode) {
            if (state) {
                setState(expandTreeNodes(state, currentNode));
            }
        };
    };
    var mergeTreeWithValues = function (tree, values) {
        var _a;
        if (!values) {
            return [];
        }
        if (!tree) {
            return values;
        }
        var merged = [
            __assign(__assign({}, values[0]), { expanded: tree[0].expanded, children: (_a = values[0].children) === null || _a === void 0 ? void 0 : _a.map(function (c) {
                    var _a, _b;
                    return (__assign(__assign({}, c), { expanded: (_b = (_a = tree[0].children) === null || _a === void 0 ? void 0 : _a.find(function (tc) { return tc.value === c.value; })) === null || _b === void 0 ? void 0 : _b.expanded }));
                }) })
        ];
        return merged;
    };
    var renderTreeFilters = function () {
        return (React.createElement(Form.Group, { inline: true },
            React.createElement(Form.Field, { className: styles.label },
                React.createElement("label", null, "Location"),
                React.createElement(TreeDropdown, { data: location !== null && location !== void 0 ? location : [], onChange: onTreeNodeChange(setLocationValues, locationValues), onNodeToggle: onTreeNodeToggle(setLocationTree, locationTree) })),
            React.createElement(Form.Field, { className: styles.label },
                React.createElement("label", null, "Category"),
                React.createElement(TreeDropdown, { data: category !== null && category !== void 0 ? category : [], onChange: onTreeNodeChange(setCategoryValues, categoryValues), onNodeToggle: onTreeNodeToggle(setCategoryTree, categoryTree) }))));
    };
    var renderCustomDateFilters = function () {
        var commonProps = {
            firstDayOfWeek: 1,
            showOutsideDays: true,
        };
        var fromProps = __assign(__assign({}, commonProps), { disabledDays: {
                before: now,
            } });
        var toProps = __assign(__assign({}, commonProps), { disabledDays: from
                ? {
                    before: from,
                }
                : undefined });
        var classNames = {
            container: styles.dayPickerContainer,
            overlay: '',
            overlayWrapper: ''
        };
        return (React.createElement(Form.Group, { inline: true, className: cn(styles.fields, styles.customDate, styles.time) },
            React.createElement(Form.Field, { className: styles.label },
                React.createElement("label", null, "Date from"),
                React.createElement(DayPicker, { classNames: classNames, dayPickerProps: fromProps, onDayChange: onFromDateChange(setFrom), value: from || 'Any date' })),
            React.createElement(Form.Field, { className: styles.label },
                React.createElement("label", null, "Date to"),
                React.createElement(DayPicker, { classNames: classNames, dayPickerProps: toProps, onDayChange: onToDateChange(setTo), value: to || 'Any date' }))));
    };
    var renderOnlyShowEventsFilters = function () {
        return (React.createElement(Form.Group, { inline: true },
            React.createElement(Form.Field, { className: styles.label },
                React.createElement("label", null, "Only show events"),
                React.createElement("div", { className: styles.checkboxGroup },
                    React.createElement(Checkbox, { checked: buyNow, label: 'Available to buy now', onChange: onCheckboxChange(setBuyNow) }),
                    React.createElement(Checkbox, { checked: sellNow, label: 'Available to sell now', onChange: onCheckboxChange(setSellNow) }),
                    React.createElement(Checkbox, { checked: recent, label: 'Recently added', onChange: onCheckboxChange(setRecent) })))));
    };
    return (React.createElement("div", __assign({}, t('Filters'), { className: cn(styles.filters, (_a = {}, _a[styles.filtersPopup] = (filtersPopup || !isMobileScreenSize()), _a)) }),
        React.createElement(Header, { as: "h3", className: styles.filtersHeader },
            "Filter By",
            React.createElement("a", __assign({ className: styles.clearLink, onClick: clearAll }, t('Clear-link')), "Clear all")),
        React.createElement(Form, null,
            renderCustomDateFilters(),
            renderTreeFilters(),
            renderOnlyShowEventsFilters()),
        isMobileScreenSize() &&
            React.createElement("div", { className: styles.applyButtonContainer },
                React.createElement(Divider, null),
                React.createElement(Button, { ternary: true, small: true, className: styles.applyButton, onClick: function () { return onApply({
                        from: from,
                        to: to,
                        buyNow: buyNow,
                        sellNow: sellNow,
                        recent: recent,
                        location: locationValues,
                        category: categoryValues
                    }); } }, "Apply"))));
}
var expandTreeNodes = function (data, selectedNode) {
    return data
        ? data.map(function (node) {
            var _a;
            var isSn = node.value === selectedNode.value;
            var isSnDescendant = (_a = node.parents) === null || _a === void 0 ? void 0 : _a.includes(selectedNode.value);
            var shouldCollapse = isSnDescendant && !selectedNode.expanded;
            return __assign(__assign({}, node), { children: expandTreeNodes(node.children, selectedNode), expanded: isSn
                    ? selectedNode.expanded
                    : shouldCollapse
                        ? selectedNode.expanded
                        : node.expanded });
        })
        : [];
};
var checkTreeNodes = function (data, selectedNodes) {
    return data
        ? data.map(function (node) {
            var isSn = !!selectedNodes.find(function (sn) { return sn.value === node.value; });
            var isSnDescendant = !isEmpty(intersection(selectedNodes.map(function (sn) { return sn.value; }), node.parents));
            return __assign(__assign({}, node), { children: checkTreeNodes(node.children, selectedNodes), checked: isSn || isSnDescendant });
        })
        : [];
};
var mapMetadataToTree = function (metadata, defaultValue) {
    var defaultNode = {
        label: 'Show All',
        value: 'show_all',
        checked: defaultValue,
        expanded: false,
        children: [],
    };
    var tree = metadata.map(function (m) { return (__assign(__assign({}, defaultNode), { checked: defaultValue, label: m.value, value: m.value, parents: [defaultNode.value], children: m.children.map(function (s) { return (__assign(__assign({}, defaultNode), { checked: defaultValue, label: s, value: s, parents: [defaultNode.value, m.value] })); }) })); });
    return [__assign(__assign({}, defaultNode), { expanded: true, children: tree })];
};
var createLocations = function (metadata, defaultValue) {
    return mapMetadataToTree(locationToMetadata(metadata) || [], defaultValue !== null && defaultValue !== void 0 ? defaultValue : true);
};
var createCategories = function (metadata, defaultValue) {
    return mapMetadataToTree(categoryToMetadata(metadata) || [], defaultValue !== null && defaultValue !== void 0 ? defaultValue : true);
};
var ensureArray = function (value) {
    return Array.isArray(value) ? value : Object.values(value);
};
export var categoryToMetadata = function (category) {
    return category === null || category === void 0 ? void 0 : category.map(function (c) { return ({
        value: c.category,
        children: ensureArray(c.subcategories)
    }); });
};
export var locationToMetadata = function (location) {
    return location === null || location === void 0 ? void 0 : location.map(function (l) { return ({
        value: l.country,
        children: ensureArray(l.cities)
    }); });
};
export var metadataToCategory = function (metadata) {
    return metadata === null || metadata === void 0 ? void 0 : metadata.map(function (m) { return ({ category: m.value, subcategories: m.children }); });
};
export var metadataToLocation = function (metadata) {
    return metadata === null || metadata === void 0 ? void 0 : metadata.map(function (m) { return ({ country: m.value, cities: m.children }); });
};
export var createTreeFromMetadata = function (metadata, defaultMetadata) {
    if (metadata) {
        if (isEmpty(xor(metadata, defaultMetadata))) {
            return undefined;
        }
        else {
            var changedNodes_1 = [];
            var defaultTree = mapMetadataToTree(defaultMetadata, true);
            metadata.forEach(function (l) {
                var _a;
                var defChildren = ((_a = defaultMetadata.find(function (d) { return d.value === l.value; })) === null || _a === void 0 ? void 0 : _a.children) || [];
                if (isEmpty(xor(l.children, defChildren))) {
                    changedNodes_1.push({ value: l.value, label: l.value, checked: true });
                }
                else {
                    l.children.forEach(function (c) { return changedNodes_1.push({ value: c, label: c, checked: true }); });
                }
            });
            return checkTreeNodes(defaultTree, changedNodes_1);
        }
    }
    return undefined;
};
export function mapTreeToMetadata(tree) {
    var _a, _b;
    var showAllNode = tree && tree[0];
    // show all is checked, so treat as filter is in default, empty state
    if (showAllNode === null || showAllNode === void 0 ? void 0 : showAllNode.checked) {
        return undefined;
        // otherwise map selections to metadata objects
    }
    else {
        return (((_b = (_a = showAllNode === null || showAllNode === void 0 ? void 0 : showAllNode.children) === null || _a === void 0 ? void 0 : _a.filter(function (p) { var _a; return p.checked || ((_a = p.children) === null || _a === void 0 ? void 0 : _a.some(function (c) { return c.checked; })); })) === null || _b === void 0 ? void 0 : _b.map(function (p) {
            var _a, _b;
            return {
                value: p.value,
                children: ((_b = (_a = p.children) === null || _a === void 0 ? void 0 : _a.filter(function (c) { return c.checked; })) === null || _b === void 0 ? void 0 : _b.map(function (c) { return c.value; })) || []
            };
        })) || undefined);
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Icon } from '@components/icon';
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React, { Component } from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';
import styles from './modal.less';
export function ModalContentRow(_a) {
    var _b;
    var children = _a.children, center = _a.center, className = _a.className, textStyle = _a.textStyle;
    return (React.createElement("div", { className: cn(styles.contentRow, (_b = {},
            _b[styles.center] = center,
            _b[styles.headline] = textStyle === 'headline',
            _b[styles.description] = textStyle === 'description',
            _b), className) }, children));
}
export function ModalActions(_a) {
    var children = _a.children, className = _a.className;
    return React.createElement("div", { className: cn(styles.actions, className) }, children);
}
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            open: false,
        };
        _this.handleClose = function () {
            var _a = _this.props, onClose = _a.onClose, open = _a.open;
            if (onClose) {
                onClose();
            }
            else if (open != null) {
                _this.setState({
                    open: false,
                });
            }
        };
        return _this;
    }
    Modal.getDerivedStateFromProps = function (props, state) {
        if (props.open != null && props.open !== state.open) {
            return __assign(__assign({}, state), { open: props.open });
        }
        return null;
    };
    Modal.prototype.componentDidMount = function () {
        var open = this.props.open;
        this.setState({ open: open == null ? true : open });
    };
    Modal.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, className = _b.className, close = _b.close, open = _b.open, onClose = _b.onClose, big = _b.big, extraLarge = _b.extraLarge, medium = _b.medium, closeRowClassName = _b.closeRowClassName, contentClassName = _b.contentClassName, autoHeightOnMobile = _b.autoHeightOnMobile, handleClickClear = _b.handleClickClear, handleClickApply = _b.handleClickApply, rest = __rest(_b, ["children", "className", "close", "open", "onClose", "big", "extraLarge", "medium", "closeRowClassName", "contentClassName", "autoHeightOnMobile", "handleClickClear", "handleClickApply"]);
        var cancelWithIcon = React.createElement("div", { className: styles.cancelBox, onClick: this.handleClose },
            React.createElement(Icon, { icon: 'close', height: 10, width: 10, className: styles.icon }),
            React.createElement("p", null, "Cancel"));
        return (React.createElement(SemanticModal, __assign({}, rest, { className: cn(styles.modal, className, (_a = {},
                _a[styles.big] = big,
                _a[styles.extraLarge] = extraLarge,
                _a[styles.medium] = medium,
                _a[styles.autoHeightOnMobile] = autoHeightOnMobile,
                _a)), onClose: this.handleClose, open: this.state.open }),
            React.createElement("div", { className: cn(styles.closeRow, closeRowClassName) },
                close === 'icon' && React.createElement(Icon, __assign({ icon: 'close', height: 24, width: 24, className: styles.icon, onClick: this.handleClose }, t('CloseModal'))),
                close === 'text' && React.createElement("div", __assign({ className: styles.closeText, onClick: this.handleClose }, t('CloseModal')), "Apply and close "),
                close === 'iconAndText' &&
                    React.createElement("div", { className: styles.filtersModalRow }, cancelWithIcon),
                handleClickClear &&
                    React.createElement("div", { className: styles.filtersModalRow },
                        cancelWithIcon,
                        React.createElement("div", { className: styles.clearAll, onClick: handleClickClear }, "Clear all "))),
            React.createElement("div", { className: cn(styles.modalContent, contentClassName) }, children),
            handleClickApply &&
                React.createElement("div", { className: styles.buttonContainer },
                    React.createElement("button", { className: styles.applyButton, onClick: handleClickApply }, "Apply"))));
    };
    return Modal;
}(Component));
export { Modal };

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export function useScrollTop(capture) {
    var _a = __read(useState(0), 2), scroll = _a[0], updateScroll = _a[1];
    useEffect(function () {
        var scrollHandler = function (ev) {
            var _a;
            var scrollTop = ((_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.scrollTop) || (document.scrollingElement || document.documentElement).scrollTop;
            updateScroll(scrollTop);
        };
        window.addEventListener('scroll', scrollHandler, !!capture);
        return function () { return window.removeEventListener('scroll', scrollHandler); };
    }, [capture]);
    return scroll;
}

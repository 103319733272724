import { Seo } from '@components/seo';
import React from 'react';
import { hasNoIndex } from '@containers/DetailsPages/pages/seo/common';
import { routes } from 'src/client/routing/routes';
export var SubcategorySeo = function (_a) {
    var details = _a.details, events = _a.events, eventsLoading = _a.eventsLoading, subcategoryDetails = _a.subcategoryDetails;
    if (!details || !subcategoryDetails) {
        return null;
    }
    var description = "Buy and Sell tickets for a massive range of " + subcategoryDetails.name + " events. Many more " + details.name + " tickets available now at cheap prices! All purchases 100% protected by the Beeyay Guarantee.";
    var title = subcategoryDetails.name + " and other " + details.name + " Tickets available to buy now - Beeyay";
    var noIndex = hasNoIndex(details === null || details === void 0 ? void 0 : details.description, events, eventsLoading);
    return (React.createElement(Seo, { title: title, description: description, keywords: subcategoryDetails.name + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", canonical: routes.subcategory(details.localId, subcategoryDetails.localId), noIndex: noIndex }));
};

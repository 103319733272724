import { ReactComponent as UserIcon } from '@components/icon/icons/ico-user.svg';
import { hideSuspendedUserPopup, showSuspendedUserPopup } from '@logic/suspendedUser';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { routes } from 'src/client/routing/routes';
import { CreateWorkItem } from '../WorkItems/createWorkItem/createWorkItem';
import styles from './navigation.less';
export function UserTriggerIcon(_a) {
    var _b, _c;
    var userDetails = _a.userDetails, location = _a.location, isMobile = _a.isMobile;
    var open = useSelector(function (state) { return state.suspendedUser.isVisible; });
    var dispatch = useDispatch();
    var activate = useCallback(function () { return dispatch(showSuspendedUserPopup()); }, [dispatch]);
    var deactivate = useCallback(function () { return dispatch(hideSuspendedUserPopup()); }, [dispatch]);
    var userIconClasses = cn(styles.userIcon, (_b = {},
        _b[styles.suspended] = userDetails && userDetails.status === 'Suspended',
        _b));
    var userIcon = React.createElement(UserIcon, { width: 24, height: 24, className: userIconClasses });
    var wrappedUserIcon = React.createElement(Popup, { trigger: userIcon, hoverable: true, className: cn(styles.suspendedUserPopup, (_c = {}, _c[styles.notification] = open > 1, _c)), open: !!open, onOpen: activate, onClose: deactivate, position: 'left center', verticalOffset: 5, hideOnScroll: true, content: React.createElement("div", null,
            "Your account has been temporarily suspended.",
            !isMobile && React.createElement("br", null),
            "Please\u00A0",
            React.createElement(CreateWorkItem, { baseRoute: routes.revertCreateUserWorkItem(location.pathname), wiType: 'fgeneric', biId: null, fromFooter: true, onOpen: deactivate })) });
    return userDetails && userDetails.status === 'Suspended' ? wrappedUserIcon : userIcon;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styles from './sorting.less';
import { Icon } from '@components/icon';
import { SortingItem } from './index';
import cn from 'classnames';
export var SortingDropdown = function (props) {
    var _a;
    var triggerIcon = props.direction === 'descending'
        ? React.createElement(Icon, { icon: 'sortDescending', width: 24, height: 24 })
        : React.createElement(Icon, { icon: 'sortAscending', width: 24, height: 24 });
    return (React.createElement("div", { className: cn(styles.sortingMobileRow, (_a = {}, _a[styles.advancedSearchBorderBottom] = props.variant === 'advanced', _a)) },
        React.createElement(Dropdown, { pointing: 'top right', item: true, icon: null, trigger: triggerIcon },
            React.createElement(Dropdown.Menu, { className: styles.sortingDropdown },
                React.createElement("div", { className: styles.dividerEmpty }),
                React.createElement(SortingItem, __assign({ name: 'event', className: styles.mobileSortingItem }, props), "Event"),
                React.createElement(SortingItem, __assign({ name: 'date', className: styles.mobileSortingItem }, props), "Date"),
                props.variant === 'advanced' && React.createElement(SortingItem, __assign({ name: 'onSaleDate', className: styles.mobileSortingItem }, props), "On sale date"),
                !props.buyOnly && React.createElement(SortingItem, __assign({ name: 'sellPrice', className: styles.mobileSortingItem }, props), "Sell price"),
                React.createElement(SortingItem, __assign({ name: 'buyPrice', className: styles.mobileSortingItem }, props), "Buy price"),
                React.createElement("div", { className: styles.dividerEmpty })))));
};

import styles from 'client/containers/DetailsPages/components/eventsTable/utils/details.less';
import { NewEvent } from '@containers/WorkItems/newEvent';
import React from 'react';
export function NoDataMessage(_a) {
    var baseRoute = _a.baseRoute;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, "We couldn't find any matching events."),
        React.createElement("div", { className: styles.noMessageAdvertise }, "If you're interested in a particular event then why not 'Request an event' below and we'll look to get this added as soon as possible!"),
        React.createElement("div", null,
            React.createElement(NewEvent, { baseRoute: baseRoute }))));
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Checkbox } from '@components/forms';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import styles from './headlineRow.less';
export var genFilterFromValues = function (title) { return function (values, getter, conditions) {
    return {
        title: title,
        values: values,
        predicate: function (row, selectedFilterValues) {
            return conditions
                ? !selectedFilterValues.length || selectedFilterValues.includes(getter(row)) || conditions(row)
                : !selectedFilterValues.length || selectedFilterValues.includes(getter(row));
        },
    };
}; };
var FilterGroup = /** @class */ (function (_super) {
    __extends(FilterGroup, _super);
    function FilterGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (_, _a) {
            var checked = _a.checked, label = _a.label;
            var _b = _this.props, title = _b.title, onChange = _b.onChange;
            onChange(title, "" + label, !!checked);
        };
        return _this;
    }
    FilterGroup.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, title = _b.title, values = _b.values, selectedValues = _b.selectedValues, orientation = _b.orientation;
        if (!values.length) {
            return null;
        }
        return (React.createElement("div", { className: styles.filterGroup },
            React.createElement("div", { className: styles.title }, title),
            React.createElement("div", { className: cn(styles.checkboxes, (_a = {}, _a[styles.verticalCheckboxes] = orientation === 'vertical', _a)) }, values.map(function (v) { return React.createElement(Checkbox, { key: v, label: v, checked: selectedValues.includes(v), strong: true, onChange: _this.handleChange }); }))));
    };
    return FilterGroup;
}(PureComponent));
export { FilterGroup };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reverseMap } from '@logic/helpers/collections';
import { ticketTypeSimplifiedMapping } from '@logic/listings';
import noop from 'lodash/noop';
import React, { useCallback, useMemo } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMap.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { actions, initialTicketTypes } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { isSmallScreen } from '@containers/components/viewPortInfo/viewPort.utils';
import { hasListing, usedListings } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/mapColor';
function CategoryComponent(props) {
    var category = props.category, colorIdx = props.colorIdx, onChange = props.onChange, onChangeHoveredCategory = props.onChangeHoveredCategory;
    var handleChangeSection = useCallback(function () { return onChange(category); }, [onChange, category]);
    var handleMouseEnter = useCallback(function () { return onChangeHoveredCategory(category); }, [onChangeHoveredCategory, category]);
    var handleMouseLeave = useCallback(function () { return onChangeHoveredCategory(null); }, [onChangeHoveredCategory]);
    var media = useViewportInfo().media;
    var isMobile = media === 'mobile';
    return (React.createElement(Checkbox, { checked: category.isActive, label: category.category, onChange: handleChangeSection, className: "category" + colorIdx, onMouseEnter: isMobile ? noop : handleMouseEnter, onMouseLeave: isMobile ? noop : handleMouseLeave }));
}
var VenueMapTicketTypesFilter = function (props) {
    var availableTicketTypes = props.availableTicketTypes, handleSelectTicketType = props.handleSelectTicketType, isLowResolution = props.isLowResolution, stateDispatch = props.stateDispatch, _a = props.selectedTicketTypes, selectedTicketTypes = _a === void 0 ? initialTicketTypes : _a, ticketTypes = props.ticketTypes;
    var ttypes = useMemo(function () {
        var elems = ['Paper', 'e-Ticket', 'Mobile'];
        return elems.filter(function (stt) { return reverseMap([stt], ticketTypeSimplifiedMapping).some(function (mapped) { return availableTicketTypes[mapped]; }); });
    }, [availableTicketTypes]);
    var onChangeTicketType = useCallback(function (tt) { return stateDispatch(actions.toggleTicketType(tt)); }, [stateDispatch]);
    var handleChangeTicketType = function (_, c) { return onChangeTicketType(c.label); };
    return React.createElement(React.Fragment, null,
        React.createElement("label", { className: 'categoryLabel' }, isLowResolution ? 'Ticket Type' : 'Filter ticket type'),
        React.createElement(Form, { className: 'categories' }, ttypes.map(function (tt) {
            return React.createElement(Checkbox, { key: tt, checked: isLowResolution ? selectedTicketTypes[tt] : ticketTypes === null || ticketTypes === void 0 ? void 0 : ticketTypes[tt], label: tt, onChange: isLowResolution ? handleSelectTicketType : handleChangeTicketType, className: styles.ticketTypeFilter });
        })));
};
export var VenueMapCategoriesFilter = function (props) {
    var categories = props.categories, changeHoveredCategory = props.changeHoveredCategory, isLowResolution = props.isLowResolution, listings = props.listings, selectedCategories = props.selectedCategories, selectedSections = props.selectedSections, setSelectedCategories = props.setSelectedCategories, setSelectedSections = props.setSelectedSections, setSectionInForm = props.setSectionInForm, stateDispatch = props.stateDispatch, ticketTypes = props.ticketTypes, transactionSide = props.transactionSide;
    var handleChangeHoveredCategory = useCallback(function (category) { return changeHoveredCategory(category || null); }, [changeHoveredCategory]);
    var handleToggleCategory = useCallback(function (category) {
        var updatedSelectedSections = selectedSections.map(function (selectedSection) { return selectedSection.category === category.category && hasListing({ category: selectedSection.category, section: selectedSection.section }, listings, transactionSide, usedListings) ? __assign(__assign({}, selectedSection), { isActive: !category.isActive }) : selectedSection; });
        setSelectedSections(updatedSelectedSections);
        var updatedSelectedCategories = selectedCategories.map(function (selectedCategory) { return selectedCategory.category === category.category ? __assign(__assign({}, selectedCategory), { isActive: !selectedCategory.isActive }) : selectedCategory; });
        setSelectedCategories(updatedSelectedCategories);
        !isLowResolution && ticketTypes && stateDispatch(actions.toggleCategoryTicketTypeSection({ categories: updatedSelectedCategories, ticketTypes: ticketTypes, sections: updatedSelectedSections }));
    }, [stateDispatch, selectedCategories, selectedSections, ticketTypes, setSelectedCategories, setSelectedSections, listings, transactionSide, isLowResolution]);
    var onChangeCategory = useCallback(function (category) {
        return setSectionInForm
            ? transactionSide === 'buy'
                ? function () { return setSectionInForm(category.category, ''); }
                : noop
            : handleToggleCategory;
    }, [handleToggleCategory, setSectionInForm, transactionSide]);
    return React.createElement("div", { className: styles.categoriesFilter },
        React.createElement("label", { className: 'categoryLabel' }, setSectionInForm
            ? 'Categories'
            : isLowResolution
                ? 'Ticket Category'
                : 'Filter ticket category'),
        React.createElement(Form, { className: 'categories' }, (isLowResolution ? selectedCategories : categories).map(function (category, idx) { return (React.createElement(CategoryComponent, { category: category, key: "" + category.category, onChange: onChangeCategory(category), onChangeHoveredCategory: handleChangeHoveredCategory, colorIdx: idx })); })));
};
export function VenueMapFilters(props) {
    var availableTicketTypes = props.availableTicketTypes, categories = props.categories, changeHoveredCategory = props.changeHoveredCategory, listings = props.listings, selectedCategories = props.selectedCategories, selectedSections = props.selectedSections, setSelectedCategories = props.setSelectedCategories, setSelectedSections = props.setSelectedSections, stateDispatch = props.stateDispatch, ticketTypes = props.ticketTypes, transactionSide = props.transactionSide;
    var media = useViewportInfo().media;
    var isLowResolution = isSmallScreen(media);
    var ticketTypeFilterComponent = React.createElement(VenueMapTicketTypesFilter, { availableTicketTypes: availableTicketTypes, isLowResolution: isLowResolution, stateDispatch: stateDispatch, ticketTypes: ticketTypes });
    var categoriesFilterComponent = React.createElement(VenueMapCategoriesFilter, { categories: categories, changeHoveredCategory: changeHoveredCategory, isLowResolution: isLowResolution, listings: listings, selectedCategories: selectedCategories, selectedSections: selectedSections, setSelectedCategories: setSelectedCategories, setSelectedSections: setSelectedSections, stateDispatch: stateDispatch, ticketTypes: ticketTypes, transactionSide: transactionSide });
    return React.createElement("div", { className: styles.filtersSection },
        isLowResolution && React.createElement("div", { className: styles.filtersTitle }, "Filters"),
        isLowResolution ? categoriesFilterComponent : ticketTypeFilterComponent,
        React.createElement("div", { className: styles.greyLine }),
        isLowResolution ? ticketTypeFilterComponent : categoriesFilterComponent);
}

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/** Reverse mapping of list values by mapping object
 * example:
 * ```
 * const map = {a: 'A', b: 'B', c: 'C', aa: 'A'}
 * const list = ['A', 'C']
 * reverseMap(map, list) -> ['a', 'c', 'aa'];
 * ```
 * @param map mapping object K -> V
 * @param list list of values to map
 */
export var reverseMap = function (list, map) {
    return list
        .map(function (item) { return Object
        .entries(map)
        .filter(function (_a) {
        var _b = __read(_a, 2), value = _b[1];
        return value === item;
    })
        .map(function (_a) {
        var _b = __read(_a, 1), key = _b[0];
        return key;
    }); })
        .flatMap(function (_) { return _; });
};

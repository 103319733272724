var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { t } from '@containers/helpers/tests';
import { search as searchAction } from '@logic/search';
import cn from 'classnames';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Modal, Search } from 'semantic-ui-react';
import { getQueryString, routes } from 'src/client/routing/routes';
import { extendedSearchRowSelector } from './extendedRowSearchSelector';
import renderContentElement from './itemContent';
import styles from './searchBox.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { ClearableInput } from '../components/clearableInput/clearableInput';
export var compareCategories = function (a, b) {
    var weights = {
        performers: 3,
        events: 2,
        venues: 1,
    };
    var weightA = weights[a] || 0;
    var weightB = weights[b] || 0;
    return weightB - weightA;
};
function routeChanged(location, phrase) {
    var route = routes.search(encodeURIComponent(phrase));
    var pathname = location.pathname, search = location.search;
    return ("" + pathname + search !== route);
}
function isAdvancedSearch(location, phrase) {
    var route = routes.search(encodeURIComponent(phrase));
    var _a = __read(route.split('?'), 1), routePathname = _a[0];
    var pathname = location.pathname;
    // if it will cause any issue in future check: #2836
    // original condition was:
    // return (pathname === routePathname && search.includes(routeSearch)); returns always false.
    // we applying default filters after layout render, so we only need to compare the pathname
    return (pathname === routePathname);
}
function setScrolling(yes) {
    var clazz = 'noscroll';
    var $html = document.querySelector('html');
    if (!$html) {
        return;
    }
    if (yes) {
        $html.classList.remove(clazz);
    }
    else {
        $html.classList.add(clazz);
    }
}
export function ControlledSearchBox(_a) {
    var phrase = _a.phrase, changePhrase = _a.changePhrase, asIconOnMobile = _a.asIconOnMobile, extraActions = _a.extraActions;
    var searchRef = useRef(null);
    var closeRef = useRef(null);
    var location = useLocation();
    var viewport = useViewportInfo();
    var loading = useSelector(function (gs) { return gs.search.isLoading; });
    var rawResults = useSelector(function (gs) { return gs.search.results[phrase] || gs.search.results[gs.search.prevSuccessSearch] || {}; });
    var results = useMemo(function () {
        var data = Object
            .keys(rawResults)
            .sort(compareCategories)
            .reduce(function (memo, collection) {
            var _a;
            return rawResults[collection].length
                ? __assign(__assign({}, memo), (_a = {}, _a[collection] = {
                    name: collection,
                    results: rawResults[collection].map(extendedSearchRowSelector),
                }, _a)) : memo;
        }, {});
        data.advanced = {
            name: '',
            results: [{
                    id: -1,
                    title: 'Display all results',
                    route: '',
                    imageUrl: '',
                    collection: '',
                }].map(extendedSearchRowSelector),
        };
        return data;
    }, [rawResults]);
    var dispatch = useDispatch();
    var history = useHistory();
    var handleSearch = useCallback(function (_, _a) {
        var value = _a.value;
        changePhrase(value || '');
        dispatch(searchAction(value || ''));
    }, [dispatch, changePhrase]);
    var handleClear = useCallback(function () { return handleSearch(null, { value: '' }); }, [handleSearch]);
    var goToAdvancedSearch = useCallback(function () {
        var _a, _b;
        if (routeChanged(location, phrase)) {
            var route = routes.search(encodeURIComponent(phrase));
            history.push(route);
        }
        (_a = closeRef === null || closeRef === void 0 ? void 0 : closeRef.current) === null || _a === void 0 ? void 0 : _a.closePopup();
        (_b = searchRef.current) === null || _b === void 0 ? void 0 : _b.close();
    }, [phrase, history, location]);
    var handleSelect = useCallback(function (_, v) {
        var _a;
        var _b = v.result, id = _b.id, route = _b.route;
        if (id === -1) {
            return goToAdvancedSearch();
        }
        (_a = closeRef === null || closeRef === void 0 ? void 0 : closeRef.current) === null || _a === void 0 ? void 0 : _a.closePopup();
        return history.push(route);
    }, [history, goToAdvancedSearch]);
    var handleSelectAdvanced = useCallback(function (e) {
        if (e.key !== 'Enter') {
            return;
        }
        goToAdvancedSearch();
    }, [goToAdvancedSearch]);
    var searchIcon = React.createElement(Icon, { name: 'search', link: true, onClick: goToAdvancedSearch });
    var placeholderText = 'Search for event, artist, venue or city';
    // Using a constructor function to allow shallow testing of the `ControlledSearchBox`.
    var searchComponent = function (rest) {
        if (rest === void 0) { rest = {}; }
        return (React.createElement(Search, __assign({ ref: searchRef, size: 'small', className: cn(styles.Search), fluid: true, placeholder: placeholderText, category: true, onSearchChange: handleSearch, loading: loading, results: results, resultRenderer: renderContentElement(phrase), onResultSelect: handleSelect, onKeyPress: handleSelectAdvanced, value: phrase, icon: searchIcon }, rest, t('SearchBox'))));
    };
    var searchBox = !isAdvancedSearch(location, phrase)
        ? searchComponent
        : function (rest) {
            if (rest === void 0) { rest = {}; }
            return (React.createElement(ClearableInput, __assign({ icon: 'search', onChange: handleSearch, onClear: handleClear, placeholder: placeholderText, value: phrase, extraAction: extraActions, fluid: true }, rest)));
        };
    if (viewport.media === 'mobile') {
        var props = {
            phrase: phrase, handleSearch: handleSearch, handleClear: handleClear, searchIcon: searchIcon, searchComponent: searchComponent, searchBox: searchBox, location: location, closeRef: closeRef, asIconOnMobile: asIconOnMobile
        };
        return (React.createElement(PopupSearch, __assign({}, props)));
    }
    return searchComponent();
}
function PopupSearch(props) {
    var popupSearchRef = useRef(null);
    var _a = __read(useState(props.phrase !== '' &&
        routeChanged(props.location, props.phrase)), 2), isPopup = _a[0], setPopup = _a[1];
    var openPopup = useCallback(function () {
        setPopup(true);
        setScrolling(false);
    }, [setPopup]);
    var closePopup = useCallback(function () {
        setPopup(false);
        setScrolling(true);
    }, [setPopup]);
    useEffect(function () {
        props.closeRef.current = { closePopup: closePopup };
        if (isPopup && popupSearchRef.current) {
            var el = popupSearchRef.current;
            el.querySelector('input').focus();
        }
        if (isPopup) {
            setScrolling(false);
        }
    });
    var phrase = props.phrase, handleClear = props.handleClear, searchIcon = props.searchIcon, searchComponent = props.searchComponent, searchBox = props.searchBox, asIconOnMobile = props.asIconOnMobile;
    var closeOnDirectClick = useCallback(function (ev) {
        if (ev.target === ev.currentTarget) {
            closePopup();
        }
    }, [closePopup]);
    var clearOrClose = useCallback(function () {
        if (phrase) {
            handleClear();
        }
        else {
            closePopup();
        }
    }, [phrase, handleClear, closePopup]);
    if (isPopup) {
        var icon = (React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: 'remove', link: true, className: styles.SearchClear, onClick: clearOrClose }),
            searchIcon));
        var searchProps = {
            open: true,
            icon: icon,
        };
        return (React.createElement(Modal, { className: styles.SearchModal, size: 'fullscreen', defaultOpen: true, onClose: closePopup },
            React.createElement(Modal.Content, { className: styles.SearchModalContent },
                React.createElement("div", { ref: popupSearchRef, className: styles.SearchModalContainer, onClick: closeOnDirectClick }, searchComponent(searchProps)))));
    }
    return asIconOnMobile
        ? React.createElement(Icon, { name: 'search', link: true, onClick: openPopup, size: 'large' })
        : searchBox({ onFocus: openPopup });
}
export function SearchBox(props) {
    var _a, _b;
    var location = useLocation();
    var queryString = getQueryString(location);
    var _c = __read(useState((_b = (_a = qs.parse(queryString)) === null || _a === void 0 ? void 0 : _a.q) !== null && _b !== void 0 ? _b : ''), 2), phrase = _c[0], changePhrase = _c[1];
    useEffect(function () {
        var q = qs.parse(queryString).q;
        changePhrase(q || '');
    }, [queryString]);
    return React.createElement(ControlledSearchBox, __assign({ phrase: "" + phrase, changePhrase: changePhrase }, props));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useToggle } from '@components/forms';
import { TrustBox } from '@components/trustpilot/trustbox';
import { getEventMainPerformer, mapEventToEventSearch } from '@logic/events';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@containers/Page';
import { actions, initialTicketTypes } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { CreateFrame } from '@containers/DetailsPages/pages/EventDetails/components/CreateFrame';
import { EventPageListings } from '@containers/DetailsPages/pages/EventDetails/components/EventPageListings';
import { PreOnSale } from '@containers/DetailsPages/pages/EventDetails/components/PreOnSale';
import { QuantityFilter } from '@containers/DetailsPages/pages/EventDetails/components/QuantityFilter';
import { Tabs } from '@containers/DetailsPages/pages/EventDetails/components/Tabs';
import styles from '@containers/DetailsPages/pages/EventDetails/components/EventDetails/eventDetailsView.less';
import sharedStyles from '@containers/DetailsPages/sharedStyles.less';
import { JsonLd } from '@components/jsonLD';
import { Breadcrumb } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { EventSeo } from '@containers/DetailsPages/pages/seo/eventSeo';
import { t } from '@containers/helpers/tests';
import { DetailsEventHeader } from '@containers/DetailsPages/components/detailsHeader/detailsEventHeader';
import { Biography } from '@containers/DetailsPages/components/biography/biography';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isMobile, isSmallScreen } from '@components/viewPortInfo/viewPort.utils';
import { GoogleMap } from '@containers/DetailsPages/components/googleMap/googleMap';
import { codeToCountry } from '@logic/helpers/enumerators';
import { windowIsDefined } from '@containers/utils';
import { PageSectionEnum, useScrollTo } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { DetailsPageNavigation } from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation';
import { VenueAddress } from '@containers/DetailsPages/components/venueAddress/venueAddress';
import { OtherDates } from '@containers/DetailsPages/components/otherDates/otherDates';
import { useSelector } from 'react-redux';
import { TopEvents } from '@containers/components/topEvents/topEvents';
import { Faq } from '@containers/DetailsPages/components/faq/faq';
import { SimilarPerformers } from '@containers/DetailsPages/components/similarPerformers/similarPerformers';
import { SellYourTickets } from '@containers/DetailsPages/pages/EventDetails/components/SellYourTickets/sellYourTickets';
import { FiltersSection } from '@containers/DetailsPages/pages/EventDetails/components/FiltersSection/filtersSection';
import { VenueMapWithFilters } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMapWithFilters';
import { precomputeFoundSections } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap';
import { Spotify } from '@containers/components/spotify/spotify';
export var EventDetails = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var event = props.event, isLoadingSeatingPlan = props.isLoadingSeatingPlan, sections = props.sections, categories = props.categories, filterValue = props.filterValue, transactionSide = props.transactionSide, onListingNew = props.onListingNew, sellListings = props.sellListings, buyListings = props.buyListings, allSellListings = props.allSellListings, allBuyListings = props.allBuyListings, hoveredListing = props.hoveredListing, notifyRequireFilter = props.notifyRequireFilter, dispatch = props.dispatch, notifyOnSaleNotification = props.notifyOnSaleNotification, ticketTypes = props.ticketTypes, isOpenQuantityModal = props.isOpenQuantityModal, closeQuantityModal = props.closeQuantityModal, settings = props.settings, buyOnly = props.buyOnly, breadcrumb = props.breadcrumb, rest = __rest(props, ["event", "isLoadingSeatingPlan", "sections", "categories", "filterValue", "transactionSide", "onListingNew", "sellListings", "buyListings", "allSellListings", "allBuyListings", "hoveredListing", "notifyRequireFilter", "dispatch", "notifyOnSaleNotification", "ticketTypes", "isOpenQuantityModal", "closeQuantityModal", "settings", "buyOnly", "breadcrumb"]);
    var smallestSellPrice = allSellListings.length === 0 ? 0 : Math.min.apply(Math, __spreadArray([], __read(allSellListings.map(function (x) { return x.price.value; }))));
    var media = useViewportInfo().media;
    var showTrustbox = !!((_a = settings === null || settings === void 0 ? void 0 : settings.uiSettings) === null || _a === void 0 ? void 0 : _a.trustpilotSliderOnEvent);
    var _g = useToggle(false), openFilterModal = _g.state, handleOpenFilterModal = _g.activate, handleCloseFilterModal = _g.deactivate;
    var googleMapData = { venueName: event.venue.name, country: codeToCountry[event.venue.address.countryCode], city: event.venue.address.city };
    var _h = useScrollTo(), bioRef = _h.bioRef, executeScroll = _h.executeScroll, faqRef = _h.faqRef, ticketsRef = _h.ticketsRef, venueInformationRef = _h.venueInformationRef;
    var isLowResolution = isSmallScreen(media);
    var venueAddressData = __assign(__assign({}, googleMapData), { address: event.venue.address, img: event.venue.imageUrl });
    var performer = getEventMainPerformer(event);
    var events = useSelector(function (gs) { return gs.events.eventsSearched; });
    var otherDatesData = { performer: performer, events: events };
    var _j = __read(useState(null), 2), hoveredCategory = _j[0], changeHoveredCategory = _j[1];
    var _k = __read(useState({}), 2), svgPanAndZoomInstance = _k[0], setSvgPanAndZoomInstance = _k[1];
    var _l = __read(useState(categories), 2), selectedCategories = _l[0], setSelectedCategories = _l[1];
    var _m = __read(useState(initialTicketTypes), 2), selectedTicketTypes = _m[0], setSelectedTicketTypes = _m[1];
    var _o = __read(useState([]), 2), selectedSections = _o[0], setSelectedSections = _o[1];
    var _p = __read(useState([]), 2), foundSections = _p[0], changeFoundSections = _p[1];
    var _q = __read(useState(false), 2), hasUserSelectedSections = _q[0], changeHasUserSelectedSections = _q[1];
    var initialSelectedSections = selectedSections.map(function (selectedSection) { return __assign(__assign({}, selectedSection), { isActive: false }); });
    var handleResetFilters = useCallback(function () {
        isLowResolution && setSelectedSections(initialSelectedSections);
        dispatch(actions.deactivateAll());
    }, [dispatch, isLowResolution, initialSelectedSections]);
    var handleSvgParsed = useCallback(function (rawSections) { return changeFoundSections(precomputeFoundSections(rawSections)); }, []);
    useEffect(function () {
        if (windowIsDefined()) {
            if (isMobile(media)) {
                executeScroll(PageSectionEnum.Tickets);
            }
            else {
                window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
            }
        }
    }, [media, executeScroll]);
    useEffect(function () {
        !hasUserSelectedSections && setSelectedSections(foundSections === null || foundSections === void 0 ? void 0 : foundSections.map(function (section) { return __assign(__assign({}, section), { isActive: false }); }));
    }, [foundSections, setSelectedSections, hasUserSelectedSections]);
    return (React.createElement(React.Fragment, null,
        React.createElement(EventSeo, { event: event, buyOnly: buyOnly, transactionSide: transactionSide }),
        React.createElement(JsonLd, { events: [mapEventToEventSearch(event, smallestSellPrice)], breadcrumbJsonLd: (_b = breadcrumb.breadcrumbPaths) === null || _b === void 0 ? void 0 : _b.jsonLD, faqs: event.faqs }),
        React.createElement(DetailsEventHeader, __assign({ event: event, containerSize: 'wide', buyOnly: buyOnly }, t('EventDetailsHeader'), { breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }) })),
        React.createElement(DetailsPageNavigation, { isFullWide: true, details: event, scrollTo: executeScroll, isEventPage: true, hasFAQs: !!((_c = event === null || event === void 0 ? void 0 : event.faqs) === null || _c === void 0 ? void 0 : _c.length) }),
        React.createElement(Container, { size: 'wide', className: sharedStyles.simplifiedContainer },
            React.createElement("div", { className: styles.listingsVenueMapSection },
                React.createElement("div", { className: 'coverLine' }),
                React.createElement("div", { ref: ticketsRef },
                    !buyOnly &&
                        React.createElement(Tabs, { transactionSide: transactionSide, eventSlug: event.eventLinkSlug, sellListings: sellListings, buyListings: buyListings }),
                    React.createElement(QuantityFilter, { filterValue: filterValue, dispatch: dispatch, notifyRequireFilter: notifyRequireFilter, transactionSide: transactionSide, buyOnly: buyOnly, sellListings: sellListings }),
                    isLowResolution ?
                        React.createElement(FiltersSection, { eventId: event.id, isLoadingSeatingPlan: isLoadingSeatingPlan, transactionSide: transactionSide, hoveredListing: hoveredListing, listings: transactionSide === 'sell' ? allBuyListings : allSellListings, filteredListings: transactionSide === 'sell' ? buyListings : sellListings, sections: sections, categories: categories, seatingPlan: event.seatingPlan, stateDispatch: dispatch, ticketTypes: ticketTypes, availableTicketTypes: event.venue.ticketTypes, modalOpen: openFilterModal, onOpenModal: handleOpenFilterModal, onCloseModal: handleCloseFilterModal, hoveredCategory: hoveredCategory, changeHoveredCategory: changeHoveredCategory, svgPanAndZoomInstance: svgPanAndZoomInstance, onPanAndZoomInstanceChange: setSvgPanAndZoomInstance, handleResetFilters: handleResetFilters, selectedCategories: selectedCategories, selectedTicketTypes: selectedTicketTypes, setSelectedCategories: setSelectedCategories, setSelectedTicketTypes: setSelectedTicketTypes, selectedSections: selectedSections, setSelectedSections: setSelectedSections, foundSections: foundSections, changeHasUserSelectedSections: changeHasUserSelectedSections, handleSvgParsed: handleSvgParsed })
                        : null,
                    React.createElement(EventPageListings, __assign({ transactionSide: transactionSide, listings: transactionSide === 'sell' ? buyListings : sellListings, filterValue: filterValue, dispatch: dispatch, eventId: event.id, seatingPlan: event.seatingPlan, onOpenFilter: handleOpenFilterModal, settings: settings, buyOnly: buyOnly }, rest)),
                    React.createElement(PreOnSale, { onSaleDate: event.onSaleDate, transactionSide: transactionSide, timezone: event.timezone, notifyOnSaleNotification: notifyOnSaleNotification }),
                    React.createElement(CreateFrame, { type: transactionSide, onListingNew: onListingNew, filterValue: filterValue, listingsCount: transactionSide === 'sell' ? buyListings.filter(function (l) { return !l.ownedByMe; }).length : sellListings.filter(function (l) { return !l.ownedByMe; }).length })),
                React.createElement("div", { className: styles.venueMapContainer },
                    React.createElement(VenueMapWithFilters, { eventId: event.id, isLoadingSeatingPlan: isLoadingSeatingPlan, transactionSide: transactionSide, hoveredListing: hoveredListing, listings: transactionSide === 'sell' ? allBuyListings : allSellListings, filteredListings: transactionSide === 'sell' ? buyListings : sellListings, sections: sections, categories: categories, seatingPlan: event.seatingPlan, stateDispatch: dispatch, ticketTypes: ticketTypes, availableTicketTypes: event.venue.ticketTypes, hoveredCategory: hoveredCategory, changeHoveredCategory: changeHoveredCategory, svgPanAndZoomInstance: svgPanAndZoomInstance, onPanAndZoomInstanceChange: setSvgPanAndZoomInstance, handleResetFilters: handleResetFilters, setSelectedCategories: setSelectedCategories, setSelectedSections: setSelectedSections, selectedSections: selectedSections, foundSections: foundSections, handleSvgParsed: handleSvgParsed }))),
            React.createElement("div", { className: sharedStyles.advancedContainer },
                React.createElement("div", { className: styles.containerBiography },
                    React.createElement(Biography, { bioRef: bioRef, details: { name: event.name, description: event.description } }),
                    ((_d = event === null || event === void 0 ? void 0 : event.mediaLinks) === null || _d === void 0 ? void 0 : _d.spotify) ? React.createElement(Spotify, { spotifyLink: (_e = event === null || event === void 0 ? void 0 : event.mediaLinks) === null || _e === void 0 ? void 0 : _e.spotify }) : null,
                    React.createElement(OtherDates, { data: otherDatesData })),
                React.createElement(VenueAddress, { venueInformationRef: venueInformationRef, data: venueAddressData }),
                React.createElement(GoogleMap, { data: googleMapData, zoom: 16 }),
                React.createElement(TopEvents, null),
                transactionSide === 'buy' ? React.createElement(SellYourTickets, { onListingNew: onListingNew }) : null,
                ((_f = event === null || event === void 0 ? void 0 : event.faqs) === null || _f === void 0 ? void 0 : _f.length) ? React.createElement(Faq, { faqRef: faqRef, data: event.faqs }) : null,
                showTrustbox && React.createElement(TrustBox, { className: styles.trustbox, type: 'slider' }),
                React.createElement("div", { className: sharedStyles.secondarySidebar },
                    React.createElement(SimilarPerformers, { event: event }))))));
};

import defaults from 'lodash/defaults';
var Property = /** @class */ (function () {
    function Property(prop) {
        this.id = prop[0] + "." + String(prop[1]);
        this.prop = prop;
    }
    Property.fromOptions = function (prop) {
        if (!Array.isArray(prop)) {
            return new Property([id, prop]);
        }
        return new Property(prop);
    };
    Property.prototype.readFrom = function (elem) {
        var styles = function (x) { return window.getComputedStyle(x); };
        return "" + styles(this.prop[0](elem))[this.prop[1]];
    };
    Property.prototype.applyTo = function (elem, val) {
        var prop = this.prop[1];
        if (prop === 'length' || prop === 'parentRule') {
            return;
        }
        this.prop[0](elem).style[prop] = val;
    };
    Property.prototype.reset = function (elem) {
        this.prop[0](elem).style.cssText = '';
    };
    return Property;
}());
var Morph = /** @class */ (function () {
    function Morph(from, to, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.initial = {};
        this.target = {};
        this.startY = 0;
        this.endY = 0;
        defaults(options, {
            props: [],
            onUpdate: id,
            onEnd: id,
        });
        this.from = from;
        this.to = to;
        this.onUpdate = nonNull(options.onUpdate);
        this.onEnd = nonNull(options.onEnd);
        this.props = nonNull(options.props).map(Property.fromOptions);
        whenScrollOnTop(function () {
            function getProps(props, elem) {
                return props.reduce(function (memo, prop) {
                    memo[prop.id] = parseFloat(prop.readFrom(elem));
                    return memo;
                }, {});
            }
            _this.initial = getProps(_this.props, from);
            _this.target = getProps(_this.props, to);
            _this.startY = rect(from).y;
            _this.endY = rect(to).y;
        });
    }
    Morph.prototype.update = function () {
        var _a = this, from = _a.from, to = _a.to, target = _a.target, initial = _a.initial;
        var currentY = rect(from).y;
        var progress = Math.min(1, Math.max(0, (this.startY - currentY) / this.startY));
        if (currentY < this.endY) {
            this.onEnd(from, to);
            this.props.forEach(function (prop) {
                var val = target[prop.id];
                prop.applyTo(from, val + "px");
            });
        }
        else {
            this.onUpdate(from, to);
            this.props.forEach(function (prop) {
                var val = target[prop.id] * progress + initial[prop.id] * (1 - progress);
                prop.applyTo(from, val + "px");
            });
        }
    };
    return Morph;
}());
export { Morph };
function whenScrollOnTop(callback) {
    var $html = nonNull(document.querySelector('html')); // take current scroll position to calculate the offsets
    var position = $html.scrollTop;
    $html.scrollTop = 0;
    callback();
    $html.scrollTop = position;
}
export function input(x) {
    return nonNull(x.querySelector('input'));
}
export function hasInput(x) {
    if (x) {
        return !!x.querySelector('input');
    }
    return false;
}
export function parent(x) {
    return nonNull(cast(x.parentNode));
}
export function nonNull(arg) {
    if (!arg) {
        throw new Error('Element missing.');
    }
    return arg;
}
function id(a) {
    return a;
}
id.toString = function () { return '(id)'; };
function cast(arg) {
    return arg;
}
function rect(x) {
    return cast(x.getBoundingClientRect());
}

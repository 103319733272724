import { defaultOrdering, defaultSorting, orderings, sortings } from '@containers/DetailsPages/components/eventsTable/sorting';
import { CATEGORY_TYPES } from '@logic/categories';
export var parseSort = function (sort, order) {
    var s = sortings[sort || ''];
    var o = orderings[order || ''];
    return {
        sort: s || defaultSorting,
        order: o || defaultOrdering,
    };
};
export var mapCategoryToEventType = function (category) {
    switch (category) {
        case CATEGORY_TYPES.concert:
            return 'Tour Dates & Concerts';
        case CATEGORY_TYPES.festival:
            return 'Festivals & Events';
        case CATEGORY_TYPES.sport:
            return 'Fixtures & Events';
        case CATEGORY_TYPES.theatre_and_comedy:
            return 'Tour Dates & Shows';
        default:
            return '';
    }
};
export var mapCategoryToEventOrVenueType = function (category) {
    switch (category) {
        case CATEGORY_TYPES.concert:
            return 'concert';
        case CATEGORY_TYPES.festival:
            return 'event';
        case CATEGORY_TYPES.sport:
            return 'event';
        case CATEGORY_TYPES.theatre_and_comedy:
            return 'show';
        default:
            return 'event';
    }
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { ControlledGenericTable, createHeader } from '@components/genericTable';
import { TicketIcon } from '@components/icon';
import { formatPrice } from '@logic/helpers/currency';
import { stringifyNormalizedSeatingSectionAndRowInfo } from '@logic/seatingInfo';
import React from 'react';
import { RestrictionsInfo } from '../DetailsPages/pages/EventDetails/components/RestrictionsInfo';
import { ListingUpdatePrice } from '../UserListings/components/updatePrice';
import styles from './marketData.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
var empty = [];
export var PriceColumn = 'Price';
export var CreationDateColumn = 'CreationDate';
var idGetter = function (l) { return l.id; };
export function Listings(_a) {
    var tableState = _a.tableState, onUpdateTableState = _a.onUpdateTableState, listings = _a.listings, patchBuyListing = _a.patchBuyListing, patchSellListing = _a.patchSellListing, isPending = _a.isPending, type = _a.type;
    var media = useViewportInfo().media;
    var beforeHeader = createHeader('', function () { return ''; }, {
        className: function () { return styles.noBorder; },
        columnKey: 'before',
        centered: true,
        sort: false,
        filter: false,
    });
    var afterHeader = createHeader('', function () { return ''; }, {
        className: function () { return styles.noBorder; },
        columnKey: 'after',
        centered: true,
        sort: false,
        filter: false,
    });
    var headers = __spreadArray(__spreadArray(__spreadArray([], __read((media !== 'mobile' && media !== 'mobileLandscape' ? [beforeHeader] : []))), [
        createHeader('Ticket type', function (el) { return el; }, {
            render: function (el) { return React.createElement(TicketIcon, { ticketType: el.ticketType, hasTicketsAssigned: !!el.hasTicketsAssigned }); },
            format: function (el) { return el.ticketType; },
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            centered: true,
        }),
        createHeader('Section', function (el) { return el; }, {
            centered: true,
            orderBy: function (el) { return el.seatingInfo.map(stringifyNormalizedSeatingSectionAndRowInfo).join('\n'); },
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            render: function (el) { return React.createElement("div", null,
                el.seatingInfo.map(stringifyNormalizedSeatingSectionAndRowInfo).join('\n'),
                React.createElement(RestrictionsInfo, { restrictions: el.restrictions })); },
        }),
        createHeader('Quantity', function (el) { return el.maxTreadableQuantities; }, {
            centered: true,
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
        }),
        createHeader(PriceColumn, function (el) { return el; }, {
            centered: true,
            orderBy: function (el) { return el.price.value; },
            className: function (el) { return el.ownedByMe ? styles.myCell : null; },
            render: function (el) {
                if (el.ownedByMe) {
                    return React.createElement(ListingUpdatePrice, { listingId: el.id, listingType: el.type, patchBuyListing: patchBuyListing, patchSellListing: patchSellListing, value: el.price.value, label: el.price.currency, sellField: 'sellPrice', buyField: 'buyPrice', minValue: 1 });
                }
                return React.createElement("div", { className: el.type === 'sell' ? styles.sellPrice : styles.buyPrice }, formatPrice(el.price));
            },
        }),
        createHeader(CreationDateColumn, function (el) { return el; }, {
            hidden: true,
            orderBy: function (el) { return el.creationDate; },
        })
    ]), __read((media !== 'mobile' && media !== 'mobileLandscape' ? [afterHeader] : [])));
    return React.createElement(ControlledGenericTable, { data: listings, filters: empty, useFiltering: false, useSorting: true, isLoading: isPending, headers: headers, omitFilteringRow: true, rowClass: function (row) { return row.ownedByMe ? (type === 'sell' ? styles.mySellRow : styles.myBuyRow) : null; }, tableState: tableState, onUpdateTableState: onUpdateTableState, top: 280, stickyType: 'top', idGetter: idGetter, stickThreshold: 0, secondarySortColumns: [CreationDateColumn], captureScroll: true });
}

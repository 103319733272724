var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { getRouteParams } from '@containers/DetailsPages/pages/EventDetails/helpers';
import { actions as eventsActions } from '@logic/events';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routes } from 'src/client/routing/routes';
import Error404 from '@containers/Error404';
export function withEventDetails(Wrapped) {
    var EventDetailsLoader = /** @class */ (function (_super) {
        __extends(EventDetailsLoader, _super);
        function EventDetailsLoader() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.handleTimeout = function () {
                var _a;
                var event = _this.props.event;
                _this.props.loadActions.push('redirectUrl' in event ? (_a = event.redirectUrl) !== null && _a !== void 0 ? _a : routes.index() : routes.index());
            };
            return _this;
        }
        EventDetailsLoader.universalFetchData = function (props, isSSR) {
            var loadActions = props.loadActions, id = props.id, loadedWithSSR = props.loadedWithSSR;
            if (!loadedWithSSR) {
                loadActions.loadEventDetails({ eventId: id, shouldFetchEvents: true });
            }
            else {
                loadActions.setEventSSRFlag(false);
            }
            if (isSSR) {
                loadActions.setEventSSRFlag(true);
            }
        };
        EventDetailsLoader.fetchData = function (state, dispatch, match, isSSR) {
            var ownProps = getRouteParams({ match: match });
            var mappedProps = mapStateToProps(state);
            var dispatchProps = mapDispatchToProps(dispatch);
            var props = __assign(__assign(__assign({}, ownProps), mappedProps), dispatchProps);
            this.universalFetchData(props, isSSR);
        };
        EventDetailsLoader.prototype.componentDidMount = function () {
            EventDetailsLoader.universalFetchData(this.props);
        };
        EventDetailsLoader.prototype.componentDidUpdate = function (oldProps) {
            if (oldProps.id !== this.props.id) {
                this.props.loadActions.loadEventDetails({ eventId: this.props.id, shouldFetchEvents: true });
            }
        };
        EventDetailsLoader.prototype.render = function () {
            var _a = this.props, event = _a.event, id = _a.id;
            if (!event || event.id !== id) {
                return React.createElement(PleaseWait, null);
            }
            if (event.type === 'eventNotFound') {
                return React.createElement(Error404, null);
            }
            // TODO any!
            var W = Wrapped;
            return React.createElement(W, __assign({}, this.props, { event: event, eventLoading: false }));
        };
        return EventDetailsLoader;
    }(Component));
    var mapStateToProps = function (state) { return ({
        // TODO [ToDr] We should probably cache previous events and allow many to be loaded concurrently.
        event: state.events.eventDetails,
        eventLoading: state.events.isLoadingDetails,
        loadedWithSSR: state.events.loadedWithSSR
    }); };
    var mapDispatchToProps = function (dispatch) { return ({
        loadActions: bindActionCreators({
            loadEventDetails: eventsActions.loadEventDetails.request,
            setEventSSRFlag: eventsActions.setEventSSRFlag,
            push: push,
        }, dispatch),
    }); };
    // TODO: any!
    return connect(mapStateToProps, mapDispatchToProps)(EventDetailsLoader);
}

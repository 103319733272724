import React from 'react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMap.less';
import { Icon } from '@containers/components/icon';
export function VenueMapNav(props) {
    var onResetFilters = props.onResetFilters, svgPanAndZoomInstance = props.svgPanAndZoomInstance, editListingPage = props.editListingPage;
    var handleClickMinus = function () {
        return svgPanAndZoomInstance.zoomOut();
    };
    var handleClickPlus = function () {
        return svgPanAndZoomInstance.zoomIn();
    };
    var handleClickClear = function () {
        onResetFilters();
        svgPanAndZoomInstance.reset();
    };
    return React.createElement("div", { className: styles.zoom },
        React.createElement("button", { className: "clearAll", onClick: handleClickClear }, editListingPage ? 'Reset' : 'Clear all'),
        React.createElement(Icon, { icon: 'ico-minus-outlined', onClick: handleClickMinus }),
        React.createElement(Icon, { icon: 'ico-plus-outlined', onClick: handleClickPlus }));
}

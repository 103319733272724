var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { reservationExpired } from '@logic/reservations';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from 'src/client/routing/routes';
import { Timer } from '../../TransactionsFlow/components/timer/timer';
import styles from './remaining.less';
export var Remaining = function () {
    var _a;
    var dispatch = useDispatch();
    var reservation = useSelector(function (gs) { return gs.reservations.reservation; });
    var _b = __read(useSelector(function (gs) { return gs.reservations.reservationState; }), 2), reservationId = _b[0], reservationUrl = _b[1];
    var endTime = reservation && reservation.endTime;
    var handleTimeout = useCallback(function () {
        if (reservation) {
            dispatch(reservationExpired({ redirect: false }));
        }
    }, [dispatch, reservation]);
    if (!reservation || !endTime || endTime < new Date()) {
        return null;
    }
    var url = reservationId === (reservation === null || reservation === void 0 ? void 0 : reservation.id) && reservationUrl
        ? reservationUrl
        : routes.reservation((_a = reservation === null || reservation === void 0 ? void 0 : reservation.type) !== null && _a !== void 0 ? _a : 'buy', 'summary');
    return (React.createElement(Link, { to: url || routes.reservation(reservation.type, 'summary'), className: styles.remaining },
        React.createElement(Timer, { endTime: endTime, onTimeout: handleTimeout })));
};

import cn from 'classnames';
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styles from '@components/image/lazyImage.less';
import { Image } from '@components/image/image';
export function TxLazyImage(_a) {
    var src = _a.src, className = _a.className, mobileSrc = _a.mobileSrc, visibleByDefault = _a.visibleByDefault, useLargeAspectRatio = _a.useLargeAspectRatio;
    return (React.createElement(LazyLoadComponent, { placeholder: React.createElement("div", { className: cn(styles.image, className) }), visibleByDefault: visibleByDefault }, useLargeAspectRatio
        ? React.createElement(Image, { src: src || '', alt: '', className: cn(styles.image, className) })
        : React.createElement(Image, { src: mobileSrc || src || '', alt: '', className: cn(styles.image, className) })));
}

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { windowIsDefined } from '@containers/utils';
// CONSTANTS
// When altering breakpoints make sure to sync up with `consts.less`
export var MOBILE_WIDTH = 480;
export var TABLET_WIDTH = 800;
export var SMALL_SCREEN_WIDTH = 980;
export var SUPER_LARGE = 1200;
export var SUPER_LARGE_TABLE_VIEW = 1600;
export var SMALL_HOME_NAVIGATION = 630;
// FUNCTIONS
var widthToMediaType = function (width, height) {
    if (width < MOBILE_WIDTH) {
        return 'mobile';
    }
    if (height < MOBILE_WIDTH) {
        return 'mobileLandscape';
    }
    if (width < TABLET_WIDTH) {
        return 'tablet';
    }
    if (width < SMALL_SCREEN_WIDTH) {
        return 'smallScreen';
    }
    if (width < SUPER_LARGE) {
        return 'largeScreen';
    }
    return 'superLarge';
};
var widthToCustomBreakPoint = function (width) {
    if (width < SMALL_HOME_NAVIGATION) {
        return 'smallHomeNavigation';
    }
    return 'regularHomeNavigation';
};
var isIpad = function (userAgent) {
    if (userAgent.indexOf('Macintosh') > -1) {
        try {
            document.createEvent('TouchEvent');
            return true;
        }
        catch (e) { /* */ }
    }
    return false;
};
var isAndroid = function (userAgent) { return userAgent.toLowerCase().indexOf('android') > -1; };
var isIOS = function (userAgent) {
    return (/iPad|iPhone|iPod/.test(userAgent) || isIpad(userAgent)) && (typeof window === 'undefined' || !window.MSStream);
};
var getMostLikelyDimensions = function (userAgent) {
    switch (true) {
        case isAndroid(userAgent): return { width: 411, height: 731 }; // pixel
        case isIOS(userAgent): return { width: 375, height: 812 }; // iPhone X
        default: return { width: 1920, height: 1080 };
    }
};
var getScreenDimensions = function (userAgent) {
    if (windowIsDefined()) {
        var _a = __read([
            document.documentElement.clientWidth || window.innerWidth,
            document.documentElement.clientHeight || window.innerHeight,
        ], 2), width = _a[0], height = _a[1];
        return { width: width, height: height };
    }
    return getMostLikelyDimensions(userAgent);
};
export var readViewport = function (userAgent) {
    var _a = getScreenDimensions(userAgent), width = _a.width, height = _a.height;
    return {
        width: width,
        height: height,
        media: widthToMediaType(width, height),
        customBreakPoint: widthToCustomBreakPoint(width),
        isAndroid: isAndroid(userAgent),
        isIOS: isIOS(userAgent),
    };
};
export var isMobile = function (media) { return ['mobile', 'mobileLandscape'].includes(media); };
export var isLikeMobile = function (media) { return ['mobile', 'mobileLandscape', 'tablet'].includes(media); };
export var isSmallScreen = function (media) { return ['mobile', 'mobileLandscape', 'tablet', 'smallScreen'].includes(media); };
export var isMediumScreen = function (media) { return ['mobile', 'mobileLandscape', 'tablet', 'smallScreen', 'largeScreen'].includes(media); };
export var isMobileScreenSize = function () { return windowIsDefined() ? window.innerWidth < MOBILE_WIDTH : false; };
export var isBetweenMobileAndThreshold = function (media, width, threshold) { return !isLikeMobile(media) && width < threshold; };

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import cn from 'classnames';
import styles from './faq.less';
import { Icon } from '@components/icon';
export var Faq = function (_a) {
    var data = _a.data, faqRef = _a.faqRef;
    var _b = __read(useState([]), 2), expandedFaqIds = _b[0], setExpandedFaqIds = _b[1];
    var sortedFaqs = __spreadArray([], __read(data)).sort(function (a, b) { return a.order - b.order; });
    var toggleFaqItem = function (faqId) {
        setExpandedFaqIds(function (prevIds) {
            return prevIds.includes(faqId)
                ? prevIds.filter(function (id) { return id !== faqId; }) // collapse it
                : __spreadArray(__spreadArray([], __read(prevIds)), [faqId]);
        } // expand it
        );
    };
    return (React.createElement("div", { className: cn(styles.faqsContainer), ref: faqRef },
        React.createElement("h1", null, "FAQs"),
        sortedFaqs.map(function (faq) { return (React.createElement("div", { key: faq.id, className: styles.faq, "data-testid": "faq-" + faq.id },
            React.createElement("div", { className: styles.question, onClick: function () { return toggleFaqItem(faq.id); } },
                React.createElement("div", null, faq.question),
                React.createElement(Icon, { height: 16, width: 16, icon: expandedFaqIds.includes(faq.id) ? 'caretUp' : 'caretDown' })),
            expandedFaqIds.includes(faq.id) && React.createElement("div", { className: styles.answer }, faq.answer))); })));
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@containers/helpers/tests';
import cn from 'classnames';
import React, { Component } from 'react';
import { useField } from 'react-final-form';
import { Checkbox as SemanticUiCheckbox } from 'semantic-ui-react';
import styles from './checkbox.less';
export function Checkbox(_a) {
    var className = _a.className, secondary = _a.secondary, deactivate = _a.deactivate, strong = _a.strong, rest = __rest(_a, ["className", "secondary", "deactivate", "strong"]);
    return (React.createElement(SemanticUiCheckbox, __assign({ className: cn(styles.checkbox, { secondary: secondary, strong: strong, deactivate: deactivate }, className) }, rest)));
}
var CheckboxBase = /** @class */ (function (_super) {
    __extends(CheckboxBase, _super);
    function CheckboxBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e, data) {
            var _a = _this.props, onChange = _a.onChange, input = _a.input;
            input.onChange(data.checked);
            input.onBlur();
            if (onChange) {
                onChange(e, data);
            }
        };
        return _this;
    }
    CheckboxBase.prototype.render = function () {
        var _a = this.props, onChange = _a.onChange, input = _a.input, checked = _a.checked, field = _a.field, formApi = _a.formApi, rest = __rest(_a, ["onChange", "input", "checked", "field", "formApi"]);
        var currentChecked = checked != null ? checked : input.value || false;
        return React.createElement(Checkbox, __assign({}, rest, { checked: currentChecked, onChange: this.handleChange }, t(input.name.replace('.', '-'))));
    };
    return CheckboxBase;
}(Component));
export { CheckboxBase };
export function CheckboxField(_a) {
    var name = _a.name, validate = _a.validate, props = __rest(_a, ["name", "validate"]);
    var field = useField(name, { validate: validate });
    return React.createElement(CheckboxBase, __assign({}, props, field));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { rawLastNextEventToLastNextEvent } from '@logic/events';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest } from 'redux-saga/effects';
import { eventsApi, rawEventSearchToEventSearch } from './events';
import { slugifyName } from './helpers/location';
import request, { blobFile, SANE_LIMIT } from './helpers/request';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { mapCategoryToEventType } from '@containers/DetailsPages/components/eventsTable/utils/utils';
export var venueInfoToDescription = function (_a) {
    var venues = _a.venues, category = _a.category;
    if (venues.length === 0) {
        return mapCategoryToEventType(category || '');
    }
    else {
        var distinctVenues = uniq(venues);
        if (distinctVenues.length === 1) {
            return distinctVenues[0].venueName + ", " + distinctVenues[0].venueCity;
        }
        return 'Multiple Venues';
    }
};
export var loadPerformerDetails = createAction('load performer details', function (id) { return ({ id: id }); });
export var performerDetailsLoaded = createAction('fetching performer details succeed', function (performer) { return ({ performer: performer }); });
export var performerDetailsLoadingFail = createAction('fetching performer details failed', function (error) { return ({ error: error }); });
export var loadPerformerEvents = createAction('load list of performer events', function (performerId, offset) { return ({ performerId: performerId, offset: offset }); });
export var performerEventsLoaded = createAction('fetching performer events succeed', function (events, count, searchParams) { return ({ events: events, count: count, searchParams: searchParams }); });
export var performerEventsLoadingFail = createAction('fetching performer events failed', function (error) { return ({ error: error }); });
export var loadPromotedPerformers = createAction('load promoted performers');
export var promotedPerformersLoaded = createAction('fetching promoted performers succeed', function (performers) { return ({ performers: performers }); });
export var promotedPerformersLoadingFail = createAction('fetching promoted performers failed', function (error) { return ({ error: error }); });
export var loadLastNextPerformerEvent = createAction('load last next performer event', function (id) { return ({ id: id }); });
export var lastNextPerformerEventLoaded = createAction('fetching last next performer event succeed', function (lastNextEvent) { return lastNextEvent; });
export var lastNextPerformerLoadingFail = createAction('fetching last next performer event failed', function (error) { return ({ error: error }); });
export var removePerformerDetails = createAction('remove performer details');
export var setPerformerSSRFlag = createAction('set performer SSR flag', function (flag) { return ({ flag: flag }); });
var initialState = {
    isLoadingDetails: true,
    isLoadingEvents: true,
    isLoadingLastNextEvents: true,
    isLoadingPromoted: true,
    performerDetails: null,
    performerEvents: [],
    performerLastNextEvent: null,
    promotedPerformers: [],
    searchParams: {
        limit: SANE_LIMIT,
        orderBy: 'eventDate',
        performerId: undefined,
        offset: 0,
    },
    eventsCount: 0,
    loadedWithSSR: false
};
export var rawPerformerToPerformer = function (performer) {
    var _a;
    return ({
        id: performer.id,
        name: performer.name,
        slug: slugifyName(performer.name),
        description: performer.description,
        keywords: performer.keywords,
        imageUrl: blobFile((_a = performer.imageUrl) !== null && _a !== void 0 ? _a : ''),
        isMainPerformer: performer.isMainPerformer,
        category: performer.category,
        status: performer.status,
        subcategory: performer.subcategory,
        similarPerformers: performer.similarPerformers,
        mediaLinks: performer.mediaLinks,
        venues: performer.venues,
        faqs: performer.faqs,
    });
};
export var rawPromotedPerformerToPromotedPerformer = function (_a) {
    var performer = _a.performer, rest = __rest(_a, ["performer"]);
    return __assign(__assign({}, rawPerformerToPerformer(performer)), rest);
};
export var performerReducer = createReducer((_a = {},
    _a[loadPromotedPerformers.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPromoted: true, promotedPerformers: [] })); },
    _a[promotedPerformersLoaded.getType()] = function (state, _a) {
        var performers = _a.performers;
        return (__assign(__assign({}, state), { isLoadingPromoted: false, promotedPerformers: performers }));
    },
    _a[promotedPerformersLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingPromoted: false, promotedPerformers: [] })); },
    _a[loadLastNextPerformerEvent.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: true, performerLastNextEvent: null })); },
    _a[lastNextPerformerEventLoaded.getType()] = function (state, lastNextEvents) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: false, performerLastNextEvent: lastNextEvents })); },
    _a[lastNextPerformerLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLastNextEvents: false, performerLastNextEvent: null })); },
    _a[removePerformerDetails.getType()] = function (state) { return (__assign(__assign({}, state), { performerDetails: null })); },
    _a[loadPerformerDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingDetails: true, performerDetails: null, performerEvents: [] })); },
    _a[performerDetailsLoaded.getType()] = function (state, _a) {
        var performer = _a.performer;
        return (__assign(__assign({}, state), { isLoadingDetails: false, performerDetails: performer }));
    },
    _a[performerDetailsLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingDetails: false, performerDetails: null })); },
    _a[loadPerformerEvents.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingEvents: true })); },
    _a[performerEventsLoaded.getType()] = function (state, _a) {
        var events = _a.events, count = _a.count, searchParams = _a.searchParams;
        return (__assign(__assign({}, state), { isLoadingEvents: false, performerEvents: state.searchParams.performerId === searchParams.performerId ? __spreadArray(__spreadArray([], __read(state.performerEvents)), __read(events)) : events, eventsCount: count, searchParams: searchParams }));
    },
    _a[performerEventsLoadingFail.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingEvents: false })); },
    _a[setPerformerSSRFlag.getType()] = function (state, _a) {
        var flag = _a.flag;
        return (__assign(__assign({}, state), { loadedWithSSR: flag }));
    },
    _a), initialState);
var performersApi = {
    getPerformer: function (performerId) { return request("/api/performers/get/" + performerId); },
    getPromotedPerformers: function () { return request('/api/performers/hottest'); },
};
export function onLoadPromotedPerfomers() {
    var rawPerformers, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(performersApi.getPromotedPerformers)];
            case 1:
                rawPerformers = _a.sent();
                return [4 /*yield*/, put(promotedPerformersLoaded(rawPerformers.slice(0, 6).map(rawPromotedPerformerToPromotedPerformer)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                console.error(e_1);
                return [4 /*yield*/, put(promotedPerformersLoadingFail(e_1))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch promoted performers')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadPerformerDetails(_a) {
    var performerDetails, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(performersApi.getPerformer, payload.id)];
            case 1:
                performerDetails = _b.sent();
                return [4 /*yield*/, put(performerDetailsLoaded(rawPerformerToPerformer(performerDetails)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_2 = _b.sent();
                return [4 /*yield*/, put(performerDetailsLoadingFail(e_2))];
            case 4:
                _b.sent();
                if (!(get(e_2, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull Performer')];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onLoadPerformerEvents(_a) {
    var queryParams, _b, items, count, e_3;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 7]);
                queryParams = {
                    performerId: payload.performerId,
                    limit: SANE_LIMIT,
                    orderBy: 'eventDate',
                    offset: payload.offset,
                };
                return [4 /*yield*/, call(eventsApi.search, queryParams)];
            case 1:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(performerEventsLoaded(items.map(rawEventSearchToEventSearch), count, queryParams))];
            case 2:
                _c.sent();
                return [3 /*break*/, 7];
            case 3:
                e_3 = _c.sent();
                return [4 /*yield*/, put(performerEventsLoadingFail(e_3))];
            case 4:
                _c.sent();
                if (!(get(e_3, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull Events for Performer')];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onLoadPerformerLastNextEvent(_a) {
    var rawLastNextEvent, lastNextEvent, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'performer', payload.id)];
            case 1:
                rawLastNextEvent = _b.sent();
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextPerformerEventLoaded(lastNextEvent))];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_4 = _b.sent();
                return [4 /*yield*/, put(lastNextPerformerLoadingFail(e_4))];
            case 4:
                _b.sent();
                if (!(get(e_4, 'status') !== 404)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.error, 'Cannot pull last and next event for Performer')];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function performerSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadPromotedPerformers.getType(), onLoadPromotedPerfomers)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadPerformerDetails.getType(), onLoadPerformerDetails)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadPerformerEvents.getType(), onLoadPerformerEvents)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadLastNextPerformerEvent.getType(), onLoadPerformerLastNextEvent)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

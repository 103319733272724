var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Icon } from '@components/icon';
import React, { useMemo } from 'react';
import { Popup } from 'semantic-ui-react';
import styles from './icons.less';
var getIconInfo = function (ticketType, hasTicketsAssigned) {
    switch (ticketType) {
        case 'any':
            return ['any-ticket', 'Any ticket type'];
        case 'paper':
            return ['paper-ticket', 'Paper ticket'];
        case 'eTicket':
            return hasTicketsAssigned ? ['instant-eticket', 'Instant download ticket'] : ['eticket', 'e-Ticket'];
        case 'mobileTransfer':
            return ['mobile-transfer-ticket', 'Mobile Transfer - Smartphone required'];
        case 'ticketmaster':
            return ['mobile-transfer-ticket', 'Ticketmaster Mobile Transfer - Ticketmaster smartphone app required'];
        case 'qrCode':
            return hasTicketsAssigned ? ['instant-eticket', 'Instant download QR Code'] : ['qrcode-ticket', 'Smartphone required'];
    }
};
export var getIconDescription = function (ticketType) {
    switch (ticketType) {
        case 'any': return 'Any Ticket';
        case 'paper': return 'Paper Ticket';
        case 'eTicket': return "e" + String.fromCharCode(8209) + "Ticket";
        case 'mobileTransfer': return 'Mobile Ticket';
        case 'ticketmaster': return 'Mobile Ticket';
        case 'qrCode': return 'QR Code';
    }
};
export function TicketIcon(_a) {
    var ticketType = _a.ticketType, hasTicketsAssigned = _a.hasTicketsAssigned, hideDescription = _a.hideDescription, rest = __rest(_a, ["ticketType", "hasTicketsAssigned", "hideDescription"]);
    var _b = __read(getIconInfo(ticketType, hasTicketsAssigned), 2), iconType = _b[0], iconPopupMessage = _b[1];
    var width = rest.width;
    var style = useMemo(function () { return (width ? { width: width } : {}); }, [width]);
    return (React.createElement(Popup, { trigger: React.createElement("div", { className: styles.icon },
            React.createElement(Icon, __assign({ icon: iconType, style: style }, rest)),
            !hideDescription && React.createElement("div", { className: styles.iconDescription }, getIconDescription(ticketType))), hoverable: true, content: iconPopupMessage }));
}

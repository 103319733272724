var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styles from '@containers/DetailsPages/components/shareSocial/shareSocial.less';
import { Icon } from '@components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useHostname } from '@components/useStaticContext';
import { copyToClipboard } from '@containers/utils';
export var ShareSocial = function (_a) {
    var links = _a.links;
    var _b = __read(useState('Click to copy'), 2), copyTooltip = _b[0], setCopyTooltip = _b[1];
    var hostname = useHostname();
    var copy = function () {
        copyToClipboard(links.originalUrl);
        setCopyTooltip('Copied!');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.shareSocialTitle }, " Share"),
        React.createElement("div", { className: styles.copySection, title: copyTooltip },
            React.createElement("div", { className: styles.textBox, onClick: copy },
                React.createElement("span", null, "" + hostname + location.pathname)),
            React.createElement(Icon, { onClick: copy, icon: 'clone', width: 24 })),
        React.createElement("div", { className: styles.appsSection },
            React.createElement("div", { className: styles.shareSocialTitle }, " Share via App"),
            React.createElement("div", { className: styles.apps },
                React.createElement("a", { href: links.facebookLink, title: "Share on Facebook", target: "_blank", rel: "noreferrer", className: styles.socialLink },
                    React.createElement(FontAwesomeIcon, { icon: faFacebook })),
                React.createElement("a", { href: links.twitterLink, className: styles.socialLink, target: "_blank", rel: "noreferrer" },
                    React.createElement(FontAwesomeIcon, { icon: faTwitter })),
                React.createElement("a", { href: links.pinterestLink, className: styles.socialLink, target: "_blank", rel: "noreferrer" },
                    React.createElement(FontAwesomeIcon, { icon: faPinterest })),
                React.createElement("a", { href: links.linkedinLink, className: styles.socialLink, target: "_blank", rel: "noreferrer" },
                    React.createElement(FontAwesomeIcon, { icon: faLinkedinIn }))))));
};

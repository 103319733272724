var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import styles from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigationMobile.less';
import { t } from '@containers/helpers/tests';
import { Icon } from '@components/icon';
import { useToggle } from '@components/forms';
import { Link } from 'react-router-dom';
import { routes } from 'src/client/routing/routes';
import cn from 'classnames';
var SubcategoryAccordion = function (_a) {
    var category = _a.category, onSelect = _a.onSelect;
    if (!category.subcategories.length) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", __assign({ className: styles.subcategoryContainer }, t('Subcategory-panel-mobile')), category.subcategories.map(function (subcategory, index) {
        return React.createElement(Link, { to: routes.subcategory(category.localId, subcategory.localId), key: index + "-subcategory-navigation", onClick: onSelect, className: styles.navRow },
            React.createElement("span", null, subcategory.name));
    })));
};
var CategoryAccordion = function (_a) {
    var category = _a.category, onSelect = _a.onSelect;
    var _b = useToggle(false), open = _b.state, handleToggleSubcategory = _b.toggle;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(styles.navRow, styles.categoryRow), onClick: handleToggleSubcategory },
            React.createElement(Link, { to: routes.category(category.localId), onClick: onSelect },
                React.createElement("span", null, category.name)),
            open ? React.createElement(Icon, { icon: 'caretUp', height: 16, width: 16 }) : React.createElement(Icon, { icon: 'caretDown', height: 16, width: 16 })),
        open && React.createElement(SubcategoryAccordion, { onSelect: onSelect, category: category })));
};
export var CategoriesNavigationMobile = function () {
    var categories = useSelector(function (gi) { return gi.categories.categories; });
    var _a = useToggle(false), open = _a.state, handleToggleCategories = _a.toggle, deactivate = _a.deactivate;
    if (!categories.length) {
        return null;
    }
    return (React.createElement("div", __assign({}, t('Category-panel-mobile'), { className: styles.iconContainer }),
        React.createElement(Icon, { icon: 'menu', className: styles.categoryMenu, onClick: handleToggleCategories, width: 24, height: 24 }),
        open &&
            React.createElement("div", { className: styles.categoriesContainer }, categories.map(function (category, index) {
                return React.createElement(React.Fragment, { key: index + "-category-mobile-navigation" },
                    React.createElement(CategoryAccordion, { category: category, onSelect: deactivate }));
            }))));
};

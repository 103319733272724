import axios from 'axios';
import { CANCEL } from 'redux-saga';
import { routes } from 'src/client/routing/routes';
import { getSession } from './localStorage';
import { windowIsDefined } from '@containers/utils';
// A sane limitation for some API requests
// to offload backend and avoid displaying
// too many results on one page.
export var SANE_LIMIT = 100;
export var serviceDiscovery = function (url) {
    var services = typeof window === 'undefined' ? global.URLS : window.URLS;
    if (!services) {
        return '';
    }
    var service = url.split('/')[2];
    var resolved = services[service];
    if (resolved == null) {
        throw Error("Unable to find URL for service: " + service + " (url: " + url + ")");
    }
    return resolved;
};
export default (function (url, body, options) {
    var _a, _b, _c;
    var source = axios.CancelToken.source();
    var foreignRequest = url.match('^https?://');
    var responseType = (_a = options === null || options === void 0 ? void 0 : options.responseType) !== null && _a !== void 0 ? _a : 'json';
    var opt = {
        url: foreignRequest ? url : serviceDiscovery(url) + url,
        method: (_b = options === null || options === void 0 ? void 0 : options.method) !== null && _b !== void 0 ? _b : 'get',
        headers: {},
        cancelToken: source.token,
        data: undefined,
        responseType: responseType,
    };
    var defaultError = function (error) {
        var _a;
        if (windowIsDefined()) {
            // TODO this logic needs to be moved to a place where we have access to the static context
            if (error.response.status === 401) {
                window.location.replace(routes.logout());
            }
            else if (error.response.status === 403) {
                window.location.replace(routes.index());
            }
        }
        return Promise.reject((_a = error.response) !== null && _a !== void 0 ? _a : {});
    };
    if (body) {
        opt.data = body;
        opt.method = (_c = options === null || options === void 0 ? void 0 : options.method) !== null && _c !== void 0 ? _c : 'post';
        opt.headers['Content-Type'] = 'application/json';
    }
    var defaultDataExtractor = function (_a) {
        var data = _a.data;
        return data;
    };
    var blobDataExtractor = function (response) {
        var _a;
        return new Blob([response.data], {
            type: (_a = options === null || options === void 0 ? void 0 : options.mimeType) !== null && _a !== void 0 ? _a : (response.headers ? response.headers['content-type'] : 'application/json'),
        });
    };
    var dataExtractor = responseType === 'blob' ? blobDataExtractor : defaultDataExtractor;
    var session = getSession();
    if (session && !foreignRequest) {
        opt.headers.Authorization = "bearer " + session.token;
    }
    var request = axios.request(opt).then(dataExtractor).catch((options === null || options === void 0 ? void 0 : options.errorHandler) || defaultError); // support request cancelation with saga, see: https://github.com/redux-saga/redux-saga/issues/651#issuecomment-262375964
    request[CANCEL] = function () { return source.cancel(); };
    return request;
});
export var download = function (blob, name, options) {
    if (typeof window === undefined) {
        return;
    }
    var url = window.URL.createObjectURL(blob);
    if (options === null || options === void 0 ? void 0 : options.openInNewTab) {
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        createAndClickLink(a);
    }
    if (!(options === null || options === void 0 ? void 0 : options.disableDownload)) {
        var a = document.createElement('a');
        a.href = url;
        a.download = name;
        createAndClickLink(a);
    }
};
var createAndClickLink = function (a) {
    var body = document.body;
    if (body) {
        body.appendChild(a);
    }
    a.click();
    if (body) {
        body.removeChild(a);
    }
};
export var downloadFile = function (file) {
    download(file, file.name);
};
var blobUrl = function () {
    var _a, _b;
    if (windowIsDefined()) {
        return (_a = window.BLOB_URL) !== null && _a !== void 0 ? _a : window.location.protocol + "//" + window.location.host + "/";
    }
    return (typeof global !== 'undefined') ? ((_b = global.BLOB_URL) !== null && _b !== void 0 ? _b : '') : '';
};
export var blobFile = function (base) {
    return blobUrl().replace(/\/$/, '') + "/" + base.replace(/^\//, '');
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { CurrencyInput, KEYCODES } from '@components/forms';
import { toIntPriceValue } from '@logic/helpers/currency';
import { patchBuyListing, patchSellListing } from '@logic/listings';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
var updateBuyListing = function (field, newValue) { return function (l) {
    var _a;
    return __assign(__assign({}, l), (_a = {}, _a[field] = newValue, _a));
}; };
var updateSellListing = function (field, newValue) { return function (l) {
    var _a;
    return __assign(__assign({}, l), (_a = {}, _a[field] = newValue, _a));
}; };
export var ListingUpdatePrice = function (props) {
    var label = props.label, minValue = props.minValue, value = props.value, listingId = props.listingId, listingType = props.listingType, sellField = props.sellField, buyField = props.buyField, disabled = props.disabled;
    var dispatch = useDispatch();
    var initialValue = (value / 100).toFixed(2);
    var _a = __read(useState(initialValue), 2), inputValue = _a[0], setInputValue = _a[1];
    var handleChange = function (_, _a) {
        var value = _a.value;
        setInputValue(value || '');
    };
    var doUpdate = useCallback(function () {
        if (toIntPriceValue(inputValue) === value) {
            return;
        }
        if (!inputValue) {
            return setInputValue(initialValue);
        }
        var isMoreThanMinValue = Number(inputValue) > minValue;
        if (listingType === 'buy') {
            return dispatch(patchBuyListing(listingId, updateBuyListing(buyField, toIntPriceValue(isMoreThanMinValue ? inputValue : minValue)), function () { return null; }));
        }
        else {
            return dispatch(patchSellListing(listingId, updateSellListing(sellField, toIntPriceValue(isMoreThanMinValue ? inputValue : minValue)), function () { return null; }));
        }
    }, [buyField, dispatch, initialValue, inputValue, listingId, listingType, minValue, sellField, value]);
    var handleKeyUp = function (e) {
        if (e.keyCode === KEYCODES.ENTER) {
            doUpdate();
        }
    };
    return React.createElement(CurrencyInput, { onBlur: doUpdate, onKeyUp: handleKeyUp, onChange: handleChange, small: true, min: minValue, label: label, value: inputValue, disabled: disabled });
};

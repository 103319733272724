var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Link, Modal } from '@components/forms';
import React, { useCallback } from 'react';
import styles from './presentReservationPopup.less';
import { Timer } from '@containers/TransactionsFlow/components/timer';
import { useDispatch, useSelector } from 'react-redux';
import { releaseReservation, reservationExpired } from '@logic/reservations';
import { routes } from 'src/client/routing/routes';
import { matchPath } from 'react-router';
export var isPathAllowedToWorkWithReservationPopup = function (path) {
    return matchPath(path, routes.reservation(':type')) ||
        matchPath(path, routes.login('buy')) ||
        matchPath(path, routes.disputeResolutionPolicy()) ||
        matchPath(path, routes.beeyayGuarantee()) ||
        matchPath(path, routes.terms()) ||
        matchPath(path, routes.register('sell')) ||
        matchPath(path, routes.login('sell')) ||
        matchPath(path, routes.resetPassword());
};
export var PresentReservationPopup = function () {
    var dispatch = useDispatch();
    var _a = __read(useSelector(function (gs) { return gs.reservations.reservationState; }), 2), reservationUrl = _a[1];
    var reservation = useSelector(function (gs) { return gs.reservations.reservation; });
    var endTime = reservation && reservation.endTime;
    var handleTimeout = useCallback(function () {
        if (reservation) {
            dispatch(reservationExpired({ redirect: false }));
        }
    }, [dispatch, reservation]);
    if (!reservation || !endTime || endTime < new Date()) {
        return null;
    }
    return (React.createElement(Modal, { open: true, closeOnDimmerClick: false },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.header }, "You currently have reserved tickets!"),
            React.createElement("div", null, "Do you wish to keep these tickets reserved or release the tickets and continue browsing?"),
            React.createElement(Timer, { className: styles.timer, endTime: endTime, onTimeout: handleTimeout }),
            React.createElement(Link, { to: reservationUrl || routes.reservation(reservation.type, 'summary'), replace: true },
                React.createElement(Button, { className: styles.button, medium: true, ternary: true },
                    "Keep this reservation and continue to ",
                    reservation.type === 'buy' ? 'purchase' : 'sale')),
            React.createElement(Button, { className: styles.button, medium: true, onClick: function () { return dispatch(releaseReservation(false)); } }, "Release this reservation and continue browsing"))));
};

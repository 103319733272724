var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@components/forms';
import { Icon } from '@components/icon';
import cn from 'classnames';
import React from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import styles from './priceButton.less';
import { BUY_ONLY } from 'src/client/routing/routes';
var roundPrice = function (value, round) {
    switch (round) {
        case 'up': return Math.ceil(value / 100);
        case 'down': return Math.floor(value / 100);
        case undefined: return value / 100;
    }
};
export var PriceButton = function (_a) {
    var _b, _c, _d;
    var price = _a.price, transactionSide = _a.transactionSide, center = _a.center, edit = _a.edit, disabled = _a.disabled, onClick = _a.onClick, hideFrom = _a.hideFrom, round = _a.round, narrow = _a.narrow, rest = __rest(_a, ["price", "transactionSide", "center", "edit", "disabled", "onClick", "hideFrom", "round", "narrow"]);
    var buyOnly = transactionSide === BUY_ONLY;
    var buySide = transactionSide === 'buy';
    var sellSide = transactionSide === 'sell';
    var value = !price ? 0 : roundPrice(price.value, round);
    var buttonType = transactionSide === BUY_ONLY ? 'buy' : transactionSide;
    var buttonContent = !price
        ? React.createElement("div", null, buySide ? 'SUBMIT A BID' : 'SUBMIT AN ASK')
        : React.createElement(React.Fragment, null,
            React.createElement("span", null,
                !hideFrom && 'FROM',
                " ",
                price.currency),
            (value).toFixed(2).length >= 8 ? (value).toFixed(0) : (value).toFixed(2));
    var buttonCn = cn(styles.from, (_b = {},
        _b[styles.doNotHavePrice] = !price,
        _b));
    return (React.createElement(Button, __assign({ fill: true, type: edit ? undefined : buttonType, className: cn(styles.button, (_c = {},
            _c[styles.center] = center,
            _c[styles.edit] = edit,
            _c[styles.narrow] = narrow,
            _c)), disabled: disabled, onClick: onClick }, rest),
        React.createElement("div", { className: cn(styles.actionInfo, (_d = {}, _d[styles.buy] = buySide || buyOnly, _d[styles.sell] = sellSide, _d)) }, (buySide || buyOnly ? 'BUY' : 'SELL') + " TICKETS",
            !edit
                ? React.createElement(Icon, { className: styles.iconArrow, icon: 'play' })
                : React.createElement(SemanticIcon, { className: styles.iconEdit, name: 'edit' })),
        !buyOnly &&
            React.createElement("div", { className: buttonCn }, buttonContent)));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { tryMapError } from './errorCodeMapper';
import request from './helpers/request';
export var loadGlobalSettings = createAction('load global settings');
export var loadGlobalSettingsSuccess = createAction('load global settings successd', function (settings) { return ({
    settings: settings,
}); });
export var loadGlobalSettingsFailure = createAction('load global settings failure', function (error) { return ({
    error: error,
}); });
export var loadUserSettings = createAction('load user settings');
export var loadUserSettingsSuccess = createAction('load user settings success', function (settings) { return ({
    settings: settings,
}); });
export var loadUserSettingsFailure = createAction('load user settings failure', function (error) { return ({
    error: error,
}); });
// move here to avoid cyclic deps.
export var setGuestCheckoutEmail = createAction('set guest checkout email', function (email) { return email; });
var initialState = {
    userSettings: null,
    globalSettings: null,
    isGlobalPending: true,
    isUserPending: true,
};
export var userSettingsReducer = createReducer((_a = {},
    _a[loadGlobalSettings.getType()] = function (state) { return (__assign(__assign({}, state), { isGlobalPending: true })); },
    _a[loadGlobalSettingsSuccess.getType()] = function (state, _a) {
        var settings = _a.settings;
        return (__assign(__assign({}, state), { globalSettings: settings, isGlobalPending: false }));
    },
    _a[loadGlobalSettingsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isGlobalPending: false })); },
    _a[loadUserSettings.getType()] = function (state) { return (__assign(__assign({}, state), { isUserPending: true })); },
    _a[loadUserSettingsSuccess.getType()] = function (state, _a) {
        var settings = _a.settings;
        return (__assign(__assign({}, state), { userSettings: settings, isUserPending: false }));
    },
    _a[loadUserSettingsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isUserPending: false })); },
    _a), initialState);
var userSettingsApi = {
    loadUserSettings: function () { return request('/api/userSettings'); },
    loadGlobalSettings: function () { return request('/api/globalSettings'); },
};
export var selectUserSettings = function (s) { return s.userSettings.userSettings; };
export var selectGlobalSettings = function (s) { return s.userSettings.globalSettings; };
export var selectSettings = function (s) { var _a; return (_a = s.userSettings.userSettings) !== null && _a !== void 0 ? _a : s.userSettings.globalSettings; };
export function onLoadGlobalSettings(_) {
    var settings, response, e_1, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(selectGlobalSettings)];
            case 1:
                settings = _b.sent();
                if (settings) {
                    return [2 /*return*/];
                }
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 8]);
                return [4 /*yield*/, call(userSettingsApi.loadGlobalSettings)];
            case 3:
                response = _b.sent();
                return [4 /*yield*/, put(loadGlobalSettingsSuccess(response))];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_1 = _b.sent();
                errorMsg = (_a = tryMapError(e_1)) !== null && _a !== void 0 ? _a : 'Cannot load information about fees.';
                return [4 /*yield*/, put(loadGlobalSettingsFailure(errorMsg))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onLoadUserSettings(_) {
    var response, e_2, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(userSettingsApi.loadUserSettings)];
            case 1:
                response = _b.sent();
                return [4 /*yield*/, put(loadUserSettingsSuccess(response))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_2 = _b.sent();
                errorMsg = (_a = tryMapError(e_2)) !== null && _a !== void 0 ? _a : 'Cannot load information about fees.';
                return [4 /*yield*/, put(loadUserSettingsFailure(errorMsg))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function userSettingsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadGlobalSettings.getType(), onLoadGlobalSettings)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadUserSettings.getType(), onLoadUserSettings)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from 'react';
import { windowIsDefined } from '@containers/utils';
export var useWindowProperty = function (propertyName, initialValue) {
    var initialValueFromWindow = windowIsDefined() ? window[propertyName] : undefined;
    var _a = __read(useState(initialValueFromWindow !== undefined ? initialValueFromWindow : initialValue), 2), propertyValue = _a[0], setPropertyValue = _a[1];
    useEffect(function () {
        if (!windowIsDefined()) {
            return; // Do nothing if SSR
        }
        var currentValue = window[propertyName];
        // The approach using Object.defineProperty()
        // actively listens for changes in window.customKey and reacts accordingly.
        Object.defineProperty(window, propertyName, {
            configurable: true,
            get: function () {
                return currentValue;
            },
            set: function (value) {
                currentValue = value;
                setPropertyValue(value);
            }
        });
    }, [propertyName]);
    return propertyValue;
};
export default useWindowProperty;

import { Icon } from '@components/icon';
import cn from 'classnames';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useUserAgent } from '../useUserAgent';
import styles from './eventNotesAndRestrictions.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
function split(notes) {
    return notes.split('\n').map(function (n, i) { return React.createElement("p", { key: i }, n); });
}
export function NotesRestrictions(_a) {
    var event = _a.event, media = _a.media;
    var description = 'description' in event ? event.description : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null,
            React.createElement("strong", null, event.name)),
        event.restrictions && React.createElement(React.Fragment, null,
            split(event.restrictions),
            React.createElement("br", null)),
        event.notes && split(event.notes),
        description && (media === 'mobile' || media === 'mobileLandscape') && split(description)));
}
export function EventNotesAndRestrictions(_a) {
    var event = _a.event, className = _a.className;
    var media = useViewportInfo().media;
    var isBot = useUserAgent().isBot;
    var restrictions = event.restrictions || '';
    if (isBot) {
        return React.createElement("div", { className: cn(styles.notesDetails, styles.botDetails) },
            React.createElement(NotesRestrictions, { event: event, media: media }));
    }
    return (React.createElement("div", { className: cn(styles.notes, className) },
        React.createElement(Popup, { trigger: React.createElement(Icon, { className: styles.icon, icon: 'info' }), hoverable: true, className: styles.notesDetails, content: React.createElement(NotesRestrictions, { event: event, media: media }) }),
        restrictions));
}

import React from 'react';
import { useViewportInfo } from '../viewPortInfo/useViewportInfo.hook';
export function MobileView(_a) {
    var mobile = _a.mobile, tablet = _a.tablet, others = _a.others;
    var media = useViewportInfo().media;
    if (media === 'mobile' || media === 'mobileLandscape') {
        return React.createElement(React.Fragment, null, mobile);
    }
    if (media === 'tablet' && !!tablet) {
        return React.createElement(React.Fragment, null, tablet);
    }
    return React.createElement(React.Fragment, null, others);
}

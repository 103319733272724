var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { CustomTrustboxSlider } from './custom-trustbox-slider/customTrustboxSlider';
import { TrustpilotLogo } from './trustpilotLogo/trustpilotLogo';
import { windowIsDefined } from '@containers/utils';
export var TrustBox = function (_a) {
    var className = _a.className, type = _a.type;
    var ref = React.useRef(null);
    var _b = __read(useState(false), 2), show = _b[0], changeShow = _b[1];
    useEffect(function () {
        if (windowIsDefined() && window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    useEffect(function () { return changeShow(true); }, []);
    if (!show) {
        return null;
    }
    switch (type) {
        case 'slider': return React.createElement(CustomTrustboxSlider, { className: className });
        case 'trustbox-slider':
            return (React.createElement("div", { ref: ref, className: cn('trustpilot-widget', className), "data-locale": "en-GB", "data-template-id": "54ad5defc6454f065c28af8b", "data-businessunit-id": "5e5ebbff92ead400019e8ec3", "data-style-height": "240px", "data-style-width": "100%", "data-theme": "light", "data-tags": "SelectedReview", "data-review-languages": "en", "data-font-family": "Roboto" },
                React.createElement(TrustpilotLogo, null)));
        case 'info':
            return (React.createElement("div", { ref: ref, className: cn('trustpilot-widget', className), "data-locale": "en-GB", "data-template-id": "5419b732fbfb950b10de65e5", "data-businessunit-id": "5e5ebbff92ead400019e8ec3", "data-style-height": "65px", "data-style-width": "100%", "data-theme": "light", "data-font-family": "Roboto" },
                React.createElement(TrustpilotLogo, null)));
    }
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, DateTimeInputField, InputField, Label, Link, Loading, Modal, ModalContentRow, TextAreaField, } from '@components/forms';
import { t } from '@containers/helpers/tests';
import { requireAuth } from '@logic/helpers/authentication';
import { formatDateTimeWithoutTZ } from '@logic/helpers/date';
import { downloadFile } from '@logic/helpers/request';
import { isRequired } from '@logic/helpers/validators';
import { createNewEventWorkItem } from '@logic/workItems';
import cn from 'classnames';
import { push } from 'connected-react-router';
import { addDays, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { Form } from 'react-final-form';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Dimmer, Icon } from 'semantic-ui-react';
import { getQueryString, routes } from 'src/client/routing/routes';
import PrivateRoute from 'src/client/routing/privateRoute';
import { Entry } from '../../ListingQuickEntry/utils';
import { Files } from '../files/files';
import { useFiles } from '../files/useFiles';
import styles from './newEvent.less';
function NewEventForm(_a) {
    var formApi = _a.formApi, filesState = _a.filesState, isPending = _a.isPending;
    var handleSend = useCallback(function () { return formApi.handleSubmit(); }, [formApi]);
    var handleDownloadNotSent = useCallback(function (_, idx) {
        downloadFile(filesState.files[idx]);
    }, [filesState.files]);
    useEffect(function () {
        formApi.form.initialize({ eventDate: { date: undefined, time: '19:00' } });
        // run only for the first time, so no deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var inputProps = useMemo(function () { return ({
        dayPickerProps: {
            disabledDays: { before: startOfDay(addDays(new Date(), 1)) },
        },
    }); }, []);
    return (React.createElement(Dimmer.Dimmable, null,
        React.createElement(Entry, { name: 'eventName' },
            React.createElement(Label, { fill: true },
                "Event name",
                React.createElement(InputField, { name: 'eventName', disabled: isPending }))),
        React.createElement(Entry, { name: 'eventDate' },
            React.createElement(Label, { fill: true },
                "Event date",
                React.createElement(DateTimeInputField, { name: 'eventDate', disabled: isPending, dayPickerInputProps: inputProps }))),
        React.createElement(Entry, { name: 'venue' },
            React.createElement(Label, { fill: true },
                "Venue",
                React.createElement(InputField, { name: 'venue', disabled: isPending }))),
        React.createElement(Entry, { name: 'city' },
            React.createElement(Label, { fill: true },
                "City",
                React.createElement(InputField, { name: 'city', disabled: isPending }))),
        React.createElement(Entry, { name: 'url' },
            React.createElement(Label, { fill: true },
                "URL link to event",
                React.createElement(InputField, { name: 'url', disabled: isPending }))),
        React.createElement(Entry, { name: 'comments' },
            React.createElement(Label, { fill: true },
                "Additional comments",
                React.createElement(TextAreaField, { name: 'comments', disabled: isPending }))),
        React.createElement(Files, { files: filesState.previewFiles, deleteFile: filesState.handleDelete, downloadFile: handleDownloadNotSent }),
        React.createElement(Dropzone, { onDrop: filesState.handleDrop, className: styles.dropzone, disabled: isPending },
            React.createElement(Icon, { name: 'attach' }),
            "add an attachment"),
        React.createElement("div", { className: styles.actions },
            React.createElement(Button, { ternary: true, medium: true, disabled: isPending, onClick: handleSend, className: styles.sendButton }, "Send ")),
        React.createElement(Loading, { active: isPending })));
}
function validate(data) {
    return {
        eventName: isRequired(data.eventName),
        eventDate: isRequired(data.eventDate && data.eventDate.date),
        venue: isRequired(data.venue),
        city: isRequired(data.city),
        url: isRequired(data.url),
    };
}
export function NewEventModal(_a) {
    var actions = _a.actions, prevLocation = _a.prevLocation, isPending = _a.isPending;
    var filesState = useFiles();
    var handleSubmit = useCallback(function (data) {
        var description = "\n    Event Name: " + data.eventName + "\n    EventDate: " + formatDateTimeWithoutTZ(data.eventDate && data.eventDate.date) + "\n    Venue: " + data.venue + "\n    City: " + data.city + "\n    URL: " + data.url + "\n    Additional Comment: " + (data.comments || '') + "\n    ";
        actions.createNewEventWorkItem(description, filesState.files, prevLocation);
    }, [actions, filesState.files, prevLocation]);
    var handleDeactivate = useCallback(function () { return actions.push(prevLocation); }, [actions, prevLocation]);
    return (React.createElement(Modal, __assign({ open: true, close: 'icon', closeOnDimmerClick: false, onClose: handleDeactivate, medium: true, className: styles.modal, contentClassName: styles.content }, t('NewEventModal')),
        React.createElement(Helmet, null,
            React.createElement("meta", { name: 'robots', content: 'noindex' })),
        React.createElement(ModalContentRow, { className: styles.description },
            "Request a new event",
            React.createElement(Form, { onSubmit: handleSubmit, validate: validate }, function (formApi) { return React.createElement(NewEventForm, { formApi: formApi, filesState: filesState, isPending: isPending }); }))));
}
export function NewEventBase(_a) {
    var baseRoute = _a.baseRoute, location = _a.location, match = _a.match, onOpen = _a.onOpen, hiddenButton = _a.hiddenButton, rest = __rest(_a, ["baseRoute", "location", "match", "onOpen", "hiddenButton"]);
    var search = getQueryString(location);
    var prevLocation = useMemo(function () { return ({ pathname: routes.revertNewEventRequest(location.pathname), search: search }); }, [location.pathname, search]);
    return (React.createElement(React.Fragment, null,
        !hiddenButton &&
            React.createElement(Link, { ternary: true, asButton: true, className: cn(styles.button, rest.className), to: routes.newEventRequestWithFilter(location.pathname, search), onClick: onOpen, rel: 'nofollow' }, "Request an event"),
        React.createElement(Switch, null,
            React.createElement(PrivateRoute, { exact: true, path: routes.newEventRequest(baseRoute), render: function () { return React.createElement(NewEventModal, __assign({ baseRoute: baseRoute, prevLocation: prevLocation }, rest)); }, authenticated: requireAuth }))));
}
export var NewEvent = withRouter(connect(function (state) { return ({ isPending: state.workItems.isPending || state.workItems.uploading > 0 }); }, function (dispatch) { return ({
    actions: bindActionCreators({
        createNewEventWorkItem: createNewEventWorkItem,
        push: push,
    }, dispatch),
}); })(NewEventBase));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ClearableInput } from '@components/clearableInput/clearableInput';
import { Button, Modal, useCallbackRef, useToggle } from '@components/forms';
import { Icon } from '@components/icon';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Navigation from '../../Navigation/navigation';
import { FilterGroup } from './filter';
import styles from './headlineRow.less';
import { MobileView } from '@components/mobileView/mobileView';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { hasGlobalPageScrollBar } from '@containers/utils';
var empty = [];
export function DesktopHeadlineRow(_a) {
    var extraActions = _a.extraActions, _b = _a.onFilterChange, onFilterChange = _b === void 0 ? function () { } : _b, search = _a.search, _c = _a.filters, filters = _c === void 0 ? [] : _c, _d = _a.filterValues, filterValues = _d === void 0 ? {} : _d, onSearch = _a.onSearch, onSearchClear = _a.onSearchClear;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.main },
            React.createElement(ClearableInput, { icon: 'search', onChange: onSearch, onClear: onSearchClear, placeholder: 'Filter by event, artist, venue or city', value: search, className: styles.search, fluid: true })),
        React.createElement("div", { className: styles.filters }, filters.map(function (f) { return React.createElement(FilterGroup, { orientation: 'vertical', key: f.title, title: f.title, values: f.values, selectedValues: filterValues[f.title] || empty, onChange: onFilterChange }); })),
        extraActions));
}
function MobileHeadline(_a) {
    var _b = _a.onFilterChange, onFilterChange = _b === void 0 ? function () { } : _b, search = _a.search, _c = _a.filters, filters = _c === void 0 ? [] : _c, _d = _a.filterValues, filterValues = _d === void 0 ? {} : _d, extraActions = _a.extraActions, extraActionsInModal = _a.extraActionsInModal, onSearch = _a.onSearch, onSearchClear = _a.onSearchClear, onFilterClear = _a.onFilterClear, onFilterDefault = _a.onFilterDefault;
    var _e = useToggle(false), isOpen = _e.state, handleOpen = _e.activate, handleClose = _e.deactivate;
    return (React.createElement("div", { className: styles.mobileHeadline },
        extraActions && !extraActionsInModal &&
            React.createElement("div", { className: styles.mobileHeadlineRow }, extraActions),
        React.createElement("div", { className: styles.mobileHeadlineRow },
            React.createElement(ClearableInput, { icon: 'search', onChange: onSearch, onClear: onSearchClear, placeholder: 'Filter by event, artist, venue or city', value: search, extraAction: (filters.length > 0 || (extraActionsInModal && extraActions)) && React.createElement(Icon, { onClick: handleOpen, icon: 'filter', width: 15, height: 15 }), className: styles.search, fluid: true })),
        React.createElement(Modal, { open: isOpen, onClose: handleClose, className: styles.filterModal },
            React.createElement(Navigation, { search: false, hideMenu: true }),
            React.createElement("div", { className: styles.filterByText },
                "Filter by",
                React.createElement("div", { className: styles.filterActions },
                    React.createElement(Button, { className: styles.clearFilters, onClick: onFilterDefault }, "Set default"),
                    React.createElement(Button, { className: styles.clearFilters, onClick: onFilterClear }, "Clear all"))),
            React.createElement(ClearableInput, { icon: 'search', onChange: onSearch, onClear: onSearchClear, placeholder: 'Filter by event, artist, venue or city', value: search, className: styles.search, fluid: true }),
            extraActions,
            React.createElement("div", { className: styles.filters }, filters.map(function (f) { return React.createElement(FilterGroup, { key: f.title, title: f.title, values: f.values, selectedValues: filterValues[f.title] || empty, onChange: onFilterChange }); })),
            React.createElement(Button, { fill: true, className: styles.mobileButton, onClick: handleClose }, "Close and apply"))));
}
export function HeadlineRow(_a) {
    var _b;
    var onSearch = _a.onSearch, onChangeStickyOffset = _a.onChangeStickyOffset, search = _a.search, props = __rest(_a, ["onSearch", "onChangeStickyOffset", "search"]);
    var _c = __read(useCallbackRef(null), 2), ref = _c[0], changeRef = _c[1];
    var _d = __read(useState(search), 2), searchValue = _d[0], changeSearchValue = _d[1];
    var _e = useViewportInfo(), media = _e.media, width = _e.width;
    var pathname = useLocation().pathname;
    var hasFixedRow = useMemo(function () { return hasGlobalPageScrollBar(pathname, media, width); }, [media, width, pathname]);
    useEffect(function () {
        if (searchValue !== search) {
            changeSearchValue(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search /* intentionally omitted: searchValue, */]);
    var handleSearch = useCallback(function (_, _a) {
        var value = _a.value;
        changeSearchValue(value);
    }, []);
    var handleSearchClear = useCallback(function () {
        changeSearchValue('');
        onSearch === null || onSearch === void 0 ? void 0 : onSearch('');
    }, [onSearch]);
    useEffect(function () {
        var id = setTimeout(function () { return searchValue !== search ? onSearch === null || onSearch === void 0 ? void 0 : onSearch(searchValue) : undefined; }, 250);
        return function () { return clearTimeout(id); };
    }, [onSearch, searchValue, search]);
    useLayoutEffect(function () {
        if (onChangeStickyOffset && ref) {
            // wait for reflow...
            setTimeout(function () {
                var style = window.getComputedStyle(ref);
                var top = parseInt(style.top || '', 10) || 0;
                var height = parseInt(style.height || '', 10) || 0;
                onChangeStickyOffset(top + height);
            }, 0);
        }
    }, [onChangeStickyOffset, ref, width]);
    return (React.createElement("div", { className: cn(styles.filteringRow, (_b = {}, _b[styles.fixedFilteringRow] = hasFixedRow, _b)), ref: changeRef },
        React.createElement(MobileView, { mobile: React.createElement(MobileHeadline, __assign({ search: searchValue }, props, { onSearch: handleSearch, onSearchClear: handleSearchClear })), tablet: React.createElement(MobileHeadline, __assign({ search: searchValue }, props, { onSearch: handleSearch, onSearchClear: handleSearchClear })), others: React.createElement(DesktopHeadlineRow, __assign({ search: searchValue }, props, { onSearch: handleSearch, onSearchClear: handleSearchClear })) })));
}

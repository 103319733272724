import { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { windowIsDefined } from '@containers/utils';
export var useStaticContext = function () {
    var routerContext = useContext(__RouterContext);
    return routerContext === null || routerContext === void 0 ? void 0 : routerContext.staticContext;
};
export function useHostname() {
    var staticContext = useStaticContext();
    if (staticContext) {
        return staticContext.hostname.replace(/\/$/, '');
    }
    return (windowIsDefined()) ? window.location.protocol + "//" + window.location.host : '';
}
export function useRoutingSetter(status) {
    var staticContext = useStaticContext();
    if (staticContext) {
        staticContext.setRouting(status);
    }
}

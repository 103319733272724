var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cardBrandToPfClass, isBrand } from '@logic/cardBrand';
import { CardCvcElement, CardExpiryElement, CardNumberElement, } from '@stripe/react-stripe-js';
import cn from 'classnames';
import React, { Component } from 'react';
import { useField } from 'react-final-form';
import styles from './card.less';
export function CardIcon(_a) {
    var className = _a.className, brand = _a.brand;
    return React.createElement("i", { className: cn(styles.brand, 'pf', cardBrandToPfClass[brand], className), id: 'brand-icon' });
}
var CardNumber = /** @class */ (function (_super) {
    __extends(CardNumber, _super);
    function CardNumber() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            brand: 'unknown',
        };
        _this.handleCardChange = function (_a) {
            var brand = _a.brand, rest = __rest(_a, ["brand"]);
            _this.setState({ brand: isBrand(brand) ? brand : 'unknown' });
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange(__assign({ brand: brand }, rest));
            }
        };
        return _this;
    }
    CardNumber.prototype.render = function () {
        return (React.createElement("div", { className: styles.cardWrapper },
            React.createElement(CardNumberElement, { className: cn(styles.input, 'fill'), onChange: this.handleCardChange }),
            React.createElement(CardIcon, { brand: this.state.brand })));
    };
    return CardNumber;
}(Component));
export { CardNumber };
function handleFieldChange(fieldProps, event) {
    fieldProps.input.onChange(event);
    fieldProps.input.onBlur();
}
export function CardNumberField(_a) {
    var name = _a.name, rest = __rest(_a, ["name"]);
    var field = useField(name);
    return (React.createElement(CardNumber, __assign({ onChange: handleFieldChange.bind(null, field) }, rest)));
}
export function CardExpiry(props) {
    return React.createElement(CardExpiryElement, __assign({ className: styles.input }, props));
}
export function CardExpiryField(_a) {
    var name = _a.name;
    var field = useField(name);
    return React.createElement(CardExpiry, { onChange: handleFieldChange.bind(null, field) });
}
export function CardCVC(props) {
    return React.createElement(CardCvcElement, __assign({ className: styles.input }, props));
}
export function CardCVCField(_a) {
    var name = _a.name;
    var field = useField(name);
    return React.createElement(CardCVC, { onChange: handleFieldChange.bind(null, field) });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { eventsApi, rawEventSearchToEventSearch, rawLastNextEventToLastNextEvent } from '@logic/events';
import { slugifyName } from '@logic/helpers/location';
import { toast } from 'react-toastify';
import request, { SANE_LIMIT } from '@logic/helpers/request';
import get from 'lodash/get';
export var findDetails = function (locations, localId) {
    var foundDetails = locations.find(function (location) { return location.localId === localId; });
    return foundDetails !== null && foundDetails !== void 0 ? foundDetails : null;
};
export var rawLocationsToLocations = function (rawCountries) {
    return rawCountries
        .map(function (rawCountry) { return (__assign(__assign({}, rawCountry), { cities: rawCountry.cities.map(function (rawCity) { return (__assign(__assign({}, rawCity), { localId: slugifyName(rawCity.name) })); }), localId: slugifyName(rawCountry.name) })); } // needs to be fixed
    );
};
export var getLocationSearchParams = function (countryDetails, cityDetails) {
    if (!countryDetails) {
        return { location: [] };
    }
    if (cityDetails) {
        // fetch specific category events
        return {
            location: [{
                    country: countryDetails.name,
                    cities: [cityDetails.name]
                }]
        };
    }
    else {
        // fetch all events
        return {
            location: [{
                    country: countryDetails.name,
                    cities: [],
                }]
        };
    }
};
var initialState = {
    isLoadingEvents: true,
    isLoadingLocations: true,
    isLoadingDetails: false,
    locations: [],
    countryDetails: null,
    cityDetails: null,
    searchParams: {
        buyNow: false,
        dateFrom: new Date(),
        limit: SANE_LIMIT,
        orderBy: 'eventDate',
        location: [],
        orderByDesc: false,
        sellNow: false,
        offset: 0
    },
    locationEvents: [],
    shouldTriggerLoadEvents: false,
    locationLastNextEvent: null,
    eventsCount: 0,
    loadedWithSSR: false
};
//
export var searchParamsSelect = function (s) { return s.locations.searchParams; };
export var locationsSelect = function (s) { return s.locations.locations; };
export var locationsApi = {
    getLocations: function () { return request('/api/portalCountries'); }
};
export var fetchLocations = createAction('fetch initial location');
export var fetchLocationsSuccess = createAction('fetch initial location succeeded', function (locations) { return ({ locations: locations }); });
export var fetchLocationsFailure = createAction('fetch initial location failed', function (e) { return e; });
export var loadLocationDetails = createAction('load location details', function (countryId, cityId) { return ({ countryId: countryId, cityId: cityId }); });
export var loadLocationDetailsSuccess = createAction('load location details succeeded', function (country, city) { return ({ country: country, city: city }); });
export var loadLocationDetailsFailure = createAction('load location details failed', function (e) { return e; });
export var loadLastNextLocationEvent = createAction('load last next location event', function (countryName, cityName) { return ({ countryName: countryName, cityName: cityName }); });
export var lastNextLocationEventLoaded = createAction('fetching last next location event succeed', function (lastNextEvent) { return lastNextEvent; });
export var lastNextLocationLoadingFailed = createAction('fetching last next location event failed', function (error) { return ({ error: error }); });
export var fetchLocationEvents = createAction('fetch location events', function (payload) { return (payload); });
export var fetchLocationEventsSuccess = createAction('fetch location events succeeded', function (result) { return ({ events: result.events, count: result.count, searchParams: result.searchParams }); });
export var fetchLocationEventsFailure = createAction('fetch location events failed', function (e) { return e; });
export var removeLocationDetails = createAction('remove location details');
export var loadFullLocationsData = createAction('load full ssr data', function (_a) {
    var countryId = _a.countryId, cityId = _a.cityId;
    return ({ countryId: countryId, cityId: cityId });
});
export var setLocationsSSRFlag = createAction('set locations SSR flag', function (flag) { return ({ flag: flag }); });
export var locationsReducer = createReducer((_a = {},
    _a[fetchLocations.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLocations: true })); },
    _a[fetchLocationsSuccess.getType()] = function (state, payload) { return (__assign(__assign({}, state), { isLoadingLocations: false, locations: payload.locations })); },
    _a[fetchLocationsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingLocations: false, locations: [] })); },
    _a[fetchLocationEvents.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingEvents: true })); },
    _a[fetchLocationEventsSuccess.getType()] = function (state, payload) {
        var _a, _b, _c, _d, _e, _f;
        var isTheSameCountry = ((_b = (_a = payload.searchParams) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.length) && ((_c = state.countryDetails) === null || _c === void 0 ? void 0 : _c.name) === payload.searchParams.location[0].country;
        var isTheSameCity = ((_e = (_d = payload.searchParams) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.length) && (((_f = state.cityDetails) === null || _f === void 0 ? void 0 : _f.name) === payload.searchParams.location[0].cities[0] || (!state.cityDetails && !payload.searchParams.location[0].cities.length));
        return (__assign(__assign({}, state), { locationEvents: isTheSameCountry && isTheSameCity ? __spreadArray(__spreadArray([], __read(state.locationEvents)), __read(payload.events)) : payload.events, eventsCount: payload.count, isLoadingEvents: false, shouldTriggerLoadEvents: false, searchParams: __assign(__assign({}, state.searchParams), payload.searchParams) }));
    },
    _a[fetchLocationEventsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { locationEvents: [], isLoadingEvents: false, shouldTriggerLoadEvents: false })); },
    _a[removeLocationDetails.getType()] = function (state) { return (__assign(__assign({}, state), { countryDetails: null, cityDetails: null })); },
    _a[loadLocationDetails.getType()] = function (state) { return (__assign(__assign({}, state), { locationDetails: null, isLoadingDetails: true, locationEvents: [] })); },
    _a[loadLocationDetailsSuccess.getType()] = function (state, payload) { return (__assign(__assign({}, state), { countryDetails: payload.country, cityDetails: payload.city, isLoadingDetails: false, shouldTriggerLoadEvents: true })); },
    _a[loadLocationDetailsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { locationDetails: null, isLoadingDetails: false })); },
    _a[loadLastNextLocationEvent.getType()] = function (state) { return (__assign(__assign({}, state), { locationLastNextEvent: null })); },
    _a[lastNextLocationEventLoaded.getType()] = function (state, lastNextEvents) {
        return __assign(__assign({}, state), { locationLastNextEvent: lastNextEvents });
    },
    _a[lastNextLocationLoadingFailed.getType()] = function (state) { return (__assign(__assign({}, state), { locationLastNextEvent: null })); },
    _a[setLocationsSSRFlag.getType()] = function (state, _a) {
        var flag = _a.flag;
        return (__assign(__assign({}, state), { loadedWithSSR: flag }));
    },
    _a), initialState);
function onInitialFetchLocations() {
    var rawResults, locations, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(locationsApi.getLocations)];
            case 1:
                rawResults = (_a.sent()).countries;
                locations = rawLocationsToLocations(rawResults);
                return [4 /*yield*/, put(fetchLocationsSuccess(locations))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                console.error(e_1);
                return [4 /*yield*/, put(fetchLocationsFailure(e_1))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch locations')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function onFetchLocationEvents(_a) {
    var params, updatedSearchParams, _b, items, count, e_2;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 4, , 7]);
                return [4 /*yield*/, select(searchParamsSelect)];
            case 1:
                params = _c.sent();
                updatedSearchParams = __assign(__assign({}, params), payload);
                return [4 /*yield*/, call(eventsApi.search, updatedSearchParams)];
            case 2:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(fetchLocationEventsSuccess({ events: items.map(rawEventSearchToEventSearch), count: count, searchParams: updatedSearchParams }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 7];
            case 4:
                e_2 = _c.sent();
                console.error(e_2);
                return [4 /*yield*/, put(fetchLocationEventsFailure(e_2))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch location events')];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function onLoadLocationDetails(_a) {
    var countries, countryDetails, cityDetails, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, select(locationsSelect)];
            case 1:
                countries = _b.sent();
                countryDetails = findDetails(countries, payload.countryId);
                cityDetails = payload.cityId && (countryDetails === null || countryDetails === void 0 ? void 0 : countryDetails.cities)
                    ? findDetails(countryDetails.cities, payload.cityId)
                    : null;
                return [4 /*yield*/, put(loadLocationDetailsSuccess(countryDetails, cityDetails))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_3 = _b.sent();
                console.error(e_3);
                return [4 /*yield*/, put(loadLocationDetailsFailure(e_3))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load location details')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadLocationLastNextEvent(_a) {
    var rawLastNextEvent, _b, lastNextEvent, e_4;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 10]);
                if (!payload.cityName) return [3 /*break*/, 2];
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'city', payload.countryName, payload.cityName)];
            case 1:
                _b = _c.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, call(eventsApi.lastNextEvent, 'country', payload.countryName)];
            case 3:
                _b = _c.sent();
                _c.label = 4;
            case 4:
                rawLastNextEvent = _b;
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextLocationEventLoaded(lastNextEvent))];
            case 5:
                _c.sent();
                return [3 /*break*/, 10];
            case 6:
                e_4 = _c.sent();
                return [4 /*yield*/, put(lastNextLocationLoadingFailed(e_4))];
            case 7:
                _c.sent();
                if (!(get(e_4, 'status') !== 404)) return [3 /*break*/, 9];
                return [4 /*yield*/, call(toast.error, "Cannot pull last and next event for " + payload.countryName)];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function onLoadFullLocationsData(_a) {
    var countryId, cityId, rawCountries, countries, countryDetails, cityDetails, rawLastNextEvent, _b, lastNextEvent, params, countryParams, updatedSearchParams, _c, items, count, e_5;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 12, , 13]);
                countryId = payload.countryId, cityId = payload.cityId;
                return [4 /*yield*/, call(locationsApi.getLocations)];
            case 1:
                rawCountries = (_d.sent()).countries;
                countries = rawLocationsToLocations(rawCountries);
                countryDetails = findDetails(countries, countryId);
                cityDetails = cityId && (countryDetails === null || countryDetails === void 0 ? void 0 : countryDetails.cities)
                    ? findDetails(countryDetails.cities, cityId)
                    : null;
                return [4 /*yield*/, put(loadLocationDetailsSuccess(countryDetails, cityDetails))];
            case 2:
                _d.sent();
                if (!countryDetails) return [3 /*break*/, 8];
                if (!cityDetails) return [3 /*break*/, 4];
                return [4 /*yield*/, call(eventsApi.lastNextEvent, 'city', countryDetails.name, cityDetails === null || cityDetails === void 0 ? void 0 : cityDetails.name)];
            case 3:
                _b = _d.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(eventsApi.lastNextEvent, 'country', countryDetails.name)];
            case 5:
                _b = _d.sent();
                _d.label = 6;
            case 6:
                rawLastNextEvent = _b;
                lastNextEvent = rawLastNextEventToLastNextEvent(rawLastNextEvent);
                return [4 /*yield*/, put(lastNextLocationEventLoaded(lastNextEvent))];
            case 7:
                _d.sent();
                _d.label = 8;
            case 8: return [4 /*yield*/, select(searchParamsSelect)];
            case 9:
                params = _d.sent();
                countryParams = getLocationSearchParams(countryDetails, cityDetails);
                updatedSearchParams = __assign(__assign({}, params), countryParams);
                return [4 /*yield*/, call(eventsApi.search, updatedSearchParams)];
            case 10:
                _c = _d.sent(), items = _c.items, count = _c.count;
                return [4 /*yield*/, put(fetchLocationEventsSuccess({ events: items.map(rawEventSearchToEventSearch), count: count, searchParams: updatedSearchParams }))];
            case 11:
                _d.sent();
                return [3 /*break*/, 13];
            case 12:
                e_5 = _d.sent();
                console.error(e_5);
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
export function locationsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(fetchLocations.getType(), onInitialFetchLocations)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(fetchLocationEvents.getType(), onFetchLocationEvents)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadLocationDetails.getType(), onLoadLocationDetails)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadLastNextLocationEvent.getType(), onLoadLocationLastNextEvent)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadFullLocationsData.getType(), onLoadFullLocationsData)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

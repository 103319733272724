var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { reducer } from 'src/client/reducers';
import { windowIsDefined } from '@containers/utils';
export function configureStore(initialState, history) {
    var sagaMiddleware = createSagaMiddleware();
    var middlewares = [sagaMiddleware, routerMiddleware(history)];
    if (process.env.NODE_ENV !== 'production' && process.env.RAZZLE_WITH_LOGGER) {
        var logger = require('redux-logger').default;
        if (windowIsDefined()) {
            middlewares.push(logger);
        }
    }
    var composeEnhancers = windowIsDefined() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    var store = createStore(reducer(history), initialState, composeEnhancers(applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares)))));
    return {
        store: store,
        runSagaMiddleware: sagaMiddleware.run,
        dispatchEnd: function () { return store.dispatch(END); },
    };
}

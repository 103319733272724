var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { CurrencyField, DateTimeInputField, DayPicker, DropdownField, genOptions, InputField, Label, } from '@components/forms';
import { hasReservedSeating } from '@logic/events';
import { seatingPlanToSections, anySection, stringifySection } from '@logic/seatingInfo';
import cn from 'classnames';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import range from 'lodash/range';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, useField } from 'react-final-form';
import { createSelector } from 'reselect';
import { Description, Entry } from '../../utils';
import { YesNoEntry } from '../yesNoEntry/yesNoEntry';
import styles from './basic.less';
import { onlyNaturalNumber, onlyNumberOrCharacter } from '@logic/helpers/keyboardEvents.utils';
export function TicketTypeSelector(_a) {
    var options = _a.options, readonly = _a.readonly, ticketTypes = _a.ticketTypes, listingType = _a.listingType;
    var availableTicketTypeOptions = useMemo(function () { return options.filter(function (tto) { return ticketTypes[tto.value] || tto.value === 'any'; }); }, [options, ticketTypes]);
    var ticketTypeHelp = listingType === 'sell'
        ? 'The type of tickets you are selling. If you switch between ticket types at a later date charges may apply due to the increased cost of delivery.'
        : 'Which type of ticket you want to buy. If you don\'t mind, select \'Any\'.';
    return (React.createElement(Entry, { name: 'ticketType' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: ticketTypeHelp, isRequired: true }, "Ticket Type"),
            React.createElement(DropdownField, { name: 'ticketType', search: true, selection: true, options: availableTicketTypeOptions, disabled: readonly }))));
}
// eslint-disable-next-line @typescript-eslint/ban-types
var hasNonETicketError = function (x) {
    return Object.entries(x)
        .filter(function (_a) {
        var _b = __read(_a, 1), key = _b[0];
        return key !== 'eticket';
    })
        .some(function (_a) {
        var _b = __read(_a, 2), value = _b[1];
        if (isString(value)) {
            return true;
        }
        if (isObject(value)) {
            return hasNonETicketError(value);
        }
        return false;
    });
};
export function ETicketPreview(_a) {
    var ticketType = _a.ticketType, onClick = _a.onClick, ticketQuantity = _a.ticketQuantity, ticketsInfo = _a.ticketsInfo, soldQuantity = _a.soldQuantity, isNew = _a.isNew, readonly = _a.readonly;
    // we need to register this as a field, so on submit it will be set as 'touched' and entry will be able to show error
    useField('eticket');
    var formState = useFormState();
    var hasErrors = !isNew && hasNonETicketError(formState.errors);
    var hasQuantity = ticketQuantity > 0;
    var cannotUpload = !hasQuantity || hasErrors || readonly;
    if (ticketType !== 'eTicket' && ticketType !== 'qrCode') {
        return null;
    }
    var nonEmptyTicketsNum = ticketsInfo.slice(soldQuantity).filter(function (_a) {
        var id = _a.id;
        return id;
    }).length;
    var actionText = ticketsInfo.every(function (_a) {
        var id = _a.id;
        return !id;
    }) ? 'Upload' : nonEmptyTicketsNum !== ticketQuantity ? 'Edit' : 'View';
    var artifact = ticketType === 'eTicket' ? 'e-Tickets' : 'QR Codes';
    return (React.createElement(Entry, { name: 'eticket', className: styles.etickets },
        hasQuantity && React.createElement("button", { onClick: onClick, disabled: cannotUpload },
            actionText,
            " ",
            artifact),
        hasErrors && React.createElement("span", null,
            "Please fix the errors to be able to upload ",
            artifact),
        !hasQuantity && React.createElement("span", null,
            "Select quantity to upload ",
            artifact)));
}
export var getSectionsOptions = createSelector(function (_a) {
    var seatingPlan = _a.seatingPlan;
    return seatingPlan;
}, function (_a) {
    var allowAny = _a.allowAny;
    return allowAny;
}, function (seatingPlan, allowAny) {
    var sections = seatingPlanToSections(seatingPlan, !!allowAny);
    var allSections = sections.length > 1 && allowAny ? [anySection].concat(sections) : sections;
    return genOptions.apply(void 0, __spreadArray([], __read(allSections.map(function (s) { return [s, stringifySection(s)]; }))));
});
export function SectionsSelector(_a) {
    var seatingPlan = _a.seatingPlan, allowAny = _a.allowAny, event = _a.event, readonly = _a.readonly, listingType = _a.listingType, clearTicketsOnChange = _a.clearTicketsOnChange;
    var formApi = useForm();
    var onChange = useCallback(function () {
        var values = formApi.getState().values;
        var section = (values.seatingInformation || {}).section || anySection;
        if (!event || !hasReservedSeating(section, event.seatingPlan)) {
            formApi.change('seatingInformation.row', '');
            formApi.change('seatingInformation.startingSeat', '');
        }
        if (clearTicketsOnChange) {
            formApi.change('ticketsInfo', []);
        }
    }, [formApi, event, clearTicketsOnChange]);
    var options = getSectionsOptions({ seatingPlan: seatingPlan, allowAny: allowAny, readonly: readonly, event: event });
    var sectionHelp = listingType === 'sell'
        ? 'The section of the venue that your tickets grant access to.'
        : 'Which part of the venue you want to buy tickets for. You can check the seating plan for this event by selecting \'Show seating plan\'.';
    useEffect(function () {
        if (options.length === 1) {
            var values = formApi.getState().values;
            var section = (values.seatingInformation || {}).section;
            if (!section) {
                formApi.change('seatingInformation.section', options[0].value);
            }
        }
    }, [options, formApi]);
    return (React.createElement(Entry, { name: 'seatingInformation.section' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: sectionHelp, isRequired: true }, " Section "),
            React.createElement(DropdownField, { name: 'seatingInformation.section', search: true, selection: true, preserveCurrentValue: true, currentValueToText: stringifySection, options: options, disabled: readonly, onChange: onChange }))));
}
export function RowSelector(_a) {
    var readonly = _a.readonly, descriptionHelp = _a.descriptionHelp, clearTicketsOnChange = _a.clearTicketsOnChange;
    var formApi = useForm();
    var onBlur = useCallback(function () {
        var values = formApi.getState().values;
        var row = get(values, 'seatingInformation.row', '');
        formApi.change('seatingInformation.row', row.toUpperCase());
        if (clearTicketsOnChange) {
            formApi.change('ticketsInfo', []);
        }
    }, [clearTicketsOnChange, formApi]);
    return (React.createElement(Entry, { name: 'seatingInformation.row' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: descriptionHelp, isLegalRequirement: true }, "Row"),
            React.createElement(InputField, { onKeyPress: onlyNumberOrCharacter, name: 'seatingInformation.row', type: 'text', disabled: readonly, onBlur: onBlur }))));
}
export function StartingSeatSelector(_a) {
    var readonly = _a.readonly, descriptionHelp = _a.descriptionHelp, clearTicketsOnChange = _a.clearTicketsOnChange;
    var formApi = useForm();
    var onBlur = useCallback(function () {
        if (clearTicketsOnChange) {
            formApi.change('ticketsInfo', []);
        }
    }, [clearTicketsOnChange, formApi]);
    return (React.createElement(Entry, { name: 'seatingInformation.startingSeat' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: descriptionHelp, isLegalRequirement: true }, "Starting Seat"),
            React.createElement(InputField, { onKeyPress: onlyNaturalNumber, name: 'seatingInformation.startingSeat', type: 'number', disabled: readonly, onBlur: onBlur }))));
}
export var quantityOptions = genOptions.apply(void 0, __spreadArray([], __read(range(51).map(function (_) { return "" + _; }).map(function (_) { return [_, _]; }))));
export function QuantitySelector(_a) {
    var prefix = _a.prefix, readonly = _a.readonly, listingType = _a.listingType, onChange = _a.onChange;
    var quantityHelp = listingType === 'sell'
        ? 'How many tickets you have to sell.'
        : 'How many tickets you want to buy.';
    return (React.createElement(Entry, { name: 'ticketQuantity' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: quantityHelp, isRequired: true }, prefix ? prefix + " Quantity" : 'Quantity'),
            React.createElement(DropdownField, { name: 'ticketQuantity', search: true, selection: true, preserveCurrentValue: true, options: quantityOptions, disabled: readonly, className: styles.quantityDropdownList, onChange: onChange }))));
}
export var allTicketSplitOptionsMap = {
    any: ['any', 'Any Split'],
    all: ['all', 'Sell all together'],
    evenOnly: ['evenOnly', 'Sell in pairs'],
    avoid1: ['avoid1', 'Avoid leaving one ticket'],
    avoid1Or3: ['avoid1Or3', 'Avoid leaving one or three tickets'],
};
// https://gitlab.com/ticketex/ticketex/-/issues/3141
// available dropdown values use different (more strict) rules than the validation
// this is to simply limit the user choice when it comes to choosing split, 
// but allow editing quantity on listings using the old split validation rules
var availableSplitOptions = {
    any: function (_) { return true; },
    all: function (quantity) { return quantity > 1; },
    evenOnly: function (quantity) { return quantity % 2 === 0 && quantity > 2; },
    avoid1: function (quantity) { return quantity > 2; },
    avoid1Or3: function (quantity) { return quantity > 4; },
};
export var getTicketSplitOptions = createSelector(function (_a) {
    var ticketQuantity = _a.ticketQuantity;
    return parseInt("" + ticketQuantity, 10) || 0;
}, function (ticketQuantity) {
    var rawOptions = Object.keys(allTicketSplitOptionsMap)
        .filter(function (key) { return availableSplitOptions[key](ticketQuantity); })
        .map(function (key) { return allTicketSplitOptionsMap[key]; });
    return genOptions.apply(void 0, __spreadArray([], __read(rawOptions)));
});
export var allTicketSplitOptions = genOptions.apply(void 0, __spreadArray([], __read(Object.keys(allTicketSplitOptionsMap).map(function (key) { return allTicketSplitOptionsMap[key]; }))));
export function TicketSplitSelector(_a) {
    var readonly = _a.readonly, ticketQuantity = _a.ticketQuantity, descriptionHelp = _a.descriptionHelp, name = _a.name;
    return (React.createElement(Entry, { name: name !== null && name !== void 0 ? name : 'ticketSplit' },
        React.createElement(Label, { fill: true },
            !name && React.createElement(Description, { help: descriptionHelp, isRequired: true }, "Ticket Split"),
            React.createElement(DropdownField, { name: name !== null && name !== void 0 ? name : 'ticketSplit', search: true, selection: true, preserveCurrentValue: true, options: getTicketSplitOptions({ ticketQuantity: ticketQuantity }), allOptions: allTicketSplitOptions, disabled: readonly }))));
}
export function FaceValueSelector(_a) {
    var listingType = _a.listingType, currency = _a.currency, readonly = _a.readonly;
    var faceValueHelp = listingType === 'sell'
        ? 'The face value of a ticket is the price of the ticket on the primary market, excluding fees, set by the event organiser. It is often the price printed on the ticket. If you do not yet have your tickets the face value should be specified in your order confirmation.'
        : 'The minimum face value per ticket that you are willing to accept. If you don\'t mind, enter 0.00. The face value of a ticket is the price of the ticket on the primary market, excluding fees, set by the event organiser. It is often the price printed on the ticket.';
    return (React.createElement(Entry, { name: 'faceValue' },
        React.createElement(Label, { fill: true },
            React.createElement(Description, { help: faceValueHelp, isRequired: true }, listingType === 'sell' ? 'Face Value' : 'Minimum Face Value'),
            React.createElement(CurrencyField, { name: 'faceValue', labelPosition: 'left', min: 0, label: currency, disabled: readonly }))));
}
export function TicketPriceSelector(_a) {
    var currency = _a.currency, readonly = _a.readonly, listingType = _a.listingType;
    var listingPricePerTicketHelp = listingType === 'sell'
        ? 'The price per ticket, excluding fees, that you are looking to sell for. Please refer to the \'You will be paid\' section for a full breakdown of the total amount you will receive if your tickets sell.'
        : 'The price per ticket, excluding fees, that you are prepared to pay. Please refer to the \'You will pay\' section for a full breakdown of the total cost.';
    return (React.createElement(Entry, { name: 'ticketPrice' },
        React.createElement(Label, { fill: true, className: styles.resizeIconHelp },
            React.createElement(Description, { help: listingPricePerTicketHelp, isRequired: true }, "Listing Price Per Ticket"),
            React.createElement(CurrencyField, { name: 'ticketPrice', labelPosition: 'left', min: 1, label: currency, disabled: readonly }))));
}
var expiryDateFieldName = 'expiryDate';
/**
 * This function return a range of dates that are not allowed for value of buy listing.
 * Ranges are computed in the venue timezone but then are transfered to the local timezone and we operates
 * in the local timezone for rest of the time.
 *
 * Fuck this shit.
 */
export var getExpiryDateRange = function (_a, settings, ticketType, preuploadTickets) {
    var timezone = _a.timezone, date = _a.date;
    // allow to select any date in the past up to expiry date (see: https://gitlab.com/ticketex/ticketex/-/issues/2790)
    var before = new Date(0);
    var cutOffHours = {
        any: Math.min(settings.eTicketDeadlines.listingCutOffNonPreuploaded, settings.paperDeadlines.listingCutOff),
        paper: settings.paperDeadlines.listingCutOff,
        eTicket: preuploadTickets ? settings.eTicketDeadlines.listingCutOffPreuploaded : settings.eTicketDeadlines.listingCutOffNonPreuploaded,
        mobileTransfer: settings.eTicketDeadlines.listingCutOffNonPreuploaded,
        ticketmaster: settings.eTicketDeadlines.listingCutOffNonPreuploaded,
        qrCode: preuploadTickets ? settings.eTicketDeadlines.listingCutOffPreuploaded : settings.eTicketDeadlines.listingCutOffNonPreuploaded,
    }[ticketType || 'any'];
    var eventDate = moment.tz(date, timezone);
    var after = eventDate.add(cutOffHours, 'h').toDate();
    return { before: before, after: after };
};
/** Convert expiry date from local timezone to the value in UTC
 *
 */
export var getExpiryDateInUTC = function (expiryDate) {
    // this is if we decide to use it in event timezone, then we should use following code:
    // const expiryDateInVenueTimezone = moment(expiryDate).tz(event.timezone).startOf('d').utc().toDate();
    return moment(expiryDate).utc().toDate();
};
export function ExpiryDateSelector(_a) {
    var event = _a.event, settings = _a.settings, ticketType = _a.ticketType, isLoading = _a.isLoading, isNew = _a.isNew, readonly = _a.readonly, adminOverridenExpiryDate = _a.adminOverridenExpiryDate, originalExpiryDate = _a.originalExpiryDate, listingType = _a.listingType, preuploadTickets = _a.preuploadTickets;
    var dayPickerProps = useMemo(function () { return ({ disabledDays: getExpiryDateRange(event, settings, ticketType, preuploadTickets) }); }, [ticketType, settings, event, preuploadTickets]);
    var _b = useForm(), change = _b.change, blur = _b.blur;
    var state = useFormState({ subscription: { values: true, touched: true } });
    var expiryDateNotOverwritten = state.values.expiryDateNotOverwritten;
    useEffect(function () {
        if (expiryDateNotOverwritten) {
            change(expiryDateFieldName, dayPickerProps.disabledDays.after);
            blur(expiryDateFieldName);
        }
        // retain original expiry date field value in form, 
        // admin override is used only for display
        if (adminOverridenExpiryDate && originalExpiryDate) {
            change(expiryDateFieldName, new Date(originalExpiryDate));
            blur(expiryDateFieldName);
        }
    }, [ticketType, expiryDateNotOverwritten, change, blur, dayPickerProps.disabledDays.after,
        adminOverridenExpiryDate, originalExpiryDate]);
    var _c = __read(useState({
        container: styles.container,
        overlay: '',
        overlayWrapper: '',
    }), 2), classNames = _c[0], setClassNames = _c[1];
    var expiryDate = adminOverridenExpiryDate
        ? new Date(adminOverridenExpiryDate)
        : state.values[expiryDateFieldName];
    useEffect(function () {
        // in case we open expired listing, then we should apply animation class.
        // this happens only for the first time, so this is why effect is used.
        if (!isNew && !isLoading && expiryDate && Date.now() > expiryDate.getTime()) {
            setClassNames({
                container: cn(styles.notification, styles.container),
                overlay: '',
                overlayWrapper: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, isLoading, !!expiryDate]);
    var dayPickerInputProps = {
        classNames: classNames,
        dayPickerProps: dayPickerProps
    };
    var helpMsg = listingType === 'sell'
        ? 'By default your listing will remain active as long as possible and this will depend on the delivery method. If you want your listing to expire earlier then you can manually change the date at which your listing will no longer be eligible for transactions.'
        : 'Your listing will be active until this date';
    var description = listingType + " listing expiry";
    return (React.createElement(React.Fragment, null,
        React.createElement(Entry, { name: expiryDateFieldName },
            React.createElement(Label, { fill: true },
                React.createElement(Description, { help: helpMsg }, description),
                !adminOverridenExpiryDate
                    ? React.createElement(DateTimeInputField, { name: expiryDateFieldName, dayPickerInputProps: dayPickerInputProps, dateMapper: function (dt) { return dt.date; }, cutoffDate: dayPickerProps.disabledDays.after, disabled: readonly || expiryDateNotOverwritten })
                    : React.createElement(DayPicker, { classNames: classNames, dayPickerProps: dayPickerProps, value: expiryDate, disabled: true }))),
        !adminOverridenExpiryDate && React.createElement(YesNoEntry, { name: 'expiryDateNotOverwritten', description: 'Keep listing active as long as possible', readonly: readonly })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { push, replace } from 'connected-react-router';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { tryMapError } from './errorCodeMapper';
import request, { download } from './helpers/request';
import { listingsApi, rawUserListingToUserListing } from './listings';
import { filteringSelect, rawTransactionToTransaction, transactionApi } from './transactions';
var rawEventInfoToEventSummary = function (_a) {
    var eventId = _a.eventId, eventName = _a.eventName, eventDate = _a.eventDate, venueCity = _a.venueCity, venueName = _a.venueName, timezone = _a.timezone, performers = _a.performers;
    return {
        id: eventId,
        name: eventName,
        date: new Date(eventDate),
        venueCity: venueCity,
        venueName: venueName,
        timezone: timezone,
        performers: performers,
        keywords: [],
        venueId: 0,
        seatingPlan: { name: '', svgFileUrl: '', structure: { categories: [] } },
        ticketTypes: {}
    };
};
var rawWorkItemToWorkItem = function (rawWI) {
    var _a = rawWI.workItem, createdAt = _a.createdAt, lastUpdatedAt = _a.lastUpdatedAt, messages = _a.messages, rest = __rest(_a, ["createdAt", "lastUpdatedAt", "messages"]);
    var eventInfo = rawWI.eventInfo;
    var convertedMessages = messages.map(function (_a) {
        var sentAt = _a.sentAt, messageRest = __rest(_a, ["sentAt"]);
        return (__assign(__assign({}, messageRest), { sentAt: new Date(sentAt) }));
    });
    var event = eventInfo ? __assign(__assign({}, rawEventInfoToEventSummary(eventInfo)), { performers: eventInfo.performers }) : null;
    return __assign(__assign({}, rest), { createdAt: new Date(createdAt), lastUpdatedAt: new Date(lastUpdatedAt), messages: convertedMessages, event: event });
};
export var createWorkItem = createAction('create work item', function (type, subject, description, businessItem, files, prevLocation) { return ({
    type: type,
    subject: subject,
    description: description,
    businessItem: businessItem,
    files: files,
    prevLocation: prevLocation,
}); });
export var createNewEventWorkItem = createAction('request new event', function (description, files, prevLocation) { return ({ description: description, files: files, prevLocation: prevLocation }); });
export var createWorkItemSuccess = createAction('create work item success', function (workItem) { return ({ workItem: workItem }); });
export var createWorkItemFailure = createAction('create work item failure', function (errors) { return ({ errors: errors }); });
export var reply = createAction('reply', function (id, text, resolve, reject) { return ({
    id: id,
    text: text,
    resolve: resolve,
    reject: reject,
}); });
export var replySuccess = createAction('reply success', function (workItem) { return ({ workItem: workItem }); });
export var replyFailure = createAction('reply failure', function (errors) { return ({ errors: errors }); });
export var uploadFiles = createAction('upload file', function (workItemId, files) { return ({ workItemId: workItemId, files: files }); });
export var uploadFilesSuccess = createAction('upload file success', function (workItem) { return ({ workItem: workItem }); });
export var uploadFilesFailure = createAction('upload file failure', function (e) { return ({ e: e }); });
export var downloadFile = createAction('download work item file', function (workItemId, file) { return ({ workItemId: workItemId, file: file }); });
export var loadWorkItems = createAction('load workItems');
export var loadWorkItemsSuccess = createAction('load workItems succeed', function (workItems) { return ({ workItems: workItems }); });
export var loadWorkItemsFailure = createAction('load workItems failed', function (errors) { return ({ errors: errors }); });
export var loadBusinessItems = createAction('load all available business items', function (withEvents) { return ({ withEvents: withEvents }); });
export var loadBusinessItemsSuccess = createAction('load all available business items success', function (businessItems) { return ({ businessItems: businessItems }); });
export var loadBusinessItemsFailure = createAction('load all available business items failure', function (error) { return ({ error: error }); });
var initialState = {
    isPending: false,
    data: [],
    uploading: 0,
    isBusinessItemsPending: false,
    businessItems: [],
};
export var workItemsReducer = createReducer((_a = {},
    _a[createWorkItem.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[createNewEventWorkItem.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[createWorkItemSuccess.getType()] = function (state, _a) {
        var workItem = _a.workItem;
        return (__assign(__assign({}, state), { data: __spreadArray([workItem], __read(state.data)), isPending: false }));
    },
    _a[createWorkItemFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[reply.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[replySuccess.getType()] = function (state, _a) {
        var workItem = _a.workItem;
        return (__assign(__assign({}, state), { data: __spreadArray([workItem], __read(state.data.filter(function (wi) { return wi.id !== workItem.id; }))), isPending: false }));
    },
    _a[replyFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[uploadFiles.getType()] = function (state) { return (__assign(__assign({}, state), { uploading: state.uploading + 1 })); },
    _a[uploadFilesSuccess.getType()] = function (state, _a) {
        var workItem = _a.workItem;
        return (__assign(__assign({}, state), { uploading: state.uploading - 1, data: __spreadArray([workItem], __read(state.data.filter(function (wi) { return wi.id !== workItem.id; }))) }));
    },
    _a[uploadFilesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { uploading: state.uploading - 1 })); },
    _a[loadWorkItems.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[loadWorkItemsSuccess.getType()] = function (state, _a) {
        var workItems = _a.workItems;
        return (__assign(__assign({}, state), { isPending: false, data: workItems }));
    },
    _a[loadWorkItemsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false, data: [] })); },
    _a[loadBusinessItems.getType()] = function (state) { return (__assign(__assign({}, state), { isBusinessItemsPending: true })); },
    _a[loadBusinessItemsSuccess.getType()] = function (state, _a) {
        var businessItems = _a.businessItems;
        return (__assign(__assign({}, state), { isBusinessItemsPending: false, businessItems: businessItems }));
    },
    _a[loadBusinessItemsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isBusinessItemsPending: false, businessItems: [] })); },
    _a), initialState);
export var workItemsApi = {
    create: function (type, subject, description, businessItem) { return request('/api/workitems/create', {
        type: type,
        subject: subject,
        description: description,
        businessItems: __spreadArray([], __read((businessItem ? [businessItem] : []))),
    }); },
    loadWorkItems: function () { return request('/api/workitems/user/0/0'); },
    loadWorkItem: function (id) { return request("/api/workitems/" + id); },
    reply: function (workItemId, messageContent) { return request('/api/workitems/reply', { workItemId: workItemId, messageContent: messageContent }); },
    getFile: function (workItemId, guid) { return request("/api/workitems/files/" + workItemId + "/" + guid, undefined, { responseType: 'blob' }); },
    uploadFiles: function (workItemId, files) {
        var form = new FormData();
        files.forEach(function (file) { return form.append('file', file, file.name); });
        return request("/api/workitems/" + workItemId + "/files", form);
    },
};
export function onUploadFile(_a) {
    var workItemId, files, rawWorkItem, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                workItemId = payload.workItemId, files = payload.files;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 7]);
                return [4 /*yield*/, call(workItemsApi.uploadFiles, workItemId, files)];
            case 2:
                rawWorkItem = _b.sent();
                return [4 /*yield*/, put(uploadFilesSuccess(rawWorkItemToWorkItem(rawWorkItem)))];
            case 3:
                _b.sent();
                return [3 /*break*/, 7];
            case 4:
                e_1 = _b.sent();
                return [4 /*yield*/, call(toast.error, "Cannot upload file, " + (get(e_1, 'status') === 413 ? 'size limited to 10MB.' : 'please try again later'))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(uploadFilesFailure(e_1))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onCreateWorkItem(_a) {
    var type, subject, description, businessItem, files, prevLocation, rawWorkItem, workItem, e_2, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                type = payload.type, subject = payload.subject, description = payload.description, businessItem = payload.businessItem, files = payload.files, prevLocation = payload.prevLocation;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 9, , 12]);
                return [4 /*yield*/, call(workItemsApi.create, type, subject, description, businessItem)];
            case 2:
                rawWorkItem = _c.sent();
                workItem = rawWorkItemToWorkItem(rawWorkItem);
                if (!(files.length > 0)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(onUploadFile, uploadFiles(workItem.id, files))];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [4 /*yield*/, put(createWorkItemSuccess(workItem))];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(loadWorkItems())];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(toast.success, 'Your message has been sent')];
            case 7:
                _c.sent();
                return [4 /*yield*/, put(replace(prevLocation))];
            case 8:
                _c.sent();
                return [3 /*break*/, 12];
            case 9:
                e_2 = _c.sent();
                errorMsg = (_b = tryMapError(e_2)) !== null && _b !== void 0 ? _b : 'Cannot send message.';
                return [4 /*yield*/, put(createWorkItemFailure([errorMsg]))];
            case 10:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 11:
                _c.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function onCreateNewEventWorkItem(_a) {
    var description, files, prevLocation, rawWorkItem, workItem, e_3, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                description = payload.description, files = payload.files, prevLocation = payload.prevLocation;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 8, , 11]);
                return [4 /*yield*/, call(workItemsApi.create, 'EventIssue', 'New Event Request', description, null)];
            case 2:
                rawWorkItem = _c.sent();
                workItem = rawWorkItemToWorkItem(rawWorkItem);
                if (!(files.length > 0)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(onUploadFile, uploadFiles(workItem.id, files))];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [4 /*yield*/, put(createWorkItemSuccess(workItem))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.success, 'Thanks! We\'ll let you know once this event has been added')];
            case 6:
                _c.sent();
                return [4 /*yield*/, put(push(prevLocation))];
            case 7:
                _c.sent();
                return [3 /*break*/, 11];
            case 8:
                e_3 = _c.sent();
                errorMsg = (_b = tryMapError(e_3)) !== null && _b !== void 0 ? _b : 'Cannot send message.';
                return [4 /*yield*/, put(createWorkItemFailure([errorMsg]))];
            case 9:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 10:
                _c.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function onReply(_a) {
    var id, text, resolve, reject, rawWorkItem, e_4, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                id = payload.id, text = payload.text, resolve = payload.resolve, reject = payload.reject;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 6, , 10]);
                return [4 /*yield*/, call(workItemsApi.reply, id, text)];
            case 2:
                rawWorkItem = _c.sent();
                return [4 /*yield*/, put(replySuccess(rawWorkItemToWorkItem(rawWorkItem)))];
            case 3:
                _c.sent();
                return [4 /*yield*/, call(resolve, rawWorkItemToWorkItem(rawWorkItem))];
            case 4:
                _c.sent();
                return [4 /*yield*/, call(toast.success, 'Your message has been sent')];
            case 5:
                _c.sent();
                return [3 /*break*/, 10];
            case 6:
                e_4 = _c.sent();
                errorMsg = (_b = tryMapError(e_4)) !== null && _b !== void 0 ? _b : 'Cannot send message.';
                return [4 /*yield*/, put(replyFailure([errorMsg]))];
            case 7:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 8:
                _c.sent();
                return [4 /*yield*/, call(reject, e_4)];
            case 9:
                _c.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function onDownloadFile(_a) {
    var workItemId, file, blob, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                workItemId = payload.workItemId, file = payload.file;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(workItemsApi.getFile, workItemId, file.id)];
            case 2:
                blob = _b.sent();
                return [4 /*yield*/, call(download, blob, file.name)];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                e_5 = _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot download file, please try again')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onLoadWorkItems(_) {
    var workItems, e_6, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(workItemsApi.loadWorkItems)];
            case 1:
                workItems = _b.sent();
                return [4 /*yield*/, put(loadWorkItemsSuccess(workItems.results.map(rawWorkItemToWorkItem).sort(function (w1, w2) { return w1.lastUpdatedAt > w2.lastUpdatedAt ? -1 : 1; })))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_6 = _b.sent();
                errorMsg = (_a = tryMapError(e_6)) !== null && _a !== void 0 ? _a : 'Cannot load Messages';
                return [4 /*yield*/, put(loadWorkItemsFailure([errorMsg]))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onBusinessItemsLoad(_a) {
    var transactionFilters, rawListings, listingItems, eventsFromListings, rawSales, salesItems, eventsFromSales, rawPurchases, purchasesItems, eventsFromPurchases, events, e_7, errorMsg;
    var _b;
    var withEvents = _a.payload.withEvents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 9]);
                return [4 /*yield*/, select(filteringSelect)];
            case 1:
                transactionFilters = _c.sent();
                return [4 /*yield*/, call(listingsApi.getListingsForUser)];
            case 2:
                rawListings = _c.sent();
                listingItems = rawListings.results.map(function (rl) { return ({
                    type: rl.listingType === 'sell' ? 'sellListing' : 'buyListing',
                    id: rl.id,
                    niceId: rl.niceId,
                    eventId: rl.event.id,
                    entity: rawUserListingToUserListing(rl),
                }); });
                eventsFromListings = rawListings.results.map(function (rl) { return ({
                    type: 'event',
                    id: rl.event.id,
                    niceId: rl.event.id + ": " + rl.event.name,
                    eventId: rl.event.id,
                }); });
                return [4 /*yield*/, call(transactionApi.getUserSales, transactionFilters)];
            case 3:
                rawSales = _c.sent();
                salesItems = rawSales.results.map(function (rs) { return ({
                    type: 'transaction',
                    id: rs.transactionId,
                    niceId: rs.orderId,
                    eventId: rs.event.id,
                    entity: rawTransactionToTransaction('sell')(rs),
                }); });
                eventsFromSales = rawSales.results.map(function (rs) { return ({
                    type: 'event',
                    id: rs.event.id,
                    niceId: rs.event.id + ": " + rs.event.name,
                    eventId: rs.event.id,
                }); });
                return [4 /*yield*/, call(transactionApi.getUserPurchases, transactionFilters)];
            case 4:
                rawPurchases = _c.sent();
                purchasesItems = rawPurchases.results.map(function (rp) { return ({
                    type: 'transaction',
                    id: rp.transactionId,
                    niceId: rp.orderId,
                    eventId: rp.event.id,
                    entity: rawTransactionToTransaction('buy')(rp),
                }); });
                eventsFromPurchases = rawPurchases.results.map(function (rp) { return ({
                    type: 'event',
                    id: rp.event.id,
                    niceId: rp.event.id + ": " + rp.event.name,
                    eventId: rp.event.id,
                }); });
                events = withEvents ? uniqBy(__spreadArray(__spreadArray(__spreadArray([], __read(eventsFromListings)), __read(eventsFromSales)), __read(eventsFromPurchases)), function (e) { return e.id + e.type; }) : [];
                return [4 /*yield*/, put(loadBusinessItemsSuccess(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(listingItems)), __read(salesItems)), __read(purchasesItems)), __read(events))))];
            case 5:
                _c.sent();
                return [3 /*break*/, 9];
            case 6:
                e_7 = _c.sent();
                errorMsg = (_b = tryMapError(e_7)) !== null && _b !== void 0 ? _b : 'Cannot load Messages';
                return [4 /*yield*/, put(loadBusinessItemsFailure([errorMsg]))];
            case 7:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function workItemsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadWorkItems.getType(), onLoadWorkItems)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(createWorkItem.getType(), onCreateWorkItem)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(createNewEventWorkItem.getType(), onCreateNewEventWorkItem)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(reply.getType(), onReply)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(uploadFiles.getType(), onUploadFile)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(downloadFile.getType(), onDownloadFile)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadBusinessItems.getType(), onBusinessItemsLoad)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BUY_ONLY } from 'src/client/routing/routes';
import { slugifyName } from '@logic/helpers/location';
import { useSorting, Sorting, } from '@containers/DetailsPages/components/eventsTable/sorting';
import { Results } from '@containers/DetailsPages/components/eventsTable/results';
export var EventsTable = function (_a) {
    var events = _a.events, locationSelector = _a.locationSelector, eventsIsLoading = _a.eventsIsLoading, noDataMessage = _a.noDataMessage, tableVariant = _a.tableVariant, getImage = _a.getImage, sortingHeader = _a.sortingHeader;
    var match = useRouteMatch();
    var paramsCity = match.params.city === BUY_ONLY ? undefined : match.params.city;
    var city = slugifyName(paramsCity !== null && paramsCity !== void 0 ? paramsCity : '');
    var buyOnly = !!match.params.buyOnly || match.params.city === BUY_ONLY;
    var _b = useSorting(events), sort = _b.sort, order = _b.order, changeSorting = _b.changeSorting, sortedEvents = _b.results;
    var eventsInCity = useMemo(function () {
        return city ? sortedEvents.filter(function (e) { return slugifyName(e.venueCity) === city; }) : sortedEvents;
    }, [city, sortedEvents]);
    var eventsElsewhere = useMemo(function () {
        return city ? sortedEvents.filter(function (e) { return slugifyName(e.venueCity) !== city; }) : [];
    }, [city, sortedEvents]);
    var showElsewhere = !!city && !!eventsElsewhere.length;
    return (React.createElement("div", null,
        React.createElement(Sorting, { variant: tableVariant, by: sort, sortingHeader: sortingHeader, direction: order, onChange: changeSorting, locationSelector: locationSelector, buyOnly: buyOnly }),
        React.createElement(Results, { isLoading: eventsIsLoading, tableVariant: tableVariant, buyOnly: buyOnly, events: eventsInCity, noDataMessage: noDataMessage, getImage: getImage }),
        showElsewhere && tableVariant !== 'advanced' &&
            React.createElement(Results, { isLoading: eventsIsLoading, tableVariant: tableVariant, events: eventsElsewhere, buyOnly: buyOnly, noDataMessage: noDataMessage, getImage: getImage, thickLine: true })));
};

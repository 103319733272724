var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useUserAgent } from '@components/useUserAgent';
import { useEffect, useState } from 'react';
import { readViewport } from '@components/viewPortInfo/viewPort.utils';
export var useViewportInfo = function () {
    var userAgent = useUserAgent().userAgent;
    var _a = __read(useState(readViewport(userAgent)), 2), vp = _a[0], changeVp = _a[1];
    useEffect(function () {
        if (typeof window === 'undefined') {
            return;
        }
        var handler = function () { return changeVp(readViewport(userAgent)); };
        window.addEventListener('resize', handler);
        window.addEventListener('orientationchange', handler);
        return function () {
            window.removeEventListener('resize', handler);
            window.removeEventListener('orientationchange', handler);
        };
    }, [userAgent]);
    return vp;
};

import React from 'react';
import styles from '@components/FollowUs/followUs.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
export var FollowUs = function (_a) {
    var header = _a.header;
    return (React.createElement(React.Fragment, null,
        header,
        React.createElement("a", { href: "https://twitter.com/AskBeeyay", className: styles.socialLink, target: "_blank", rel: "noreferrer", "aria-label": 'Twitter - Ask Beeyay' },
            React.createElement(FontAwesomeIcon, { icon: faTwitter })),
        React.createElement("a", { href: "https://instagram.com/askbeeyay/", className: styles.socialLink, target: "_blank", rel: "noreferrer", "aria-label": 'Instagram - Ask Beeyay' },
            React.createElement(FontAwesomeIcon, { icon: faInstagram }))));
};

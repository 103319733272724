var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallbackRef } from '@components/forms';
import { useStaticContext } from '@components/useStaticContext';
import { useUserAgent } from '@components/useUserAgent';
import { t } from '@containers/helpers/tests';
import { requireNoAuth } from '@logic/helpers/authentication';
import { getRedirectTo } from '@logic/helpers/location';
import cn from 'classnames';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import { defaultState, getQueryString, routes } from 'src/client/routing/routes';
import { ControlledSearchBox } from '../SearchBox/searchBox';
import { ReactComponent as DownArrow } from './img/downArrow.svg';
import beeyayLogo from './img/beeyay-logo-color.svg';
import styles from './navigation.less';
import { useMorpher } from './useMorpher';
import { UserTriggerIcon } from './userTriggerIcon';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isLikeMobile } from '@components/viewPortInfo/viewPort.utils';
import { Image } from '@components/image/image';
import beeyayBackground from '@containers/Navigation/img/crowd-at-concert.jpg';
import { SecondaryMarketplaceBanner } from '@components/marketPlaceBanner/marketPlaceBanner';
import { CategoriesNavigationMobile } from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigationMobile';
import { CategoriesHomeSearchLinks } from '@containers/DetailsPages/components/categoriesNavigation/categoriesNavigationHome';
import { countDOMLines } from '@containers/utils';
var headerId = 'The-tickets-you-want';
export var bannerId = 'Beeyay-banner-id';
export function Navigation(props) {
    var _a, _b;
    var _c, _d, _e, _f, _g;
    var search = props.search, extraNavElement = props.extraNavElement, hideMenu = props.hideMenu, showCategoriesNavigation = props.showCategoriesNavigation;
    var _h = __read(useCallbackRef(null), 2), heroRef = _h[0], changeHeroRef = _h[1];
    var _j = __read(useCallbackRef(null), 2), searchRef = _j[0], changeSearchRef = _j[1];
    var _k = useViewportInfo(), customBreakPoint = _k.customBreakPoint, media = _k.media, width = _k.width;
    var isBot = useUserAgent().isBot;
    useEffect(function () {
        if (isBot) {
            document.getElementsByTagName('html')[0].classList.add(styles.bot);
        }
    }, [isBot]);
    var _l = __read(useState(0), 2), headerLines = _l[0], setHeaderLines = _l[1];
    var _m = __read(useState(0), 2), bannerLines = _m[0], setBannerLines = _m[1];
    useEffect(function () {
        setHeaderLines(countDOMLines(headerId));
        setBannerLines(countDOMLines(bannerId));
    }, [width]);
    useMorpher(heroRef, searchRef, search);
    (_e = (_d = (_c = useStaticContext()) === null || _c === void 0 ? void 0 : _c.imagesToPush) === null || _d === void 0 ? void 0 : _d.add) === null || _e === void 0 ? void 0 : _e.call(_d, beeyayBackground);
    var location = useLocation();
    var queryString = getQueryString(location);
    var _o = __read(useState((_g = (_f = qs.parse(queryString)) === null || _f === void 0 ? void 0 : _f.q) !== null && _g !== void 0 ? _g : ''), 2), phrase = _o[0], changePhrase = _o[1];
    var scrollHandler = useCallback(function () { return window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }); }, []);
    var isExpanded = search === 'expanded';
    var searchClass = search === false || isExpanded ? 'hide' : 'visible';
    var searchComponent = (React.createElement("div", { className: cn(styles.searchContainer, searchClass, (_a = {}, _a[styles.expandedSearchClass] = isExpanded, _a)), ref: changeSearchRef },
        React.createElement(CategoriesHomeSearchLinks, { isFixedNavigation: true, hide: !isExpanded }),
        React.createElement(ControlledSearchBox, { phrase: "" + phrase, changePhrase: changePhrase, asIconOnMobile: !isExpanded })));
    var menu = (React.createElement(Menu, __assign({ fixed: 'top', size: 'large' }, t('Navigation'), { borderless: true, className: cn(styles.nav, (_b = {},
            _b[styles.fixedSearch] = search !== false && isExpanded,
            _b[styles.expanded] = isExpanded,
            _b[styles.primaryBackground] = !isExpanded,
            _b)) }),
        isExpanded && React.createElement(Image, { className: styles.navBackground, src: beeyayBackground, alt: '' }),
        React.createElement(Menu.Item, __assign({ as: NavLink, to: routes.index(), className: styles.logoLink }, t('Main')),
            React.createElement(Image, { src: beeyayLogo, alt: 'Beeyay - home', className: styles.logo })),
        searchComponent,
        hideMenu && extraNavElement,
        !hideMenu && React.createElement(NavbarMenu, { extraNavElement: extraNavElement, showCategoriesNavigation: showCategoriesNavigation })));
    var homePageMenu = (React.createElement(React.Fragment, null,
        (customBreakPoint === 'smallHomeNavigation') && React.createElement(Image, { className: styles.heroBackgroundImage, src: beeyayBackground, alt: '' }),
        menu,
        React.createElement("div", { className: cn(styles.heroContainer, "line-counter-" + headerLines, "banner-lines-" + bannerLines), ref: changeHeroRef },
            React.createElement(SecondaryMarketplaceBanner, { transparentBackground: true }),
            React.createElement(Image, { className: styles.heroBackgroundImage, src: beeyayBackground, alt: '' }),
            React.createElement(Hero, { isForceVisible: customBreakPoint === 'smallHomeNavigation' && media !== 'mobileLandscape', phrase: "" + phrase, changePhrase: changePhrase })),
        media !== 'mobileLandscape' && React.createElement(DownArrow, { className: styles.downButton, onClick: scrollHandler })));
    if (!isExpanded) {
        return (menu);
    }
    return (homePageMenu);
}
function NavbarMenu(props) {
    var _a;
    var media = useViewportInfo().media;
    var isMobile = isLikeMobile(media);
    var location = useLocation();
    var userDetails = useSelector(function (state) { return state.user.userDetails; });
    var extraNavElement = props.extraNavElement, showCategoriesNavigation = props.showCategoriesNavigation;
    var NotLogged = function (Component) {
        var redirectToLogin = getRedirectTo({
            redirect: routes.login(),
            location: location,
        });
        var redirectToRegister = getRedirectTo({
            redirect: routes.register(),
            location: location,
        });
        return (React.createElement(React.Fragment, null,
            React.createElement(Component, __assign({ as: NavLink, to: redirectToLogin }, t('Login')), "Login"),
            React.createElement(Component, __assign({ as: NavLink, to: redirectToRegister }, t('Register')), "Register")));
    };
    var Logged = function () { return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown.Header, null,
            "Logged in as: ",
            userDetails ? userDetails.email : null),
        React.createElement("div", { className: styles.dividerEmpty }),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Profile', as: NavLink, to: routes.userProfile() }, t('Settings'))),
        React.createElement("div", { className: styles.divider }),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Listings', as: NavLink, to: routes.userListingsWithFilters(defaultState) }, t('My-listings'))),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Purchases', as: NavLink, to: routes.userPurchasesWithFilter(defaultState) }, t('My-purchases'))),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Sales', as: NavLink, to: routes.userSalesWithFilter(defaultState) }, t('My-sales'))),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Messages', as: NavLink, to: routes.userWorkItems() }, t('My-workitems'))),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Payouts', as: NavLink, to: routes.userFinancialsWithFilters(defaultState) }, t('My-financials'))),
        React.createElement("div", { className: styles.divider }),
        React.createElement(Dropdown.Item, __assign({ className: 'item-override', text: 'Logout', as: NavLink, to: routes.logout() }, t('Logout'))),
        React.createElement("div", { className: styles.dividerEmpty }))); };
    if (isMobile) {
        return (React.createElement(Menu.Menu, { position: 'right', className: cn((_a = {}, _a[styles.rightMenu] = media === 'mobileLandscape', _a)) },
            extraNavElement,
            showCategoriesNavigation && React.createElement(CategoriesNavigationMobile, null),
            React.createElement(Dropdown, __assign({ item: true, icon: null, className: styles.burger, trigger: React.createElement(UserTriggerIcon, { isMobile: true, userDetails: userDetails, location: location }) }, t('User-panel')),
                React.createElement(Dropdown.Menu, null, requireNoAuth() ? NotLogged(Dropdown.Item) : Logged()))));
    }
    if (requireNoAuth()) {
        return (React.createElement(Menu.Menu, { position: 'right', className: styles.rightMenu },
            extraNavElement,
            NotLogged(Menu.Item)));
    }
    return (React.createElement(Menu.Menu, { position: 'right', className: styles.rightMenu },
        extraNavElement,
        React.createElement(Menu.Item, __assign({ as: NavLink, to: routes.search() }, t('BrowseEvents')), "Browse events"),
        React.createElement(Dropdown, __assign({ item: true, icon: null, trigger: React.createElement(UserTriggerIcon, { isMobile: false, userDetails: userDetails, location: location }) }, t('User-panel')),
            React.createElement(Dropdown.Menu, null,
                React.createElement(Logged, null)))));
}
var Hero = function (_a) {
    var _b;
    var phrase = _a.phrase, changePhrase = _a.changePhrase, isForceVisible = _a.isForceVisible;
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { id: headerId }, "The tickets you want at the price you choose"),
        React.createElement("div", { className: cn(styles.searchBoxNavigationContainer, (_b = {}, _b[styles.forcedVisible] = isForceVisible, _b)) },
            React.createElement(CategoriesHomeSearchLinks, null),
            React.createElement(ControlledSearchBox, { phrase: phrase, changePhrase: changePhrase, asIconOnMobile: false }))));
};
export default Navigation;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { Container, withFetchData, withPageOptions } from '@containers/Page';
import { useSorting } from '../components/eventsTable/sorting';
import { DetailsPageNavigation } from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation';
import { EventsTable, NoDataMessage } from '@containers/DetailsPages/components/eventsTable';
import { t } from '@containers/helpers/tests';
import { routes } from 'src/client/routing/routes';
import { JsonLd } from '@components/jsonLD';
import styles from '@containers/DetailsPages/sharedStyles.less';
import { getImage } from '@containers/DetailsPages/utils';
import { Biography } from '../components/biography/biography';
import { PageSectionEnum, useScrollTo } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { DetailsPageOptions } from '@containers/DetailsPages/constants';
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { Error404 } from '@containers/Error404/error404';
import { fetchData, useDetailsLocation } from '@containers/DetailsPages/hooks/useDetailsLocation.hook';
import { LocationSeo } from '@containers/DetailsPages/pages/seo/locationSeo';
import { useRouteMatch } from 'react-router';
import { fetchLocationEvents, getLocationSearchParams } from '@logic/locations';
import { Breadcrumb, EMPTY_BREADCRUMB } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { createCountryCityBreadcrumb } from '@containers/DetailsPages/breadcrumbFactory.utils';
import { DetailsHeader } from '@containers/DetailsPages/components/detailsHeader/detailsHeader';
import { GoogleMap } from '@containers/DetailsPages/components/googleMap/googleMap';
import { LastNextEventComponent } from '@containers/DetailsPages/components/lastNextEvent/lastNextEvent.component';
import { MoreResultsButton } from '@containers/components/moreResultsButton/moreResultsButton';
import { useDispatch } from 'react-redux';
import { SANE_LIMIT } from '@logic/helpers/request';
var CityDetails = function () {
    var _a;
    var _b = useDetailsLocation(), countryDetails = _b.countryDetails, cityDetails = _b.cityDetails, events = _b.events, eventsIsLoading = _b.eventsIsLoading, detailsLoading = _b.detailsLoading, headerDetails = _b.headerDetails, lastNextEvent = _b.lastNextEvent, eventsCount = _b.eventsCount, searchParams = _b.searchParams;
    var sortedEvents = useSorting(events).results;
    var _c = useScrollTo(), bioRef = _c.bioRef, eventsRef = _c.eventsRef, executeScroll = _c.executeScroll;
    var _d = useRouteMatch().params, countryId = _d.countryId, cityId = _d.cityId;
    var dispatch = useDispatch();
    var breadcrumb = useMemo(function () { return countryDetails
        ? createCountryCityBreadcrumb(countryDetails, cityDetails)
        : EMPTY_BREADCRUMB; }, [countryDetails, cityDetails]);
    if (!cityDetails || !countryDetails) {
        return detailsLoading
            ? React.createElement(PleaseWait, null)
            : React.createElement(Error404, null);
    }
    var googleMapData = { placeId: cityDetails.placeId, country: countryDetails.name, city: cityDetails.name };
    var onMoreResultsClick = function () {
        return dispatch(fetchLocationEvents(__assign(__assign({}, getLocationSearchParams(countryDetails, cityDetails)), { offset: ((searchParams === null || searchParams === void 0 ? void 0 : searchParams.offset) || 0) + SANE_LIMIT })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LocationSeo, { details: cityDetails, eventsLoading: eventsIsLoading, events: events, canonicalUrl: routes.city(countryId, cityId) }),
        React.createElement(JsonLd, { events: sortedEvents, breadcrumbJsonLd: (_a = breadcrumb.breadcrumbPaths) === null || _a === void 0 ? void 0 : _a.jsonLD }),
        React.createElement(DetailsHeader, __assign({ details: headerDetails }, t('City'), { breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }), scrollToCallback: function () { return executeScroll(PageSectionEnum.Bio); } })),
        React.createElement(DetailsPageNavigation, { bioHeader: PageSectionEnum.About, details: headerDetails, isFullWide: false, scrollTo: executeScroll }),
        React.createElement(Container, { className: styles.simplifiedContainer },
            React.createElement("div", null,
                React.createElement(EventsTable, { sortingHeader: React.createElement("h1", { ref: eventsRef }, cityDetails === null || cityDetails === void 0 ? void 0 :
                        cityDetails.name,
                        " Events"), events: sortedEvents, eventsIsLoading: eventsIsLoading, tableVariant: 'simple', getImage: getImage, noDataMessage: React.createElement(NoDataMessage, { baseRoute: routes.category() }) }),
                React.createElement(MoreResultsButton, { onMoreResultsClick: function () { return onMoreResultsClick(); }, events: events, eventsCount: eventsCount })),
            React.createElement(Biography, { details: cityDetails, bioRef: bioRef }),
            React.createElement(GoogleMap, { data: googleMapData, zoom: 8 }),
            React.createElement(LastNextEventComponent, { eventCategory: '', lastNextEvent: lastNextEvent }))));
};
export default withPageOptions(DetailsPageOptions)(withFetchData(fetchData)(CityDetails));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styles from '@containers/DetailsPages/pages/EventDetails/components/SellYourTickets/sellYourTickets.less';
import { Button } from '@components/forms';
import { t } from '@containers/helpers/tests';
import { PushableImage } from '@containers/PushableImage';
export var SellYourTickets = function (props) {
    var onListingNew = props.onListingNew;
    return React.createElement("div", __assign({ className: styles.sellYourTickets }, t('SellTicketsNow')),
        React.createElement("div", null,
            React.createElement("div", { className: 'blackBox' }),
            React.createElement(PushableImage, { className: 'backgroundImage', src: '/tickets-to-sell.jpeg', alt: 'Sell your tickets' })),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'title' }, "Sell Your Tickets Now"),
            React.createElement("div", { className: 'subtitle' }, "All transactions are covered by the Beeyay Guarantee"),
            React.createElement(Button, { onClick: function () { return onListingNew('sell'); } }, "I've got tickets to sell")));
};

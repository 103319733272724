var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LocationSelector } from '@containers/DetailsPages/components/eventsTable';
import { PleaseWait } from '@components/pleaseWait/pleaseWait';
import { t } from '@containers/helpers/tests';
import { getEventsDateRangeText } from '@logic/events';
import { slugifyName } from '@logic/helpers/location';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { loadPerformerEvents } from '@logic/performer';
import { routes, BUY_ONLY } from 'src/client/routing/routes';
import { JsonLd } from '@components/jsonLD';
import { Error404 } from '@containers/Error404/error404';
import { Container, withFetchData, withPageOptions } from '@containers/Page';
import cn from 'classnames';
import styles from '@containers/DetailsPages/sharedStyles.less';
import { PerformerSeo } from '@containers/DetailsPages/pages/seo/performerSeo';
import { NotAvailableAction } from '@components/notAvailableAction';
import { TopEvents } from '@components/topEvents/topEvents';
import { LastNextEventComponent } from '@containers/DetailsPages/components/lastNextEvent/lastNextEvent.component';
import { DetailsPageNavigation } from '@containers/DetailsPages/components/detailsPageNavigation/detailsPageNavigation';
import { mapCategoryToEventType } from '../components/eventsTable/utils/utils';
import { ScrollToTopOnMount } from '@components/scrollToTopOnMount/scrollToTopOnMount';
import { SimilarPerformers } from '../components/similarPerformers/similarPerformers';
import { Biography } from '../components/biography/biography';
import { EventsTable } from '@containers/DetailsPages/components/eventsTable/eventsTable';
import { getVenueImage } from '@containers/DetailsPages/utils';
import { NoDataMessage } from '../components/eventsTable/utils/utilsComponents';
import { PageSectionEnum, useScrollTo } from '@containers/DetailsPages/hooks/useScrollTo.hook';
import { DetailsPageOptions } from '@containers/DetailsPages/constants';
import { fetchData, useDetailPageBase } from '@containers/DetailsPages/hooks/useDetailsPageBase.hook';
import { useLocationSelector } from '@containers/DetailsPages/hooks/useLocationSelector';
import { createPerformerBreadcrumb } from '@containers/DetailsPages/breadcrumbFactory.utils';
import { Breadcrumb, EMPTY_BREADCRUMB } from '@containers/DetailsPages/components/breadcrumb/breadcrumb';
import { DetailsHeader } from '@containers/DetailsPages/components/detailsHeader/detailsHeader';
import { Faq } from '@containers/DetailsPages/components/faq/faq';
import { Spotify } from '@containers/components/spotify/spotify';
import { MoreResultsButton } from '@containers/components/moreResultsButton/moreResultsButton';
import { useDispatch } from 'react-redux';
import { SANE_LIMIT } from '@logic/helpers/request';
export var PerformerDetails = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useDetailPageBase('performer'), details = _h.details, detailsLoading = _h.detailsLoading, eventsIsLoading = _h.eventsIsLoading, events = _h.events, lastNextEvent = _h.lastNextEvent, searchParams = _h.searchParams, eventsCount = _h.eventsCount;
    var match = useRouteMatch();
    var paramsCity = match.params.city === BUY_ONLY ? undefined : match.params.city;
    var location = paramsCity !== null && paramsCity !== void 0 ? paramsCity : 'All';
    var city = slugifyName(paramsCity !== null && paramsCity !== void 0 ? paramsCity : '');
    var buyOnly = !!match.params.buyOnly || match.params.city === BUY_ONLY;
    var dispatch = useDispatch();
    var _j = useScrollTo(), bioRef = _j.bioRef, eventsRef = _j.eventsRef, executeScroll = _j.executeScroll, faqRef = _j.faqRef;
    var detailsHeaderSubtitle = mapCategoryToEventType((details === null || details === void 0 ? void 0 : details.category) || '') + " " + getEventsDateRangeText(events);
    var breadcrumb = useMemo(function () { return details
        ? createPerformerBreadcrumb(details)
        : EMPTY_BREADCRUMB; }, [details]);
    var _k = useLocationSelector({
        variant: 'performer',
        slug: (_a = details === null || details === void 0 ? void 0 : details.slug) !== null && _a !== void 0 ? _a : '',
        id: (_b = details === null || details === void 0 ? void 0 : details.id) !== null && _b !== void 0 ? _b : 0,
        events: events,
        buyOnly: buyOnly
    }), locationOptions = _k.locationOptions, handleLocationChange = _k.handleLocationChange;
    var bioHeader = (details === null || details === void 0 ? void 0 : details.category) === 'Sport' || (details === null || details === void 0 ? void 0 : details.category) === 'Festivals'
        ? PageSectionEnum.About
        : PageSectionEnum.Bio;
    var onMoreResultsClick = function () {
        return dispatch(loadPerformerEvents(details.id, (searchParams.offset || 0) + SANE_LIMIT));
    };
    if (!details) {
        return detailsLoading
            ? React.createElement(PleaseWait, null)
            : React.createElement(Error404, null);
    }
    switch (details.status) {
        case 'cancelled':
            return React.createElement(Error404, null);
        case 'suspended':
            return (React.createElement(React.Fragment, null,
                React.createElement(PerformerSeo, { details: details, events: events, city: city, eventsLoading: eventsIsLoading, buyOnly: buyOnly }),
                React.createElement(ScrollToTopOnMount, null),
                React.createElement(DetailsHeader, __assign({ details: details, detailsSubtitle: detailsHeaderSubtitle }, t('Performer'))),
                React.createElement(NotAvailableAction, { topMargin: true, message: 'This performer is currently under review.', details: 'We\'re working on some small changes to this performer. Please try again later, or return Home.', to: routes.index(), actionText: 'Go back Home' })));
        case 'active':
            return (React.createElement(React.Fragment, null,
                React.createElement(PerformerSeo, { details: details, events: events, city: city, eventsLoading: eventsIsLoading, buyOnly: buyOnly }),
                React.createElement(ScrollToTopOnMount, null),
                React.createElement(JsonLd, { events: events, breadcrumbJsonLd: (_c = breadcrumb.breadcrumbPaths) === null || _c === void 0 ? void 0 : _c.jsonLD, faqs: details.faqs }),
                React.createElement(DetailsHeader, __assign({ details: details, containerSize: 'wide' }, t('Performer'), { breadcrumb: React.createElement(Breadcrumb, { breadcrumbPaths: breadcrumb.breadcrumbPaths }), detailsSubtitle: detailsHeaderSubtitle, scrollToCallback: function () { return executeScroll(PageSectionEnum.Bio); } })),
                React.createElement(DetailsPageNavigation, { bioHeader: bioHeader, isFullWide: true, details: details, scrollTo: executeScroll, hasFAQs: !!((_d = details === null || details === void 0 ? void 0 : details.faqs) === null || _d === void 0 ? void 0 : _d.length) }),
                React.createElement(Container, { className: styles.advancedContainer, size: 'wide' },
                    React.createElement("div", null,
                        React.createElement(EventsTable, { sortingHeader: React.createElement("h1", { ref: eventsRef }, "Events"), tableVariant: 'simpleWithLocationFilter', events: events, locationSelector: React.createElement(LocationSelector, { locationOptions: locationOptions, location: location, onLocationChange: handleLocationChange }), eventsIsLoading: eventsIsLoading, getImage: getVenueImage, noDataMessage: React.createElement(NoDataMessage, { baseRoute: routes.performer(':id', ':performerName', ':city?') }) }),
                        React.createElement(MoreResultsButton, { onMoreResultsClick: onMoreResultsClick, events: events, eventsCount: eventsCount })),
                    React.createElement(Biography, { details: details, bioRef: bioRef }),
                    ((_e = details === null || details === void 0 ? void 0 : details.mediaLinks) === null || _e === void 0 ? void 0 : _e.spotify) ? React.createElement(Spotify, { spotifyLink: (_f = details === null || details === void 0 ? void 0 : details.mediaLinks) === null || _f === void 0 ? void 0 : _f.spotify }) : null,
                    React.createElement(LastNextEventComponent, { eventCategory: details === null || details === void 0 ? void 0 : details.category, lastNextEvent: lastNextEvent }),
                    React.createElement(TopEvents, null),
                    ((_g = details === null || details === void 0 ? void 0 : details.faqs) === null || _g === void 0 ? void 0 : _g.length) ? React.createElement(Faq, { data: details.faqs, faqRef: faqRef }) : null,
                    React.createElement("div", { className: cn(styles.secondarySidebar, styles.performerSidebar) },
                        React.createElement(SimilarPerformers, null)))));
    }
};
export default withPageOptions(DetailsPageOptions)(withFetchData(fetchData('performer'))(PerformerDetails));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from '@containers/helpers/tests';
import { getNewEventsSearchParams, actions as eventsActions } from '@logic/events';
import { Images } from '@logic/images';
import debounce from 'lodash/debounce';
import isMatch from 'lodash/isMatch';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Dimmer } from 'semantic-ui-react';
import { Modal } from '@components/forms';
import { getQueryString, routes } from 'src/client/routing/routes';
import { isMobileScreenSize } from '@components/viewPortInfo/viewPort.utils';
import { Icon } from '@components/icon';
import { MoreResultsButton } from '@components/moreResultsButton/moreResultsButton';
import { withPageOptions } from '../Page';
import { SearchBox } from '../SearchBox/searchBox';
import { NewEvent } from '../WorkItems/newEvent';
import styles from './advancedSearch.less';
import { DEBOUNCE_TIME, Filters, mapTreeToMetadata, metadataToCategory, metadataToLocation, categoryToMetadata, createTreeFromMetadata, locationToMetadata } from './filters';
import { EventsTable, parseSort } from '@containers/DetailsPages/components/eventsTable';
import { startOfDay } from 'date-fns';
import cn from 'classnames';
var noFilterMessage = 'Please search for an event, artist, venue or city';
var noDataMessage = "Sorry, we can't find any matching events.\n  Please try again using a different search term or if you have a specific event in mind why not request\n  the event using the button below.";
var defaultSorting = 'date';
var defaultOrdering = 'ascending';
var limitPerPage = 10;
var NewEventContainer = function (_a) {
    var _b;
    var isMobile = _a.isMobile;
    return React.createElement("div", { className: cn(styles.newEvent, (_b = {}, _b[styles.newEventContainer] = isMobile, _b)) },
        React.createElement("span", { className: styles.description }, "Can't find the event you're looking for?"),
        React.createElement(NewEvent, { baseRoute: routes.search(), className: styles.newEventButton }));
};
var AdvancedSearch = /** @class */ (function (_super) {
    __extends(AdvancedSearch, _super);
    function AdvancedSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            search: {},
            phrase: '',
            sort: defaultSorting,
            order: defaultOrdering,
            from: new Date(),
            to: undefined,
            location: undefined,
            category: undefined,
            buyNow: undefined,
            sellNow: undefined,
            recent: undefined,
            limit: limitPerPage,
            searchInProgress: true,
            filtersPopup: false,
            offset: 0,
        };
        _this.searchEvents = debounce(function () {
            _this.setState({ searchInProgress: true });
            _this.props.actions.searchEvents(_this.createQueryParams());
        }, DEBOUNCE_TIME);
        _this.eventsLoaded = function (props, oldProps) {
            return !props.isLoadingEvents && oldProps.isLoadingEvents;
        };
        _this.locationChanged = function (props, oldProps) {
            return (oldProps.location !== props.location || oldProps.match !== props.match);
        };
        _this.metadataChanged = function (props, oldProps) {
            return (oldProps.eventCategories !== props.eventCategories || oldProps.eventLocations !== props.eventLocations);
        };
        _this.metadataLoaded = function (props) {
            return (props.eventLocations.length && props.eventCategories.length);
        };
        _this.createQueryParams = function () {
            var _a = getNewEventsSearchParams(), onSaleDateFrom = _a.onSaleDateFrom, onSaleDateTo = _a.onSaleDateTo;
            var queryMapping = {
                searchPhrase: function (state) { return isset(state.phrase) ? state.phrase : null; },
                dateFrom: function (state) { return state.from; },
                dateTo: function (state) { return state.to; },
                location: function (state) { return metadataToLocation(mapTreeToMetadata(state.location)); },
                category: function (state) { return metadataToCategory(mapTreeToMetadata(state.category)); },
                buyNow: function (state) { return state.buyNow; },
                sellNow: function (state) { return state.sellNow; },
                onSaleDateFrom: function (state) { return state.recent ? onSaleDateFrom : null; },
                onSaleDateTo: function (state) { return state.recent ? onSaleDateTo : null; },
                orderBy: function (state) { return ({
                    date: 'eventDate',
                    event: 'eventName',
                    onSaleDate: 'onSaleDate',
                    sellPrice: 'maxBuyPrice',
                    buyPrice: 'minSellPrice',
                })[state.sort]; },
                orderByDesc: function (state) { return state.order === 'descending' ? 'true' : 'false'; },
                limit: function (state) { return state.limit; },
                offset: function (state) { return state.offset; }
            };
            var queryParams = Object.keys(queryMapping).reduce(function (memo, param) {
                var _a;
                var value = Object.prototype.hasOwnProperty.call(queryMapping, param) && queryMapping[param](_this.state);
                return value !== null
                    ? __assign(__assign({}, memo), (_a = {}, _a[param] = value, _a)) : memo;
            }, {});
            return queryParams;
        };
        _this.updateEvents = function () {
            _this.updateLocation();
            _this.searchEvents();
        };
        _this.updateLocation = function () {
            var _a = _this.state, phrase = _a.phrase, sort = _a.sort, order = _a.order, from = _a.from, to = _a.to, location = _a.location, category = _a.category, buyNow = _a.buyNow, sellNow = _a.sellNow, recent = _a.recent;
            var query = qs.stringify(clearUrl({
                sort: sort,
                order: order,
                from: from ? (from.getTime() / 1000).toString() : undefined,
                to: to ? (to.getTime() / 1000).toString() : undefined,
                location: metadataToLocation(mapTreeToMetadata(location)),
                category: metadataToCategory(mapTreeToMetadata(category)),
                buyNow: buyNow,
                sellNow: sellNow,
                recent: recent,
                q: phrase,
            }));
            var pathname = _this.props.location.pathname;
            var searchQ = query ? "?" + query : '';
            var currentLoc = _this.props.location;
            if (pathname !== currentLoc.pathname || searchQ !== currentLoc.search) {
                _this.props.history.replace("" + pathname + searchQ);
            }
        };
        _this.handleSorting = function (by, direction) {
            _this.setState({
                sort: by,
                order: direction,
            }, _this.updateEvents);
        };
        _this.handleFiltering = function (filters) {
            _this.setState(__assign(__assign({}, filters), { searchInProgress: true }), _this.updateEvents);
        };
        _this.handleApply = function (filters) {
            _this.setState(__assign(__assign({}, filters), { searchInProgress: true }), _this.updateEvents);
            _this.handleFiltersClose();
        };
        _this.handleFiltersOpen = function () {
            _this.setState({ filtersPopup: true });
        };
        _this.handleFiltersClose = function () {
            _this.setState({ filtersPopup: false });
        };
        _this.handleFiltersOnClick = function (ev) {
            if (ev.target === ev.currentTarget) {
                _this.handleFiltersClose();
            }
        };
        _this.openFiltersAction = function () {
            return React.createElement(Icon, { onClick: _this.handleFiltersOpen, icon: 'filter', width: 15, height: 15 });
        };
        _this.renderFiltersPopup = function () {
            return (React.createElement(Modal, { className: styles.FiltersModal, open: _this.state.filtersPopup, defaultOpen: true, onClose: _this.handleFiltersClose, close: 'iconAndText' }, _this.renderFilters()));
        };
        _this.renderFilters = function () {
            var _a = _this.props, eventLocations = _a.eventLocations, eventCategories = _a.eventCategories;
            var _b = _this.state, from = _b.from, to = _b.to, location = _b.location, category = _b.category, buyNow = _b.buyNow, sellNow = _b.sellNow, recent = _b.recent, filtersPopup = _b.filtersPopup;
            return (React.createElement(Filters, { from: from, to: to, location: location, category: category, buyNow: !!buyNow, sellNow: !!sellNow, recent: !!recent, eventCategories: eventCategories, eventLocations: eventLocations, onChange: _this.handleFiltering, onApply: _this.handleApply, filtersPopup: filtersPopup }));
        };
        _this.getImage = function (e) { return e.imageUrl + "/" + Images.advancedSearch(e.name); };
        _this.onMoreResultsClick = function () { return _this.setState({ offset: _this.state.offset + limitPerPage, searchInProgress: true }, _this.updateEvents); };
        return _this;
    }
    AdvancedSearch.prototype.componentWillUnmount = function () {
        this.searchEvents.cancel();
    };
    AdvancedSearch.prototype.componentDidMount = function () {
        if (!this.props.eventCategories.length) {
            this.props.actions.loadCategories();
        }
        if (!this.props.eventLocations.length) {
            this.props.actions.loadLocations();
        }
        if (this.metadataLoaded(this.props)) {
            this.updateSearchStrings(this.props);
        }
    };
    AdvancedSearch.prototype.componentDidUpdate = function (oldProps) {
        if (this.eventsLoaded(this.props, oldProps)) {
            this.setState({ searchInProgress: false });
        }
        if (this.metadataChanged(this.props, oldProps) && this.metadataLoaded(this.props)) {
            this.updateSearchStrings(this.props);
        }
        if (this.locationChanged(this.props, oldProps) && this.metadataLoaded(this.props)) {
            this.updateSearchStrings(this.props);
        }
    };
    AdvancedSearch.prototype.updateSearchStrings = function (_a) {
        var location = _a.location;
        var search = qs.parse(getQueryString(location), { arrayLimit: 1000 });
        var _b = parseSort(search.sort || defaultSorting, search.order || defaultOrdering), sort = _b.sort, order = _b.order;
        var newSearch = {
            search: search,
            phrase: search.q || '',
            sort: sort,
            order: order,
            from: search.from ? new Date(parseInt(search.from, 10) * 1000) : startOfDay(new Date),
            to: search.to ? new Date(parseInt(search.to, 10) * 1000) : undefined,
            buyNow: search.buyNow,
            sellNow: search.sellNow,
            recent: search.recent,
            category: createTreeFromMetadata(categoryToMetadata(search.category), categoryToMetadata(this.props.eventCategories) || []),
            location: createTreeFromMetadata(locationToMetadata(search.location), locationToMetadata(this.props.eventLocations) || []),
            limit: Number(search.limit) || limitPerPage,
            offset: 0
        };
        if (!isMatch(this.state, newSearch)) {
            this.setState(newSearch, this.updateEvents);
        }
    };
    AdvancedSearch.prototype.render = function () {
        var _a = this.props, events = _a.events, isLoadingEvents = _a.isLoadingEvents, isLoadingCategories = _a.isLoadingCategories, isLoadingLocations = _a.isLoadingLocations, count = _a.count;
        var _b = this.state, phrase = _b.phrase, searchInProgress = _b.searchInProgress;
        var isLoading = searchInProgress || isLoadingEvents || isLoadingCategories || isLoadingLocations;
        return (React.createElement("div", __assign({ className: styles.gridAdvancedSearch }, t('AdvancedSearch')),
            isMobileScreenSize()
                ? this.renderFiltersPopup()
                : React.createElement("div", null,
                    React.createElement("h1", { className: styles.header },
                        React.createElement("strong", null, "Event search")),
                    this.renderFilters(),
                    React.createElement(NewEventContainer, null)),
            React.createElement("div", null,
                React.createElement("div", { className: styles.searchContainer },
                    React.createElement(SearchBox, { extraActions: this.openFiltersAction() })),
                React.createElement(Dimmer.Dimmable, null,
                    React.createElement(EventsTable, { events: events, eventsIsLoading: isLoading, tableVariant: 'advanced', getImage: this.getImage, noDataMessage: !phrase ? noFilterMessage : noDataMessage }),
                    !isLoading && React.createElement(MoreResultsButton, { onMoreResultsClick: this.onMoreResultsClick, events: events, eventsCount: count }),
                    isMobileScreenSize() && !isLoading && React.createElement(NewEventContainer, { isMobile: true })))));
    };
    return AdvancedSearch;
}(React.Component));
export { AdvancedSearch };
function clearUrl(obj) {
    // clear out empty keys
    var anyObj = obj;
    Object.keys(anyObj)
        .filter(function (key) { return !anyObj[key]; })
        .forEach(function (key) {
        delete anyObj[key];
    });
    if (obj.page === 1) {
        delete obj.page;
    }
    return obj;
}
var REG = /[^\s]+/;
function isset(x) {
    return REG.test(x) && x !== '*';
}
var mapStateToProps = function (state) { return ({
    count: state.events.eventsCount,
    events: state.events.eventsSearched,
    isLoadingEvents: state.events.isLoading,
    isLoadingLocations: state.events.isLoadingLocations,
    isLoadingCategories: state.events.isLoadingCategories,
    eventLocations: state.events.locations,
    eventCategories: state.events.categories
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        searchEvents: eventsActions.searchEvents.request,
        loadCategories: eventsActions.loadEventCategories.request,
        loadLocations: eventsActions.loadEventLocations.request,
    }, dispatch),
}); };
export default compose(withPageOptions({
    search: false,
    containerSize: 'wide'
}), connect(mapStateToProps, mapDispatchToProps))(AdvancedSearch);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styles from './googleMap.less';
import { t } from '@containers/helpers/tests';
import useWindowProperty from '@containers/hooks/useWindowProperty.hook';
export var replaceSpacesWithPlus = function (value) {
    // Use a regular expression with the global flag 'g' to replace all occurrences of spaces with '+'
    return value ? value.replace(/\s+/g, '+') : '';
};
// We propose using the Place Id of the venue if it exists in the venue static. If not, then use a combination of the venue name, city name and country.
export var generateEmbeddedMapId = function (details) {
    var sanitizedValues = [details.venueName, details.city, details.country].filter(Boolean).map(replaceSpacesWithPlus);
    var sanitizedSrc = sanitizedValues.join('+');
    return (details.placeId
        ? "place_id:" + details.placeId
        : sanitizedSrc);
};
export var generateEmbeddedMapLink = function (data, zoom, googleMapKeyValue) {
    return ('https://www.google.com/maps/embed/v1/place' +
        ("?q=" + generateEmbeddedMapId(data)) +
        ("&key=" + googleMapKeyValue) +
        ("&zoom=" + zoom));
};
export var GoogleMap = function (_a) {
    var data = _a.data, zoom = _a.zoom;
    var _b = __read(useState(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(''), 2), src = _c[0], setSrc = _c[1];
    var googleMapKeyValue = useWindowProperty('GOOGLE_MAP_KEY', '');
    var handleImageLoad = function () {
        setIsLoading(false);
    };
    useEffect(function () {
        if (googleMapKeyValue !== null) {
            var srcValue = generateEmbeddedMapLink(data, zoom, googleMapKeyValue);
            setSrc(srcValue);
        }
    }, [data, zoom, googleMapKeyValue]);
    return (React.createElement("div", __assign({ className: styles.googleMapContainer }, t('EmbeddedGoogleMap')),
        (isLoading || !src) && React.createElement("div", { className: styles.loader }, " Loading... "),
        React.createElement("iframe", { onLoad: handleImageLoad, frameBorder: '0', referrerPolicy: 'no-referrer-when-downgrade', src: src })));
};

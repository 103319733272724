var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Button, DropdownField, genOptions, InputField, Label, Link, Loading, Modal, ModalContentRow, TextAreaField, } from '@components/forms';
import { t } from '@containers/helpers/tests';
import { requireAuth } from '@logic/helpers/authentication';
import { downloadFile } from '@logic/helpers/request';
import { isRequired, success } from '@logic/helpers/validators';
import { createWorkItem, loadBusinessItems, } from '@logic/workItems';
import cn from 'classnames';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { Form } from 'react-final-form';
import Helmet from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { matchPath, Switch, useLocation, useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';
import { getQueryString, routes } from 'src/client/routing/routes';
import PrivateRoute from 'src/client/routing/privateRoute';
import { Entry } from '../../ListingQuickEntry/utils';
import { Files } from '../files/files';
import { useFiles } from '../files/useFiles';
import styles from './createWorkItem.less';
var businessItemTypeToString = {
    buyListing: 'Buy listing',
    sellListing: 'Sell listing',
    transaction: 'Order',
    venue: 'Venue',
    event: 'Event',
    generic: 'Generic',
    performer: 'Performer',
};
function Item(_a) {
    var businessItem = _a.businessItem;
    return (React.createElement("div", { className: styles.item },
        React.createElement("span", { className: styles.label }, businessItemTypeToString[businessItem.type]),
        React.createElement("span", { className: styles.itemValue }, businessItem.niceId)));
}
var workItemCreationToBusinessItemMapping = {
    fgeneric: 'generic',
    generic: 'generic',
    event: 'event',
    performer: 'performer',
    venue: 'venue',
    sellListing: 'sellListing',
    buyListing: 'buyListing',
    saleOrder: 'transaction',
    purchaseOrder: 'transaction',
};
var selectBusinessId = function (wiType, biId, items) { return items.find(function (bi) { return bi.type === workItemCreationToBusinessItemMapping[wiType] && "" + bi.niceId === biId; }); };
var selectEventOfBusinessId = function (wiType, biId, items) {
    var selectedItem = items.find(function (bi) { return bi.type === workItemCreationToBusinessItemMapping[wiType] && "" + bi.niceId === biId; });
    if (selectedItem && selectedItem.eventId) {
        return selectBusinessId('event', "" + selectedItem.eventId, items);
    }
};
var genericOtherSubject = {
    name: 'Other',
    idSelector: selectBusinessId,
    workItemType: 'GenericQuery',
};
var subjectsMapping = {
    fgeneric: [genericOtherSubject],
    generic: [genericOtherSubject],
    event: [genericOtherSubject],
    performer: [genericOtherSubject],
    venue: [genericOtherSubject],
    buyListing: [
        { name: 'Listing Issue', idSelector: selectBusinessId, workItemType: 'ListingIssue' },
        { name: 'Event Issue', idSelector: selectEventOfBusinessId, workItemType: 'EventIssue' },
        __assign(__assign({}, genericOtherSubject), { idSelector: function () { return undefined; } }),
    ],
    sellListing: [
        { name: 'Listing Issue', idSelector: selectBusinessId, workItemType: 'ListingIssue' },
        { name: 'Event Issue', idSelector: selectEventOfBusinessId, workItemType: 'EventIssue' },
        __assign(__assign({}, genericOtherSubject), { idSelector: function () { return undefined; } }),
    ],
    purchaseOrder: [
        { name: 'My tickets have an issue', idSelector: selectBusinessId, workItemType: 'TransactionIssue' },
        { name: 'My tickets have not been delivered', idSelector: selectBusinessId, workItemType: 'TransactionIssue' },
        { name: 'I had an issue with accessing the event', idSelector: selectBusinessId, workItemType: 'TransactionIssue' },
        __assign(__assign({}, genericOtherSubject), { workItemType: 'TransactionIssue' }),
    ],
    saleOrder: [
        {
            name: 'I cannot fulfil this sale',
            idSelector: selectBusinessId,
            warning: 'Beeyay will take over this sale. We reserve the right to charge you any costs involved in sourcing replacement tickets as per our terms and conditions.',
            workItemType: 'TransactionIssue',
        },
        {
            name: 'I wish to offer alternative tickets',
            idSelector: selectBusinessId,
            warning: 'If this change is not accepted by the buyer your sale will be cancelled, and we reserve the right to charge you any costs involved in sourcing replacement tickets as per our terms and conditions.',
            workItemType: 'TransactionIssue',
        },
        {
            name: 'I dispute this cancelled sale',
            idSelector: selectBusinessId,
            workItemType: 'TransactionIssue',
            predicate: function (bi) { return !!bi && bi.type === 'transaction' && bi.entity.status === 'cancelled'; },
        },
        __assign(__assign({}, genericOtherSubject), { workItemType: 'TransactionIssue' }),
    ],
};
var defaultPredefinedSubjectMapping = {
    fgeneric: genericOtherSubject.name,
    generic: genericOtherSubject.name,
    event: genericOtherSubject.name,
    performer: genericOtherSubject.name,
    venue: genericOtherSubject.name,
    buyListing: 'Listing Issue',
    sellListing: 'Listing Issue',
    purchaseOrder: undefined,
    saleOrder: undefined,
};
function CreateWorkItemForm(_a) {
    var _b;
    var _c, _d;
    var formApi = _a.formApi, isPending = _a.isPending, filesState = _a.filesState, businessItems = _a.businessItems, wiType = _a.wiType, biId = _a.biId, loadBusinessItemsAction = _a.loadBusinessItemsAction;
    useEffect(function () {
        loadBusinessItemsAction(wiType !== 'generic');
    }, [loadBusinessItemsAction, wiType]);
    var handleDownloadNotSent = useCallback(function (_, idx) {
        downloadFile(filesState.files[idx]);
    }, [filesState.files]);
    var change = formApi.form.change;
    useEffect(function () {
        change('predefinedSubject', defaultPredefinedSubjectMapping[wiType]);
    }, [change, wiType]);
    var predefinedSubject = formApi.form.getState().values.predefinedSubject;
    var _e = __read(useMemo(function () {
        var computedSubjectInfo = subjectsMapping[wiType].find(function (s) { return s.name === (predefinedSubject || 'Other'); });
        var computedSelectedBusinessItem = computedSubjectInfo ? computedSubjectInfo.idSelector(wiType, biId, businessItems) : undefined;
        return [computedSelectedBusinessItem, computedSubjectInfo];
    }, [wiType, predefinedSubject, biId, businessItems]), 2), selectedBusinessItem = _e[0], subjectInfo = _e[1];
    useEffect(function () {
        change('businessItem', selectedBusinessItem);
    }, [change, selectedBusinessItem]);
    useEffect(function () {
        if (subjectInfo && subjectInfo.warning) {
            var id_1 = toast.error(subjectInfo.warning);
            return function () { return toast.dismiss(id_1); };
        }
    }, [subjectInfo]);
    var handleSubmit = useCallback(function () { return formApi.handleSubmit(); }, [formApi]);
    var options = useMemo(function () {
        return businessItems.map(function (bi) { return ({
            key: bi.type + "_" + bi.id,
            text: "" + bi.niceId,
            nativeText: businessItemTypeToString[bi.type] + ": " + bi.niceId,
            value: bi,
            content: React.createElement(Item, { businessItem: bi }),
        }); });
    }, [businessItems]);
    var predefinedSubjectsOptions = useMemo(function () { return genOptions.apply(void 0, __spreadArray([], __read(subjectsMapping[wiType].filter(function (s) { return !s.predicate || s.predicate(selectedBusinessItem); }).map(function (x) { return [x.name, x.name]; })))); }, [wiType, selectedBusinessItem]);
    var businessItem = ((_c = formApi.values) !== null && _c !== void 0 ? _c : {}).businessItem;
    var dropdownLabel = businessItem ? React.createElement("span", { className: styles.label }, businessItemTypeToString[businessItem.type]) : null;
    var itemString = (_d = {
        sellListing: 'Listing',
        buyListing: 'Listing',
        purchaseOrder: 'Order',
        saleOrder: 'Order',
    }[wiType]) !== null && _d !== void 0 ? _d : 'Order or Listing';
    return (React.createElement("div", null,
        React.createElement(Entry, { name: 'predefinedSubject' },
            React.createElement(Label, { fill: true },
                "Subject",
                predefinedSubjectsOptions.length > 1 && React.createElement(DropdownField, { name: 'predefinedSubject', options: predefinedSubjectsOptions, selection: true }))),
        React.createElement(Entry, { name: 'subject' },
            React.createElement(Label, { fill: true }, (predefinedSubjectsOptions.length === 1 || formApi.form.getState().values.predefinedSubject === 'Other') && React.createElement(InputField, __assign({ name: 'subject' }, t('Subject'))))),
        React.createElement(Entry, { name: 'businessItem' },
            React.createElement(Label, { fill: true },
                itemString,
                " ",
                !biId && '(optional)',
                React.createElement(DropdownField, __assign({ search: true, selection: true, name: 'businessItem', selectOnBlur: false, icon: dropdownLabel, placeholder: "Link your " + itemString + " ID", options: options, className: cn(styles.dropdown, (_b = {}, _b[styles.selected] = !!businessItem, _b)), disabled: !!biId }, t('BusinessItem'))))),
        React.createElement(Entry, { name: 'description' },
            React.createElement(Label, { fill: true },
                "Message",
                React.createElement(TextAreaField, { name: 'description', fill: true, rows: 10, className: styles.message }))),
        React.createElement(Files, { files: filesState.previewFiles, deleteFile: filesState.handleDelete, downloadFile: handleDownloadNotSent }),
        React.createElement(Dropzone, { onDrop: filesState.handleDrop, className: styles.dropzone },
            React.createElement(Icon, { name: 'attach' }),
            "add an attachment"),
        React.createElement("div", { className: styles.actions },
            React.createElement(Button, __assign({ className: styles.sendButton, ternary: true, medium: true, type: "submit", onClick: handleSubmit, disabled: isPending }, t('Send')), "Send"))));
}
export function CreateWorkItemModal(_a) {
    var _b;
    var isPending = _a.isPending, businessItems = _a.businessItems, actions = _a.actions, prevLocation = _a.prevLocation, fromFooter = _a.fromFooter;
    var match = useRouteMatch();
    var filesState = useFiles([]);
    var witype = (_b = match === null || match === void 0 ? void 0 : match.params.witype) !== null && _b !== void 0 ? _b : 'generic';
    var handleCreate = useCallback(function (_a) {
        var _b;
        var predefinedSubject = _a.predefinedSubject, subject = _a.subject, description = _a.description, businessItem = _a.businessItem;
        var subjectInfo = subjectsMapping[witype].find(function (ps) { return ps.name === (predefinedSubject || 'Other'); });
        var wiType = (_b = subjectInfo === null || subjectInfo === void 0 ? void 0 : subjectInfo.workItemType) !== null && _b !== void 0 ? _b : 'GenericQuery';
        actions.createWorkItem(wiType, (predefinedSubject === 'Other' || !predefinedSubject ? subject : predefinedSubject) || '', description || '', businessItem, filesState.files, prevLocation);
    }, [actions, filesState.files, witype, prevLocation]);
    var handleDeactivate = useCallback(function () { return actions.push(prevLocation); }, [actions, prevLocation]);
    var validate = useCallback(function (values) {
        var noPredefinedSubject = subjectsMapping[witype].length === 1;
        return {
            predefinedSubject: noPredefinedSubject ? success : isRequired(values.predefinedSubject),
            subject: (values.predefinedSubject === 'Other' || noPredefinedSubject) ? isRequired(values.subject) : success,
            description: isRequired(values.description),
        };
    }, [witype]);
    if ((witype === 'fgeneric' && !fromFooter) || (witype !== 'fgeneric' && fromFooter)) {
        return null;
    }
    return (React.createElement(Modal, __assign({ open: true, onClose: handleDeactivate, close: 'icon' }, t('CreateWorkItemForm')),
        React.createElement(Helmet, null,
            React.createElement("meta", { name: 'robots', content: 'noindex' })),
        React.createElement(ModalContentRow, null,
            React.createElement("div", { className: styles.header }, "Contact Us")),
        React.createElement(Loading, { active: isPending }),
        React.createElement(ModalContentRow, null,
            React.createElement(Form, { onSubmit: handleCreate, validate: validate }, function (formApi) {
                var _a;
                return React.createElement(CreateWorkItemForm, { formApi: formApi, isPending: isPending, filesState: filesState, businessItems: businessItems, loadBusinessItemsAction: actions.loadBusinessItems, wiType: witype, biId: (_a = match === null || match === void 0 ? void 0 : match.params.biid) !== null && _a !== void 0 ? _a : '' });
            }))));
}
var protectedRoutes = [
    routes.login(),
    routes.register(),
    routes.resetPassword(),
];
export function CreateWorkItemBase(_a) {
    var hiddenButton = _a.hiddenButton, onOpen = _a.onOpen, baseRoute = _a.baseRoute, replaceRoute = _a.replaceRoute, wiType = _a.wiType, biId = _a.biId, linkProps = _a.linkProps, linkText = _a.linkText, rest = __rest(_a, ["hiddenButton", "onOpen", "baseRoute", "replaceRoute", "wiType", "biId", "linkProps", "linkText"]);
    var location = useLocation();
    var search = getQueryString(location);
    var user = useSelector(function (gs) { return gs.user.userDetails; });
    var prevLocation = useMemo(function () { return ({
        pathname: routes.revertCreateUserWorkItem(location.pathname) || routes.index(),
        search: search,
    }); }, [location.pathname, search]);
    var handleOpen = useCallback(function () {
        if (!user) {
            toast.info('In order for us to respond to you please log in to your account or create a new account if you do not have one.');
        }
        if (protectedRoutes.some(function (p) { return matchPath(location.pathname, p); })) {
            return;
        }
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    }, [onOpen, location.pathname, user]);
    var link = routes.createUserWorkItemFilter(replaceRoute || location.pathname, wiType, biId || '', getQueryString(location));
    return (React.createElement(React.Fragment, null,
        !hiddenButton &&
            React.createElement(Link, __assign({}, linkProps, { replace: true, to: protectedRoutes.some(function (p) { return matchPath(location.pathname, p); }) ? location.pathname : link, onClick: handleOpen, rel: 'nofollow' }, t('OpenContactUs')), linkText !== null && linkText !== void 0 ? linkText : 'Contact us'),
        React.createElement(Switch, null,
            React.createElement(PrivateRoute, { exact: true, path: routes.createUserWorkItem(baseRoute, ':witype', ':biid?'), render: function () { return React.createElement(CreateWorkItemModal, __assign({ prevLocation: prevLocation }, rest)); }, authenticated: requireAuth }))));
}
var mapStateToProps = function (state) { return ({
    isPending: state.workItems.isPending || state.workItems.uploading > 0,
    businessItems: state.workItems.businessItems,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({
        createWorkItem: createWorkItem,
        push: push,
        loadBusinessItems: loadBusinessItems,
    }, dispatch),
}); };
export var CreateWorkItem = connect(mapStateToProps, mapDispatchToProps)(CreateWorkItemBase);

import { useEffect } from 'react';
import { windowIsDefined } from '@containers/utils';
export var ScrollToTopOnMount = function (_a) {
    var scrollToTop = _a.scrollToTop;
    useEffect(function () {
        if (windowIsDefined()) {
            window.scrollTo({ left: 0, top: scrollToTop !== null && scrollToTop !== void 0 ? scrollToTop : 0, behavior: 'auto' });
        }
    }, [scrollToTop]);
    return null;
};

import { Seo } from '@components/seo';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React from 'react';
import { routes } from 'src/client/routing/routes';
import { hasNoIndex } from '@containers/DetailsPages/pages/seo/common';
import { matchPath, useLocation } from 'react-router-dom';
export var VenueSeo = function (_a) {
    var _b;
    var details = _a.details, events = _a.events, eventsLoading = _a.eventsLoading;
    var pathname = useLocation().pathname;
    if (!details) {
        return null;
    }
    var description = details.name + " tickets are available now with low prices. Want even cheaper? Name your price! All purchases 100% protected by the Beeyay Guarantee.";
    var categories = sortBy(uniq((_b = events === null || events === void 0 ? void 0 : events.map(function (e) { return e.category; })) !== null && _b !== void 0 ? _b : [])).join(', ');
    var title = "Tickets for all " + details.name + " Tickets |  " + categories + " | Lots of events available to buy now on Beeyay";
    var noIndex = hasNoIndex(details.description, events, eventsLoading);
    var isMovedPermanently = !!matchPath(pathname, { path: routes.venueD() });
    return (React.createElement(Seo, { title: title, description: description, keywords: details.name + " tickets, buy, sell, tickets, beeyay, concert, sport, theater", canonical: routes.venue(details.slug, details.id), isMovedPermanently: isMovedPermanently, noIndex: noIndex }));
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import debounce from 'lodash/debounce';
import React, { Children, useEffect, useMemo, useState } from 'react';
export var InfiniteScrollLoop = function (_a) {
    var _b = _a.direction, direction = _b === void 0 ? 'vertical' : _b, className = _a.className, scrollRef = _a.scrollRef, childSize = _a.childSize, children = _a.children;
    var _c = __read(useState(0), 2), diff = _c[0], setDiff = _c[1];
    var _d = __read(useState(1), 2), bck = _d[0], setBck = _d[1];
    var _e = __read(useState(1), 2), frd = _e[0], setFrd = _e[1];
    var handleScroll = function () { return handleEndScroll(); };
    var handleEndScroll = useMemo(function () { return debounce(function () {
        if (!scrollRef.current)
            return;
        var property = direction === 'vertical'
            ? 'scrollTop'
            : 'scrollLeft';
        var scroll = scrollRef.current[property];
        if (scroll < diff) {
            setBck(bck + 1);
            scrollRef.current[property] += diff;
        }
        else if (scroll - bck * diff > 0) {
            setFrd(frd + 1);
        }
    }, 200); }, [bck, diff, direction, frd, scrollRef]);
    useEffect(function () {
        var childrenArr = Children.toArray(children);
        if (childrenArr.length) {
            setDiff(childSize * childrenArr.length);
            if (scrollRef.current) {
                if (direction === 'vertical') {
                    scrollRef.current.scrollTop = diff;
                }
                else {
                    scrollRef.current.scrollLeft = diff;
                }
            }
        }
    }, [childSize, children, diff, direction, scrollRef]);
    return (React.createElement("div", { className: className, ref: scrollRef, onScroll: handleScroll },
        Array(bck)
            .fill(null)
            .map(function (_, index) { return (React.createElement(React.Fragment, { key: "bck-" + index }, children)); }),
        children,
        Array(frd)
            .fill(null)
            .map(function (_, index) { return (React.createElement(React.Fragment, { key: "frd-" + index }, children)); })));
};

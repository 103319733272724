var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'redux-act';
export var setDefaultLocalization = createAction('set user localization', function (country) { return ({ country: country }); });
export var selectLocalization = function (s) { return s.localization.userCountry; };
var initialState = {
    userCountry: null,
};
export var localizationReducer = createReducer((_a = {},
    _a[setDefaultLocalization.getType()] = function (state, _a) {
        var country = _a.country;
        return (__assign(__assign({}, state), { userCountry: country }));
    },
    _a), initialState);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Icon } from '@components/icon';
import { slugifyName } from '@logic/helpers/location';
import { t } from '@containers/helpers/tests';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styles from '@containers/DetailsPages/components/eventsTable/details.less';
import cn from 'classnames';
export var LocationSelector = function (_a) {
    var locationOptions = _a.locationOptions, location = _a.location, onLocationChange = _a.onLocationChange;
    return (React.createElement("div", { className: styles.location },
        "Location ",
        React.createElement(Icon, { icon: 'point', width: 16, className: cn(styles.pointer, 'visibleIcon') }),
        React.createElement(Dropdown, __assign({ options: locationOptions, value: slugifyName(location), inline: true, onChange: function (_, data) { return onLocationChange("" + data.value); }, className: styles.dropdown }, t('LocationSelector')))));
};

import { Images } from '@logic/images';
import memoizeOne from 'memoize-one';
export var getVenueImage = function (e) { return e.venueImageUrl + "/" + Images.eventList(e.venueName + "-" + e.venueCity); };
export var getImage = function (e) { return e.imageUrl + "/" + Images.eventList(e.name); };
export var generateHeaderDetails = function (details) {
    var det = details;
    if (!det) {
        return undefined;
    }
    return ({
        description: det.description,
        imageUrl: det.imageUri,
        name: det.name + " Tickets",
    });
};
var getSlugBase = function (props) {
    var slug = props.params.id;
    return parseInt(slug, 10);
};
export var getSlug = memoizeOne(getSlugBase);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { t } from '@containers/helpers/tests';
import styles from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMap.less';
import { VenueMapFilters } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMapFilters';
import { VenueMapNav } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap/venueMapNav';
import { VenueMap } from '@containers/DetailsPages/pages/EventDetails/components/VenueMap';
import { isSmallScreen } from '@containers/components/viewPortInfo/viewPort.utils';
import { useViewportInfo } from '@containers/components/viewPortInfo/useViewportInfo.hook';
export function VenueMapWithFilters(props) {
    var seatingPlan = props.seatingPlan, categories = props.categories, listings = props.listings, transactionSide = props.transactionSide, stateDispatch = props.stateDispatch, isLoadingSeatingPlan = props.isLoadingSeatingPlan, eventId = props.eventId, ticketTypes = props.ticketTypes, availableTicketTypes = props.availableTicketTypes, hoveredCategory = props.hoveredCategory, filteredListings = props.filteredListings, hoveredListing = props.hoveredListing, sections = props.sections, changeHoveredCategory = props.changeHoveredCategory, svgPanAndZoomInstance = props.svgPanAndZoomInstance, handleResetFilters = props.handleResetFilters, onPanAndZoomInstanceChange = props.onPanAndZoomInstanceChange, setSelectedCategories = props.setSelectedCategories, selectedSections = props.selectedSections, setSelectedSections = props.setSelectedSections, foundSections = props.foundSections, handleSvgParsed = props.handleSvgParsed;
    var media = useViewportInfo().media;
    var isLowResolution = isSmallScreen(media);
    if (isLowResolution) {
        return null;
    }
    else {
        return (React.createElement("div", __assign({}, t('VenueMap'), { className: styles.venueMap }),
            React.createElement("div", { className: styles.filtersSectionWrapper },
                React.createElement(VenueMapFilters, { categories: categories, ticketTypes: ticketTypes, availableTicketTypes: availableTicketTypes, stateDispatch: stateDispatch, changeHoveredCategory: changeHoveredCategory, selectedCategories: categories, setSelectedCategories: setSelectedCategories, selectedSections: selectedSections, setSelectedSections: setSelectedSections, transactionSide: transactionSide, listings: listings }),
                React.createElement(VenueMapNav, { svgPanAndZoomInstance: svgPanAndZoomInstance, onResetFilters: handleResetFilters })),
            React.createElement(VenueMap, { eventId: eventId, isLoadingSeatingPlan: isLoadingSeatingPlan, transactionSide: transactionSide, hoveredListing: hoveredListing, listings: listings, filteredListings: filteredListings, sections: sections, categories: categories, seatingPlan: seatingPlan, stateDispatch: stateDispatch, ticketTypes: ticketTypes, hoveredCategory: hoveredCategory, onPanAndZoomInstanceChange: onPanAndZoomInstanceChange, selectedCategories: categories, setSelectedCategories: setSelectedCategories, setSelectedSections: setSelectedSections, selectedSections: selectedSections, foundSections: foundSections, handleSvgParsed: handleSvgParsed })));
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { formatDateWithoutTZ, parseDate } from '@logic/helpers/date';
import cn from 'classnames';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useField, useFormState } from 'react-final-form';
import { useFirstNotNullableValue } from '../useFirstNotNullableValue';
import styles from './dayPicker.less';
var dateFormat = ['eee dd MMM yyyy', 'yyyy-MM-dd'];
export function DayPicker(_a) {
    var classNames = _a.classNames, error = _a.error, disabled = _a.disabled, className = _a.className, rest = __rest(_a, ["classNames", "error", "disabled", "className"]);
    var newClassNames = useMemo(function () {
        var _a;
        return {
            container: cn(styles.container, className, (_a = {}, _a[styles.error] = !!error, _a), classNames ? classNames.container : ''),
            overlay: cn(styles.overlay, classNames ? classNames.overlay : ''),
            overlayWrapper: cn(styles.overlayWrapper, classNames ? classNames.overlayWrapper : ''),
        };
    }, [className, classNames, error]);
    var inputProps = useMemo(function () { return (({ disabled: disabled, readOnly: true })); }, [disabled]);
    return React.createElement(DayPickerInput, __assign({ placeholder: '', format: dateFormat, parseDate: parseDate, formatDate: formatDateWithoutTZ, inputProps: inputProps, classNames: newClassNames }, rest));
}
export function DayPickerField(_a) {
    var name = _a.name, rest = __rest(_a, ["name"]);
    var state = useFormState();
    var initialValue = useFirstNotNullableValue(function () { return get(state.values, name); });
    var input = useField(name, { initialValue: initialValue }).input;
    return (React.createElement(DayPicker, __assign({}, rest, input, { onDayChange: input.onChange, onDayPickerShow: input.onBlur })));
}

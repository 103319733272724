var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Input } from '@components/forms';
import { PasswordInput } from '@components/forms/input';
import { Seo } from '@components/seo';
import { t } from '@containers/helpers/tests';
import { getRedirectToWithReplace } from '@logic/helpers/location';
import { isEmail } from '@logic/helpers/validators';
import { login, loginReset } from '@logic/user';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Header } from 'semantic-ui-react';
import { routes } from 'src/client/routing/routes';
import { ExternalLogin } from '../ExternalLogin/externalLogin';
import { Page } from '../Page';
import styles from './login.less';
import { windowIsDefined } from '@containers/utils';
import { ScrollToTopOnMount } from '../components/scrollToTopOnMount/scrollToTopOnMount';
var getHeader = function (reason) {
    switch (reason) {
        case 'idle':
            return 'You have been logged out due to inacitvity';
        case 'sell':
            return '';
        case 'buy':
            return 'Your tickets have been reserved!';
        default:
            return '';
    }
};
var getSubheader = function (reason) {
    switch (reason) {
        case 'idle':
            return '';
        case 'sell':
            return 'In order to complete your sale, please login to your account. If you don\'t yet have an account you can sign up below.';
        case 'buy':
            return 'In order to complete your purchase, please login to your account. If you don\'t yet have an account you can sign up below.';
        default:
            return '';
    }
};
export var Login = function (_a) {
    var location = _a.location, match = _a.match;
    var dispatch = useDispatch();
    var _b = __read(useSelector(function (gs) { return gs.reservations.reservationState; }), 2), reservationUrl = _b[1];
    var error = useSelector(function (gs) { return gs.user.error; });
    var isPending = useSelector(function (gs) { return gs.user.isPending; });
    var subheader = getSubheader(match.params.reason);
    var _c = __read(useState(''), 2), email = _c[0], setEmail = _c[1];
    var _d = __read(useState(''), 2), password = _d[0], setPassword = _d[1];
    var _e = __read(useState(false), 2), emailInvalid = _e[0], setEmailInvalid = _e[1];
    var _f = __read(useState(false), 2), passwordInvalid = _f[0], setPasswordInvalid = _f[1];
    useEffect(function () {
        dispatch(loginReset());
    }, [dispatch]);
    var handleEmail = function (_, data) {
        setEmail(data.value);
        setEmailInvalid(!isEmail(data.value));
    };
    var handlePassword = function (_, data) {
        setPassword(data.value);
        setPasswordInvalid(!data.value);
    };
    var handleSubmit = function () {
        dispatch(login({
            email: email,
            password: password,
            redirect: reservationUrl !== null && reservationUrl !== void 0 ? reservationUrl : null,
        }));
    };
    return (React.createElement(Page, { search: false, showBeeyayGuarantee: match.params.reason === 'buy', containerSize: 'full' },
        React.createElement(Seo, { title: 'Welcome back! Login for secure access to your Beeyay account.', description: 'Access your tickets, check on the status of your order, keep tabs on the market, change your profile settings and much more.' }),
        React.createElement(ScrollToTopOnMount, null),
        React.createElement("div", { className: styles.container },
            React.createElement("div", __assign({ className: styles.login }, t('loginPage')),
                React.createElement(Header, { as: "h4", className: styles.header, textAlign: "center" }, getHeader(match.params.reason)),
                subheader && React.createElement(Header, { as: "h5", className: styles.subheader }, subheader),
                React.createElement(Form, { loading: isPending, error: emailInvalid || passwordInvalid || !!error },
                    React.createElement(Input, __assign({ type: "email", large: true, error: emailInvalid, fluid: true, onChange: handleEmail, placeholder: "user@email.com", value: email }, t('user'))),
                    React.createElement(PasswordInput, __assign({ large: true, error: passwordInvalid, fluid: true, onChange: handlePassword, placeholder: "***********", value: password }, t('password'))),
                    React.createElement("div", __assign({ className: styles.errorMessage }, t('error')), error),
                    React.createElement(Button, __assign({ className: styles.button, ternary: true, disabled: emailInvalid || passwordInvalid || !email.length || !password.length, fill: true, onClick: handleSubmit }, t('login')), "Login")),
                React.createElement("div", { className: styles.footer },
                    React.createElement(Link, { to: getRedirectToWithReplace({
                            redirect: routes.resetPassword(),
                            location: location,
                        }), replace: true },
                        React.createElement("span", __assign({}, t('toReset')), "Forgot password?"))),
                React.createElement("div", { className: styles.footer },
                    "Don't have an account? ",
                    React.createElement(Link, { to: getRedirectToWithReplace({
                            redirect: routes.register(match.params.reason),
                            location: location,
                        }), replace: true },
                        React.createElement("span", __assign({}, t('toRegister')), "Sign Up"))),
                windowIsDefined() && window.EXTERNAL_AUTH_ENABLED
                    ? (React.createElement(React.Fragment, null,
                        React.createElement(Header, { as: "h4", className: styles.header, textAlign: "center" }, "OR"),
                        React.createElement(ExternalLogin, null)))
                    : null))));
};
export default Login;

import { useIntervalRefresh } from '@components/forms';
import { days, hours, minutes } from '@logic/helpers/date';
import { differenceInMilliseconds } from 'date-fns';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './onSaleDate.less';
/*
 * Countdown timer shows "On sale now!" if event is on sale,
 * whole number of minutes (rounded up to nearest minute) if on sale time is between 0-60 minutes from now,
 * whole number of hours (rounded up to nearest hour) if on sale time is between 1-24 hours from now,
 * whole number of days (rounded up to nearest day) if on sale time is >= 1 day from now
 */
export var OnSaleDate = function (_a) {
    var date = _a.date;
    var dt = useIntervalRefresh(1000, function () { return differenceInMilliseconds(date, new Date()) || 0; });
    var text = (function () {
        var plural = function (val, base) { return (val === 1 ? val + " " + base : val + " " + base + "s"); };
        if (dt >= 1 * days) {
            return plural(Math.round(dt / days), 'day');
        }
        if (dt >= 1 * hours) {
            return plural(Math.round(dt / hours), 'hour');
        }
        if (dt > 0) {
            return plural(Math.ceil(dt / minutes), 'minute');
        }
        return 'On sale now!';
    })();
    return React.createElement("span", { className: styles.onSaleDate },
        React.createElement(Icon, { name: 'clock outline' }),
        text);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { routes } from 'src/client/routing/routes';
import { tryMapError } from './errorCodeMapper';
import { countryToCode, isCountry, isCountryCode } from './helpers/enumerators';
import request from './helpers/request';
import { createAsyncAction, getType } from 'typesafe-actions';
export var actions = {
    createCard: createAsyncAction('create card', 'create card success', 'create card failure')(),
    updateCard: createAsyncAction('update card', 'update card success', 'update card failure')(),
    deleteCard: createAsyncAction('delete card', 'delete card success', 'delete card failure')(),
    setAsDefault: createAsyncAction('set as default card', 'set as default card success', 'set as default card failure')(),
    loadCards: createAsyncAction('load cards', 'load cards success', 'load cards failure')(),
    generateSetupIntent: createAsyncAction('generate client secret for setup intent', 'generate client secret for setup intent success', 'generate client secret for setup intent failure')()
};
export var initialState = {
    isPending: false,
    data: null,
    errors: [],
    defaultCard: null,
    clientSecret: undefined
};
export var cardsReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case getType(actions.createCard.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.createCard.success): return __assign(__assign({}, state), { isPending: false, data: __spreadArray(__spreadArray([], __read(((_a = state.data) !== null && _a !== void 0 ? _a : []))), [action.payload]) });
        case getType(actions.createCard.failure): return __assign(__assign({}, state), { isPending: false });
        case getType(actions.updateCard.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.updateCard.success): return __assign(__assign({}, state), { isPending: false, data: (state.data || []).map(function (existingCard) { return existingCard.id === action.payload.id ? action.payload : existingCard; }) });
        case getType(actions.updateCard.failure): return __assign(__assign({}, state), { isPending: false });
        case getType(actions.deleteCard.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.deleteCard.success): return __assign(__assign({}, state), { isPending: false, data: (state.data || []).filter(function (existingCard) { return existingCard.id !== action.payload; }) });
        case getType(actions.deleteCard.failure): return __assign(__assign({}, state), { isPending: false });
        case getType(actions.setAsDefault.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.setAsDefault.success): return __assign(__assign({}, state), { isPending: false, defaultCard: action.payload });
        case getType(actions.setAsDefault.failure): return __assign(__assign({}, state), { isPending: false });
        case getType(actions.loadCards.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.loadCards.success): return __assign(__assign({}, state), { isPending: false, data: action.payload.cards, errors: [], defaultCard: action.payload.defaultCard });
        case getType(actions.loadCards.failure): return __assign(__assign({}, state), { isPending: false, data: [], errors: action.payload });
        case getType(actions.generateSetupIntent.request): return __assign(__assign({}, state), { isPending: true });
        case getType(actions.generateSetupIntent.success): return __assign(__assign({}, state), { isPending: false, clientSecret: action.payload.clientSecret });
        case getType(actions.generateSetupIntent.failure): return __assign(__assign({}, state), { isPending: false, data: [], errors: action.payload });
        default: return state;
    }
};
var rawCardToCard = function (raw) {
    return {
        id: raw.id,
        name: raw.name,
        address: {
            fullName: raw.name,
            line1: raw.address_line1,
            line2: raw.address_line2,
            city: raw.address_city,
            country: isCountry(raw.address_country)
                ? countryToCode[raw.address_country]
                : isCountryCode(raw.address_country)
                    ? raw.address_country
                    : '',
            postalCode: raw.address_zip,
        },
        currency: raw.currency,
        customerId: raw.customerId,
        expirationMonth: raw.exp_month,
        expirationYear: raw.exp_year,
        last4: raw.last4,
        brand: raw.brand.toLowerCase(),
    };
};
export var cardsApi = {
    loadCards: function () { return request('/api/paymentmethods/cards'); },
    getCard: function (id) { return request("/api/paymentmethods/card?id=" + id); },
    createCard: function (token) { return request('/api/paymentmethods/createcard', {
        token: token,
    }); },
    updateCard: function (card) { return request('/api/paymentmethods/updatecard', __assign(__assign({}, card), { addressState: '' })); },
    deleteCard: function (id) { return request('/api/paymentmethods/deletecard', {
        id: id,
    }); },
    setAsDefalt: function (id) { return request('/api/paymentmethods/setasdefault', {
        id: id,
    }); },
    generateSetupIntent: function () { return request('/api/paymentmethods/generatesetupintent', {}); },
};
export function onLoadCards(_) {
    var cardsData, e_1, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(cardsApi.loadCards)];
            case 1:
                cardsData = _b.sent();
                return [4 /*yield*/, put(actions.loadCards.success({ cards: cardsData.list.map(rawCardToCard), defaultCard: cardsData.defaultCard }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _b.sent();
                errorMsg = (_a = tryMapError(e_1)) !== null && _a !== void 0 ? _a : 'Cannot load cards.';
                return [4 /*yield*/, put(actions.loadCards.failure([errorMsg]))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onCreateCard(_a) {
    var stripe, element, card, asDefault, redirect, clientSecret, _b, setupIntent, error, pmId, cardInfo, e_2, errorMsg;
    var _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                stripe = payload.stripe, element = payload.element, card = payload.card, asDefault = payload.asDefault, redirect = payload.redirect, clientSecret = payload.clientSecret;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 10, , 13]);
                if (!clientSecret) {
                    throw new Error('Client secret not found');
                }
                return [4 /*yield*/, call(stripe.confirmCardSetup, clientSecret, {
                        payment_method: {
                            card: element,
                            billing_details: {
                                name: card.name,
                                address: {
                                    line1: card.addressLine1,
                                    line2: card.addressLine2,
                                    city: card.addressCity,
                                    country: card.addressCountry,
                                    postal_code: card.addressZip,
                                    state: card.addressState,
                                }
                            },
                        }
                    })];
            case 2:
                _b = _d.sent(), setupIntent = _b.setupIntent, error = _b.error;
                if (!setupIntent || !setupIntent.payment_method) {
                    throw error;
                }
                pmId = setupIntent.payment_method;
                return [4 /*yield*/, call(cardsApi.getCard, pmId)];
            case 3:
                cardInfo = _d.sent();
                return [4 /*yield*/, put(actions.createCard.success(rawCardToCard(cardInfo)))];
            case 4:
                _d.sent();
                if (!asDefault) return [3 /*break*/, 7];
                return [4 /*yield*/, call(cardsApi.setAsDefalt, pmId)];
            case 5:
                _d.sent();
                return [4 /*yield*/, put(actions.setAsDefault.success(pmId))];
            case 6:
                _d.sent();
                _d.label = 7;
            case 7:
                if (!redirect) return [3 /*break*/, 9];
                return [4 /*yield*/, put(push(routes.userCards()))];
            case 8:
                _d.sent();
                _d.label = 9;
            case 9: return [3 /*break*/, 13];
            case 10:
                e_2 = _d.sent();
                errorMsg = (_c = tryMapError(e_2)) !== null && _c !== void 0 ? _c : 'We have been unable to add this payment method. Please check that the details you have entered are correct and try again or alternatively please try another card.';
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 11:
                _d.sent();
                return [4 /*yield*/, put(actions.createCard.failure([errorMsg]))];
            case 12:
                _d.sent();
                return [2 /*return*/, false];
            case 13: return [2 /*return*/, true];
        }
    });
}
export function onUpdateCard(_a) {
    var card, updatedCard, e_3, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                card = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call(cardsApi.updateCard, card)];
            case 2:
                updatedCard = _c.sent();
                return [4 /*yield*/, put(actions.updateCard.success(rawCardToCard(updatedCard)))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(push(routes.userCards()))];
            case 4:
                _c.sent();
                return [3 /*break*/, 8];
            case 5:
                e_3 = _c.sent();
                errorMsg = (_b = tryMapError(e_3)) !== null && _b !== void 0 ? _b : 'We have been unable to update this payment method. Please try again later.';
                return [4 /*yield*/, put(actions.updateCard.failure([errorMsg]))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onDeleteCard(_a) {
    var cardId, e_4, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                cardId = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call(cardsApi.deleteCard, cardId)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(actions.deleteCard.success(cardId))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(actions.loadCards.request())];
            case 4:
                _c.sent();
                return [3 /*break*/, 8];
            case 5:
                e_4 = _c.sent();
                errorMsg = (_b = tryMapError(e_4)) !== null && _b !== void 0 ? _b : 'Cannot delete card';
                return [4 /*yield*/, put(actions.deleteCard.failure([errorMsg]))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onSetAsDefaultCard(_a) {
    var cardId, e_5, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                cardId = payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 7]);
                return [4 /*yield*/, call(cardsApi.setAsDefalt, cardId)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(actions.setAsDefault.success(cardId))];
            case 3:
                _c.sent();
                return [3 /*break*/, 7];
            case 4:
                e_5 = _c.sent();
                errorMsg = (_b = tryMapError(e_5)) !== null && _b !== void 0 ? _b : 'Cannot set card as default';
                return [4 /*yield*/, put(actions.setAsDefault.failure([errorMsg]))];
            case 5:
                _c.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onGenerateSetupIntent() {
    var intent, e_6, errorMsg;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(cardsApi.generateSetupIntent)];
            case 1:
                intent = _b.sent();
                return [4 /*yield*/, put(actions.generateSetupIntent.success(intent))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_6 = _b.sent();
                errorMsg = (_a = tryMapError(e_6)) !== null && _a !== void 0 ? _a : 'We encounter problems with Stripe';
                return [4 /*yield*/, put(actions.generateSetupIntent.failure([errorMsg]))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, errorMsg)];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function cardsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadCards.request), onLoadCards)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.createCard.request), onCreateCard)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.updateCard.request), onUpdateCard)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.deleteCard.request), onDeleteCard)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.setAsDefault.request), onSetAsDefaultCard)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(getType(actions.generateSetupIntent.request), onGenerateSetupIntent)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

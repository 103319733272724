var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { eventsApi, rawEventSearchToEventSearch } from './events';
import sortBy from 'lodash/sortBy';
export var loadEventsForNewListing = createAction('load events for new listing', function (search, limit, orderBy, orderByDesc) { return ({ search: search, limit: limit, orderBy: orderBy, orderByDesc: orderByDesc }); });
export var loadEventsForNewListingSuccess = createAction('load events for new listing success', function (events, count) { return ({ events: events, count: count }); });
export var loadEventsForNewListingFailure = createAction('load events for new listing failure', function (error) { return ({ error: error }); });
var initialState = {
    events: [],
    count: 0,
    pending: false,
};
export var newListingReducer = createReducer((_a = {},
    _a[loadEventsForNewListing.getType()] = function (state) { return (__assign(__assign({}, state), { pending: true })); },
    _a[loadEventsForNewListingSuccess.getType()] = function (state, _a) {
        var events = _a.events, count = _a.count;
        return (__assign(__assign({}, state), { events: sortBy(events, function (e) { return e.eventDate; }), count: count, pending: false }));
    },
    _a[loadEventsForNewListingFailure.getType()] = function (state) { return (__assign(__assign({}, state), { events: [], pending: false })); },
    _a), initialState);
export function onLoadEventsForNewListing(_a) {
    var searchPayload, _b, items, count, e_1;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                searchPayload = {
                    searchPhrase: payload.search,
                    limit: payload.limit,
                    orderBy: payload.orderBy,
                    orderByDesc: payload.orderByDesc
                };
                return [4 /*yield*/, delay(200)];
            case 1:
                _c.sent();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(eventsApi.search, searchPayload)];
            case 3:
                _b = _c.sent(), items = _b.items, count = _b.count;
                return [4 /*yield*/, put(loadEventsForNewListingSuccess(items.map(rawEventSearchToEventSearch), count))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _c.sent();
                return [4 /*yield*/, put(loadEventsForNewListingFailure(e_1))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function newListingSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loadEventsForNewListing.getType(), onLoadEventsForNewListing)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

import { prepareJsonLdData } from '@logic/helpers/jsonLdHelper';
import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHostname } from './useStaticContext';
export var JsonLd = function (_a) {
    var events = _a.events, breadcrumbJsonLd = _a.breadcrumbJsonLd, faqs = _a.faqs;
    var location = useLocation();
    var hostname = useHostname();
    var userSettings = useSelector(function (gs) { var _a; return (_a = gs.userSettings.userSettings) !== null && _a !== void 0 ? _a : gs.userSettings.globalSettings; });
    var locationPathName = location.pathname.replace(/^\/$/, '');
    var additionalOptions = { breadcrumbs: breadcrumbJsonLd, faqs: faqs };
    return userSettings && React.createElement(Helmet, null,
        React.createElement("script", { type: 'application/ld+json' }, "\n            {\n              \"@context\":\"http://schema.org\",\n              \"@graph\": " + prepareJsonLdData(events, userSettings, { locationPathName: locationPathName, hostname: hostname }, additionalOptions) + "\n            }\n          "));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setEventQuantityFilter } from '@logic/helpers/localStorage';
import { ticketTypeSimplifiedMapping } from '@logic/listings';
import { seatingPlanToSections, stringifyNormalizedSeatingInfo, } from '@logic/seatingInfo';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import { useMemo, useReducer } from 'react';
import { createAction, getType } from 'typesafe-actions';
export var initialTicketTypes = {
    'Any': false,
    'Mobile': false,
    'Paper': false,
    'e-Ticket': false,
};
var deactivateAll = function (elements) {
    return elements.map(function (element) { return !element.isActive
        ? element
        : __assign(__assign({}, element), { isActive: false }); });
};
export var actions = {
    initialize: createAction('initialize')(),
    revertFilter: createAction('bring back filter')(),
    toggleTicketType: createAction('toggle ticket type')(),
    deactivateAll: createAction('deactivate all sections & categories')(),
    changeSort: createAction('change sort')(),
    changeFilter: createAction('change filter')(),
    toggleCategoryTicketTypeSection: createAction('change filter category, ticket type, section')()
};
var sorter = {
    price: [function (a) { return a.price.value; }, function (a) { return a.creationDate; }],
    quantity: [function (a) { return a.maxTreadableQuantities; }, function (a) { return a.creationDate; }],
    section: [function (a) { return a.seatingInfo.map(stringifyNormalizedSeatingInfo).join(' ').toLowerCase(); }, function (a) { return a.creationDate; }],
};
var updateListings = function (state, side) {
    var matchSection = function (_a) {
        var seatingInfo = _a.seatingInfo;
        return state.sections.some(function (s) { return seatingInfo.some(function (nsi) {
            return s.category === nsi.category
                && (s.section === nsi.section || (side === 'sell' && nsi.section === ''));
        })
            && s.isActive; });
    };
    var matchCategory = function (_a) {
        var seatingInfo = _a.seatingInfo;
        return state.categories.some(function (c) { return seatingInfo.some(function (nsi) { return c.category === nsi.category; }) && c.isActive; });
    };
    var event = state.event;
    if (!event) {
        return state;
    }
    var isPreSaleTime = event.onSaleDate.getTime() > Date.now();
    var filterByPreSaleTime = isPreSaleTime && side === 'buy' ? state.allListings.filter(function (l) { return l.ownedByMe; }) : state.allListings;
    var anyFiltering = state.sections.some(function (s) { return s.isActive; }) || state.categories.some(function (c) { return c.isActive; });
    var filteredBySections = !anyFiltering
        ? filterByPreSaleTime
        : filterByPreSaleTime.filter(function (l) { return matchCategory(l) || matchSection(l) || l.seatingInfo.some(function (nsi) { return nsi.matchAny; }); });
    var quantityFilterValue = state.quantity;
    var filterByQuantity = quantityFilterValue === 'any'
        ? filteredBySections
        : filteredBySections.filter(function (l) { return l.isReservation || l.treadableQuantities.includes(quantityFilterValue); });
    var filterByTicketType = Object.values(state.ticketTypes).some(function (p) { return p; })
        ? filterByQuantity.filter(function (l) { return state.ticketTypes[ticketTypeSimplifiedMapping[l.ticketType]] || l.ticketType === 'any'; })
        : filterByQuantity;
    var listings = orderBy(filterByTicketType, sorter[state.sort], [state.sortReverse ? 'desc' : 'asc', 'asc']);
    return __assign(__assign({}, state), { listings: listings });
};
var EventListingToEventListingToDisplay = function (eventListing) {
    var reservations = eventListing.currentReservations
        .filter(function (_a) {
        var expirationTime = _a.expirationTime;
        return expirationTime.getTime() + 10000 > Date.now();
    })
        .map(function (_a, idx) {
        var quantity = _a.quantity, expirationTime = _a.expirationTime;
        return (__assign(__assign({}, eventListing), { id: eventListing.id + "_" + idx, treadableQuantities: [quantity], maxTreadableQuantities: quantity, isReservation: true, expirationTime: new Date(expirationTime.getTime() + 10000) }));
    });
    return __spreadArray(__spreadArray([], __read((eventListing.treadableQuantities.length ? [eventListing] : []))), __read(reservations));
};
function prefilterListings(allListings, event, side) {
    var seatingPlan = event.seatingPlan;
    if (!seatingPlan) {
        return allListings.flatMap(EventListingToEventListingToDisplay);
    }
    var allSections = seatingPlanToSections(seatingPlan, true) || [];
    return side === 'sell'
        ? allListings
            .filter(function (p) { return p.seatingInfo.some(function (si) { return si.matchAny || allSections.find(function (t) { return t.category === si.category && t.section === si.section; }); }); })
            .flatMap(EventListingToEventListingToDisplay)
        : allListings.flatMap(EventListingToEventListingToDisplay);
}
function initialize(state, event) {
    var seatingPlan = event.seatingPlan;
    if (!seatingPlan) {
        return {};
    }
    var theSameEvent = !!state.event && state.event.id === event.id;
    var prevActiveSections = keyBy(state.sections
        .filter(function (s) { return s.isActive; })
        .map(function (_a) {
        var category = _a.category, section = _a.section;
        return category + "_" + section;
    }));
    var prevActiveCategories = keyBy(state.categories
        .filter(function (s) { return s.isActive; })
        .map(function (_a) {
        var category = _a.category;
        return category;
    }));
    var sections = seatingPlan.structure.categories
        .map(function (c) {
        if (!c.sections || !c.sections.length) {
            return [{
                    category: c.name,
                    section: '',
                    isActive: theSameEvent && !!prevActiveSections[c.name + "_"],
                }];
        }
        return c.sections.map(function (s) { return ({
            category: c.name,
            section: s,
            isActive: theSameEvent && !!prevActiveSections[c.name + "_" + s],
        }); });
    }).reduce(function (a, b) { return __spreadArray(__spreadArray([], __read(a)), __read(b)); }, []);
    var categories = seatingPlan.structure.categories.map(function (c) { return ({
        category: c.name,
        isActive: theSameEvent && !!prevActiveCategories[c.name],
    }); });
    return { sections: sections, categories: categories };
}
export var eventTabReducer = function (side) { return function (state, action) {
    var _a;
    switch (action.type) {
        case getType(actions.initialize): return updateListings(__assign(__assign(__assign({}, state), initialize(state, action.payload.event)), { event: action.payload.event, listings: prefilterListings(action.payload.allListings, action.payload.event, side), allListings: prefilterListings(action.payload.allListings, action.payload.event, side) }), side);
        case getType(actions.revertFilter):
            return updateListings(__assign(__assign({}, state), action.payload), side);
        case getType(actions.deactivateAll): return updateListings(__assign(__assign({}, state), { sections: deactivateAll(state.sections), categories: deactivateAll(state.categories), ticketTypes: initialTicketTypes }), side);
        case getType(actions.changeSort): {
            if (action.payload === state.sort) {
                return updateListings(__assign(__assign({}, state), { sortReverse: !state.sortReverse }), side);
            }
            return updateListings(__assign(__assign({}, state), { sort: action.payload, sortReverse: false }), side);
        }
        case getType(actions.changeFilter): {
            if (state.event) {
                setEventQuantityFilter(state.event, side, action.payload);
            }
            return updateListings(__assign(__assign({}, state), { quantity: action.payload }), side);
        }
        case getType(actions.toggleTicketType): return updateListings(__assign(__assign({}, state), { ticketTypes: __assign(__assign({}, state.ticketTypes), (_a = {}, _a[action.payload] = !state.ticketTypes[action.payload], _a)) }), side);
        case getType(actions.toggleCategoryTicketTypeSection):
            return updateListings(__assign(__assign({}, state), { ticketTypes: action.payload.ticketTypes, categories: action.payload.categories, sections: action.payload.sections }), side);
    }
}; };
export function useEventTabState(transactionSide, init) {
    var reducer = useMemo(function () {
        return eventTabReducer(transactionSide);
    }, [transactionSide]);
    return useReducer(reducer, init);
}

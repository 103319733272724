import { ConnectedRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './app';
import { initializeGTM } from './gtm';
import registerServiceWorker from './registerServiceWorker';
import { configureStore } from './store';
import { saga } from 'src/client/sagas';
import { GoogleOAuthProvider } from '@react-oauth/google';
var history = createHistory();
var _a = configureStore(window.REDUX_STATE, history), store = _a.store, runSagaMiddleware = _a.runSagaMiddleware;
runSagaMiddleware(saga);
initializeGTM(history);
export var render = function () {
    hydrate(React.createElement(Provider, { store: store },
        React.createElement(ConnectedRouter, { history: history },
            React.createElement(GoogleOAuthProvider, { clientId: window.GOOGLE_CLIENT_ID },
                React.createElement(App, null)))), document.getElementById('root'));
    registerServiceWorker();
};

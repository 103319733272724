var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useCallback, useMemo, useState } from 'react';
var createPreview = function (file, idx) { return ({
    name: file.name,
    id: "" + idx,
    type: 'customer',
}); };
export function useFiles(initialFiles) {
    if (initialFiles === void 0) { initialFiles = []; }
    var _a = __read(useState(initialFiles), 2), files = _a[0], changeFiles = _a[1];
    var handleDrop = useCallback(function (newFiles) { return changeFiles(__spreadArray(__spreadArray([], __read(files)), __read(newFiles))); }, [files, changeFiles]);
    var previewFiles = useMemo(function () { return files.map(createPreview); }, [files]);
    var handleDelete = useCallback(function (id) { changeFiles(files.filter(function (_, idx) { return "" + idx !== id; })); }, [files, changeFiles]);
    var clearAll = useCallback(function () { return changeFiles([]); }, [changeFiles]);
    return useMemo(function () { return ({
        files: files,
        previewFiles: previewFiles,
        handleDrop: handleDrop,
        handleDelete: handleDelete,
        clearAll: clearAll,
    }); }, [
        files,
        previewFiles,
        handleDrop,
        handleDelete,
        clearAll,
    ]);
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useStateWithCallback } from '@components/forms';
import camelCase from 'lodash/camelCase';
import intersection from 'lodash/intersection';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import qs from 'qs';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getQueryString } from 'src/client/routing/routes';
import { ControlledGenericTable } from './controlledGenericTable';
import { useDispatch } from 'react-redux';
export var emptyTableState = {
    search: '',
    filters: {},
    sortColumns: null
};
var getTableStateFromUrl = function (locationSearch, storeFilterInLocation, defaultFilter, defaultSortColumn, defaultSortReverse, filters) {
    if (!storeFilterInLocation) {
        return emptyTableState;
    }
    var _a = qs.parse(locationSearch), useDefaults = _a.default, searchParams = __rest(_a, ["default"]);
    var parsedSortColumns = Array.isArray(searchParams === null || searchParams === void 0 ? void 0 : searchParams.sortColumns)
        ? (searchParams === null || searchParams === void 0 ? void 0 : searchParams.sortColumns).map(function (sc) { return ({
            sortColumn: sc === null || sc === void 0 ? void 0 : sc.sortColumn,
            sortReverse: (sc === null || sc === void 0 ? void 0 : sc.sortReverse) === 'true',
            primary: (sc === null || sc === void 0 ? void 0 : sc.primary) === 'true'
        }); })
        : undefined;
    if (useDefaults && defaultFilter) {
        return {
            search: searchParams.search || '',
            filters: defaultFilter,
            sortColumns: parsedSortColumns || (defaultSortColumn && [{ sortColumn: defaultSortColumn, sortReverse: false, primary: true }]) || null,
        };
    }
    var newFilters = Object.entries(searchParams)
        .map(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        var filter = filters.find(function (f) { return camelCase(f.title) === k; });
        if (filter) {
            if (Array.isArray(v)) {
                return [filter.title, intersection(v, filter.values)];
            }
            return [filter.title, (filter.values.includes(v) ? [v] : [])];
        }
        return null;
    })
        .filter(function (x) { return x !== null; })
        .reduce(function (agg, _a) {
        var _b;
        var _c = __read(_a, 2), k = _c[0], v = _c[1];
        return (__assign(__assign({}, agg), (_b = {}, _b[k] = v, _b)));
    }, {});
    var newState = {
        search: searchParams.search || '',
        filters: newFilters,
        sortColumns: parsedSortColumns || null
    };
    return newState;
};
export function GenericTable(props) {
    var location = useLocation();
    var history = useHistory();
    var dispatch = useDispatch();
    var filters = props.filters, storeFilterInLocation = props.storeFilterInLocation, defaultFilter = props.defaultFilter, defaultSortColumn = props.defaultSortColumn, defaultSortReverse = props.defaultSortReverse, handleUpdateTableStateFromProps = props.onUpdateTableState, updateFiltersAction = props.updateFiltersAction;
    var updateFilterQuery = function (newState) {
        var rawQueryParams = __assign(__assign({}, mapKeys(newState.filters, function (_, key) { return camelCase(key); })), { search: newState.search, sortColumns: newState.sortColumns });
        var oldParams = omit(qs.parse(getQueryString(location)), __spreadArray(__spreadArray(__spreadArray([], __read(Object.keys(newState))), __read(filters.map(function (f) { return camelCase(f.title); }))), [
            'default',
        ]));
        var queryParams = pickBy(rawQueryParams, function (v) { return (Array.isArray(v) && v.length) || (!Array.isArray(v) && !!v); });
        var query = qs.stringify(__assign(__assign({}, oldParams), queryParams));
        if (storeFilterInLocation && getQueryString(location) !== query) {
            history.replace(location.pathname + "?" + query);
        }
    };
    var _a = __read(useStateWithCallback(getTableStateFromUrl(getQueryString(location), storeFilterInLocation, defaultFilter, defaultSortColumn, defaultSortReverse, filters), updateFilterQuery), 2), state = _a[0], changeState = _a[1];
    useEffect(function () {
        updateFiltersAction && dispatch(updateFiltersAction(state));
    }, [dispatch, state, updateFiltersAction]);
    var handleUpdateTableState = useCallback(function (tableState) {
        changeState(tableState);
        handleUpdateTableStateFromProps === null || handleUpdateTableStateFromProps === void 0 ? void 0 : handleUpdateTableStateFromProps(tableState);
    }, [changeState, handleUpdateTableStateFromProps]);
    return React.createElement(ControlledGenericTable, __assign({}, props, { tableState: state, onUpdateTableState: handleUpdateTableState }));
}
